import {
  call, put, takeLatest, delay,
} from 'redux-saga/effects';

import { smsTree as api } from '../api';
import {
  mapFormValuesToSmsTree,
  mapSmsTreeToFormValues,
} from '../mappers/smsTreeMappers';
import { smsTree as smsTreeActions, snackbar as snackbarActions } from '../redux/actions';

function* createSmsTree({ payload: { values } }) {
  try {
    yield put(smsTreeActions.createSmsTreeRoutine.request());
    const [transformedValues] = mapFormValuesToSmsTree(values);

    const { id, widgets: [{ settings: { conditions } }] } = transformedValues;

    const smsTree = yield call(api.postSmsTree, id, JSON.stringify({ items: conditions }));

    yield put(smsTreeActions.createSmsTreeRoutine.success(smsTree));
  } catch (error) {
    yield put(smsTreeActions.createSmsTreeRoutine.failure(error));
  } finally {
    yield put(smsTreeActions.createSmsTreeRoutine.fulfill());
  }
}

const STATUSES = {
  active: 1,
  onHold: 2,
  archived: 3,
};

const INVERT_STATUSES = {
  1: 'active',
  2: 'onHold',
  3: 'archived',
};

function* activateSmsTree({ payload: { id, pageView } }) {
  try {
    yield call(api.changeStatusSmsTree, id, STATUSES.active);
    yield put(
      smsTreeActions.fetchSmsTreeList({ page: 1, perPage: 10, status: pageView }),
    );
  } catch (error) {
    const message = error.request && error.request.status === 403 ? 'You cannot set Active status!' : 'Change status error occurred!';
    yield put(
      snackbarActions.enqueueSnackbar({
        message,
        options: { variant: 'error' },
      }),
    );
  }
}

function* holdSmsTree({ payload: { id, pageView } }) {
  try {
    yield call(api.changeStatusSmsTree, id, STATUSES.onHold);
    yield put(
      smsTreeActions.fetchSmsTreeList({ page: 1, perPage: 10, status: pageView }),
    );
  } catch (error) {
    yield put(
      snackbarActions.enqueueSnackbar({
        message: 'Hold sms error occurred!',
        options: { variant: 'error' },
      }),
    );
  }
}

function* fetchSmsTree({ payload: smsTreeId }) {
  try {
    const smsTree = yield call(api.fetchSmsTree, smsTreeId);
    yield put(smsTreeActions.putSmsTree(mapSmsTreeToFormValues(smsTree)));
  } catch (error) {
    yield put(
      snackbarActions.enqueueSnackbar({
        message: 'Fetch sms error occurred!',
        options: { variant: 'error' },
      }),
    );
  }
}

const mapStatus = (list) => list.map((item) => ({ ...item, status: INVERT_STATUSES[item.status] }));

function* fetchSmsTreeList({
  payload: {
    page, perPage, status, search,
  },
}) {
  try {
    const [list, total] = yield call(api.fetchSmsTreeList, {
      page,
      perPage,
      status: STATUSES[status],
      search,
    });

    yield put(smsTreeActions.putSmsTreeList({ list: mapStatus(list), total }));
  } catch (error) {
    yield put(
      snackbarActions.enqueueSnackbar({
        message: 'Fetch sms list error occurred!',
        options: { variant: 'error' },
      }),
    );
  }
}


function* validateCampaignName({
  payload: {
    name, id, resolve, reject,
  },
}) {
  try {
    if (name) {
      yield delay(500);

      const result = yield call(api.validateCampaignName, name, id);
      if (result) {
        resolve();
      } else {
        reject({ campaign_name: 'This name is already used' });
      }
    }
  } catch (error) {
    reject();
  }
}

export default function* watcher() {
  yield takeLatest(smsTreeActions.createSmsTreeRoutine.TRIGGER, createSmsTree);
  yield takeLatest(smsTreeActions.activateSmsTree.toString(), activateSmsTree);
  yield takeLatest(smsTreeActions.holdSmsTree.toString(), holdSmsTree);
  yield takeLatest(smsTreeActions.fetchSmsTree.toString(), fetchSmsTree);
  yield takeLatest(
    smsTreeActions.fetchSmsTreeList.toString(),
    fetchSmsTreeList,
  );
  yield takeLatest(
    smsTreeActions.validateCampaignName.toString(),
    validateCampaignName,
  );
}
