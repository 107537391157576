export const fromPx = (string = '0px') => Number(string.slice(0, -2));

export const fromDeg = (string = '0px') => Number(string.slice(0, -3));

export const fromPercentage = (string = '0%') => Number(string.slice(0, -1));

/**
 * Convert milliseconds string to number.
 *
 * @param {string} string
 * @returns {number} number
 */
export const fromMs = (string = '0ms') => parseInt(string);

export const formatNumber = (number, precision = 0) => new Intl.NumberFormat('fr-FR', {
  minimumFractionDigits: precision,
  maximumFractionDigits: precision,
}).format(number).replace(',','.')
