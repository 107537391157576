import set from 'lodash/set';
import { call, put, takeLatest, delay, cancel } from 'redux-saga/effects';
import {
  change,
  startAsyncValidation,
  stopAsyncValidation,
  touch
} from 'redux-form';
import {campaignOffer as campaignOfferActions, snackbar as snackbarActions} from '../redux/actions';
import { campaignOffer as api } from '../api';
import { fileToB64 } from '../help';

import {
  mapFormValuesToCampaignOffer,
  mapCampaignOfferToFormValues
} from '../mappers/campaignOfferMappers';

function* createCampaignOffer({ payload: { values } }) {
  try {
    yield put(campaignOfferActions.createCampaignOfferRoutine.request());

    const [transformedValues, file, filename] = mapFormValuesToCampaignOffer(values);
    const { id } = transformedValues;
    const data = new FormData();

    data.append('form', JSON.stringify(transformedValues));
    if (file) {
      data.append('promocodes', file, filename);
    }

    const campaignOffer = yield call(api.postCampaignOffer, id, data);

    yield put(campaignOfferActions.createCampaignOfferRoutine.success(campaignOffer));
  } catch (error) {
    yield put(campaignOfferActions.createCampaignOfferRoutine.failure(error));
  } finally {
    yield put(campaignOfferActions.createCampaignOfferRoutine.fulfill());
  }
}

const STATUSES = {
  active: 1,
  onHold: 2,
  archived: 3
};

const INVERT_STATUSES = {
  1: 'active',
  2: 'onHold',
  3: 'archived'
};

function* activateCampaignOffer({ payload: { id, pageView } }) {
  try {
    yield call(api.changeStatusCampaignOffer, id, STATUSES.active);
    yield put(
      campaignOfferActions.fetchCampaignOfferList({ page: 1, perPage: 10, status: pageView })
    );
  } catch (error) {
    const message = error.request && error.request.status === 403 ? 'You cannot set Active status!' : 'Change status error occurred!'
    yield put(
        snackbarActions.enqueueSnackbar({
          message,
          options: { variant: 'error' }
        })
    );
  }
}

function* holdCampaignOffer({ payload: { id, pageView } }) {
  try {
    yield call(api.changeStatusCampaignOffer, id, STATUSES.onHold);
    yield put(
      campaignOfferActions.fetchCampaignOfferList({ page: 1, perPage: 10, status: pageView })
    );
  } catch (error) {}
}

function* fetchCampaignOffer({ payload: campaignOfferId }) {
  try {
    const campaignOffer = yield call(api.fetchCampaignOffer, campaignOfferId);

    const offer = mapCampaignOfferToFormValues(campaignOffer)
    yield put(campaignOfferActions.putCampaignOffer(offer));
  } catch (error) {}
}

const mapStatus = list =>
  list.map(item => ({ ...item, status: INVERT_STATUSES[item.status] }));

function* fetchCampaignOfferList({ payload: { page, perPage, status, search } }) {
  try {
    const [list, total] = yield call(api.fetchCampaignOfferList, {
      page,
      perPage,
      status: STATUSES[status],
      search: search
    });

    yield put(campaignOfferActions.putCampaignOfferList({ list: mapStatus(list), total }));
  } catch (error) {}
}

function* validateCampaignName({ payload: { name, id, resolve, reject } }) {
  try {
    if (name) {
      yield delay(500);

      const result = yield call(api.validateCampaignName, name, id);
      if (result) {
        resolve();
      } else {
        reject({ campaign_name: 'This name is already used' });
      }
    }
  } catch (error) {
    reject();
  }
}

function* uploadPromoCodesFile({
  payload: { file, formName, field, fileFiled }
}) {
  try {
    yield put(startAsyncValidation(formName));
    const { success, count } = yield call(api.sendPromoCodesFile, file);

    if (!success) {
      yield put(touch(formName, field));
      yield put(
        stopAsyncValidation(
          formName,
          set({}, field, 'Error with file uploading')
        )
      );
      yield cancel();
    }

    const base64File = yield call(fileToB64, file);

    yield put(change(formName, field, count, true));
    yield put(
      change(formName, fileFiled, { file: base64File, name: file.name }, true)
    );
    yield put(stopAsyncValidation(formName));
  } catch (error) {
    yield put(touch(formName, field));
    yield put(
      stopAsyncValidation(formName, set({}, field, 'Error with file uploading'))
    );
  }
}

export default function* watcher() {
  yield takeLatest(campaignOfferActions.createCampaignOfferRoutine.TRIGGER, createCampaignOffer);
  yield takeLatest(campaignOfferActions.activateCampaignOffer.toString(), activateCampaignOffer);
  yield takeLatest(campaignOfferActions.holdCampaignOffer.toString(), holdCampaignOffer);
  yield takeLatest(campaignOfferActions.fetchCampaignOffer.toString(), fetchCampaignOffer);
  yield takeLatest(
    campaignOfferActions.fetchCampaignOfferList.toString(),
    fetchCampaignOfferList
  );
  yield takeLatest(
    campaignOfferActions.validateCampaignName.toString(),
    validateCampaignName
  );
  yield takeLatest(
    campaignOfferActions.uploadPromoCodesFile.toString(),
    uploadPromoCodesFile
  );
}
