import { parseBg } from '../parseBg';

import { parseStyles } from './commonParsers/commonStylesParser';

/*
  <div style="width, height, alignItems...">
    <div style="background">
      <p><b>Text!</b></p>
    <div>
  </div>
*/
const getWidth = (el, root, horizontal) => {
  if (horizontal) {
    return el.style.width;
  }

  return root.style.width;
};

export const textParser = (el, id, type, horizontal) => {
  const root = el.firstElementChild;
  const bgFields = parseBg(root);
  const width = getWidth(el, root, horizontal);
  const { height } = el.style;
  const rotateDeg = el.dataset.rotateDeg || '0deg';
  const horizontalTranslate = el.dataset.horizontalTranslate || '0px';
  const verticalTranslate = el.dataset.verticalTranslate || '0px';
  const rotateVerticalOrigin = el.dataset.rotateVerticalOrigin || 'bottom';
  const rotateHorizontalOrigin = el.dataset.rotateHorizontalOrigin || 'right';
  const animation = el.dataset.animation || 'unset';
  const text = root.firstElementChild.innerHTML;
  const stylesOptions = parseStyles(root.firstElementChild.style);

  if (text) {
    return {
      type: 'text',
      ...bgFields,
      ...stylesOptions,
      width,
      height,
      rotateDeg,
      rotateVerticalOrigin,
      rotateHorizontalOrigin,
      horizontalTranslate,
      verticalTranslate,
      text,
      id,
      animation,
    };
  }
};
