import classNames from 'classnames';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import React, { memo } from 'react';

import TextEditorField from '../../../components/OnsiteForm/WidgetFormsFields/TextEditorField';
import { CURRENT_EDITOR } from '../../../constants/editor';
import { ANIMATIONS_STYLE } from '../../../constants/onsiteWidgets';
import bgStyles from '../../../help/bgStyles';
import { openTextEditor } from '../../../help/handleEditor';


const getTextWidth = (width) => {
  if (!isNil(width) && width !== '0px') {
    return width;
  }

  return '100%';
};

const parser = new DOMParser();

export const Text = memo(({
  id: elementId,
  itemId,
  commonStyle,
  text,
  backgroundColor,
  backgroundImage,
  backgroundOption,
  width,
  textShadow,
  rotateDeg,
  rotateVerticalOrigin,
  rotateHorizontalOrigin,
  horizontalTranslate,
  verticalTranslate,
  animation = 'unset',
  setActiveEditor,
  focusTextEditor,
  activeElement,
  form,
  section,
  horizontal,
  values,
  events,
  isEditing,
}) => {
  const textWidth = getTextWidth(width);

  const rootBgStyle = {
    ...commonStyle,
    flexGrow: 1,
    overflow: animation === 'marqueeLeftRight' ? 'hidden' : 'visible',
  };

  const rotateStyles = {
    transform: `rotate(-${rotateDeg}) translate(${horizontalTranslate}, ${verticalTranslate})`,
    transformOrigin: `${rotateHorizontalOrigin} ${rotateVerticalOrigin}`,
  };

  const currentAnimation = ANIMATIONS_STYLE[animation];

  const textStyle = {
    display: 'block',
    ...bgStyles({
      color: backgroundColor,
      option: backgroundOption,
      image: get(backgroundImage, 'url'),
    }),
    lineHeight: 1.1,
    width: textWidth,
    pointerEvents: 'auto',
    animation: animation !== 'unset' ? currentAnimation : 'unset',
  };

  const { body } = parser.parseFromString(text, 'text/html');
  let textHtml = text;

  if (body.firstElementChild) {
    const p = body.firstElementChild;
    p.style.setProperty('text-shadow', textShadow?.rawValue);
    textHtml = p.outerHTML;
  }

  const animationClassNames = classNames({
    'comfolks-animated': animation !== 'unset' && !isEditing,
  });

  return (
    <div
      data-animation={animation}
      data-rotate-deg={rotateDeg}
      data-rotate-vertical-origin={rotateVerticalOrigin}
      data-rotate-horizontal-origin={rotateHorizontalOrigin}
      data-horizontal-translate={horizontalTranslate}
      data-vertical-translate={verticalTranslate}
      id={itemId}
      style={rootBgStyle}
    >
      <div
        className={animationClassNames}
        data-editor-name={CURRENT_EDITOR.TEXT_HTML}
        data-text-editor
        onClick={openTextEditor(setActiveEditor)}
        style={textStyle}
      >
        {focusTextEditor === CURRENT_EDITOR.TEXT_HTML && elementId === activeElement.id ? (
          <TextEditorField
            editorType="inline-editor"
            type={activeElement.element.type}
            path={activeElement.path}
            isHorizontal={horizontal}
            values={values}
            section={section}
            form={form}
            element={activeElement.element}
            events={events}
            focusTextEditor={focusTextEditor}
          />
        ) : <div style={rotateStyles} dangerouslySetInnerHTML={{ __html: textHtml }} />}
      </div>
    </div>
  );
});
