import React from 'react';

import TextEditorField from '../../components/OnsiteForm/WidgetFormsFields/TextEditorField';
import { CURRENT_EDITOR } from '../../constants/editor';
import { formWidthOptions, RECAPTCHA_KEY_ID } from '../../constants/onsiteWidgets';
import { analyticConstants } from '../../help/analytics';
import { fromPx } from '../../help/format';
import { openTextEditor } from '../../help/handleEditor';
import { toPx } from '../../help/parse';

export const OrderForm = ({
  activeTab,
  tariff,
  itemId,
  id,
  sizes,
  commonStyle,
  text,
  nextButtontext,
  nextSecondButtonText,
  backButtonText,
  tariffInfoText,
  pointInfoText,
  horizontal,
  placeholderEmail,
  placeholderPhone,
  placeholderName,
  simCardPlaceholder,
  tariffPlaceholder,
  cityPlaceholder,
  salePointPlaceholder,
  inputStyles,
  buttonStyles,
  orientation,
  selector,
  layoutProps,
  boxShadow,
  showButton,
  widthOption,
  maxWidth,
  position,
  setActiveEditor,
  activeElement,
  values,
  section,
  form,
  events,
  focusTextEditor,
  errorText,
  successText,
}) => {
  const { padding } = layoutProps || {};
  const isFormHorizontal = orientation === 'row';
  const widgetHeight = toPx(fromPx(sizes.height) - fromPx(padding) * 2);

  const buttonStyle = {
    display: `${showButton ? 'initial' : 'none'}`,
    border: 0,
    lineHeight: '1.1',
    background: buttonStyles.backgroundColor,
    WebkitAppearance: 'none',
    ...(isFormHorizontal
      ? { width: '30%', padding: '10px' }
      : { width: '100%', padding: '10px 0' }),
    ...buttonStyles,
    boxShadow: buttonStyles.boxShadow?.rawValue,
    pointerEvents: 'auto',
  };

  const inputStyle = {
    boxSizing: 'border-box',
    minHeight: '35px',
    border: '1px solid',
    padding: '10px',
    lineHeight: '1.1',
    ...(isFormHorizontal
      ? { width: '70%', marginBottom: '0', marginRight: '10px' }
      : { width: '100%', marginBottom: '10px', marginRight: '0' }),
    ...inputStyles,
  };

  const formWidth = widthOption === formWidthOptions.fullWidth ? '100%' : maxWidth;

  const formPosition = {
    left: '0 auto 0 0',
    center: '0 auto',
    right: '0 0 0 auto',
  };

  const formStyle = {
    position: 'relative',
    boxShadow: boxShadow?.rawValue,
    display: 'flex',
    flexDirection: isFormHorizontal ? 'row' : 'column',
    margin: formPosition[position],
    ...(horizontal
      ? { width: '320px', height: widgetHeight }
      : { maxWidth: formWidth }),
  };

  const errorTextContainerStyle = {
    width: '100%',
    margin: '0 auto 10px',
    fontSize: '14px',
    color: 'rgb(255, 0, 0)',
  };

  const loaderSectionStyle = {
    display: 'none',
    position: 'absolute',
    top: '0',
    right: '0',
    width: '30px',
    height: '30px',
    border: '1px solid transparent',
    borderRadius: '5px',
    backgroundPosition: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    backgroundRepeat: 'no-repeat',
    backgroundImage: 'url(\'data:image/svg+xml,%3Csvg version="1.1" id="L4" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 36 20" enable-background="new 0 0 0 0" xml:space="preserve"%3E%3Ccircle fill="%23fff" stroke="none" cx="4" cy="10" r="4"%3E%3Canimate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.1"/%3E%3C/circle%3E%3Ccircle fill="%23fff" stroke="none" cx="18" cy="10" r="4"%3E%3Canimate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.2"/%3E%3C/circle%3E%3Ccircle fill="%23fff" stroke="none" cx="32" cy="10" r="4"%3E%3Canimate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.3"/%3E%3C/circle%3E%3C/svg%3E%0A\')',
  };

  const comfolksFormStepStyle = {
    width: '100%',
  };

  return (
    <form
      data-position={position}
      data-width-option={widthOption}
      data-max-width={maxWidth}
      data-show-button={showButton}
      id={itemId}
      data-id={selector}
      style={{ ...commonStyle, ...formStyle }}
      data-tariff={tariff}
      data-success={successText}
      data-error={errorText}
    >
      <div className="comfolks-form-loader" style={loaderSectionStyle} />
      <div
        className="comfolks-form-step"
        style={{
          ...comfolksFormStepStyle,
          display: activeTab === 'personal' ? 'block' : 'none',
        }}
      >
        <input
          className="comfolks-form-input form-input"
          name="emailOrder"
          type="email"
          placeholder={placeholderEmail}
          onChange={() => { }}
          style={inputStyle}
        />
        <input
          className="comfolks-form-input form-input"
          name="phoneOrder"
          type="tel"
          placeholder={placeholderPhone}
          onChange={() => { }}
          style={inputStyle}
        />
        <input
          className="comfolks-form-input form-input"
          name="nameOrder"
          type="text"
          placeholder={placeholderName}
          onChange={() => { }}
          style={inputStyle}
        />
      </div>
      <div
        className="comfolks-form-step"
        style={{
          ...comfolksFormStepStyle,
          display: activeTab === 'tariff' ? 'block' : 'none',
        }}
      >
        <select
          style={inputStyle}
          name="simCardOrder"
          className="comfolks-form-input form-input"
        >
          <option value="default">
            {simCardPlaceholder}
          </option>
        </select>
        <select
          style={inputStyle}
          name="tariffOrder"
          className="comfolks-form-input form-input"
        >
          <option value="default">
            {tariffPlaceholder}
          </option>
        </select>
        <div
          data-editor-name={CURRENT_EDITOR.FORM_TARIFF_INFO}
          data-text-editor
          onClick={openTextEditor(setActiveEditor)}
          className="comfolks-form-info-tariff"
          style={{
            pointerEvents: 'auto',
          }}
        >
          {focusTextEditor === CURRENT_EDITOR.FORM_TARIFF_INFO && id === activeElement.id ? (
            <TextEditorField
              editorType="inline-editor"
              name="tariffInfoText"
              type={activeElement.element.type}
              path={activeElement.path}
              isHorizontal={horizontal}
              values={values}
              section={section}
              form={form}
              element={activeElement.element}
              events={events}
              focusTextEditor={focusTextEditor}
            />
          ) : (
            <span dangerouslySetInnerHTML={{ __html: tariffInfoText }} />
          )
          }
        </div>
      </div>
      <div
        className="comfolks-form-step"
        style={{
          ...comfolksFormStepStyle,
          display: activeTab === 'delivery' ? 'block' : 'none',
        }}
      >
        <select
          style={inputStyle}
          name="cityOrder"
          className="comfolks-form-input form-input"
        >
          <option value="default">
            {cityPlaceholder}
          </option>
        </select>
        <select
          style={inputStyle}
          name="salePointOrder"
          className="comfolks-form-input form-input"
        >
          <option value="default">
            {salePointPlaceholder}
          </option>
        </select>
        <div
          className="comfolks-form-info-point"
          data-editor-name={CURRENT_EDITOR.FORM_POINT_INFO}
          data-text-editor
          onClick={openTextEditor(setActiveEditor)}
          style={{
            pointerEvents: 'auto',
          }}
        >
          {focusTextEditor === CURRENT_EDITOR.FORM_POINT_INFO && id === activeElement.id ? (
            <TextEditorField
              editorType="inline-editor"
              name="pointInfoText"
              type={activeElement.element.type}
              path={activeElement.path}
              isHorizontal={horizontal}
              values={values}
              section={section}
              form={form}
              element={activeElement.element}
              events={events}
              focusTextEditor={focusTextEditor}
            />
          ) : (
            <span dangerouslySetInnerHTML={{ __html: pointInfoText }} />
          )
          }
        </div>
      </div>
      <div
        className="comfolks-form-error"
        style={errorTextContainerStyle}
      />
      <slot
        style={{
          marginBottom: '20px',
          display: 'none',
        }}
        name="recaptcha-slot"
      />
      <button
        data-editor-name={CURRENT_EDITOR.FORM_BUTTON_NEXT_TEXT}
        data-text-editor
        className="comfolks-form-button-next"
        onClick={openTextEditor(setActiveEditor)}
        type="button"
        style={{
          ...buttonStyle,
          display: activeTab === 'personal' ? 'block' : 'none',
        }}
        {...analyticConstants.attributesToTrackClick}
      >
        {focusTextEditor === CURRENT_EDITOR.FORM_BUTTON_NEXT_TEXT && id === activeElement.id ? (
          <TextEditorField
            editorType="inline-editor"
            name="nextButtontext"
            type={activeElement.element.type}
            path={activeElement.path}
            isHorizontal={horizontal}
            values={values}
            section={section}
            form={form}
            element={activeElement.element}
            events={events}
            focusTextEditor={focusTextEditor}
          />
        ) : (
          <span dangerouslySetInnerHTML={{ __html: nextButtontext }} />
        )
        }
      </button>
      <button
        data-editor-name={CURRENT_EDITOR.FORM_BUTTON_NEXT_SECOND_TEXT}
        data-text-editor
        className="comfolks-form-button-second-next"
        onClick={openTextEditor(setActiveEditor)}
        type="button"
        style={{
          ...buttonStyle,
          display: activeTab === 'tariff' ? 'block' : 'none',
        }}
        {...analyticConstants.attributesToTrackClick}
      >
        {focusTextEditor === CURRENT_EDITOR.FORM_BUTTON_NEXT_SECOND_TEXT && id === activeElement.id ? (
          <TextEditorField
            editorType="inline-editor"
            name="nextSecondButtonText"
            type={activeElement.element.type}
            path={activeElement.path}
            isHorizontal={horizontal}
            values={values}
            section={section}
            form={form}
            element={activeElement.element}
            events={events}
            focusTextEditor={focusTextEditor}
          />
        ) : (
          <span dangerouslySetInnerHTML={{ __html: nextSecondButtonText }} />
        )
        }
      </button>
      <button
        className="comfolks-form-button-submit"
        data-editor-name={CURRENT_EDITOR.FORM_BUTTON_TEXT}
        data-text-editor
        onClick={openTextEditor(setActiveEditor)}
        type="submit"
        style={{
          ...buttonStyle,
          display: activeTab === 'delivery' ? 'block' : 'none',
        }}
        {...analyticConstants.attributesToTrackClick}
      >
        {focusTextEditor === CURRENT_EDITOR.FORM_BUTTON_TEXT && id === activeElement.id ? (
          <TextEditorField
            editorType="inline-editor"
            type={activeElement.element.type}
            path={activeElement.path}
            isHorizontal={horizontal}
            values={values}
            section={section}
            form={form}
            element={activeElement.element}
            events={events}
            focusTextEditor={focusTextEditor}
          />
        ) : (
          <span dangerouslySetInnerHTML={{ __html: text }} />
        )
        }
      </button>
      <button
        data-editor-name={CURRENT_EDITOR.FORM_BUTTON_BACK_TEXT}
        data-text-editor
        className="comfolks-form-button-back"
        onClick={openTextEditor(setActiveEditor)}
        type="button"
        style={{
          ...buttonStyle,
          backgroundColor: 'transparent',
          color: buttonStyles.backgroundColor,
          boxShadow: 'none',
          display: activeTab !== 'personal' ? 'block' : 'none',
        }}
        {...analyticConstants.attributesToTrackClick}
      >
        {focusTextEditor === CURRENT_EDITOR.FORM_BUTTON_BACK_TEXT && id === activeElement.id ? (
          <TextEditorField
            editorType="inline-editor"
            name="backButtonText"
            type={activeElement.element.type}
            path={activeElement.path}
            isHorizontal={horizontal}
            values={values}
            section={section}
            form={form}
            element={activeElement.element}
            events={events}
            focusTextEditor={focusTextEditor}
          />
        ) : (
          <span dangerouslySetInnerHTML={{ __html: backButtonText }} />
        )
        }
      </button>
    </form>
  );
};
