import React, { PureComponent } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { withStyles, withTheme } from "@material-ui/core/styles";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";

import history from "../../navigation/history";
import { programState as programStateAction } from "../../redux/actions";
import { ROUTE_ERROR } from "../../constants";

const styles = (theme) => ({
  root: {
    display: "flex",
    color: theme.palette.grey["400"],
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: 265,
  },
  list: {
    width: "100%",
  },
  listItemGroup: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    paddingLeft: 20,
    paddingRight: 20,
  },
  listItemNameGroup: {
    display: "flex",
    color: theme.palette.grey["400"],
  },
  nested: {
    paddingLeft: 50,
    color: theme.palette.grey["400"],
  },
  icon: {
    marginRight: 10,
  },
  link: {
    textDecoration: "none",
    color: theme.palette.grey["400"],
  },
  listItemText: {
    color: theme.palette.grey["400"],
    "& span": {
      color: theme.palette.grey["400"],
      fontSize: "0.75rem",
    },
  },
  expand: {
    color: theme.palette.grey["400"],
  },
});

class ListItemMenu extends PureComponent {
  static propTypes = {
    classes: PropTypes.shape(),
    name: PropTypes.string.isRequired,
    list: PropTypes.arrayOf(PropTypes.string),
    icon: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    theme: PropTypes.shape().isRequired,
    toggleBaseMenuMobile: PropTypes.func.isRequired,
    toggleActiveStep: PropTypes.func.isRequired,
    isAllowed: PropTypes.bool,
  };

  static defaultProps = {
    classes: {},
    list: [],
  };

  state = {
    currentLocation: history.location.pathname,
  };

  componentDidMount() {
    this.listener = history.listen(this.onChangeHistory);
  }

  componentWillUnmount() {
    this.listener();
  }

  onChangeHistory = ({ pathname }) => {
    const { currentLocation } = this.state;
    if (pathname !== currentLocation) {
      this.onChangedViewBaseMenu(pathname);
    }
  };

  onChangedViewBaseMenu = (currentLocation) => {
    this.setState({ currentLocation });
  };

  onItemClick = () => {
    const { name, onClick } = this.props;
    onClick(name);
  };

  getSubMenuName = (submenuName) => {
    const { name } = this.props;
    return `/${name} ${submenuName}`.toLowerCase().replace(/\s/g, "-");
  };

  selectedSubmenu = (name) => {
    const { toggleBaseMenuMobile, toggleActiveStep } = this.props;
    this.setState((prevState) => ({
      isSelected: prevState.isSelected !== name ? name : null,
    }));
    if (name === "New Campaign") {
      toggleActiveStep(0);
    }
    toggleBaseMenuMobile(false);
  };

  render() {
    const { currentLocation } = this.state;
    const {
      classes,
      name,
      list,
      icon: Icon,
      isOpen,
      theme,
      isAllowed,
    } = this.props;
    const {
      palette: { grey },
    } = theme;
    return (
      <>
        <MenuItem
          button
          onClick={this.onItemClick}
          className={classes.menuItem}
        >
          <ListItemIcon>
            <Icon className={classes.icon} fillColor={grey[400]} />
          </ListItemIcon>
          <ListItemText primary={name} className={classes.listItemText} />
          {isOpen ? (
            <ExpandLess className={classes.expand} />
          ) : (
            <ExpandMore className={classes.expand} />
          )}
        </MenuItem>
        {list &&
          list.map((item) => (
            <Collapse key={item} in={isOpen} timeout="auto" unmountOnExit>
              <MenuList component="div" disablePadding>
                <Link
                  to={isAllowed ? this.getSubMenuName(item) : ROUTE_ERROR}
                  className={classes.link}
                >
                  <MenuItem
                    button
                    className={classes.nested}
                    onClick={() => this.selectedSubmenu(item)}
                    selected={currentLocation === this.getSubMenuName(item)}
                  >
                    <ListItemText primary={item} />
                  </MenuItem>
                </Link>
              </MenuList>
            </Collapse>
          ))}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      toggleBaseMenuMobile: programStateAction.toggleBaseMenuMobile,
      toggleActiveStep: programStateAction.toggleActiveStep,
    },
    dispatch
  );

export default connect(
  null,
  mapDispatchToProps
)(withTheme(withStyles(styles)(ListItemMenu)));
