import ImageIcon from '@material-ui/icons/AddPhotoAlternate';
import classNames from 'classnames';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import React from 'react';

import { ANIMATIONS_STYLE } from '../../constants/onsiteWidgets';
import { analyticConstants } from '../../help/analytics';
import { fromPx } from '../../help/format';
import { toPx } from '../../help/parse';

const trackAttributes = analyticConstants.attributesToTrackClick;

const ItemPlaceholder = ({ height }) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      padding: '10px',
      height,
      width: '100%',
    }}
  >
    <ImageIcon
      style={{ height: '25px', width: '25px', fill: 'rgb(76, 175, 80)' }}
    />
  </div>
);

const positionsToJustifyContentMapping = {
  center: 'center',
  top: 'flex-start',
  right: 'flex-end',
  bottom: 'flex-end',
  left: 'flex-start',
};

export const Image = ({
  itemId,
  commonStyle,
  sizes,
  horizontal,
  image,
  linkOptions,
  alt = '',
  width,
  height,
  position,
  isEditing,
  layoutProps,
  boxShadow,
  animation = 'unset',
  rotateDeg,
  horizontalTranslate,
  verticalTranslate,
  rotateHorizontalOrigin,
  rotateVerticalOrigin,
}) => {
  const { padding } = layoutProps || {};
  const widgetHeight = toPx(fromPx(sizes.height) - fromPx(padding) * 2);
  const widgetWidth = 'auto';

  const rotateStyles = {
    transform: `rotate(-${rotateDeg}) translate(${horizontalTranslate}, ${verticalTranslate})`,
    transformOrigin: `${rotateHorizontalOrigin} ${rotateVerticalOrigin}`,
  };

  const imgStyle = horizontal
    ? {
      height: `${!isNil(height) && height !== '0px' ? height : '100%'}`,
      boxShadow: boxShadow?.rawValue || 'none',
    }
    : {
      width: `${!isNil(width) && width !== '0px' ? width : '100%'}`,
      boxShadow: boxShadow?.rawValue || 'none',
    };

  const cursorStyle = { cursor: 'pointer' };

  const containerSizes = horizontal
    ? { height: widgetHeight }
    : { width: widgetWidth };

  const currentAnimation = ANIMATIONS_STYLE[animation];

  const containerStyle = {
    display: 'flex',
    flexDirection: horizontal ? 'column' : 'row',
    justifyContent: positionsToJustifyContentMapping[position],
    animation: animation !== 'unset' ? currentAnimation : 'unset',
    ...containerSizes,
  };

  if (isEditing && !image) {
    return <ItemPlaceholder height={horizontal && widgetHeight} />;
  }

  const animationClassNames = classNames({
    'comfolks-animated': animation !== 'unset' && !isEditing,
  });

  return (
    <div
      data-animation={animation}
      data-rotate-deg={rotateDeg}
      data-rotate-vertical-origin={rotateVerticalOrigin}
      data-rotate-horizontal-origin={rotateHorizontalOrigin}
      data-horizontal-translate={horizontalTranslate}
      data-vertical-translate={verticalTranslate}
      id={itemId}
      style={{ ...commonStyle, ...containerStyle }}
      className={animationClassNames}
    >
      {linkOptions.url && (
        <a href={linkOptions.url} target={linkOptions.target} {...trackAttributes}>
          <img style={Object.assign(imgStyle, cursorStyle, rotateStyles)} src={get(image, 'url')} alt={alt} />
        </a>
      )}
      {!linkOptions.url && <img style={Object.assign(imgStyle, rotateStyles)} src={get(image, 'url')} alt={alt} /> }
    </div>
  );
};
