/*
  <div>
    <input name="email" placeholder="Write!" />
    <button style="color:#ffffff;background-color:#000000">Click me!</button>
  </div>
*/

import { DEFAULT_ELEMENT_VALUES, elements } from '../../constants/onsiteWidgets';
import { parseBoxShadow } from '../../help/itemStyles/boxShadow';
import CssClassesToSelectors from '../../help/itemStyles/CssClassesToSelectors';
import { classNames } from '../items/PhoneVerification';

import { socialParser } from './socialParser';

const selectors = CssClassesToSelectors(classNames);

const defaultValues = DEFAULT_ELEMENT_VALUES[elements.PHONE_VERIFICATION];

const getButtonFields = (el) => {
  if (!el) return {};
  const text = el.innerHTML;
  const { color } = el.style;
  const { backgroundColor } = el.style;
  const { borderRadius } = el.style;
  const { fontSize } = el.style;
  const { fontFamily } = el.style;
  const { fontWeight } = el.style;
  const { textDecoration } = el.style;
  const { fontStyle } = el.style;
  const boxShadow = parseBoxShadow(el.style);

  return {
    text,
    buttonStyles: {
      color,
      backgroundColor,
      borderRadius,
      fontSize,
      fontFamily: fontFamily === '"Times New Roman"' ? 'Times New Roman' : fontFamily,
      fontWeight,
      textDecoration,
      fontStyle,
      ...boxShadow,
    },
  };
};

const getInputFields = (el) => {
  if (!el) return {};
  const { fontSize } = el.style;
  const { fontFamily } = el.style;
  const { fontWeight } = el.style;
  const { textDecoration } = el.style;
  const { borderRadius } = el.style;
  const { fontStyle } = el.style;
  const { color } = el.style;
  const { backgroundColor } = el.style;
  const { borderColor } = el.style;

  return {
    inputStyles: {
      fontSize,
      fontFamily: fontFamily === '"Times New Roman"' ? 'Times New Roman' : fontFamily,
      fontWeight,
      textDecoration,
      borderRadius,
      fontStyle,
      color,
      backgroundColor,
      borderColor,
    },
  };
};

const getFormFields = (el) => {
  const orientation = el.style.flexDirection;
  return { orientation };
};

const getErrorFields = (el) => {
  if (!el) return {};

  const fields = getInputFields(el);

  return {
    errorStyles: {
      ...fields.inputStyles,
    },
  };
};

/**
 * @param {HTMLElement} el
 */
const getPhoneSectionFields = (el) => {
  if (!el) return {};

  const phoneInputLabel = el.querySelector(selectors.sectionTitle)?.innerHTML;
  const phoneInputStyles = getInputFields(el.querySelector('input'))?.inputStyles;
  const phoneInputPlaceholder = el.querySelector(`label:not(${selectors.sectionTitle})`)?.textContent;
  const buttonStyle = getButtonFields(el.querySelector('button'));

  const errorStyles = getErrorFields(el.querySelector(selectors.errorContainer));

  const result = {
    phoneInputLabel,
    phoneInputStyles,
    phoneInputPlaceholder,
    ...errorStyles,
    ...buttonStyle,
  };

  if (el.querySelector('.comfolks-telegram-btn')) {
    const telegramButton = getButtonFields(el.querySelector('.comfolks-telegram-btn'));
    result.telegramButtonText = telegramButton.text;
    result.telegramButtonStyles = telegramButton.buttonStyles;
  }

  return result;
};

/**
 * @param {HTMLElement} el
 */
const getOtpSectionFields = (el) => {
  if (!el) return {};

  const codeInputLabel = el.querySelector(selectors.sectionTitle)?.innerHTML;
  const codeInputSubLabel = el.querySelector(`div${selectors.spacing}`)?.innerHTML;
  const codeInputStyles = getInputFields(el.querySelector('input'))?.inputStyles;
  const codeInputPlaceholder = el.querySelector(`label:not(${selectors.sectionTitle})`)?.textContent;
  const backLinkText = el.querySelector(selectors.otpBack)?.innerHTML;
  const resendLinkText = el.querySelector(selectors.otpResend)?.innerHTML;

  return {
    codeInputLabel,
    codeInputSubLabel,
    codeInputStyles,
    codeInputPlaceholder,
    backLinkText,
    resendLinkText,
  };
};

/**
 * @param {HTMLElement} el
 */
const getResultsSectionFields = (el) => {
  if (!el) return {};

  const successText = el.querySelector('div')?.innerHTML;

  const socialEl = el.querySelector('[id]');
  const socialProps = socialEl ? socialParser(socialEl, socialEl.id) : {};
  const showSocial = Boolean(socialEl) && socialProps;

  return {
    successText,
    socialProps,
    showSocial,
  };
};

/**
 * @param {HTMLElement} el
 * @param {string} id
 */
export const phoneVerificationParser = (el, id) => {
  const phoneSection = el.querySelector(selectors.phoneSection);
  const otpSection = el.querySelector(selectors.otpSection);
  const resultsSection = el.querySelector(selectors.resultsSection);

  const phoneSectionProps = getPhoneSectionFields(phoneSection);
  const otpSectionProps = getOtpSectionFields(otpSection);
  const resultsSectionProps = getResultsSectionFields(resultsSection);
  const formFields = getFormFields(el);

  let maskCodeList = null;
  if (el.dataset.maskCodeList) {
    try {
      maskCodeList = JSON.parse(el.dataset.maskCodeList);
    } catch (error) {
      maskCodeList = null;
    }
  }

  let showTelegramButton = false;
  if (el.dataset.showTelegramButton) {
    try {
      showTelegramButton = JSON.parse(el.dataset.showTelegramButton);
    } catch (error) {
      showTelegramButton = false;
    }
  }

  return {
    ...defaultValues,
    type: 'phoneVerification',
    ...phoneSectionProps,
    ...otpSectionProps,
    ...resultsSectionProps,
    ...formFields,
    operator: el.dataset.mno,
    service: el.dataset.service,
    maxWidth: el.dataset.maxWidth,
    widthOption: el.dataset.widthOption,
    position: el.dataset.position,
    mask: el.dataset.mask,
    validators: el.dataset.validators,
    phonePlaceholder: el.dataset.placeholder,
    maskCodeList,
    phoneLength: el.dataset.phoneLength,
    showTelegramButton,
    orientation: el.dataset.orientation,
    code: el.dataset.code,
    activeTab: 0,
    id,
  };
};
