import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import { Teaser } from './Teaser';


const useStyle = makeStyles({
  cards: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignContent: 'flex-start',
    flexWrap: 'wrap',
    gap: '20px',
    paddingTop: '50px',
    transition: 'all 0.5s ease-in-out',
    '@media (max-width: 980px)': {
      justifyContent: 'center',
    },
  },
  card: (props) => ({
    width: '100%',
    maxWidth: '300px',
    borderRadius: '15px',
    overflow: 'hidden',
    height: '400px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignContent: 'center',
    position: 'relative',
    transition: 'transform 0.3s ease-in-out',
    cursor: 'pointer',
    '& div': {
      transition: 'transform 0.3s ease-in-out',
    },
    '&:hover': {
      transform: props.animationMode === 'translate' ? 'translate(0px, -10px)' : 'translate(0, 0)',
      '& div': {
        transform: props.animationMode === 'scale' ? 'scale(1.1)' : 'scale(1)',
      },
    },
  }),
  large: {
    width: '100%',
    maxWidth: '620px',
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    '@media (max-width: 659px)': {
      maxWidth: '300px',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignContent: 'center',
    },
  },
});

export const OfferList = ({
  offers,
  bgColor,
  color,
  onTeaserClick,
  isPreviewMode,
  settings,
  animationMode,
}) => {
  const classes = useStyle({ animationMode });
  return (
    <Grid
      container
      pacing={2}
      className={classes.cards}
    >
      {offers.map((offer, index) => {
        const { isHorizontal } = offer;


        let cardClass = classes.card;

        if (isHorizontal) {
          cardClass += ` ${classes.large}`;
        }

        return (
          <Grid key={offer.id} className={cardClass}>
            {(!isPreviewMode ? (
              <Teaser
                offer={offer}
                bgColor={bgColor}
                color={color}
                onTeaserClick={() => onTeaserClick(offer)}
                settings={settings}
              />
            ) : (
              <Teaser
                offer={offer}
                bgColor={bgColor}
                color={color}
                settings={settings}
              />
            ))}
          </Grid>
        );
      })}
    </Grid>
  );
};
