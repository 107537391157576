import React from 'react';
import PropTypes from 'prop-types';

const SvgFacebookIcon = ({ fillColor, ...props }) => (
  <svg width={6.754} height={15} {...props}>
    <path
      fill={fillColor}
      d="M5.017 2.853h1.736V0h-2.04v.01c-2.473.1-2.98 1.645-3.025 3.271h-.005v1.426H-.001v2.8h1.684v7.5h2.538v-7.5H6.3l.4-2.8H4.22v-.862a.889.889 0 0 1 .797-.992z"
    />
  </svg>
);

SvgFacebookIcon.propTypes = {
  fillColor: PropTypes.string,
};

SvgFacebookIcon.defaultProps = {
  fillColor: '#ffffff',
};

export default SvgFacebookIcon;
