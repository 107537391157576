import isFinite from 'lodash/isFinite';
import isNumber from 'lodash/isNumber';

import { FORM_WIDGET_SCHEMA } from '../constants';

import { fromMs, fromPx } from './format';
import { toMs, toPx } from './parse';

export const convertToNumber = (value) => (isNumber(Number(value)) ? Number(value) : 0);

export const bePositive = (value) => (isNumber(value) && isFinite(value) && value < 0 ? 0 : value);

export const beNaturalNumberPx = (value) => {
  const number = fromPx(value);
  const natural = bePositive(number);

  return toPx(natural);
};

export const beNaturalNumberMs = (value) => {
  const number = fromMs(value);
  const natural = bePositive(number);

  return toMs(natural);
};

export const minMaxNumberCreator = (minValue, maxValue) => (value) => {
  const natural = bePositive(value);
  return natural >= minValue && natural <= maxValue ? natural : 0;
};

export const hundredNumber = minMaxNumberCreator(0, 100);

export const bePositiveNumber = (value) => bePositive(parseInt(value));

/**
 *
 * @param {('events' | 'items' | 'items2' | 'elementsOptions')} property
 */
export const getFormWidgetSchemaFullPath = (property) => {
  const _property = FORM_WIDGET_SCHEMA.properties[property];

  if (!_property) return;

  return `${FORM_WIDGET_SCHEMA.name}.${_property}`;
};
