import { CLOSE_WIDGET_CLASS_NAME, buttonActionTypes } from '../../constants/onsiteWidgets';
import { parseAction } from '../../help/actions';
import { parseBoxShadow } from '../../help/itemStyles/boxShadow';

import { parseStyles } from './commonParsers/commonStylesParser';

/*
  <div>
    <input name="email" placeholder="Write!" />
    <button style="color:#ffffff;background-color:#000000">Click me!</button>
  </div>
*/

const getButtonFields = (el) => {
  const text = el.innerHTML;
  const { color } = el.style;
  const { backgroundColor } = el.style;
  const { borderRadius } = el.style;
  const { fontSize } = el.style;
  const { fontFamily } = el.style;
  const { fontWeight } = el.style;
  const { textDecoration } = el.style;
  const { fontStyle } = el.style;
  const boxShadow = parseBoxShadow(el.style);

  return {
    text,
    buttonStyles: {
      color,
      backgroundColor,
      borderRadius,
      fontSize,
      fontFamily: fontFamily === '"Times New Roman"' ? 'Times New Roman' : fontFamily,
      fontWeight,
      textDecoration,
      fontStyle,
      ...boxShadow,
    },
  };
};

const getInputFields = (el) => {
  const placeholder = el.getAttribute('placeholder');
  const {
    fontSize,
    fontFamily,
    fontWeight,
    textDecoration,
    borderRadius,
    fontStyle,
    color,
    backgroundColor,
    borderColor,
    height,
  } = el.style;

  return {
    placeholder,
    inputStyles: {
      fontSize,
      fontFamily: fontFamily === '"Times New Roman"' ? 'Times New Roman' : fontFamily,
      fontWeight,
      textDecoration,
      borderRadius,
      fontStyle,
      color,
      backgroundColor,
      borderColor,
      height,
    },
  };
};

const getFormFields = (el) => {
  const { flexDirection } = el.style;
  let showButton = true;
  if (el.dataset.showButton) {
    showButton = JSON.parse(el.dataset.showButton);
  }
  const orientation = flexDirection;

  const { widthOption, maxWidth, position } = el.dataset;

  if (el.classList.contains(CLOSE_WIDGET_CLASS_NAME)) {
    return {
      showButton,
      orientation,
      action: buttonActionTypes.closeType,
      widthOption,
      maxWidth,
      position,
    };
  }

  const actionString = el.dataset.eventTrigger;
  let action = {};
  if (actionString) {
    const actionS = JSON.parse(actionString);
    action = parseAction(actionS);
  }


  return {
    showButton,
    orientation,
    widthOption,
    maxWidth,
    position,
    ...action,
  };
};

export const orderFormParser = (el, id) => {
  const buttonElement = el.querySelector('.comfolks-form-button-submit');
  const nextButtontext = el.querySelector('.comfolks-form-button-next')?.innerHTML || '';
  const nextSecondButtonText = el.querySelector('.comfolks-form-button-second-next')?.innerHTML || '';
  const backButtonText = el.querySelector('.comfolks-form-button-back')?.innerHTML || '';
  const tariffInfoText = el.querySelector('.comfolks-form-info-tariff')?.innerHTML || '';
  const pointInfoText = el.querySelector('.comfolks-form-info-point')?.innerHTML || '';
  const inputElement = el.getElementsByTagName('input').item(0);
  const placeholderEmail = el.querySelector('input[name="emailOrder"]')?.getAttribute('placeholder') || '';
  const placeholderPhone = el.querySelector('input[name="phoneOrder"]')?.getAttribute('placeholder') || '';
  const placeholderName = el.querySelector('input[name="nameOrder"]')?.getAttribute('placeholder') || '';

  const simCardPlaceholder = el.querySelector('select[name="simCardOrder"] option:first-child')?.text || '';
  const tariffPlaceholder = el.querySelector('select[name="tariffOrder"] option:first-child')?.text || '';
  const cityPlaceholder = el.querySelector('select[name="cityOrder"] option:first-child')?.text || '';
  const salePointPlaceholder = el.querySelector('select[name="salePointOrder"] option:first-child')?.text || '';

  if (inputElement) {
    const { success: successText, error: errorText, tariff } = el.dataset;
    const inputFields = getInputFields(inputElement);

    const formFields = getFormFields(el);

    const styleOptions = parseStyles(el.style);

    const buttonFields = getButtonFields(buttonElement);

    return {
      type: 'orderForm',
      ...buttonFields,
      ...inputFields,
      ...formFields,
      ...styleOptions,
      activeTab: 'personal',
      placeholderEmail,
      placeholderPhone,
      placeholderName,
      simCardPlaceholder,
      tariffPlaceholder,
      tariff,
      cityPlaceholder,
      salePointPlaceholder,
      successText,
      errorText,
      nextButtontext,
      nextSecondButtonText,
      backButtonText,
      tariffInfoText,
      pointInfoText,
      id,
    };
  }

  return {};
};
