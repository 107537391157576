import React from "react";
import { Provider } from "react-redux";
import { QueryParamProvider } from "use-query-params";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { PersistGate } from "redux-persist/es/integration/react";
import { render } from "react-dom";
import { Router, Route } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import history from "./navigation/history";
import { store, persistor } from "./redux/store/index";
import "./index.css";
import App from "./App";
import ResizeObserver from "./help/ResizeObserver";

ResizeObserver();

render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router history={history}>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          preventDuplicate={false}
        >
          <QueryParamProvider ReactRouterRoute={Route}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <App />
            </MuiPickersUtilsProvider>
          </QueryParamProvider>
        </SnackbarProvider>
      </Router>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
