import {
  PROMO_CODE_UPLOADS_ENDPOINT,
  PROMO_CODE_UPLOADS_FILE_REPORT,
  PROMO_CODE_UPLOADS_STATUS_ENDPOINT,
  PROMO_CODE_UPLOADS_VERIFY_NAME_ENDPOINT,
} from '../constants/endpoints';

import axios from './axios';

const changeStatus = async (id, setObjectStatus) => {
  const { data } = await axios.post(
    `${PROMO_CODE_UPLOADS_STATUS_ENDPOINT}/${id}`,
    { status: setObjectStatus },
  );
  return data;
};

const fetchCampaign = async (id) => {
  const { data: campaign } = await axios.get(`${PROMO_CODE_UPLOADS_ENDPOINT}/${id}`);

  return campaign;
};

const fetchCampaignList = async ({
  status = '', perPage = 10, page = 1, search = '',
}) => {
  const {
    data: { campaigns, total },
  } = await axios.get(
    `${PROMO_CODE_UPLOADS_ENDPOINT}?status=${status || ''}&page=${page}&per_page=${perPage}&search=${search || ''}`,
  );

  return [campaigns, total];
};

const validateCampaignName = async (name, id) => {
  const { data } = await axios.get(
    `${PROMO_CODE_UPLOADS_VERIFY_NAME_ENDPOINT}?name=${name}${
      id ? `&id=${id}` : ''
    }`,
  );
  return data;
};

const postPromoCodeUpload = async (id, data) => {
  const { data: createdPromoCodeUpload } = await axios.post(
    id ? `${PROMO_CODE_UPLOADS_ENDPOINT}/${id}` : PROMO_CODE_UPLOADS_ENDPOINT,
    data,
  );
  return createdPromoCodeUpload;
};

const fetchFileReport = async id => {
  await axios.get(`${PROMO_CODE_UPLOADS_FILE_REPORT}/${id}`, { responseType: 'blob' }).then((response) => {
    const href = URL.createObjectURL(response.data);
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', `${id}_promo_codes_info.xlsx`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  });
};

export default {
  changeStatus,
  fetchCampaign,
  fetchCampaignList,
  validateCampaignName,
  postPromoCodeUpload,
  fetchFileReport,
};
