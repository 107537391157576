export function checkSubDomain(url) {
    const subdomains = ['uz']
    const currentUrl = window.location.href
    if (currentUrl && url) {
        for (let subdomain of subdomains) {
            subdomain = '//' + subdomain + '.'
            if (currentUrl.indexOf(subdomain) !== -1) {
                const newUrl = url.replace('//', subdomain)
                return newUrl
            }
        }
    }

    return url
}