import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/styles/makeStyles';

const useStyles = makeStyles({
  root: {
    position: 'absolute',
    width: '100%',
    height: '100%'
  },
  bg: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  loader: {
    visibility: 'visible',
    color: '#212121'
  }
});

export const Loading = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Skeleton
        className={classes.bg}
        variant="rect"
        width="100%"
        height="100%"
      >
        <CircularProgress className={classes.loader} size={100} thickness={1.5} />
      </Skeleton>
    </div>
  );
};
