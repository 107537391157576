/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

const SettingsIcon = ({ fillColor, ...props }) => (
  <svg width={15.383} height={15.98} {...props}>
    <path
      d="M13.258 9.026l1.257.725a.354.354 0 0 1 .161.412 7.3 7.3 0 0 1-1.613 2.789.354.354 0 0 1-.437.066l-1.256-.725a5.649 5.649 0 0 1-1.791 1.036v1.45a.354.354 0 0 1-.276.346 7.37 7.37 0 0 1-3.22 0 .355.355 0 0 1-.283-.346v-1.45a5.648 5.648 0 0 1-1.791-1.036l-1.256.725a.354.354 0 0 1-.437-.066 7.3 7.3 0 0 1-1.613-2.789.355.355 0 0 1 .161-.412l1.257-.725a5.7 5.7 0 0 1 0-2.07L.864 6.231a.354.354 0 0 1-.161-.412 7.3 7.3 0 0 1 1.617-2.79.354.354 0 0 1 .437-.066l1.256.725A5.649 5.649 0 0 1 5.8 2.652v-1.45a.354.354 0 0 1 .276-.346 7.37 7.37 0 0 1 3.22 0 .355.355 0 0 1 .277.346v1.45a5.648 5.648 0 0 1 1.791 1.036l1.256-.725a.354.354 0 0 1 .437.066 7.3 7.3 0 0 1 1.613 2.789.355.355 0 0 1-.161.412l-1.257.725a5.7 5.7 0 0 1 0 2.07zM10.05 7.991a2.359 2.359 0 1 0-2.359 2.359 2.361 2.361 0 0 0 2.36-2.359z"
      fill="none"
      stroke={fillColor}
      strokeWidth={1.35}
    />
  </svg>
);

SettingsIcon.propTypes = {
  fillColor: PropTypes.string.isRequired,
};

export default SettingsIcon;
