import React, { useState, useRef } from "react";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import makeStyle from "@material-ui/styles/makeStyles";

const BACKSPACE_CODE = 8;
const LEFT_ARROW_CODE = 37;
const RIGHT_ARROW_CODE = 39;

const useStyles = makeStyle((theme) => ({
  codeBox: {
    display: "flex",
    justifyContent: "center",
  },
  codeNumber: {
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    width: theme.spacing(4),
    "& input": {
      textAlign: "center",
      fontSize: "1.5rem",
      paddingBottom: "5px",
    },
    "&:not(:first-child)": {
      marginLeft: theme.spacing(2),
    },
  },
  input: {
    color: (props) => props.textColor || "#000000",
    "&::before": {
      borderColor: (props) => props.textColor || "#000000",
    },
  },
}));

export const CheckCodeFields = ({
  telNumber,
  onAccept,
  code1Ref,
  textColor,
}) => {
  const classes = useStyles({ textColor });
  const code2Ref = useRef();
  const code3Ref = useRef();
  const code4Ref = useRef();
  const [code1, setCode1] = useState("");
  const [code2, setCode2] = useState("");
  const [code3, setCode3] = useState("");
  const [code4, setCode4] = useState("");

  const onNotLastChange = (event, currentValue, change, nextRef) => {
    const { value } = event.target;

    if (value.length === 1 && Number(value) <= 9 && Number(value) >= 0) {
      change(value);
      nextRef.current.focus();

      return;
    }

    if (value.length === 2) {
      if (
        value[0] !== currentValue &&
        Number(value[0]) <= 9 &&
        Number(value[0]) >= 0
      ) {
        change(value[0]);

        return;
      }

      if (
        value[1] !== currentValue &&
        Number(value[1]) <= 9 &&
        Number(value[1]) >= 0
      ) {
        change(value[1]);

        return;
      }
    }

    change("");
  };

  const onKeyDown = (event, change, { prevRef, nextRef }) => {
    if (event.keyCode === BACKSPACE_CODE && prevRef) {
      prevRef.current.focus();
      change("");

      return;
    }

    if (event.keyCode === LEFT_ARROW_CODE && prevRef) {
      prevRef.current.focus();

      return;
    }

    if (event.keyCode === RIGHT_ARROW_CODE && nextRef) {
      nextRef.current.focus();

      return;
    }
  };

  const onKeyDown1 = (event) => {
    onKeyDown(event, setCode1, { nextRef: code2Ref });
  };

  const onKeyDown2 = (event) => {
    onKeyDown(event, setCode2, { prevRef: code1Ref, nextRef: code3Ref });
  };

  const onKeyDown3 = (event) => {
    onKeyDown(event, setCode3, { prevRef: code2Ref, nextRef: code4Ref });
  };

  const onKeyDown4 = (event) => {
    onKeyDown(event, setCode4, { prevRef: code3Ref });
  };

  const onCode1Change = (event) => {
    onNotLastChange(event, code1, setCode1, code2Ref);
  };

  const onCode2Change = (event) => {
    onNotLastChange(event, code2, setCode2, code3Ref);
  };

  const onCode3Change = (event) => {
    onNotLastChange(event, code3, setCode3, code4Ref);
  };

  const onCode4Change = (event) => {
    const { value } = event.target;

    if (value.length === 2) {
      if (
        value[0] !== code4 &&
        Number(value[0]) <= 9 &&
        Number(value[0]) >= 0
      ) {
        setCode4(value[0]);

        return;
      }

      if (
        value[1] !== code4 &&
        Number(value[1]) <= 9 &&
        Number(value[1]) >= 0
      ) {
        setCode4(value[1]);

        return;
      }
    }

    if (value.length === 1 && Number(value) <= 9 && Number(value) >= 0) {
      setCode4(value);

      if (code1 && code2 && code3 && value) {
        const code = [code1, code2, code3, value].join("");

        onAccept(telNumber, code);
      }

      return;
    }

    setCode4("");
  };

  return (
    <Box className={classes.codeBox}>
      <TextField
        type={"number"}
        value={code1}
        onChange={onCode1Change}
        onKeyDown={onKeyDown1}
        className={classes.codeNumber}
        inputRef={code1Ref}
        InputProps={{ className: classes.input }}
      />
      <TextField
        type={"number"}
        value={code2}
        onChange={onCode2Change}
        onKeyDown={onKeyDown2}
        className={classes.codeNumber}
        inputRef={code2Ref}
        InputProps={{ className: classes.input }}
      />
      <TextField
        type={"number"}
        value={code3}
        onChange={onCode3Change}
        onKeyDown={onKeyDown3}
        className={classes.codeNumber}
        inputRef={code3Ref}
        InputProps={{ className: classes.input }}
      />
      <TextField
        type={"number"}
        value={code4}
        onChange={onCode4Change}
        onKeyDown={onKeyDown4}
        className={classes.codeNumber}
        inputRef={code4Ref}
        InputProps={{ className: classes.input }}
      />
    </Box>
  );
};

export default CheckCodeFields;
