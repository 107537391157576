import axios from 'axios';
import { store } from '../redux/store';
import { API_ENDPOINT } from '../constants/endpoints';
import { DEFAULT_HEADER } from '../constants/network';
import Cookies from 'js-cookie'

const instance = axios.create({
  baseURL: API_ENDPOINT,
  headers: DEFAULT_HEADER
});

instance.interceptors.request.use(config => {
  const token = store.getState().user.token;
  const apiKey = store.getState().user.apiKey;

  if (apiKey && config.url.trim().indexOf('http') !== 0) {
    config.url = apiKey + config.url
  }

  if (token) {
    config.headers.Authorization = token ? `Bearer ${token}` : null;
  } else {
    delete config.headers.Authorization;
  }

  const xdebugDebug = Cookies.get('XDEBUG_SESSION')
  const xdebugProfile = Cookies.get('XDEBUG_PROFILE')
  const isQuestion = config.url.indexOf('?') !== -1
  if (xdebugDebug) {
    config.url = config.url + (isQuestion ? '&' : '?') + "XDEBUG_SESSION=" + xdebugDebug
  } else if (xdebugProfile) {
    config.url = config.url + (isQuestion ? '&' : '?') + "XDEBUG_PROFILE=" + xdebugProfile
  }

  return config;
});

export default instance;
