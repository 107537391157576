import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Menu from '../../components/Menu';

const style = () => ({
  base: {
    display: 'flex',
    flex: '1 1 auto',
    justifyContent: 'center',
    alignItems: 'center',
  },
});


function Page({ classes, location }) {
  return (
    <>
      <Menu />
      <div className={classes.base}>
        <h1>
          <span>No match for </span>
          <code>{location.pathname}</code>
        </h1>
      </div>
    </>
  );
}

Page.propTypes = {
  classes: PropTypes.shape().isRequired,
  location: PropTypes.shape().isRequired,
};

export default memo(withStyles(style)(Page));
