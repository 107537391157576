/**
 * @typedef {object} TransitionOptions
 * @property {string} transitionTimingFunction
 * @property {string} transitionDuration
 */

/**
 * 
 * @param {CSSStyleDeclaration} styles 
 * @returns {TransitionOptions} result
 */
const parseTransition = (styles) => {
    const timingFunction = styles.transitionTimingFunction
    const duration = styles.transitionDuration

    return {
        transitionTimingFunction: timingFunction,
        transitionDuration: duration,
    }
}

export default parseTransition