import { handleActions } from 'redux-actions';
import {
  putSocialSharing,
  putCampaignList,
  updateSocialSharing
} from '../actions/socialSharing';

const INITIAL_STATE = {
  list: [],
  total: 0,
  editedItem: null
};

export default handleActions(
  {
    [putSocialSharing]: (state, { payload }) => ({
      ...state,
      editedItem: payload
    }),
    [putCampaignList]: (state, { payload: { list, total } }) => ({
      ...state,
      list,
      total
    }),
    [updateSocialSharing]: (state, { payload }) => ({
      ...state,
      editedItem: { ...state.editedItem, ...payload }
    })
  },
  INITIAL_STATE
);
