import React from 'react'
import {
  CAROUSEL_TYPES
} from '../OnsiteForm/WidgetFormsFields/MainFields/FieldsByWidgetType/CarouselFields'
import { HistoryCarousel, FullScreenCarousel } from './CarouselComponent';


export function CarouselLayoutComponent({
  id,
  moveOption,
  effect,
  controlColor,
  verticalMargin,
  commonUrl,
  widgetType,
  slides,
  moveSlideDistance,
  carouselDuration,
  carouselDelay,
  carouselAnimationFunction,
  carouselType,
  slideWidth,
  layoutProperties,
  allElements,
  styleInjectionPlaceholder,
  eventsByElementIdMap,
}) {
  if (carouselType === CAROUSEL_TYPES.HISTORY_CAROUSEL) {
    return (
      <HistoryCarousel
        id={id}
        moveOption={moveOption}
        effect={effect}
        controlColor={controlColor}
        verticalMargin={verticalMargin}
        styleInjectionPlaceholder={styleInjectionPlaceholder}
        widgetType={widgetType}
        slides={slides}
        moveSlideDistance={moveSlideDistance}
        carouselDuration={carouselDuration}
        carouselDelay={carouselDelay}
        carouselAnimationFunction={carouselAnimationFunction}
        carouselType={carouselType}
        allElements={allElements}
        slideWidth={slideWidth}
        layoutProperties={layoutProperties}
        eventsByElementIdMap={eventsByElementIdMap}
      />
    )
  }
  if (carouselType === CAROUSEL_TYPES.FULL_SCREEN_CAROUSEL) {
    return (
      <FullScreenCarousel
        id={id}
        moveOption={moveOption}
        effect={effect}
        controlColor={controlColor}
        verticalMargin={verticalMargin}
        commonUrl={commonUrl}
        styleInjectionPlaceholder={styleInjectionPlaceholder}
        allElements={allElements}
        widgetType={widgetType}
        slides={slides}
        moveSlideDistance={moveSlideDistance}
        carouselDuration={carouselDuration}
        carouselDelay={carouselDelay}
        carouselAnimationFunction={carouselAnimationFunction}
        carouselType={carouselType}
        layoutProperties={layoutProperties}
        eventsByElementIdMap={eventsByElementIdMap}
      />
    )
  }
};
