import React from 'react';
import PropTypes from 'prop-types';

const SvgTwitter = ({ fillColor, ...props }) => (
  <svg width={20} height={16.244} {...props}>
    <path
      fill={fillColor}
      d="M17.944 4.048c.013.178.013.355.013.533A11.583 11.583 0 0 1 6.294 16.244 11.583 11.583 0 0 1 0 14.404a8.48 8.48 0 0 0 .99.051 8.209 8.209 0 0 0 5.089-1.751 4.106 4.106 0 0 1-3.833-2.843 5.169 5.169 0 0 0 .774.063 4.335 4.335 0 0 0 1.079-.14A4.1 4.1 0 0 1 .812 5.761V5.71a4.128 4.128 0 0 0 1.853.52A4.105 4.105 0 0 1 1.4.749a11.651 11.651 0 0 0 8.448 4.289 4.627 4.627 0 0 1-.1-.939 4.1 4.1 0 0 1 7.094-2.8 8.07 8.07 0 0 0 2.6-.99 4.088 4.088 0 0 1-1.8 2.259A8.217 8.217 0 0 0 20 1.929a8.811 8.811 0 0 1-2.056 2.119z"
    />
  </svg>
);
SvgTwitter.propTypes = {
  fillColor: PropTypes.string,
};

SvgTwitter.defaultProps = {
  fillColor: '#ffffff',
};

export default SvgTwitter;
