import { TARGET_VALUES, TARGET } from "../../../constants/linkAttributes"

/** @param {HTMLAnchorElement} link */
const getLinkTarget = (link) => {
    const target = link.getAttribute('href')
    return target === '_self' ? TARGET_VALUES[TARGET.TOP] : target
}

/**
 * 
 * @param {HTMLAnchorElement} link 
 * @returns {{ linkOptions: { url?: string, target: string } }}
 */
const linkAttributesParser = (link) => {
    if (!link || link.nodeName !== 'A') return { 
        linkOptions: {
            target: TARGET_VALUES[TARGET.BLANK]
        } 
    }

    const target = getLinkTarget(link)

    return {
        linkOptions: {
            url: link.getAttribute('href'),
            target,
        }
    }
}

export default linkAttributesParser