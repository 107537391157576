import { createAction } from 'redux-actions';
import { createRoutine, bindRoutineToReduxForm } from 'redux-saga-routines';

const CREATE_EMAIL = '@email/CREATE_EMAIL';
const FETCH_EMAIL = '@email/FETCH_EMAIL';
const PUT_EMAIL = '@email/PUT_EMAIL';
const UPDATE_EMAIL = '@email/UPDATE_EMAIL';
const HOLD_EMAIL = '@email/HOLD_EMAIL';
const ACTIVATE_EMAIL = '@email/ACTIVATE_EMAIL';
const FETCH_EMAIL_LIST = '@email/FETCH_EMAIL_LIST';
const PUT_EMAIL_LIST = '@email/PUT_EMAIL_LIST';
const FETCH_EMAIL_VISITORS = '@email/FETCH_VISITORS';
const PUT_EMAIL_VISITORS = '@email/PUT_VISITORS';
const FETCH_EMAIL_CAMPAIGN_NAMES = '@email/FETCH_CURRENT_CAMPAIGN_NAMES';
const PUT_EMAIL_CAMPAIGN_NAMES = '@email/PUT_CAMPAIGN_NAMES';
const VALIDATE_EMAIL_CAMPAIGN_NAME = '@email/VALIDATE_CAMPAIGN_NAME';
const UPLOAD_EMAIL_PROMO_CODES_FILE = '@email/UPLOAD_PROMO_CODES_FILE';
const FETCH_TEMPLATE_LIST = '@email/FETCH_TEMPLATE_LIST';
const PUT_TEMPLATE_LIST = '@email/PUT_TEMPLATE_LIST';
const POST_TEMPLATE = '@email/POST_TEMPLATE';
const DELETE_TEMPLATE = '@email/DELETE_TEMPLATE';
const FETCH_DOWNLOAD_STAT = '@email/FETCH_DOWNLOAD_STAT';

export const createEmailRoutine = createRoutine(CREATE_EMAIL);
export const createEmailHandler = bindRoutineToReduxForm(
  createEmailRoutine
);

export const activateEmail = createAction(ACTIVATE_EMAIL);
export const holdEmail = createAction(HOLD_EMAIL);

export const fetchEmail = createAction(FETCH_EMAIL);
export const putEmail = createAction(PUT_EMAIL);
export const updateEmail = createAction(UPDATE_EMAIL);

export const fetchEmailList = createAction(FETCH_EMAIL_LIST);
export const putEmailList = createAction(PUT_EMAIL_LIST);

export const fetchVisitors = createAction(FETCH_EMAIL_VISITORS);
export const putVisitors = createAction(PUT_EMAIL_VISITORS);

export const fetchTemplateList = createAction(FETCH_TEMPLATE_LIST);
export const putTemplateList = createAction(PUT_TEMPLATE_LIST);

export const postTemplate = createAction(POST_TEMPLATE);
export const deleteTemplate = createAction(DELETE_TEMPLATE);
export const fetchDownloadStat = createAction(FETCH_DOWNLOAD_STAT);

export const fetchCurrentCampaignNames = createAction(
  FETCH_EMAIL_CAMPAIGN_NAMES
);
export const putCampaignNames = createAction(PUT_EMAIL_CAMPAIGN_NAMES);

export const validateCampaignName = createAction(
  VALIDATE_EMAIL_CAMPAIGN_NAME
);

export const uploadPromoCodesFile = createAction(
  UPLOAD_EMAIL_PROMO_CODES_FILE
);
