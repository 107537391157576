export const FORM_SCHEMA_ONSITE = {
  EDITED_WIDGET: 'editedWidget',
  WIDGETS: 'widgets',
  TRIGGER: 'trigger',
  PROPERTIES: {
    EVENTS: 'events',
    ITEMS: 'items',
    ITEMS2: 'items2',
    ELEMENTS_OPTIONS: 'elementsOptions',
    CAROUSEL_MOVE_OPTION: 'carouselMoveOption',
    CAROUSEL_EFFECT: 'carouselEffect',
    CAROUSEL_CONTROL_COLOR: 'carouselControlColor',
    CAROUSEL_VERTICAL_MARGIN: 'carouselVerticalMargin',
    CAROUSEL_COMMON_URL: 'carouselCommonUrl',
    CAROUSEL_SLIDE_COUNT: 'carouselSlideCount',
    CAROUSEL_SLIDE_WIDTH: 'carouselSlideWidth',
    CAROUSEL_SLIDE_SPACE_WIDTH: 'carouselSlideSpaceWidth',
    CAROUSEL_SLIDES: 'carouselSlides',
    CAROUSEL_CURRENT_SLIDE: 'carouselCurrentSlide',
    CAROUSEL_DURATION: 'carouselDuration',
    CAROUSEL_DELAY: 'carouselDelay',
    CAROUSEL_ANIMATION_FUNCTION: 'carouselAnimationFunction',
    CAROUSEL_TYPE: 'carouselType',
    CAROUSEL_INTEGRATED_OPTIONS: 'carouselIntegratedOptions',
  }
}

export const FORM_SCHEMA_NAMES = {
  ONSITE: 'FORM_SCHEMA_ONSITE'
}
