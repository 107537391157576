import { getBorderProperty } from '../../help';
import { getSocialMediaPostQuery } from '../../help/social/parseSocialMediaPostLinkFromString';

/* 
  <a class="twitterLink" href="" style="background-color:rgba(); color:rgba(); padding: 10px; border: 1px solid rgba();">
    ...
  </a>
  <a class="vkLink" href="" style="background-color:rgba(); color:rgba(); padding: 10px; border: 1px solid rgba();">
    ...
  </a>
  <a class="okLink" href="" style="background-color:rgba(); color:rgba(); padding: 10px; border: 1px solid rgba();">
    ...
  </a>
  ...
*/

const enabledLinkMapping = {
  twitterLink: 'enableTwitterLink',
  facebookLink: 'enableFacebookLink',
  okLink: 'enableOkLink',
  vkLink: 'enableVkLink'
};

export const socialParser = (el, id) => {
  const alignItems = el.style.alignItems;
  const justifyContent = el.style.justifyContent;
  const itemFields = {
    alignItems,
    justifyContent
  };

  const childrenLength = el.children.length;

  let postTitle = '', postText = ''

  for (let index = 0; index < childrenLength; index++) {
    const child = el.children.item(index);

    const linkType = child.className;
    const link = child.href;

    if (! link || ! linkType) {
      continue
    }

    itemFields[linkType] = link;
    itemFields[enabledLinkMapping[linkType]] = Boolean(link);

    const query = getSocialMediaPostQuery(linkType, link)

    postTitle = query.title ? query.title : postTitle
    postText = query.text ? query.text : postText

    itemFields.backgroundColor = child.style.backgroundColor;
    itemFields.color = child.style.color;
    itemFields.padding = child.style.padding;

    const border = getBorderProperty(child.style.border);

    itemFields.borderWidth = border.borderWidth;
    itemFields.borderColor = border.borderColor;
  }

  return {
    type: 'social',
    id,
    postText,
    postTitle,
    ...itemFields
  };
};
