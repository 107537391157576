import { createAction } from 'redux-actions';
import { createRoutine, bindRoutineToReduxForm } from 'redux-saga-routines';

const CREATE_CHAT_BOT = '@chat-bot/CREATE_CHAT_BOT';
const FETCH_CHAT_BOT = '@chat-bot/FETCH_CHAT_BOT';
const FETCH_CHAT_BOT_STAT = '@chat-bot/FETCH_CHAT_BOT_STAT';
const PUT_CHAT_BOT = '@chat-bot/PUT_CHAT_BOT';
const UPDATE_CHAT_BOT = '@chat-bot/UPDATE_CHAT_BOT';
const HOLD_CHAT_BOT = '@chat-bot/HOLD_CHAT_BOT';
const ACTIVATE_CHAT_BOT = '@chat-bot/ACTIVATE_CHAT_BOT';
const FETCH_CHAT_BOT_LIST = '@chat-bot/FETCH_CHAT_BOT_LIST';
const PUT_CHAT_BOT_LIST = '@chat-bot/PUT_CHAT_BOT_LIST';
const PUT_CHAT_BOT_CAMPAIGN_NAMES = '@chat-bot/PUT_CAMPAIGN_NAMES';
const VALIDATE_CHAT_BOT_CAMPAIGN_NAME = '@chat-bot/VALIDATE_CAMPAIGN_NAME';
const PUT_CHAT_BOT_VISITORS = '@chat-bot/PUT_VISITORS';

const CREATE_BLOCK = '@chat-bot/CREATE_BLOCK';
const DELETE_BLOCK = '@chat-bot/DELETE_BLOCK';
const UPDATE_BLOCK = '@chat-bot/UPDATE_BLOCK';
const CREATE_RESPONSE = '@chat-bot/CREATE_RESPONSE';
const UPDATE_RESPONSE = '@chat-bot/UPDATE_RESPONSE';
const DELETE_RESPONSE = '@chat-bot/DELETE_RESPONSE';
const LINK_RESPONSE = '@chat-bot/LINK_RESPONSE';
const UNLINK_RESPONSE = '@chat-bot/UNLINK_RESPONSE';

export const createChatBotRoutine = createRoutine(CREATE_CHAT_BOT);
export const createChatBotHandler = bindRoutineToReduxForm(
  createChatBotRoutine,
);

export const activateChatBot = createAction(ACTIVATE_CHAT_BOT);
export const holdChatBot = createAction(HOLD_CHAT_BOT);

export const fetchChatBot = createAction(FETCH_CHAT_BOT);
export const putChatBot = createAction(PUT_CHAT_BOT);
export const updateChatBot = createAction(UPDATE_CHAT_BOT);
export const fetchDownloadStat = createAction(FETCH_CHAT_BOT_STAT);

export const fetchChatBotList = createAction(FETCH_CHAT_BOT_LIST);
export const putChatBotList = createAction(PUT_CHAT_BOT_LIST);

export const putVisitors = createAction(PUT_CHAT_BOT_VISITORS);

export const putCampaignNames = createAction(PUT_CHAT_BOT_CAMPAIGN_NAMES);

export const validateCampaignName = createAction(
  VALIDATE_CHAT_BOT_CAMPAIGN_NAME,
);

export const createBlock = createAction(CREATE_BLOCK);
export const deleteBlock = createAction(DELETE_BLOCK);
export const updateBlock = createAction(UPDATE_BLOCK);
export const createResponse = createAction(CREATE_RESPONSE);
export const updateResponse = createAction(UPDATE_RESPONSE);
export const deleteResponse = createAction(DELETE_RESPONSE);
export const linkResponse = createAction(LINK_RESPONSE);
export const unlinkResponse = createAction(UNLINK_RESPONSE);
