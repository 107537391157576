import get from 'lodash/fp/get'
import isNil from 'lodash/fp/isNil'
import omit from 'lodash/fp/omit'
import compose from 'lodash/fp/compose'
import { transformTarget, transformToTarget } from './targetMappers'
import { mapFormValuesToBonus, mapBonusToFormValues } from './bonusMappers'
import { b64ToBlob } from '../help'

export const mapFormValuesToOfferWallSettings = (fields) => ({
  id: fields.id,
  campaign_name: get('offerWall.campaignName', fields),
  campaign_header: get('offerWall.campaignHeader', fields),
  language: get('offerWall.language', fields),
  background_color: get('offerWall.backgroundColor', fields),
  header_color: get('offerWall.headerColor', fields),
  animation_mode: get('offerWall.animationMode', fields),
  header_text_color: get('offerWall.headerTextColor', fields),
  block_list: get('blockList', fields),
  logo: get('offerWall.logo.url', fields),
  is_horizontal: get('offerWall.isHorizontal', fields),
  offerOperator: get('offerWall.offerOperator', fields),
  offerService: get('offerWall.offerService', fields),

  // brand name
  brand_name_bold: get('offerWall.settings.brand_name_bold', fields),
  brand_name_italic: get('offerWall.settings.brand_name_italic', fields),
  brand_name_underline: get('offerWall.settings.brand_name_underline', fields),
  brand_name_font_size: get('offerWall.settings.brand_name_font_size', fields),
  brand_name_color: get('offerWall.settings.brand_name_color', fields),
  brand_name_font_family: get('offerWall.settings.brand_name_font_family', fields),
  brand_name_text_align: get('offerWall.settings.brand_name_text_align', fields),
  // company name
  company_name_bold: get('offerWall.settings.company_name_bold', fields),
  company_name_italic: get('offerWall.settings.company_name_italic', fields),
  company_name_underline: get('offerWall.settings.company_name_underline', fields),
  company_name_font_size: get('offerWall.settings.company_name_font_size', fields),
  company_name_color: get('offerWall.settings.company_name_color', fields),
  company_name_font_family: get('offerWall.settings.company_name_font_family', fields),
  company_name_text_align: get('offerWall.settings.company_name_text_align', fields),
  // description
  description_bold: get('offerWall.settings.description_bold', fields),
  description_italic: get('offerWall.settings.description_italic', fields),
  description_underline: get('offerWall.settings.description_underline', fields),
  description_font_size: get('offerWall.settings.description_font_size', fields),
  description_font_family: get('offerWall.settings.description_font_family', fields),
  description_text_align: get('offerWall.settings.description_text_align', fields),
  description_color: get('offerWall.settings.description_color', fields),
})

export const mapFormValuesToCpaLoyalty = (fields) => {

  const transformed = mapFormValuesToOfferWallSettings(fields)

  // TODO: Temporary solution. Need to rewrite form structure
  const actualTransformed = {
    id: transformed.id,
    campaign_name: transformed.campaign_name,
    campaign_header: transformed.campaign_header,
    language: transformed.language,
    is_horizontal: transformed.is_horizontal,
    widgets: [
      {
        id: fields.widgetId,
        campaign_id: fields.id,
        html: {
          background_color: transformed.background_color,
          header_color: transformed.header_color,
          animation_mode: transformed.animation_mode,
          header_text_color: transformed.header_text_color,
          block_list: transformed.block_list,
          logo: transformed.logo,
          // brand name
          brand_name_bold: transformed.brand_name_bold,
          brand_name_italic: transformed.brand_name_italic,
          brand_name_underline: transformed.brand_name_underline,
          brand_name_font_size: transformed.brand_name_font_size,
          brand_name_font_family: transformed.brand_name_font_family,
          brand_name_text_align: transformed.brand_name_text_align,
          brand_name_color: transformed.brand_name_color,
          // company name
          company_name_bold: transformed.company_name_bold,
          company_name_italic: transformed.company_name_italic,
          company_name_underline: transformed.company_name_underline,
          company_name_font_size: transformed.company_name_font_size,
          company_name_font_family: transformed.company_name_font_family,
          company_name_text_align: transformed.company_name_text_align,
          company_name_color: transformed.company_name_color,
          // description
          description_bold: transformed.description_bold,
          description_italic: transformed.description_italic,
          description_underline: transformed.description_underline,
          description_font_size: transformed.description_font_size,
          description_font_family: transformed.description_font_family,
          description_text_align: transformed.description_text_align,
          description_color: transformed.description_color,
        },
        layout: null,
        settings: null
      }
    ]
  }

  return actualTransformed
}

export const getFirstWidgetValue = (path, widgetParentObject, defaultValue) =>
  get(`widgets.0.${path}`, widgetParentObject) || defaultValue

export const mapCpaLoyaltyResponseToFormValues = (res) => {
  // TODO: Temporary solution. Need to rewrite form structure
  return {
    id: res.id,
    widgetId: getFirstWidgetValue('id', res),
    offerWall: {
      campaignName: res.campaign_name,
      campaignHeader: res.campaign_header,
      backgroundColor: getFirstWidgetValue('html.background_color', res),
      headerColor: getFirstWidgetValue('html.header_color', res),
      animationMode: getFirstWidgetValue('html.animation_mode', res),
      headerTextColor: getFirstWidgetValue('html.header_text_color', res),
      logo: { file: getFirstWidgetValue('html.logo', res), name: '' },
      isHorizontal: res.is_horizontal,
      offerWallText: 'brand_name',
      language: getFirstWidgetValue('language', res) || '',
      settings: {
        // brand name
        brand_name_bold: getFirstWidgetValue('html.brand_name_bold', res),
        brand_name_italic: getFirstWidgetValue('html.brand_name_italic', res),
        brand_name_underline: getFirstWidgetValue('html.brand_name_underline', res),
        brand_name_font_size: getFirstWidgetValue('html.brand_name_font_size', res),
        brand_name_font_family: getFirstWidgetValue('html.brand_name_font_family', res),
        brand_name_text_align: getFirstWidgetValue('html.brand_name_text_align', res),
        brand_name_color: getFirstWidgetValue('html.brand_name_color', res),
        // company name
        company_name_bold: getFirstWidgetValue('html.company_name_bold', res),
        company_name_italic: getFirstWidgetValue('html.company_name_italic', res),
        company_name_underline: getFirstWidgetValue('html.company_name_underline', res),
        company_name_font_size: getFirstWidgetValue('html.company_name_font_size', res),
        company_name_font_family: getFirstWidgetValue('html.company_name_font_family', res),
        company_name_text_align: getFirstWidgetValue('html.company_name_text_align', res),
        company_name_color: getFirstWidgetValue('html.company_name_color', res),
        // description
        description_bold: getFirstWidgetValue('html.description_bold', res),
        description_italic: getFirstWidgetValue('html.description_italic', res),
        description_underline: getFirstWidgetValue('html.description_underline', res),
        description_font_size: getFirstWidgetValue('html.description_font_size', res),
        description_font_family: getFirstWidgetValue('html.description_font_family', res),
        description_text_align: getFirstWidgetValue('html.description_text_align', res),
        description_color: getFirstWidgetValue('html.description_color', res),
      }

    },
    blockList: getFirstWidgetValue('html.block_list', res),
  }
}


//here
export const mapFormValuesToOfferCard = (fields) => {
  return (
    {
      logo: fields.logo,
      teaser_logo: fields.teaserLogo,
      teaser: fields.teaser,
      bonus: fields.bonus,
      phone_number_label: fields.phoneNumberLabel,
      notice: fields.notice,
      background: fields.background,
      text_color: fields.textColor,
      offer_description_title: fields.offerDescriptionTitle,
      language: fields.language,
      smsCampaignId: fields.smsCampaignId,
      emailCampaignId: fields.emailCampaignId,
      tags: fields.tags,
      short_description: fields.shortDescription,
      full_description: fields.fullDescription,
      type: fields.type,
      teaser_width: fields.teaserWidth,
      teaser_position: fields.teaserPosition,
      logo_width: fields.logoWidth,
      logo_position: fields.logoPosition,
      price: fields.price,
      landing_page_url: fields.landingPageUrl,
      is_click_offer: fields.isClickOffer,
      button_title: fields.buttonTitle,
      description: fields.description,
      gradient_start_color: fields.gradientStartColor,
      start_gradient_width: fields.startGradientWidth,
      gradient_end_color: fields.gradientEndColor,
      end_gradient_width: fields.endGradientWidth,
      is_horizontal: fields.isHorizontal,
      degrees: fields.isHorizontal ? '90deg' : fields.degrees,
      brand_name: fields.brandName,
      sort_custom: fields.sort_custom,
      offerOperator: fields.offerOperator,
      offerService: fields.offerService,
    }
  )
}


//here
export const mapOfferCardToFormValues = (res) => {

  return {
  logo: res.logo,
  teaserLogo: res.teaser_logo,
  teaser: res.teaser,
  bonus: res.bonus,
  shortDescription: res.short_description,
  fullDescription: res.full_description,
  phoneNumberLabel: res.phone_number_label,
  notice: res.notice,
  price: res.price,
  background: res.background,
  offerDescriptionTitle: res.offer_description_title,
  language: res.language,
  smsCampaignId: res.smsCampaignId,
  emailCampaignId: res.emailCampaignId,
  tags: res.tags,
  type: res.type,
  teaserWidth: res.teaser_width,
  teaserPosition: res.teaser_position,
  logoWidth: res.logo_width,
  logoPosition: res.logo_position,
  landingPageUrl: res.landing_page_url,
  isClickOffer: res.is_click_offer,
  textColor: res.text_color,
  operator: res.operator,
  buttonTitle: res.button_title,
  description: res.description,
  gradientStartColor: res.gradient_start_color,
  startGradientWidth: res.start_gradient_width,
  gradientEndColor: res.gradient_end_color,
  endGradientWidth: res.end_gradient_width,
  isHorizontal: res.is_horizontal,
  degrees: res.isHorizontal ? '90deg' : res.degrees,
  brandName: res.brand_name,
  sort_custom: res.sort_custom,
  offerOperator: res.offerOperator,
  offerService: res.offerService,
}}

export const mapFormValuesToCpaOffer = (fields) => {
  const [transformed, file, filename] = mapFormValuesToCpaOfferOld(fields)

  const transformedFields = compose([
    omit(['bonus', 'offer_card']),
    (v) => ({
      ...v,
      widgets: [
        {
          campaign_id: fields.id,
          id: fields.widgetId,
          html: get('offer_card', v),
          settings: {
            bonus: get('bonus', v),
            otp: {
              operator: get('offer_card.offerOperator', v),
              service: get('offer_card.offerService', v),
            }
          }
        }
      ]
    })
  ])(transformed)

  return [transformedFields, file, filename, transformed]
}

/**
 * TODO: Remove function and make cpa offer structure as API
 */
export const mapFormValuesToCpaOfferOld = (fields) => {
  const fileFields = get('bonus.promoCodesFile', fields)
  const transformedFields = compose([
    omit(['bonus.promoCodesFile', 'trigger']),
    (v) => ({
      campaign_name: v.campaign_name,
      bonus: mapFormValuesToBonus(v),
      offer_card: mapFormValuesToOfferCard(v.offerCard),
      targets: {
        ...v.targets,
        targets: v.targets && v.targets.targets ? v.targets.targets.map(transformTarget) : []
      }
    })
  ])(fields)

  if (fileFields) {
    const filename = fileFields.name
    const fileBlob = b64ToBlob(fileFields.file)

    return [transformedFields, fileBlob, filename]
  }

  return [transformedFields]
}

export const mapCpaOfferResponseToFormValues = (res) => {
  const offerCard = mapOfferCardToFormValues(getFirstWidgetValue('html', res, {}))
  offerCard['smsCampaignId'] = getFirstWidgetValue('settings.smsCampaignId', res, null)
  offerCard['emailCampaignId'] = getFirstWidgetValue('settings.emailCampaignId', res, null)
  const result = compose([
    omit(['campaign_came', 'offer_card', 'widgets']),
    (v) => ({
      ...res,
      campaign_name: res.campaign_name,
      targets: {
        match_all: res.targets.match_all,
        targets: res.targets.targets.filter((target) => !isNil(target)).map(transformToTarget)
      },
      // TODO: Remove function and make cpa offer structure as API
      offerCard,
      bonus: mapBonusToFormValues(getFirstWidgetValue('settings.bonus', res, {})),
      widgetId: getFirstWidgetValue('id', res)
    })
  ])(res)

  return result
}

//here
export const mapResponseToOffer = (res) => {
  const offer = {
    id: res.id,
    type: res.type,
    companyName: res.campaign_name,
    bonus: res.bonus,
    teaserLogo: res.teaser_logo,
    logo: res.logo,
    teaser: res.teaser,
    brandName: res.brand_name,
    sort_custom: res.sort_custom,
    fullDescription: res.full_description,
    shortDescription: res.short_description,
    acceptedPeople: res.accepted_people,
    validUntil: res.timeFrameOnlyInTo,
    teaserWidth: res.teaser_width,
    teaserPosition: res.teaser_position,
    logoWidth: res.logo_width,
    logoPosition: res.logo_position,
    phoneNumberLabel: res.phone_number_label,
    notice: res.notice,
    background: res.background,
    offerDescriptionTitle: res.offer_description_title,
    language: res.language,
    smsCampaignId: res.smsCampaignId,
    emailCampaignId: res.emailCampaignId,
    tags: res.tags,
    price: res.price,
    landingPageUrl: res.landing_page_url,
    isClickOffer: res.is_click_offer,
    textColor: res.text_color,
    operator: res.operator,
    buttonTitle: res.button_title,
    description: res.description,
    gradientStartColor: res.gradient_start_color,
    startGradientWidth: res.start_gradient_width,
    gradientEndColor: res.gradient_end_color,
    endGradientWidth: res.end_gradient_width,
    isHorizontal: res.is_horizontal,
    degrees: res.isHorizontal ? '90deg' : res.degrees,
    settings: {
      // brand name
      brand_name_bold: res.brand_name_bold,
      brand_name_italic: res.brand_name_italic,
      brand_name_underline: res.brand_name_underline,
      brand_name_font_size: res.brand_name_font_size,
      brand_name_font_family: res.brand_name_font_family,
      brand_name_text_align: res.brand_name_text_align,
      brand_name_color: res.brand_name_color,
      // company name
      company_name_bold: res.company_name_bold,
      company_name_italic: res.company_name_italic,
      company_name_underline: res.company_name_underline,
      company_name_font_size: res.company_name_font_size,
      company_name_font_family: res.company_name_font_family,
      company_name_text_align: res.company_name_text_align,
      company_name_color: res.company_name_color,
      // description
      description_bold: res.description_bold,
      description_italic: res.description_italic,
      description_underline: res.description_underline,
      description_font_size: res.description_font_size,
      description_font_family: res.description_font_family,
      description_text_align: res.description_text_align,
      description_color: res.description_color,
    },
  }

  return offer
}

export const mapResponseToCpaWall = (res) => {

  const wallState = {
    type: res.type,
    logo: res.logo,
    backgroundColor: res.background_color,
    headerColor: res.header_color,
    animationMode: res.animation_mode,
    headerTextColor: res.header_text_color,
    username: res.username,
    msisdn: res.msisdn,
    companyName: res.campaign_name,
    operatorPrefix: res.operator_prefix,
    operatorMask: res.operator_mask,
    operatorCountry: res.operator_country,
    operatorCode: res.operator_code,
    operatorLength: res.operator_length,
  }

  return wallState
}

export const mapCpaCampaignToTableRow = (res) => {
  return {
    id: res.id,
    name: res.name,
    status: res.status,
    updated_at: res.updated_at, // TODO: rename
    delivered: res.delivered || 0,
    text: res.text || 'No text',
    sentTo: res.sentTo || 0,
    seenTo: res.seen[0] || 0,
    clickTo: res.ctr[0] || 0,
    ctrTo: res.ctr[1] || 0,
    statisticByEach: res.statisticByEach || [],
    language: res.language,
    seen: `${res.seen[0] || 0} (${res.seen[1] || 0}%)`,
    ctr: `${res.ctr[0] || 0} (${res.ctr[1] || 0}%)`,
    unsubscribed: `${res.unsubscribed[0] || 0} (${res.unsubscribed[1] || 0}%)`,
    cpaType: res.cpa_type,
    url: res.url
  }
}
