import qs from 'qs'

const parseSocialMediaPostLinkFromString = (type, urlString) => {
    const mediaPostUrl = new URL(urlString);
    const query = qs.parse(mediaPostUrl.search, {
      ignoreQueryPrefix: true
    });
  
    if (type === 'twitterLink' || type === 'okLink' || type === 'vkLink') {
      const { url } = query;
  
      return url;
    }
  
    if (type === 'facebookLink') {
      const { u } = query;
  
      return u;
    }
};

const getSocialMediaPostQuery = (type, urlString) => {
    const mediaPostUrl = new URL(urlString);
    const query = qs.parse(mediaPostUrl.search, {
      ignoreQueryPrefix: true
    });
  
    if (type === 'twitterLink' || type === 'okLink' || type === 'vkLink') {
      return query;
    }
  
    if (type === 'facebookLink') {
      return query;
    }
};

export {
    getSocialMediaPostQuery
}

export default parseSocialMediaPostLinkFromString