import compose from 'lodash/fp/compose';
import get from 'lodash/fp/get';
import omit from 'lodash/fp/omit';

import { b64ToBlob } from '../help';

import { transformTarget } from './targetMappers';
import { transformTrigger } from './triggerMappers';

export const mapFormValuesToSmsTree = (fields) => {
  const [transformed, fileBlob, filename] = mapFormValuesToSmsTreeOld(fields);

  const flattenedConditions = [
    {
      sms_campaign_id: transformed.notification.message,
      id: transformed.notification.messageId,
      name: transformed.campaign_name,
      conditions: transformed.notification.conditions,
      add_to_telegram_bot: transformed.add_to_telegram_bot,
    },
  ];

  const transformedFields = compose([
    omit(['notification']),
    (v) => ({
      ...v,
      widgets: [{
        id: fields.widgetId,
        campaign_id: fields.id,
        message: v.notification.message,
        settings: {
          conditions: flattenedConditions,
        },
        html: v.notification,
      }],
    }),
  ])(transformed);

  return [transformedFields, fileBlob, filename];
};


export const mapFormValuesToSmsTreeOld = (fields) => {
  const fileFields = get('bonus.promoCodesFile', fields);
  const transformedFields = compose([
    (v) => ({
      ...v,
      bonus: {
        ...v.bonus,
        mode: v.bonus.mode === 'uploadedDiscountList' ? 'currentDiscountList' : v.bonus.mode,
        currentDiscountList: false,
      },
    }),
    (v) => ({
      ...v,
      targets: {
        ...v.targets,
        targets: v.targets && v.targets.targets ? v.targets.targets.map(transformTarget) : [],
      },
    }),
    (v) => ({ ...v, trigger: transformTrigger(v.trigger) }),
    omit(['campaignName', 'bonus.promoCodesFile']),
  ])(fields);

  if (fileFields) {
    const filename = fileFields.name;
    const fileBlob = b64ToBlob(fileFields.file);

    return [transformedFields, fileBlob, filename];
  }

  return [transformedFields];
};

export const mapCampaignSmsTreeToTableRow = (wp) => ({
  ...wp,
  delivered: wp.delivered || 0,
  text: wp.text || 'No text',
  sentTo: wp.sentTo || 0,
  seenTo: wp.seen?.[0] || 0,
  clickTo: wp.ctr?.[0] || 0,
  ctrTo: wp.ctr?.[1] || 0,
  seen: `${wp.seen?.[0] || 0} (${wp.seen?.[1] || 0}%)`,
  ctr: `${wp.ctr?.[0] || 0} (${wp.ctr?.[1] || 0}%)`,
  unsubscribed: `${wp.unsubscribed?.[0] || 0} (${wp.unsubscribed?.[1] || 0}%)`,
});

export const mapSmsTreeToFormValues = (smsTree) => {
  const rootNode = smsTree.find((node) => node.parent_id === null);

  const values = {
    id: rootNode.id,
    campaign_name: rootNode.name,
    add_to_telegram_bot: rootNode.add_to_telegram_bot,
    targets: {
      match_all: true,
      targets: [],
    },
    trigger: {
      option: 'null',
      timeAppearance: 'immediately',
      timeFrame: 'anyTime',
      repeat: 'oncePerSession',
    },
    notification: {
      subject: null,
      message: rootNode.sms_campaign_id,
      messageId: rootNode.id,
      conditions: [],
    },
    bonus: {
      mode: 'notAllowed',
      additionalInfo: null,
      timeFrame: 'anyTime',
    },
  };

  const getNestedConditions = (parentId) => {
    return smsTree.filter((node) => node.parent_id === parentId).map((conditionNode) => {
      const condition = {
        id: conditionNode.id,
        expression: conditionNode.expression,
        sms_campaign_id: conditionNode.sms_campaign_id,
        conditions: getNestedConditions(conditionNode.id),
      };
      return condition;
    });
  };

  values.notification.conditions = getNestedConditions(rootNode.id);

  return values;
};
