import {
  IMAGE,
  TEXT,
  SEPARATOR,
  VIDEO,
  BASE_GRAY,
  MAX_WIDTH,
  DEFAULT_IMAGE_WIDTH,
  DEFAULT_SEPARATOR_WIDTH,
  DARK_BLUE,
  DEFAULT_VIDEO_WIDTH,
  MAX_SEPARATOR_WIDTH,
} from './index';
import { NO_IMAGE_ICON } from './image';

export const TEXT_DEFAULT_OBJECT = {
  name: TEXT,
  value: 'Lorem ipsum dolar',
  checkboxes: {
    fill: false,
    border: false,
  },
  style: {
    fontFamily: 'Roboto',
    textAlign: 'left',
    fontSize: 14,
    color: `${DARK_BLUE}`,
    borderColor: '#FFFFFF',
    borderWidth: 0,
    borderStyle: 'solid',
    width: '100%',
    outline: 'none',
    boxSizing: 'border-box',
    maxWidth: MAX_WIDTH,
    backgroundColor: 'inherit',
    fontWeight: 'normal',
    fontStyle: 'normal',
    textDecoration: 'none',
    whiteSpace: 'pre-line',
    marginTop: '19px',
    marginBottom: '19px',
    resize: 'none',
    lineHeight: '1.1',
  },
};

export const SOCIAL_DEFAULT_OBJECT = {
  checkboxes: {
    twitter: true,
    facebook: true,
    ok: false,
    vk: true,
    fill: false,
    border: false,
    iconColor: false,
  },
  url: '',
  style: {
    justifyContent: 'center',
    width: '100%',
    display: 'flex',
    alignContent: 'center',
    flexDirection: 'row',
  },
  styleLink: {
    borderColor: '#ffffff',
    borderWidth: 0,
    borderStyle: 'none',
    backgroundColor: '#A8AFBF',
    width: 46,
    height: 46,
    display: 'flex',
    alignItems: 'center',
    marginRight: 15,
    marginLeft: 15,
    borderRadius: '50%',
    justifyContent: 'center',
  },
  styleSvg: {
    fill: '#ffffff',
  },
};

export const SEPARATOR_DEFAULT_OBJECT = {
  name: SEPARATOR,
  maxWidth: MAX_SEPARATOR_WIDTH,
  checkboxes: {
    fullWidth: false,
  },
  styleWrapper: {
    justifyContent: 'center',
    width: '100%',
    display: 'flex',
    marginTop: 0,
    marginBottom: 0,
  },
  style: {
    width: DEFAULT_SEPARATOR_WIDTH,
    border: 'none',
    borderBottomColor: `${BASE_GRAY}`,
    borderBottomStyle: 'solid',
    margin: 0,
  },
};

export const VIDEO_DEFAULT_OBJECT = {
  name: VIDEO,
  url: '',
  maxWidth: DEFAULT_VIDEO_WIDTH,
  widthWrapper: DEFAULT_VIDEO_WIDTH,
  width: DEFAULT_VIDEO_WIDTH,
  urlError: false,
  checkboxes: {
    fullWidth: true,
  },
  styleVideoContent: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  styleVideoWrapper: {
    position: 'relative',
    paddingTop: '56.25%',
    width: '100%',
  },
  styleVideoPlayer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  styleVideoPlayerEmpty: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '200px',
    border: '1px solid #A8AFBF',
    margin: '5px 10px',
    boxSizing: 'border-box',
  },
};

export const IMAGE_DEFAULT_OBJECT = {
  name: IMAGE,
  maxWidth: DEFAULT_IMAGE_WIDTH,
  file: {
    file: null,
    url: NO_IMAGE_ICON,
  },
  checkboxes: {
    fullWidth: false,
  },
  style: {
    width: DEFAULT_IMAGE_WIDTH,
    height: 'auto',
    margin: 0,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: `${BASE_GRAY}`,
  },
  styleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 0,
    padding: 0,
    width: '100%',
  },
};

export const ELEMENT_ARRAY = [
  TEXT,
  SEPARATOR,
  IMAGE,
];

export const ACTIVE_ITEM_DIV_ID = 'active-item-container';
export const MAIN_CONTAINER_DIV_ID = 'widget-paper';
