import {
  USER_LOGOUT,
  USER_REGISTRATION_REQUEST,
  USER_REGISTRATION_SUCCESS,
  USER_REGISTRATION_FAILURE,
  USER_LOGOUT_REQUEST,
  USER_LOGOUT_SUCCESS,
  USER_LOGOUT_FAILURE,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILURE,
  USER_API_KEY,
  USER_REMEMBER_ME,
  USER_GET_ALLOWED_MODULES_SUCCESS,
  USER_GET_ALLOWED_MODULES_REQUEST,
  USER_PING,
  USER_PING_FAILURE,
  USER_PING_FAILURE_OTP,
  USER_OTP_REQUEST,
  USER_OTP_SUCCESS,
  USER_OTP_FAILURE,
  USER_OTP_RESEND, 
  USER_OTP_RESEND_SUCCESS,
} from '../actionTypes';

const INITIAL_STATE = {
  isLoggedIn: false,
  errorOtp: null,
  rememberMe: false,
  timeLogIn: null,
  id: null,
  email: null,
  profileImage: null,
  name: null,
  message: null,
  token: null,
  apiKey: null,
  allowedModules: [],
  permissions: [],
  isAllowedModulesLoading: false
};

function userReducer(state = INITIAL_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case USER_LOGOUT: {
      return {
        ...INITIAL_STATE,
      };
    }
    case USER_REGISTRATION_REQUEST: {
      return {
        ...state,
      };
    }
    case USER_PING: {
      return {
        ...state,
      };
    }
    case USER_PING_FAILURE: {
      return {
        ...INITIAL_STATE,
      };
    }
    case USER_PING_FAILURE_OTP: {
      return {
        ...state,
      };
    }
    case USER_REGISTRATION_SUCCESS: {
      return {
        ...state,
        token: payload.token,
        name: payload.name,
        email: payload.email,
        id: payload.id,
        isLoggedIn: Boolean(payload.token),
        timeLogIn: Date.now(),
      };
    }
    case USER_REGISTRATION_FAILURE: {
      return {
        ...state,
        message: payload,
      };
    }
    case USER_LOGIN_REQUEST: {
      return {
        ...state,
      };
    }
    case USER_OTP_REQUEST: {
      return {
        ...state,
        errorOtp: null,
      };
    }
    case USER_OTP_RESEND: {
      return {
        ...state,
        errorOtp: null,
      };
    }
    case USER_OTP_RESEND_SUCCESS: {
      return {
        ...state,
        errorOtp: null,
      };
    }
    case USER_OTP_SUCCESS: {
      console.log('USER_OTP_SUCCESS', payload);
      return {
        ...state,
        errorOtp: null,
      };
    }
    case USER_OTP_FAILURE: {
      return {
        ...state,
        errorOtp: payload.message,
      };
    }
    case USER_LOGIN_SUCCESS: {
      return {
        ...state,
        token: payload.token,
        apiKey: payload.apiKey,
        permissions: payload.permissions,
        name: payload.name,
        email: payload.email,
        id: payload.id,
        isLoggedIn: Boolean(payload.token),
        timeLogIn: Date.now(),
      };
    }
    case USER_LOGIN_FAILURE: {
      return {
        ...state,
        message: payload,
      };
    }
    case USER_LOGOUT_REQUEST: {
      return {
        ...state,
      };
    }
    case USER_LOGOUT_SUCCESS: {
      return {
        ...INITIAL_STATE,
      };
    }
    case USER_LOGOUT_FAILURE: {
      return {
        ...state,
        message: payload,
      };
    }
    case USER_REMEMBER_ME: {
      return {
        ...state,
        rememberMe: payload,
      };
    }
    case USER_API_KEY: {
      return {
        ...state,
        apiKey: payload,
      };
    }
    case USER_GET_ALLOWED_MODULES_REQUEST: {
      return {
        ...state,
        isAllowedModulesLoading: true
      };
    }
    case USER_GET_ALLOWED_MODULES_SUCCESS: {
      return {
        ...state,
        allowedModules: payload,
        isAllowedModulesLoading: false
      };
    }
    default: return state;
  }
}

export default userReducer;
