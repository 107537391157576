import get from 'lodash/get';
import isNil from 'lodash/isNil';
import React from 'react'
import VideoIcon from '@material-ui/icons/PersonalVideo';
import { analyticConstants } from "../../help/analytics";

const trackAttributes = analyticConstants.attributesToTrackClick

const ItemPlaceholder = ({ height }) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      padding: '10px',
      height,
      width: '100%'
    }}
  >
    <VideoIcon
      style={{ height: '25px', width: '25px', fill: 'rgb(76, 175, 80)' }}
    />
  </div>
);

const positionsToJustifyContentMapping = {
  center: 'center',
  top: 'flex-start',
  right: 'flex-end',
  bottom: 'flex-end',
  left: 'flex-start'
};

export const Video = ({
  itemId,
  id,
  horizontal,
  video,
  linkOptions,
  width,
  height,
  position,
  isEditing,
  layoutProps,
  poster,
  autoMoveMode,
  controlledMode,
}) => {
  const widgetHeight = `100%`
  const widgetWidth = `100%`
  const defaultStyle = '100%'

  const realId = id || itemId
  const videoId = realId + '-video'
  const src = get(video, 'url')
  const srcPoster = get(poster, 'url')
  let currentPoster = ''
  if (srcPoster) {
    currentPoster = srcPoster
  } else if (src) {
    currentPoster = src.replace('onsite/', 'onsite/thumbnail/') + '.jpg'
  }

  const styles = {
    width: `${!isNil(width) && width !== '0px' ? width : defaultStyle}`,
    height: `${!isNil(height) && height !== '0px' ? height : defaultStyle}`,
  }

  const stylesVideo = {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  }

  const containerSizes = horizontal
    ? { height: widgetHeight }
    : { width: widgetWidth };

  const containerStyle = {
    display: 'flex',
    flexDirection: horizontal ? 'column' : 'row',
    justifyContent: positionsToJustifyContentMapping[position],
    ...containerSizes
  }

  if (isEditing && !video) {
    return <ItemPlaceholder height={horizontal && widgetHeight} />;
  }

  return (
    <div id={realId} style={containerStyle}>
      <div id={realId + "-inner"} data-vjs-player="" style={styles}>
        {(linkOptions.url && !isEditing) ? (
          <a style={{ display: 'block' }} href={linkOptions.url} target={linkOptions.target} {...trackAttributes}>
            <video
              data-auto-move-mode={autoMoveMode}
              data-controlled-mode={controlledMode}
              autoPlay={!autoMoveMode && !controlledMode && 'autoplay'}
              loop={!autoMoveMode && 'loop'}
              muted="muted"
              playsInline="playsinline"
              className="comfolks-video"
              id={videoId}
              poster={currentPoster}
              style={stylesVideo}
              controls={controlledMode ? "controls" : undefined}
              controlsList="nodownload"
            >
              <source src={src} />
            </video>
          </a>
        ) : (
          <video
            data-auto-move-mode={autoMoveMode}
            data-controlled-mode={controlledMode}
            autoPlay={!autoMoveMode && !controlledMode && 'autoplay'}
            loop={!autoMoveMode && 'loop'}
            muted="muted"
            playsInline="playsinline"
            className="comfolks-video"
            id={videoId}
            poster={currentPoster}
            style={stylesVideo}
            controls={controlledMode ? "controls" : undefined}
            controlsList="nodownload"
          >
            <source src={src} />
          </video>
        )}
      </div>
    </div>
  );
};
