import { stringify } from 'qs';
import axios from './axios';
import {
  SOCIAL_SHARING_ENDPOINT,
  SOCIAL_SHARING_STATUS_ENDPOINT,
  SOCIAL_SHARING_CALCULATE_VISITORS_ENDPOINT,
  SOCIAL_SHARING_FETCH_CAMPAIGN_NAMES_ENDPOINT,
  SOCIAL_SHARING_VERIFY_CAMPAIGN_NAME_ENDPOINT,
  SOCIAL_SHARING_SEND_PROMOCODES_FILE_ENDPOINT
} from '../constants/endpoints';

const postCampaign = async (id, campaign) => {
  const { data: createdCampaign } = await axios.post(
    id ? `${SOCIAL_SHARING_ENDPOINT}/${id}` : SOCIAL_SHARING_ENDPOINT,
    campaign
  );
  return createdCampaign;
};

const changeStatus = async (id, setObjectStatus) => {
  const { data } = await axios.post(
      `${SOCIAL_SHARING_STATUS_ENDPOINT}/${id}`,
      { status: setObjectStatus });
  return data;
};

const fetchCampaign = async id => {
  const { data: campaign } = await axios.get(
    `${SOCIAL_SHARING_ENDPOINT}/${id}`
  );

  return campaign;
};

const fetchCampaignList = async ({ status = '', perPage = 10, page = 1, search = '' }) => {
  const {
    data: { campaigns, total }
  } = await axios.get(
    `${SOCIAL_SHARING_ENDPOINT}?status=${status || ''}&page=${page}&per_page=${perPage}&search=${search || ''}`
  );

  return [campaigns, total];
};

const fetchVisitors = async fields => {
  const {
    data: { visitors }
  } = await axios.get(
    `${SOCIAL_SHARING_CALCULATE_VISITORS_ENDPOINT}?${stringify(fields)}`
  );

  return visitors;
};

const fetchCampaignNames = async name => {
  const { data: campaignNames } = await axios.get(
    `${SOCIAL_SHARING_FETCH_CAMPAIGN_NAMES_ENDPOINT}?name=${name}`
  );

  return campaignNames;
};

const validateCampaignName = async (name, id) => {
  const { data } = await axios.get(
    `${SOCIAL_SHARING_VERIFY_CAMPAIGN_NAME_ENDPOINT}?name=${name}${
      id ? `&id=${id}` : ''
    }`
  );
  return data;
};

const sendPromoCodesFile = async file => {
  const data = new FormData();
  data.append('file', file);

  const {
    data: { success, count }
  } = await axios.post(SOCIAL_SHARING_SEND_PROMOCODES_FILE_ENDPOINT, data);

  return { success, count };
};

export default {
  postCampaign,
  changeStatus,
  fetchCampaign,
  fetchCampaignList,
  fetchVisitors,
  fetchCampaignNames,
  validateCampaignName,
  sendPromoCodesFile
};
