import React from 'react';
import TextField from '@material-ui/core/TextField';
import InputMask from 'react-input-mask';
import ReactCountryFlag from 'react-country-flag';
import makeStyle from '@material-ui/styles/makeStyles';

const useStyles = makeStyle(theme => ({
  container: {
    position: 'relative'
  },
  flag: {
    position: 'absolute',
    left: 13,
    top: '34%'
  },
  input: {
    color: props => props.textColor || '#000000'
  }
}));

const CustomPhoneInput = ({ country, value, onChange, textColor }) => {
  const classes = useStyles({ textColor });

  return (
    <div className={classes.container}>
      {country ? (
        <>
          <InputMask
            mask="\9\98 (\99) 999 9* **"
            value={value}
            onChange={onChange}
            maskChar={' '}
          >
            {() => (
              <TextField
                fullWidth
                variant="outlined"
                className={classes.input}
                type="tel"
                inputProps={{
                  style: {
                    paddingLeft: 50,
                    color: textColor,
                    border: `1px solid ${textColor}`,
                    borderRadius: 4
                  }
                }}
              />
            )}
          </InputMask>
          <div className={classes.flag}>
            <ReactCountryFlag countryCode={country} svg style={{ width: 25 }} />
          </div>
        </>
      ) : (
        <TextField
          fullWidth
          value={value}
          onChange={onChange}
          variant="outlined"
          type="tel"
          InputProps={{
            className: classes.input,
            style: {
              color: textColor,
              border: `1px solid ${textColor}`,
              borderRadius: 4
            }
          }}
        />
      )}
    </div>
  );
};

export default CustomPhoneInput;
