import React from 'react';

import { fromPx } from '../../help/format';
import { toPx } from '../../help/parse';

const createSeparatorStyle = ({
  size,
  fullLength,
  length,
  color,
  margins,
}) => {
  const rootStyle = {
    display: 'flex',
    justifyContent: 'center',
    width: fullLength ? '100%' : 'auto',
  };

  const separatorStyle = {
    height: size,
    width: fullLength ? '100%' : length,
    backgroundColor: color,
    margin: `${margins} 0`,
  };

  return [rootStyle, separatorStyle];
};

export const Separator = ({
  itemId,
  commonStyle,
  size,
  sizes,
  length,
  margins,
  fullLength,
  color,
  layoutProps,
}) => {
  const { padding } = layoutProps;
  const widgetHeight = toPx(fromPx(sizes.height) - fromPx(padding) * 2);
  const [rootStyle, separatorStyle] = createSeparatorStyle({
    widgetHeight,
    size,
    length,
    fullLength,
    color,
    margins,
  });

  return (
    <div id={itemId} style={{ ...commonStyle, ...rootStyle }}>
      <div
        className={fullLength ? 'cf-separator__full-length' : ''}
        style={separatorStyle}
      />
    </div>
  );
};
