import { imageParser } from "./imageParser";
import { buttonParser } from "./buttonParser";
import { formParser } from "./formParser";
import { orderFormParser } from "./orderFormParser";
import { productCardParser } from "./productCardParser";
import { separatorParser } from "./separatorParser";
import { textParser } from "./textParser";
import { socialParser } from "./socialParser";
import { ratingParser } from "./ratingParser";
import { collapseParser } from "./collapseParser"
import { stepperParser } from "./stepperParser";
import { phoneVerificationParser } from "./phoneVerificationParser";
import { clickPayParser } from "./clickPayParser";
import { createErrorBoundary } from "../../constants/onsiteWidgets";
import { cloneWidgetElement, getElementTypeFromId } from "../../help/element";
import { createChildrenParser } from "./commonParsers/childrenParser";
import { videoParser } from "./videoParser";

const itemTypeToParserMapping = {
  image: imageParser,
  video: videoParser,
  form: formParser,
  orderForm: orderFormParser,
  button: buttonParser,
  separator: separatorParser,
  text: textParser,
  social: socialParser,
  rating: ratingParser,
  collapse: collapseParser,
  stepper: stepperParser,
  phoneVerification: phoneVerificationParser,
  clickPay: clickPayParser,
  productCard: productCardParser,
};

// const arr = itemTypeToParserMapping

const parser = (createNewIds, ...args) => {
  const [, id] = args
  const itemType = getElementTypeFromId(id)
  let result

  try {
    const childrenParser = createChildrenParser(createNewIds)

    let itemObject = itemTypeToParserMapping[itemType](...args, { childrenParser })
    
    if (createNewIds) {
      itemObject = cloneWidgetElement(itemObject)
    }

    result = itemObject
  } catch (error) {
    result = createErrorBoundary({ text: error.toString(), targetType: itemType })
  }

  return result
}

export default parser