import React, { memo } from 'react';
import MobileBaseMenu from './MobileBaseMenu';
import DesktopMenu from './DesktopMenu';


function BaseMenu() {
  return (
    <>
      <MobileBaseMenu />
      <DesktopMenu />
    </>
  );
}


export default memo(BaseMenu);
