/* eslint-disable max-len */
import PropTypes from 'prop-types';
import React from 'react';

const SimNumbersIcon = ({ fillColor, ...props }) => (
  <svg width={16} height={16} viewBox="0 0 24 24" {...props} fill="none">
    <path d="M12 15L12 2M12 2L15 5.5M12 2L9 5.5" stroke={fillColor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8 22.0002H16C18.8284 22.0002 20.2426 22.0002 21.1213 21.1215C22 20.2429 22 18.8286 22 16.0002V15.0002C22 12.1718 22 10.7576 21.1213 9.8789C20.3529 9.11051 19.175 9.01406 17 9.00195M7 9.00195C4.82497 9.01406 3.64706 9.11051 2.87868 9.87889C2 10.7576 2 12.1718 2 15.0002L2 16.0002C2 18.8286 2 20.2429 2.87868 21.1215C3.17848 21.4213 3.54062 21.6188 4 21.749" stroke={fillColor} strokeWidth="1.5" strokeLinecap="round" />
  </svg>
);

SimNumbersIcon.propTypes = {
  fillColor: PropTypes.string.isRequired,
};

export default SimNumbersIcon;
