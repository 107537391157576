import { stringify } from "qs";
import axios from "./axios";
import {
  CPA_LOYALTY_ENDPOINT,
  CPA_OFFER_ENDPOINT,
  CPA_CAMPAIGNS_ENDPOINT,
  CPA_STATUS_ENDPOINT,
  CPA_FETCH_OFFERS_TO_BLOCK_ENDPOINT,
  CPA_FETCH_OFFER_CAMPAIGN_NAMES_ENDPOINT,
  CPA_VERIFY_LOYALTY_CAMPAIGN_NAME_ENDPOINT,
  CPA_VERIFY_OFFER_CAMPAIGN_NAME_ENDPOINT,
  CPA_SEND_PROMOCODES_FILE_ENDPOINT,
  CPA_ACTIVITIES_ENDPOINT, CPA_OFFERS_ENDPOINT, CPA_STATUS_OFFER_ENDPOINT,
  CPA_LOGS_ENDPOINT,
} from "../constants/endpoints";

// CPA_OFFERS_ENDPOINT, CPA_STATUS_OFFER_ENDPOINT,

const postCpaLoyaltyCampaign = async (id, campaign) => {
  const { data: createdCampaign } = await axios.post(
    id ? `${CPA_LOYALTY_ENDPOINT}/${id}` : CPA_LOYALTY_ENDPOINT,
    campaign
  );
  return createdCampaign;
};

const validateCpaLoyaltyCampaignName = async (name, id) => {
  const query = stringify({ name, id });
  const { data } = await axios.get(
    `${CPA_VERIFY_LOYALTY_CAMPAIGN_NAME_ENDPOINT}?${query}`
  );
  return data;
};

const fetchCpaLoyaltyCampaignNames = async (name) => {
  const { data: campaignNames } = await axios.get(
    `${CPA_FETCH_OFFERS_TO_BLOCK_ENDPOINT}?name=${name}`
  );

  return campaignNames;
};

const postCpaOfferCampaign = async (id, campaign) => {
  const { data: createdCampaign } = await axios.post(
    id ? `${CPA_OFFER_ENDPOINT}/${id}` : CPA_OFFER_ENDPOINT,
    campaign
  );
  return createdCampaign;
};

const validateCpaOfferCampaignName = async (name, id) => {
  const query = stringify({ name, id });
  const { data } = await axios.get(
    `${CPA_VERIFY_OFFER_CAMPAIGN_NAME_ENDPOINT}?${query}`
  );
  return data;
};

const fetchCpaLoyaltyCampaign = async (id) => {
  const { data: campaign } = await axios.get(`${CPA_LOYALTY_ENDPOINT}/${id}`);

  return campaign;
};

const fetchCpaOfferCampaign = async (id) => {
  const { data: campaign } = await axios.get(`${CPA_OFFER_ENDPOINT}/${id}`);

  return campaign;
};

const fetchCpaOfferCampaignNames = async (name) => {
  const { data: campaignNames } = await axios.get(
    `${CPA_FETCH_OFFER_CAMPAIGN_NAMES_ENDPOINT}?name=${name}`
  );

  return campaignNames;
};

const fetchMsisdnActivities = async (text, page, perPage = 10) => {
  const {
    data: { list, total },
  } = await axios.get(
    `${CPA_ACTIVITIES_ENDPOINT}?msisdn=${text}&page=${page}&per_page=${perPage}`
  );

  return [list, total];
};

const fetchMsisdnLogs = async (text, dateFrom, dateTo) => {
  const {
    data: { list },
  } = await axios.get(
    `${CPA_LOGS_ENDPOINT}?msisdn=${text}&to=${dateTo}&from=${dateFrom}`
  );

  return list;
};

const sendPromoCodesFile = async (file) => {
  const data = new FormData();
  data.append("file", file);

  const {
    data: { success, count },
  } = await axios.post(CPA_SEND_PROMOCODES_FILE_ENDPOINT, data);

  return { success, count };
};

const fetchCampaignList = async ({ status = "", perPage = 10, page = 1, search = '' }) => {
  const query = stringify({ status: status || "", search: search || "", page, per_page: perPage });
  const {
    data: { campaigns, total },
  } = await axios.get(`${CPA_CAMPAIGNS_ENDPOINT}?${query}`);

  return [campaigns, total];
};

const fetchOfferList = async ({ status = "", perPage = 10, page = 1, search = '' }) => {
  const query = stringify({ status: status || "", search: search || "", page, per_page: perPage });
  const {
    data: { campaigns, total },
  } = await axios.get(`${CPA_OFFERS_ENDPOINT}?${query}`);

  return [campaigns, total];
};

const changeStatus = async (id, status) => {
  const { data } = await axios.post(`${CPA_STATUS_ENDPOINT}/${id}`, {
    status,
  });
  return data;
};

const changeStatusOffer = async (id, status) => {
  const { data } = await axios.post(`${CPA_STATUS_OFFER_ENDPOINT}/${id}`, {
    status,
  });
  return data;
};

export default {
  postCpaLoyaltyCampaign,
  postCpaOfferCampaign,
  changeStatus,
  changeStatusOffer,
  fetchCpaLoyaltyCampaign,
  fetchCpaOfferCampaign,
  fetchCampaignList,
  fetchOfferList,
  fetchMsisdnActivities,
  fetchCpaLoyaltyCampaignNames,
  fetchCpaOfferCampaignNames,
  validateCpaLoyaltyCampaignName,
  validateCpaOfferCampaignName,
  sendPromoCodesFile,
  fetchMsisdnLogs,
};
