import { parseBoxShadow } from '../../../help/itemStyles/boxShadow'

/**
 * 
 * @param {CSSStyleDeclaration} styles 
 */
export const parseStyles = (styles) => {
    return {
        ...parseBoxShadow(styles)
    }
}