import { stringify } from "qs";
import axios from "./axios";
import {
  WEB_PUSH_ENDPOINT,
  WEB_PUSH_STATUS_ENDPOINT,
  WEB_PUSH_CALCULATE_VISITORS_ENDPOINT,
  WEB_PUSH_FETCH_CAMPAIGN_NAMES_ENDPOINT,
  WEB_PUSH_VERIFY_CAMPAIGN_NAME_ENDPOINT,
  WEB_PUSH_SEND_PROMOCODES_FILE_ENDPOINT
} from "../constants/endpoints";

const postWebPush = async (id, webPushFormData) => {
  const { data: createdWebPush } = await axios.post(
    id ? `${WEB_PUSH_ENDPOINT}/${id}` : WEB_PUSH_ENDPOINT,
    webPushFormData
  );
  return createdWebPush;
};

const changeStatusWebPush = async (webPushId, setObjectStatus) => {
  const { data } = await axios.post(
    `${WEB_PUSH_STATUS_ENDPOINT}/${webPushId}`,
    { status: setObjectStatus }
  );
  return data;
};

const fetchWebPush = async webPushId => {
  const { data: webPush } = await axios.get(
    `${WEB_PUSH_ENDPOINT}/${webPushId}`
  );

  return webPush;
};

const fetchWebPushList = async ({ status = "", perPage = 10, page = 1, search = '' }) => {
  const {
    data: { campaigns, total }
  } = await axios.get(
    `${WEB_PUSH_ENDPOINT}?status=${status || ""}&page=${page}&per_page=${perPage}&search=${search || ''}`
  );

  return [campaigns, total];
};

const fetchVisitors = async fields => {
  const {
    data: { visitors }
  } = await axios.get(
    `${WEB_PUSH_CALCULATE_VISITORS_ENDPOINT}?${stringify(fields)}`
  );

  return visitors;
};

const fetchCampaignNames = async name => {
  const { data: campaignNames } = await axios.get(
    `${WEB_PUSH_FETCH_CAMPAIGN_NAMES_ENDPOINT}?name=${name}`
  );

  return campaignNames;
};

const validateCampaignName = async (name, id) => {
  const { data } = await axios.get(
    `${WEB_PUSH_VERIFY_CAMPAIGN_NAME_ENDPOINT}?name=${name}${
      id ? `&id=${id}` : ""
    }`
  );
  return data;
};

const sendPromoCodesFile = async file => {
  const data = new FormData();
  data.append("file", file);

  const {
    data: { success, count }
  } = await axios.post(WEB_PUSH_SEND_PROMOCODES_FILE_ENDPOINT, data);

  return { success, count };
};

export default {
  postWebPush,
  changeStatusWebPush,
  fetchWebPush,
  fetchWebPushList,
  fetchVisitors,
  fetchCampaignNames,
  validateCampaignName,
  sendPromoCodesFile
};
