import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Drawer from '@material-ui/core/Drawer';

import history from '../../navigation/history';
import { programState as programStateAction, user as userActions } from '../../redux/actions';
import HamburgerPanel from '../HamburgerPanel/HamburgerPanel';
import MenuPanel from './MenuPanel';

const styles = theme => ({
  baseMenuMobile: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      position: 'relative',
      zIndex: 5,
      height: '100%',
    },
  },
  buttonMenu: {
    position: 'absolute',
    top: 2,
    left: 5,
    display: 'none',
    zIndex: 5,
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
    },
  },
});

class BaseMenu extends PureComponent {
  static propTypes = {
    classes: PropTypes.shape(),
    toggleBaseMenuMobile: PropTypes.func.isRequired,
    isOpenBaseMenuMobile: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    classes: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      activeItem: this.getActiveMenuItemName(),
    };
  }


  onItemClick = (name) => {
    const activeItem = name.toLowerCase();
    this.setState(prevState => ({ activeItem: prevState.activeItem !== activeItem ? activeItem : null }));
  };

  getActiveMenuItemName = () => {
    const { pathname } = history.location;
    return pathname.split('-')[0].slice(1) || null;
  };

  toggleBaseMenu = open => (event) => {
    const { toggleBaseMenuMobile } = this.props;
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    toggleBaseMenuMobile(open);
  };


  render() {
    const { classes, isOpenBaseMenuMobile, toggleBaseMenuMobile } = this.props;
    return (
      <>
        <Drawer
          open={isOpenBaseMenuMobile}
          onClose={this.toggleBaseMenu(false)}
          className={classes.baseMenuMobile}
        >
          <MenuPanel />
        </Drawer>
        <HamburgerPanel type="menu" toggleMenu={toggleBaseMenuMobile} styleHamburger={classes.buttonMenu} />
      </>
    );
  }
}

const mapStateToProps = ({ programState = {} }) => ({
  isOpenBaseMenuMobile: programState.isOpenBaseMenuMobile,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  userLogoutRequest: userActions.userLogoutRequest,
  toggleBaseMenuMobile: programStateAction.toggleBaseMenuMobile,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(BaseMenu));
