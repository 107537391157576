import cond from 'lodash/cond';
import equals from 'lodash/fp/equals';
import always from 'lodash/fp/always';

const getOption = cond([
  [
    equals({
      backgroundRepeat: 'repeat',
      backgroundSize: 'contain'
    }),
    always('repeat')
  ],
  [
    equals({
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100% 100%'
    }),
    always('fill')
  ],
  [
    equals({
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover'
    }),
    always('stretch')
  ],
  [
    equals({
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'auto'
    }),
    always('center')
  ],
  [() => true, always('repeat')]
]);

// return backgroundColor, backgroundImage: { url }, backgroundOption

export const parseBg = element => {
  const fields = {};
  const backgroundColor = element.style.backgroundColor;

  if (backgroundColor) {
    fields.backgroundColor = backgroundColor;
  }


  const backgroundImage = element.dataset?.bg ? `url(${element.dataset?.bg})` : element.style.backgroundImage;

  if (backgroundImage && backgroundImage !== 'url("undefined")') {
    fields.backgroundImage = {};
    // extract from url(...)
    fields.backgroundImage = { url: backgroundImage.slice(4, -1) };

    const backgroundRepeat = element.style.backgroundRepeat;
    const backgroundSize = element.style.backgroundSize;

    fields.backgroundOption = getOption({
      backgroundRepeat,
      backgroundSize
    });
  }

  return fields;
};
