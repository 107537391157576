import get from 'lodash/get';
import React, {
  useContext, useEffect, useMemo, useRef,
} from 'react';

import { FormContext } from '../../components/OnsiteForm/DesignStep/FormContext';
import { getClasses } from '../../constants/onsiteWidgetCSSClasses';
import { elements } from '../../constants/onsiteWidgets';
import bgStyles from '../../help/bgStyles';
import { ChildrenElements } from '../../help/draggable/ChildrenElements';
import { getHeightProperties } from '../parsers/commonParsers/heightParser';

const wrapStyle = {
  overflow: 'visible',
  width: '100%',
  height: '100%',
};

const stepStyle = {
  flex: '0 0 100%',
  overflow: 'visible',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  flexDirection: 'column',
};

const hiddenStepStyle = {
  zIndex: -1,
  opacity: 0,
  pointerEvents: 'none',
  visibility: 'hidden',
  position: 'absolute',
  height: '0px',
  width: '0px',
};

const visibleStepStyle = {
  zIndex: 0,
  opacity: 1,
  pointerEvents: 'usnet',
  visibility: 'unset',
  position: 'unset',
  height: '100%',
  width: '100%',
};

const Stepper = ({
  itemId,
  commonStyle,
  steps = [],
  activeStep = 0,
  heightOption,
  fixedHeight,
  backgroundColor,
  backgroundOption,
  backgroundImage,
  transitionDuration,
  transitionTimingFunction,
  friendlyId,
  children,
  isEditing,
  ...other

}) => {
  const stepperRef = useRef(null);
  const elementClasses = getClasses(elements.STEPPER);
  const { height: initHeight } = getHeightProperties(heightOption, fixedHeight);
  const transition = `opacity ${transitionDuration} ${transitionTimingFunction}`;
  const { activeElement, changeFormField } = useContext(FormContext) || {};

  const rootBgStyle = {
    ...commonStyle,
  };

  const wrapStyles = {
    ...wrapStyle,
    ...bgStyles({
      color: backgroundColor,
      option: backgroundOption,
      image: get(backgroundImage, 'url'),
    }),
    height: initHeight,
  };

  const stepsContainerStyle = {
    width: '100%',
    height: '100%',
    display: 'flex',
    position: 'relative',
  };

  const stepStyles = useMemo(() => steps.reduce((acc, step, index) => {
    let hideStyles = index === activeStep ? visibleStepStyle : hiddenStepStyle;

    if (!isEditing) {
      hideStyles = index === 0 ? visibleStepStyle : hiddenStepStyle;
    }

    return {
      ...acc,
      [index]: {
        ...stepStyle,
        ...hideStyles,
        transition,
      },
    };
  }, {}), [activeStep, steps, transition, isEditing]);

  useEffect(() => {
    if (!stepperRef.current) { console.log(stepperRef.current); }
  }, [stepperRef, activeStep]);


  const handleDragEnd = (result) => {
    const stepChild = steps[activeStep]?.childrenElements;

    if (!Array.isArray(stepChild) || !result.destination) return;
    const { draggableId } = result;
    const target = stepChild.find((item) => item.id === draggableId);
    const newChildsArray = [...stepChild];

    newChildsArray.splice(result.source.index, 1);
    newChildsArray.splice(result.destination.index, 0, target);

    changeFormField(`${activeElement.parentPath}`, newChildsArray);
  };

  return (
    <div id={itemId} style={rootBgStyle}>
      <div
        style={wrapStyles}
        data-friendly-id={friendlyId}
      >
        <div
          style={stepsContainerStyle}
          className={elementClasses.stepsContainer}
          data-active-step={isEditing ? activeStep : 0}
        >
          {steps.map((step, index) => (
            <div
              style={stepStyles[index]}
              key={index}
              ref={stepperRef}
            >
              <ChildrenElements
                isEditing={isEditing}
                childrenElements={step.childrenElements}
                onDragEnd={handleDragEnd}
                droppableId={friendlyId + index}
                width="100%"
                {...other}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Stepper;
