import {
  OPEN_MODAL_SELECT_ITEM,
  OPEN_MODAL_DELETE,
  OPEN_BASE_MENU,
  OPEN_RIGHT_MENU,
  CHANGE_ACTIVE_STEP,
  SHOW_PICKER_COLOR,
  TOGGLE_EDITOR_STYLE,
  TOGGLE_EDITOR_BLOCK_STYLE,
  SET_EDITOR_COLOR,
  SET_EDITOR_FONTSIZE,
  SET_EDITOR_FONTWEIGHT,
  SET_EDITOR_FONTFAMILY,
  SET_EDITOR_BORDER_COLOR,
  SET_EDITOR_BORDER,
  UNSET_EDITOR_BORDER,
  SET_BOLD_BUTTON_STATUS,
  SET_ITALIC_BUTTON_STATUS,
  SET_UNDERLINED_BUTTON_STATUS
} from '../actionTypes';

export const toggleModalSelectItem = payload => ({
  type: OPEN_MODAL_SELECT_ITEM,
  payload
});

export const toggleModalDelete = payload => ({
  type: OPEN_MODAL_DELETE,
  payload
});

export const toggleBaseMenuMobile = payload => ({
  type: OPEN_BASE_MENU,
  payload
});

export const toggleRightMenuMobile = payload => ({
  type: OPEN_RIGHT_MENU,
  payload
});

export const toggleActiveStep = payload => ({
  type: CHANGE_ACTIVE_STEP,
  payload
});

export const toggleEditorStyle = payload => ({
  type: TOGGLE_EDITOR_STYLE,
  payload
});
export const toggleEditorBlockStyle = payload => ({
  type: TOGGLE_EDITOR_BLOCK_STYLE,
  payload
});
export const setEditorColor = payload => ({ type: SET_EDITOR_COLOR, payload });
export const setEditorFontSize = payload => ({
  type: SET_EDITOR_FONTSIZE,
  payload
});
export const setEditorFontWeight = payload => ({
  type: SET_EDITOR_FONTWEIGHT,
  payload
});
export const setEditorFontFamily = payload => ({
  type: SET_EDITOR_FONTFAMILY,
  payload
});
export const setEditorBorderColor = payload => ({
  type: SET_EDITOR_BORDER_COLOR,
  payload
});
export const setEditorBorder = payload => ({
  type: SET_EDITOR_BORDER,
  payload
});
export const unsetEditorBorder = payload => ({
  type: UNSET_EDITOR_BORDER,
  payload
});
export const setBoldButtonStatus = payload => ({
  type: SET_BOLD_BUTTON_STATUS,
  payload
});
export const setItalicButtonStatus = payload => ({
  type: SET_ITALIC_BUTTON_STATUS,
  payload
});
export const setUnderlinedButtonStatus = payload => ({
  type: SET_UNDERLINED_BUTTON_STATUS,
  payload
});

export const showPickerColor = payload => ({
  type: SHOW_PICKER_COLOR,
  payload
});
