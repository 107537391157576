import { createAction } from 'redux-actions';
import { createRoutine, bindRoutineToReduxForm } from 'redux-saga-routines';

const FETCH_PROMO_CODE_UPLOADS = '@onsite/FETCH_PROMO_CODE_UPLOADS';
const FETCH_PROMO_CODE_UPLOADS_LIST = '@onsite/FETCH_PROMO_CODE_UPLOADS_LIST';
const PUT_PROMO_CODE_UPLOADS = '@onsite/PUT_PROMO_CODE_UPLOADS';
const PUT_PROMO_CODE_UPLOADS_LIST = '@onsite/PUT_PROMO_CODE_UPLOADS_LIST';
const ACTIVATE_PROMO_CODE_UPLOADS = '@onsite/ACTIVATE_PROMO_CODE_UPLOADS';
const HOLD_PROMO_CODE_UPLOADS = '@onsite/HOLD_PROMO_CODE_UPLOADS';
const VALIDATE_PROMO_CODE_UPLOADS_NAME = '@onsite/VALIDATE_PROMO_CODE_UPLOADS_NAME';
const CREATE_PROMO_CODE_UPLOADS = '@onsite/CREATE_PROMO_CODE_UPLOADS';
const FETCH_FILE_REPORT = '@onsite/FETCH_FILE_REPORT';

export const fetchPromoCodeUploads = createAction(FETCH_PROMO_CODE_UPLOADS);
export const fetchPromoCodeUploadsList = createAction(FETCH_PROMO_CODE_UPLOADS_LIST);
export const putPromoCodeUploads = createAction(PUT_PROMO_CODE_UPLOADS);
export const putPromoCodeUploadsList = createAction(PUT_PROMO_CODE_UPLOADS_LIST);
export const activatePromoCodeUploads = createAction(ACTIVATE_PROMO_CODE_UPLOADS);
export const holdPromoCodeUploads = createAction(HOLD_PROMO_CODE_UPLOADS);
export const validatePromoCodeUploadName = createAction(VALIDATE_PROMO_CODE_UPLOADS_NAME);
export const fetchFileReport = createAction(FETCH_FILE_REPORT);

export const createPromoCodeUploadRoutine = createRoutine(CREATE_PROMO_CODE_UPLOADS);
export const createPromoCodeUploadHandler = bindRoutineToReduxForm(
    createPromoCodeUploadRoutine
);