const TARGET = {
    BLANK: 'BLANK',
    TOP: 'TOP',
}

const TARGET_VALUES = {
    [TARGET.BLANK]: '_blank',
    [TARGET.TOP]: '_top',
}

const TARGET_LABELS = {
    [TARGET.BLANK]: 'New page',
    [TARGET.TOP]: 'Current page',
}

const TARGET_LIST = Object.keys(TARGET).map(k => ({ value: TARGET_VALUES[k], label: TARGET_LABELS[k] }))

export {
    TARGET,
    TARGET_VALUES,
    TARGET_LIST,
    TARGET_LABELS
}