import classnames from 'classnames';
import get from 'lodash/get';
import React from 'react';

import ElementStyles from '../../components/OnsiteForm/DesignStep/ElementStyles';
import TextEditorField from '../../components/OnsiteForm/WidgetFormsFields/TextEditorField';
import { CURRENT_EDITOR } from '../../constants/editor';
import { elements, formWidthOptions } from '../../constants/onsiteWidgets';
import { analyticConstants } from '../../help/analytics';
import { openTextEditor } from '../../help/handleEditor';

export const classNames = {
  form: 'clickPay',
  input: 'clickPay__input',
  inputOtp: 'clickPay__input-otp',
  card: 'clickPay__card',
  cardMonth: 'clickPay__card-month',
  cardYear: 'clickPay__card-year',
  phone: 'clickPay__phone',
  phoneLabel: 'clickPay__phone-label',
  cardLabel: 'clickPay__card-label',
  cardMonthLabel: 'clickPay__card-month-label',
  cardYearLabel: 'clickPay__card-year-label',
  amount: 'clickPay__amount',
  amountLabel: 'clickPay__amount-label',
  cleanAmount: 'clickPay__clean-amount',
  inputNoPlaceholder: 'clickPay__input-no-placeholder',
  inputHasText: 'has-text',
  spacing: 'spacing',
  spacing2: 'spacing-2',
  phoneSection: 'clickPay__phone-section',
  rowSection: 'clickPay__row-section',
  cardSection: 'clickPay__card-section',
  badge: 'clickPay__badge',
  cardDateContainer: 'clickPay__card-date-container',
  cardDateItem: 'clickPay__card-date-item',
  sectionTitle: 'clickPay__section-title',
  sectionTitleOtp: 'clickPay__section-title-otp',
  sectionTitleSuccess: 'clickPay__section-title-success',
  errorContainer: 'clickPay__error-text',
  phoneErrorContainer: 'clickPay__phone-error',
  otpErrorContainer: 'clickPay__otp-error',
  otpLabel: 'clickPay__otp-label',
  otpBtn: 'clickPay__otp-btn',
  depositBtn: 'clickPay__deposit-btn',
  buttonBack: 'clickPay__button-back',
  humoIcon: 'clickPay__humo-icon',
  uzcardIcon: 'clickPay__uzcard-icon',
};

export const ClickPay = ({
  itemId,
  commonStyle,
  text,
  orientation = 'column',
  buttonStyles,
  showText,
  isWidget = false,
  isEditing,
  phoneInputLabel,
  phoneInputPlaceholder,
  cardInputPlaceholder,
  cardMonthInputPlaceholder,
  cardYearInputPlaceholder,
  cardInputStyles,
  amountInputPlaceholder,
  amountInputStyles,
  rowStyles,
  badgeStyles,
  badgeTexts,
  helperText,
  cardStyles,
  image,
  imageStyles,
  mask,
  maskCodeList,
  code,
  phoneLength,
  validators,
  phonePlaceholder,
  id,
  widthOption,
  widthButtonOption,
  maxWidth,
  position,
  errorStyles,
  setActiveEditor,
  focusTextEditor,
  activeElement,
  horizontal,
  values,
  section,
  form,
  events,
  clickPayOtpLabel,
  clickPaySuccessText,
  otpInputPlaceholder,
  clickPayOtpSendBtn,
  activeTab,
  clickBackButtonText,
}) => {
  const loaderSectionStyle = {
    display: 'none',
    position: 'absolute',
    bottom: '15px',
    right: '8px',
    zIndex: '1',
    width: '30px',
    height: '30px',
    border: '1px solid transparent',
    borderRadius: '5px',
    backgroundPosition: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    backgroundRepeat: 'no-repeat',
    backgroundImage: 'url(\'data:image/svg+xml,%3Csvg version="1.1" id="L4" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 36 20" enable-background="new 0 0 0 0" xml:space="preserve"%3E%3Ccircle fill="%23fff" stroke="none" cx="4" cy="10" r="4"%3E%3Canimate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.1"/%3E%3C/circle%3E%3Ccircle fill="%23fff" stroke="none" cx="18" cy="10" r="4"%3E%3Canimate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.2"/%3E%3C/circle%3E%3Ccircle fill="%23fff" stroke="none" cx="32" cy="10" r="4"%3E%3Canimate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.3"/%3E%3C/circle%3E%3C/svg%3E%0A\')',
  };

  const buttonsWrapperStyle = {
    display: 'flex',
    flexDirection: orientation,
  };

  const phoneInputStyle = {
    ...amountInputStyles,
  };

  const phoneLabelStyle = {
    color: phoneInputStyle.color,
    opacity: 0.8,
    fontFamily: phoneInputStyle.fontFamily,
  };

  const cardInputStyle = {
    ...cardInputStyles,
  };

  const cardLabelStyle = {
    color: cardInputStyle.color,
    opacity: 0.8,
    fontFamily: cardInputStyle.fontFamily,
  };

  const elementId = itemId || id;
  const phoneInputId = `phone-${elementId}`;
  const amountInputId = `amount-${elementId}`;
  const otpInputId = `otp-${elementId}`;
  const cardInputId = `card-${elementId}`;
  const cardMonthInputId = `card-month-${elementId}`;
  const cardYearInputId = `card-year-${elementId}`;

  let maskCodeListStr = '';
  if (maskCodeList && Array.isArray(maskCodeList)) {
    maskCodeListStr = JSON.stringify(maskCodeList);
  }

  const formWidth = widthOption === formWidthOptions.fullWidth ? '100%' : maxWidth;

  const formPosition = {
    left: '0 auto 0 0',
    center: '0 auto',
    right: '0 0 0 auto',
  };

  let buttonPadding = '10px 0';
  if (buttonStyles.paddingVertical) {
    buttonPadding = `${buttonStyles.paddingVertical} 0`;
  }
  const buttonStyle = {
    border: 0,
    lineHeight: '1.1',
    background: buttonStyles.backgroundColor,
    WebkitAppearance: 'none',
    width: '100%',
    padding: buttonPadding,
    ...buttonStyles,
    boxShadow: buttonStyles.boxShadow?.rawValue,
    pointerEvents: 'auto',
    margin: formPosition[position],
  };
  if (widthButtonOption === formWidthOptions.fullWidth) {
    delete buttonStyle.maxWidth;
  } else {
    buttonStyle.maxWidth = buttonStyles.maxWidth ? buttonStyles.maxWidth : '100px';
  }

  const rowStyle = {
    display: 'flex',
    margin: `${rowStyles.marginVertical} ${rowStyles.marginHorizontal}`,
  };

  const colStyle = {
    flex: '50%',
  };

  const formStyle = {
    display: 'block',
    position: 'relative',
    margin: formPosition[position],
    boxSizing: 'border-box',
    maxWidth: formWidth,
    width: '100%',
    padding: isWidget ? `${cardStyles.marginVertical} ${cardStyles.marginHorizontal}` : 0,
  };

  const errorTextContainerStyle = {
    width: '100%',
    display: isEditing ? 'block' : 'none',
    margin: '0 auto 10px',
    textAlign: 'center',
    maxWidth: formWidth,
    ...errorStyles,
  };

  const isShowText = isEditing && showText;

  const amountInputStyle = {
    ...amountInputStyles,
  };

  const amountLabelStyle = {
    color: amountInputStyle.color,
    opacity: 0.7,
    fontFamily: badgeStyles.fontFamily,
  };

  const badgeStyle = {
    color: badgeStyles.color,
    backgroundColor: badgeStyles.backgroundColor,
    borderRadius: badgeStyles.borderRadius,
    fontFamily: badgeStyles.fontFamily,
    fontSize: badgeStyles.fontSize,
    fontWeight: badgeStyles.fontWeight,
    fontStyle: badgeStyles.fontStyle,
    textDecoration: badgeStyles.textDecoration,
    padding: `${badgeStyles.paddingVertical} ${badgeStyles.paddingHorizontal}`,
  };

  const sectionMargin = {
    margin: isWidget ? `${cardStyles.marginVertical} ${cardStyles.marginHorizontal}` : 0,
  };

  const cardStyle = {
    backgroundColor: cardStyles.backgroundColor,
    borderRadius: cardStyles.borderRadius,
    padding: `${cardStyles.paddingVertical} ${cardStyles.paddingHorizontal}`,
    ...colStyle,
    ...sectionMargin,
  };

  const phoneStyle = {
    ...colStyle,
    ...sectionMargin,
  };

  const titleStyle = {
    pointerEvents: 'auto',
    marginLeft: isWidget ? cardStyles.marginHorizontal : 0,
  };

  const dateSeparator = {
    fontSize: cardInputStyle.fontSize,
    fontFamily: cardInputStyle.fontFamily,
    width: '30px',
    textAlign: 'center',
  };

  const imageStyle = {
    height: imageStyles.height,
    width: imageStyles.width,
  };

  return (
    <div
      id={elementId}
      style={{ ...commonStyle, ...formStyle }}
      data-width-option={widthOption}
      data-width-button-option={widthButtonOption}
      data-max-width={maxWidth}
      data-position={position}
      data-mask={mask || ''}
      data-mask-code-list={maskCodeListStr}
      data-code={code || ''}
      data-phone-length={phoneLength || ''}
      data-validators={validators || ''}
      data-placeholder={phonePlaceholder || ''}
      data-action="none"
      data-orientation={orientation}
      className={classnames(classNames.form, itemId || id)}
    >
      <div className="comfolks-form-loader" style={loaderSectionStyle} />
      <div className="clickpay__step" style={{ display: activeTab === 'phone' ? 'block' : 'none' }}>
        <label
          data-text-editor
          data-editor-name={CURRENT_EDITOR.PHONE_INPUT_LABEL}
          onClick={openTextEditor(setActiveEditor)}
          style={titleStyle}
          htmlFor={phoneInputId}
          className={classnames(classNames.spacing, classNames.sectionTitle)}
        >
          {focusTextEditor === CURRENT_EDITOR.PHONE_INPUT_LABEL && elementId === activeElement.id ? (
            <TextEditorField
              editorType="inline-editor"
              type={activeElement.element.type}
              path={activeElement.path}
              // TODO имена тут и в fields нужно вынести в константы
              name="phoneInputLabel"
              isHorizontal={horizontal}
              values={values}
              section={section}
              form={form}
              element={activeElement.element}
              events={events}
              focusTextEditor={focusTextEditor}
            />
          ) : (
            <span dangerouslySetInnerHTML={{ __html: phoneInputLabel }} />
          )}
        </label>
        <div style={rowStyle} className={classNames.rowSection}>
          <div className={classNames.phoneSection} style={phoneStyle}>
            <div className={classNames.spacing2} style={isWidget ? { marginTop: 0 } : {}}>
              <input
                type="text"
                inputMode="numeric"
                style={phoneInputStyle}
                placeholder={phonePlaceholder}
                className={classnames(
                  classNames.input,
                  classNames.phone,
                  showText && isEditing && classNames.inputHasText,
                  !phoneInputPlaceholder && classNames.inputNoPlaceholder,
                  'has-text',
                )}
                id={phoneInputId}
              />
              <label
                htmlFor={phoneInputId}
                style={phoneLabelStyle}
                className={classNames.phoneLabel}
              >
                {phoneInputPlaceholder}
              </label>
            </div>

            <div className={classNames.spacing2}>
              <button className={classNames.cleanAmount} style={{ display: 'none' }} />
              <input
                type="text"
                inputMode="numeric"
                style={amountInputStyle}
                className={classnames(
                  classNames.input,
                  classNames.amount,
                  showText && classNames.inputHasText,
                  !amountInputPlaceholder && classNames.inputNoPlaceholder,
                  'has-text',
                )}
                id={amountInputId}
              />
              <label
                htmlFor={amountInputId}
                style={amountLabelStyle}
                className={classNames.amountLabel}
              >
                {amountInputPlaceholder}
              </label>
            </div>

            {isWidget && (
              <>
                <div>
                  <span
                    data-active-bg-color={badgeStyles.activeBgColor}
                    data-bg-color={badgeStyle.backgroundColor}
                    data-color={badgeStyle.color}
                    className={classNames.badge}
                    style={badgeStyle}
                  >
                    {badgeTexts.badgeText1}
                  </span>
                  <span
                    data-active-bg-color={badgeStyles.activeBgColor}
                    data-bg-color={badgeStyle.backgroundColor}
                    data-color={badgeStyle.color}
                    className={classNames.badge}
                    style={badgeStyle}
                  >
                    {badgeTexts.badgeText2}
                  </span>
                  <span
                    data-active-bg-color={badgeStyles.activeBgColor}
                    data-bg-color={badgeStyle.backgroundColor}
                    data-color={badgeStyle.color}
                    className={classNames.badge}
                    style={badgeStyle}
                  >
                    {badgeTexts.badgeText3}
                  </span>
                </div>

                <div
                  data-text-editor
                  data-editor-name={CURRENT_EDITOR.HELPER_TEXT}
                  onClick={openTextEditor(setActiveEditor)}
                  style={{ pointerEvents: 'auto', marginTop: '8px' }}
                >
                  {focusTextEditor === CURRENT_EDITOR.HELPER_TEXT && elementId === activeElement.id ? (
                    <TextEditorField
                      editorType="inline-editor"
                      type={activeElement.element.type}
                      path={activeElement.path}
                      // TODO имена тут и в fields нужно вынести в константы
                      name="helperText"
                      isHorizontal={horizontal}
                      values={values}
                      section={section}
                      form={form}
                      element={activeElement.element}
                      events={events}
                      focusTextEditor={focusTextEditor}
                    />
                  ) : (
                    <span dangerouslySetInnerHTML={{ __html: helperText }} />
                  )}
                </div>
              </>
            )}
          </div>
          {isWidget && (
            <div className={classNames.cardSection} style={cardStyle}>
              {image && (
                <div>
                  <img style={imageStyle} src={get(image, 'url')} alt="" />
                </div>
              )}

              <div className={classNames.spacing2}>
                <div className={classNames.humoIcon} />
                <div className={classNames.uzcardIcon} />
                <input
                  type="text"
                  inputMode="numeric"
                  style={cardInputStyle}
                  className={classnames(
                    classNames.input,
                    classNames.card,
                    showText && isEditing && classNames.inputHasText,
                    !cardInputPlaceholder && classNames.inputNoPlaceholder,
                    'has-text',
                  )}
                  id={cardInputId}
                />
                <label
                  htmlFor={cardInputId}
                  style={cardLabelStyle}
                  className={classNames.cardLabel}
                >
                  {cardInputPlaceholder}
                </label>
              </div>


              <div className={classnames(classNames.spacing2, classNames.cardDateContainer)} style={{ marginBottom: 0 }}>
                <div className={classNames.cardDateItem}>
                  <input
                    type="text"
                    inputMode="numeric"
                    style={cardInputStyle}
                    className={classnames(
                      classNames.input,
                      classNames.cardMonth,
                      showText && isEditing && classNames.inputHasText,
                      !cardMonthInputPlaceholder && classNames.inputNoPlaceholder,
                      'has-text',
                    )}
                    id={cardMonthInputId}
                  />
                  <label
                    htmlFor={cardMonthInputId}
                    style={cardLabelStyle}
                    className={classNames.cardMonthLabel}
                  >
                    {cardMonthInputPlaceholder}
                  </label>
                </div>

                <div className={classNames.cardDateItem} style={dateSeparator}>/</div>

                <div className={classNames.cardDateItem}>
                  <input
                    type="text"
                    inputMode="numeric"
                    style={cardInputStyle}
                    className={classnames(
                      classNames.input,
                      classNames.cardYear,
                      showText && isEditing && classNames.inputHasText,
                      !cardYearInputPlaceholder && classNames.inputNoPlaceholder,
                      'has-text',
                    )}
                    id={cardYearInputId}
                  />
                  <label
                    htmlFor={cardYearInputId}
                    style={cardLabelStyle}
                    className={classNames.cardYearLabel}
                  >
                    {cardYearInputPlaceholder}
                  </label>
                </div>
              </div>
            </div>
          )}
        </div>
        <div
          className={classnames(classNames.errorContainer, classNames.phoneErrorContainer)}
          style={errorTextContainerStyle}
        >
          {isShowText ? 'Here will be error text' : ''}
        </div>
        <div style={buttonsWrapperStyle}>
          <button
            id="get-code-btn"
            data-editor-name={CURRENT_EDITOR.PHONE_SUBMIT_TEXT}
            data-text-editor
            onClick={openTextEditor(setActiveEditor)}
            type={focusTextEditor === CURRENT_EDITOR.PHONE_SUBMIT_TEXT && elementId === activeElement.id ? 'button' : 'submit'}
            style={buttonStyle}
            className={classNames.depositBtn}
            {...analyticConstants.attributesToTrackClick}
          >
            {focusTextEditor === CURRENT_EDITOR.PHONE_SUBMIT_TEXT && elementId === activeElement.id
              ? (
                <TextEditorField
                  editorType="inline-editor"
                  type={activeElement.element.type}
                  path={activeElement.path}
                  isHorizontal={horizontal}
                  values={values}
                  section={section}
                  form={form}
                  element={activeElement.element}
                  events={events}
                  focusTextEditor={focusTextEditor}
                />
              )
              : (
                <span dangerouslySetInnerHTML={{ __html: text }} />
              )
            }
          </button>
        </div>
      </div>
      <div className="clickpay__step" style={{ display: activeTab === 'otp' ? 'block' : 'none' }}>
        <label
          data-text-editor
          data-editor-name={CURRENT_EDITOR.CLICK_PAY_OTP_LABEL}
          onClick={openTextEditor(setActiveEditor)}
          style={titleStyle}
          htmlFor={otpInputId}
          className={classnames(classNames.spacing, classNames.sectionTitleOtp)}
        >
          {focusTextEditor === CURRENT_EDITOR.CLICK_PAY_OTP_LABEL && elementId === activeElement.id ? (
            <TextEditorField
              editorType="inline-editor"
              type={activeElement.element.type}
              path={activeElement.path}
              // TODO имена тут и в fields нужно вынести в константы
              name="clickPayOtpLabel"
              isHorizontal={horizontal}
              values={values}
              section={section}
              form={form}
              element={activeElement.element}
              events={events}
              focusTextEditor={focusTextEditor}
            />
          ) : (
            <span dangerouslySetInnerHTML={{ __html: clickPayOtpLabel }} />
          )}
        </label>
        <div className={classNames.spacing2}>
          <input
            type="text"
            inputMode="numeric"
            style={amountInputStyle}
            className={classnames(
              classNames.input,
              classNames.inputOtp,
              showText && classNames.inputHasText,
              !otpInputPlaceholder && classNames.inputNoPlaceholder,
              'has-text',
            )}
            id={otpInputId}
          />
          <label
            htmlFor={otpInputId}
            style={amountLabelStyle}
            className={classNames.otpLabel}
          >
            {otpInputPlaceholder}
          </label>
        </div>
        <div
          className={classnames(classNames.errorContainer, classNames.otpErrorContainer)}
          style={errorTextContainerStyle}
        >
          {isShowText ? 'Here will be otp error text' : ''}
        </div>
        <div style={buttonsWrapperStyle}>
          <button
            className={classNames.otpBtn}
            id="send-otp-btn"
            data-editor-name={CURRENT_EDITOR.CLICK_PAY_OTP_SEND_BTN}
            data-text-editor
            onClick={openTextEditor(setActiveEditor)}
            type={focusTextEditor === CURRENT_EDITOR.CLICK_PAY_OTP_SEND_BTN && elementId === activeElement.id ? 'button' : 'submit'}
            style={buttonStyle}
            {...analyticConstants.attributesToTrackClick}
          >
            {focusTextEditor === CURRENT_EDITOR.CLICK_PAY_OTP_SEND_BTN && elementId === activeElement.id
              ? (
                <TextEditorField
                  editorType="inline-editor"
                  type={activeElement.element.type}
                  path={activeElement.path}
                  name="clickPayOtpSendBtn"
                  isHorizontal={horizontal}
                  values={values}
                  section={section}
                  form={form}
                  element={activeElement.element}
                  events={events}
                  focusTextEditor={focusTextEditor}
                />
              )
              : (
                <span dangerouslySetInnerHTML={{ __html: clickPayOtpSendBtn }} />
              )
            }
          </button>
        </div>
        <button
          data-editor-name={CURRENT_EDITOR.CLICK_FORM_BUTTON_BACK_TEXT}
          data-text-editor
          className={classNames.buttonBack}
          onClick={openTextEditor(setActiveEditor)}
          type="button"
          style={{
            ...buttonStyle,
            backgroundColor: 'transparent',
            color: buttonStyles.backgroundColor,
            boxShadow: 'none',
            display: activeTab !== 'personal' ? 'block' : 'none',
          }}
          {...analyticConstants.attributesToTrackClick}
        >
          {focusTextEditor === CURRENT_EDITOR.CLICK_FORM_BUTTON_BACK_TEXT && id === activeElement.id ? (
            <TextEditorField
              editorType="inline-editor"
              name="clickBackButtonText"
              type={activeElement.element.type}
              path={activeElement.path}
              isHorizontal={horizontal}
              values={values}
              section={section}
              form={form}
              element={activeElement.element}
              events={events}
              focusTextEditor={focusTextEditor}
            />
          ) : (
            <span dangerouslySetInnerHTML={{ __html: clickBackButtonText }} />
          )
          }
        </button>
      </div>
      <div className="clickpay__step" style={{ display: activeTab === 'success' ? 'block' : 'none' }}>
        <div
          data-text-editor
          data-editor-name={CURRENT_EDITOR.CLICK_PAY_SUCCESS_TEXT}
          onClick={openTextEditor(setActiveEditor)}
          style={titleStyle}
          className={classnames(classNames.spacing, classNames.sectionTitleSuccess)}
        >
          {focusTextEditor === CURRENT_EDITOR.CLICK_PAY_SUCCESS_TEXT && elementId === activeElement.id ? (
            <TextEditorField
              editorType="inline-editor"
              type={activeElement.element.type}
              path={activeElement.path}
              // TODO имена тут и в fields нужно вынести в константы
              name="clickPaySuccessText"
              isHorizontal={horizontal}
              values={values}
              section={section}
              form={form}
              element={activeElement.element}
              events={events}
              focusTextEditor={focusTextEditor}
            />
          ) : (
            <span dangerouslySetInnerHTML={{ __html: clickPaySuccessText }} />
          )}
        </div>
      </div>
    </div>
  );
};
