const bgImageOptionMapping = {
  repeat: {
    backgroundRepeat: 'repeat',
    backgroundSize: 'unset'
  },
  fill: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%'
  },
  stretch: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  },
  center: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'auto'
  }
};

const bgStyles = ({ color, option, image }) => {
  if (image) {
    return {
      backgroundColor: color,
      backgroundImage: image === 'unset' ? 'unset' : `url(${image})`,
      backgroundPosition: 'center center',
      ...(bgImageOptionMapping[option] || {})
    };
  }

  return { backgroundColor: color };
};

export default bgStyles;
