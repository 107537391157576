import React from 'react';
import PropTypes from 'prop-types';

const SupportIcon = ({ fillColor, ...props }) => (
  <svg width={16} height={16} {...props}>
    <defs>
      <style>
        {
          `.support_svg__a{fill:${fillColor}.support_svg__e{fill:none}.support_svg__d{stroke:none}`
        }
      </style>
    </defs>
    <path
      fill={fillColor}
      d="M3.959 1.535L14.464 12.04l-2.424 2.425L1.534 3.96z"
    />
    <path
      fill={fillColor}
      d="M1.535 12.041L12.04 1.536l2.425 2.424L3.96 14.466z"
    />
    <g stroke={fillColor} strokeWidth={1.35} fill="none">
      <circle className="support_svg__d" cx={8} cy={8} r={8} />
      <circle className="support_svg__e" cx={8} cy={8} r={7.325} />
    </g>
    <g
      transform="translate(4.571 4.571)"
      fill="#fff"
      stroke={fillColor}
      strokeWidth={1.35}
    >
      <circle className="support_svg__d" cx={3.429} cy={3.429} r={3.429} />
      <circle className="support_svg__e" cx={3.429} cy={3.429} r={2.754} />
    </g>
  </svg>
);

SupportIcon.propTypes = {
  fillColor: PropTypes.string.isRequired,
};


export default SupportIcon;
