/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

const PromoCodeIcon = ({ fillColor, ...props }) => (
  <svg width={16} height={16} {...props} viewBox="0 0 24 24">
    <path fill={fillColor} d="M2,7V5A2,2 0 0,1 4,3H20A2,2 0 0,1 22,5V19A2,2 0 0,1 20,21H4A2,2 0 0,1 2,19V17H4V19H20V5H4V7H2M7,8H17V10H7V8M7,11H17V13H7V11M7,14H14V16H7V14Z"/>
  </svg>
);

PromoCodeIcon.propTypes = {
  fillColor: PropTypes.string.isRequired,
};

export default PromoCodeIcon;
