import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MenuPanel from './MenuPanel';

const styles = theme => ({
  baseMenuDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      height: '100%',
    },
  },
});

function BaseMenu({ classes }) {
  return (
    <div className={classes.baseMenuDesktop}>
      <MenuPanel />
    </div>
  );
}

BaseMenu.propTypes = {
  classes: PropTypes.shape(),
};

BaseMenu.defaultProps = {
  classes: {},
};

export default memo(withStyles(styles)(BaseMenu));
