import { get } from 'lodash';

import { FORM_WIDGET_ELEMENT_CHILDREN_KEY, FORM_WIDGET_SCHEMA } from '../constants';
import { elements as ELEMENTS } from '../constants/onsiteWidgets';

import { getUniqueIdWithPrefix } from './getUniqueIdWithPrefix';
import '../types';

/**
 * @param {string} id                                                 - child element id
 * @param {OnsiteWidget.Element[]} elements      - parent element
 * @returns {{
 *  path: string,
 *  child: Element,
 *  parentIndex: number
 * }} result
 *
 */
const findChildElement = (id, element, path) => {
  if (element.id === id) {
    return {
      path,
      child: element,
    };
  }

  if (Array.isArray(element.childrenElements)) {
    for (let i = 0; i < element.childrenElements.length; i++) {
      const child = element.childrenElements[i];
      const childPath = `${path}.${FORM_WIDGET_ELEMENT_CHILDREN_KEY}.${i}`;
      const result = findChildElement(id, child, childPath);
      if (result) {
        return result;
      }
    }
  }

  if (element.type === ELEMENTS.STEPPER && element.steps) {
    for (let i = 0; i < element.steps.length; i++) {
      const step = element.steps[i];
      if (!Array.isArray(step.childrenElements)) {
        continue;
      }

      for (let j = 0; j < step.childrenElements.length; j++) {
        const child = step.childrenElements[j];
        const childPath = `${path}.steps.${i}.${FORM_WIDGET_ELEMENT_CHILDREN_KEY}.${j}`;
        const result = findChildElement(id, child, childPath);
        if (result) {
          return result;
        }
      }
    }
  }

  return null;
};

export const findChildren = (id, elements) => {
  for (let i = 0; i < elements.length; i++) {
    const element = elements[i];
    const path = `${i}`;
    const result = findChildElement(id, element, path);
    if (result) {
      return {
        ...result,
        parentIndex: i,
      };
    }
  }

  return {
    path: '',
    child: null,
    parentIndex: -1,
  };
};

// TODO findElementByPath does't search item if there are two columns.
// selectElementToInsert should pass not only 'items' but also ['items', 'items2'].
export const findElementByPath = (id, formValues, itemsKey) => {
  const {
    name,
  } = FORM_WIDGET_SCHEMA;

  const items = get(formValues, `${name}.${itemsKey}`, []);
  let index = items.findIndex((el) => el.id === id);
  const childrenPath = '';

  let path = index >= 0 ? `${itemsKey}.${index}` : '';

  let element = items[index] || {};


  if (!path) {
    const { child, path: currentPath, parentIndex } = findChildren(id, items);

    index = parentIndex;

    if (child) {
      path = `${itemsKey}${path}.${currentPath}`;
      // path = `${path}.${childrenPath}`
      element = child;
    }
  }

  let parentPath = path.split('.');
  parentPath.pop();
  parentPath = parentPath.join('.');

  return {
    id,
    itemIndex: index,
    itemsKey,
    path,
    childrenPath,
    parentPath,
    element,
  };
};

/**
 *
 * @param {string} id
 * @param {object} formValues - form object
 */
export const findElement = (id, formValues) => {
  const {
    name,
    properties: { items2: items2Key, items: itemsKey },
  } = FORM_WIDGET_SCHEMA;

  const items = get(formValues, `${name}.${itemsKey}`, []);
  const items2 = get(formValues, `${name}.${items2Key}`, []);

  let index = items.findIndex((el) => el.id === id);
  let index2 = items2.findIndex((el) => el.id === id);
  const childrenPath = '';

  let path = index >= 0 ? `${itemsKey}.${index}` : '';
  path = index2 >= 0 ? `${items2Key}.${index2}` : path;

  let element = {};
  if (index >= 0) {
    element = items[index];
  } else if (index2 >= 0) {
    element = items2[index2];
  }

  if (!path) {
    const { child: child1, path: path1, parentIndex: parentIndex1 } = findChildren(id, items);
    const { child: child2, path: path2, parentIndex: parentIndex2 } = findChildren(id, items2);

    index = parentIndex1;
    index2 = parentIndex2;

    if (child1) {
      path = `${itemsKey}.${path1}`;
      element = child1;
    }

    if (child2) {
      path = `${items2Key}.${path2}`;
      element = child2;
    }
  }

  const itemIndex = index >= 0 ? index : index2;

  let itemsKeyResult = '';
  if (index >= 0) {
    itemsKeyResult = itemsKey;
  } else if (index2 >= 0) {
    itemsKeyResult = items2Key;
  }

  let parentPath = path.split('.');
  parentPath.pop();
  parentPath = parentPath.join('.');

  return {
    id,
    itemIndex,
    itemsKey: itemsKeyResult,
    path,
    childrenPath,
    parentPath,
    element,
  };
};

/**
 * Helper to get children from element.
 */
const elementChildrenParsers = {
  [ELEMENTS.STEPPER]: (element) => element?.steps
    .map((step) => step[FORM_WIDGET_ELEMENT_CHILDREN_KEY] || [])
    .reduce((acc, child) => [...acc, ...child], []),
};

const getElementChildrensArray = (element) => {
  if (elementChildrenParsers[element?.type]) {
    return elementChildrenParsers[element?.type](element);
  }

  return element[FORM_WIDGET_ELEMENT_CHILDREN_KEY] || [];
};

/**
 * Recursive find all children
 * @param {OnsiteWidget.Element} item
 */
const findChildrenRecurive = (item) => {
  const result = [];
  const children = getElementChildrensArray(item);

  children.forEach((child) => {
    result.push(...findChildrenRecurive(child));
  });

  return [...children, ...result];
};

/**
 * Apply function to all widget elements
 * @param {OnsiteWidget.Widget} widget
 * @returns
 */
export const applyToAllElements = () => { };

/**
 * Return all elements with children.
 *
 * @param {OnsiteWidget.Element[]} items
 */
export const getAllElements = (items) => {
  const children = [];
  items.forEach((item) => {
    children.push(...findChildrenRecurive(item));
  });

  return [...items, ...children];
};

/**
 * @param {object} widget
 * @returns {OnsiteWidget.Element[]}
 */
export const getAllElementsFromForm = (widget) => {
  const items1 = get(widget, FORM_WIDGET_SCHEMA.properties.items, []);
  const items2 = get(widget, FORM_WIDGET_SCHEMA.properties.items2, []);
  const allElements = getAllElements([...items1, ...items2]);
  return allElements;
};

/**
 * @param {OnsiteWidget.Element[]} items
 * @returns {boolean}
 */
export const hasErrorBoundary = (items) => items.some((el) => el.type === ELEMENTS.ERROR_BOUNDARY);

const getParentArray = (element, formValues) => {
  const { name } = FORM_WIDGET_SCHEMA;
  const arr = get(formValues, `${name}.${element.parentPath}`, []);
  const elIndex = arr.findIndex((item) => item.id === element.element.id);

  return {
    array: arr,
    index: elIndex,
  };
};

export const moveElement = (currentEl, array, index, targetIndex) => {
  if (targetIndex > array.length) {
    return {
      path: currentEl.parentPath,
      value: array,
    };
  }
  const tmp = { ...array[index] };

  array.splice(index, 1);
  array.splice(targetIndex, 0, tmp);

  return {
    path: currentEl.parentPath,
    value: array,
  };
};

const getCurrentEl = (id, formValues, path) => {
  let currentEl = findElementByPath(id, formValues, path);
  if (Object.keys(currentEl.element).length === 0) {
    currentEl = findElement(id, formValues);
  }
  return currentEl;
};

export const moveElementUp = (id, formValues, path) => {
  const currentEl = getCurrentEl(id, formValues, path);
  const { array, index } = getParentArray(currentEl, formValues);
  const targetIndex = index - 1;
  return moveElement(currentEl, array, index, targetIndex);
};

export const moveElementDown = (id, formValues, path) => {
  const currentEl = getCurrentEl(id, formValues, path);
  const { array, index } = getParentArray(currentEl, formValues);
  const targetIndex = index + 1;

  return moveElement(currentEl, array, index, targetIndex);
};

export const removeElement = (id, formValues, path) => {
  const currentEl = getCurrentEl(id, formValues, path);
  const { array, index } = getParentArray(currentEl, formValues);

  if (index >= 0) {
    array.splice(index, 1);
  }

  return {
    path: currentEl.parentPath,
    value: array,
  };
};

export const createEventString = (event, trigger) => {
  if (!event) return '';

  return JSON.stringify({
    trigger,
    event,
  });
};

/**
 *
 * @param {string} id
 * @param {'up' | 'down'} direction
 */
export const isDisabledMoveElement = (currentEl, formValues, direction) => {
  const { array, index } = getParentArray(currentEl, formValues);

  if (direction === 'up') {
    const targetIndex = index - 1;
    return targetIndex < 0;
  }

  if (direction === 'down') {
    const targetIndex = index + 1;
    return targetIndex >= array.length;
  }

  return null;
};

/**
 * Return possible children elements to insert.
 * @returns {string[]}
 */
export const getPossibleChildrenElements = () => Object.values(ELEMENTS).filter((el) => el !== ELEMENTS.ERROR_BOUNDARY);

/**
 * @param {OnsiteWidget.Element} element
 * @returns {string}
 */
export const createWidgetElementId = (element) => getUniqueIdWithPrefix(element.type);

/**
 * Create new element with new id.
 *
 * @param {OnsiteWidget.Element} element
 * @returns {OnsiteWidget.Element}
 */
export const cloneWidgetElement = (element) => ({
  ...element,
  id: createWidgetElementId(element),
  oldId: element.id,
});

/**
 * Find element by old id.
 * Can be used only for copied company.
 *
 * @param {OnsiteWidget.Element[]} elements
 * @param {string} id
 * @returns {OnsiteWidget.Element[] | null}
 */
export const findElementAfterClone = (elements, id) => elements.find((el) => el.oldId === id);

/**
 * Get element type from id.
 *
 * @param {string} id
 */
export const getElementTypeFromId = (id) => id.split('-')[0];
