import { createAction } from 'redux-actions';
import { createRoutine, bindRoutineToReduxForm } from 'redux-saga-routines';

const CREATE_ONSITE = '@onsite/CREATE_ONSITE';
const FETCH_ONSITE = '@onsite/FETCH_ONSITE';
const FETCH_DOWNLOAD_STAT = '@onsite/FETCH_DOWNLOAD_STAT';
const PUT_ONSITE = '@onsite/PUT_ONSITE';
const UPDATE_ONSITE = '@onsite/UPDATE_ONSITE';
const HOLD_ONSITE = '@onsite/HOLD_ONSITE';
const ACTIVATE_ONSITE = '@onsite/ACTIVATE_ONSITE';
const FETCH_ONSITE_LIST = '@onsite/FETCH_ONSITE_LIST';
const PUT_ONSITE_LIST = '@onsite/PUT_ONSITE_LIST';
const FETCH_ONSITE_VISITORS = '@onsite/FETCH_VISITORS';
const PUT_ONSITE_VISITORS = '@onsite/PUT_VISITORS';
const FETCH_ONSITE_CAMPAIGN_NAMES = '@onsite/FETCH_CURRENT_CAMPAIGN_NAMES';
const PUT_ONSITE_CAMPAIGN_NAMES = '@onsite/PUT_CAMPAIGN_NAMES';
const FETCH_TEMPLATE_LIST = '@onsite/FETCH_TEMPLATE_LIST';
const PUT_TEMPLATE_LIST = '@onsite/PUT_TEMPLATE_LIST';
const POST_TEMPLATE = '@onsite/POST_TEMPLATE';
const DELETE_TEMPLATE = '@onsite/DELETE_TEMPLATE';
const VALIDATE_ONSITE_CAMPAIGN_NAME = '@onsite/VALIDATE_CAMPAIGN_NAME';
const UPLOAD_ONSITE_PROMO_CODES_FILE = '@onsite/UPLOAD_PROMO_CODES_FILE';

export const createOnsiteRoutine = createRoutine(CREATE_ONSITE);
export const createOnsiteHandler = bindRoutineToReduxForm(createOnsiteRoutine);

export const activateOnsite = createAction(ACTIVATE_ONSITE);
export const holdOnsite = createAction(HOLD_ONSITE);

export const fetchOnsite = createAction(FETCH_ONSITE);
export const putOnsite = createAction(PUT_ONSITE);
export const updateOnsite = createAction(UPDATE_ONSITE);

export const fetchDownloadStat = createAction(FETCH_DOWNLOAD_STAT);

export const fetchOnsiteList = createAction(FETCH_ONSITE_LIST);
export const putOnsiteList = createAction(PUT_ONSITE_LIST);

export const fetchTemplateList = createAction(FETCH_TEMPLATE_LIST);
export const putTemplateList = createAction(PUT_TEMPLATE_LIST);

export const postTemplate = createAction(POST_TEMPLATE);
export const deleteTemplate = createAction(DELETE_TEMPLATE);

export const fetchVisitors = createAction(FETCH_ONSITE_VISITORS);
export const putVisitors = createAction(PUT_ONSITE_VISITORS);

export const fetchCurrentCampaignNames = createAction(
  FETCH_ONSITE_CAMPAIGN_NAMES
);
export const putCampaignNames = createAction(PUT_ONSITE_CAMPAIGN_NAMES);

export const validateCampaignName = createAction(VALIDATE_ONSITE_CAMPAIGN_NAME);

export const uploadPromoCodesFile = createAction(
  UPLOAD_ONSITE_PROMO_CODES_FILE
);
