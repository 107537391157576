import React from "react";
import { Button } from "./items/Button";
import { Form } from "./items/Form";
import { Image } from "./items/Image";
import { OrderForm } from "./items/OrderForm";
import { ProductCard } from "./items/ProductCard";
import { Video } from "./items/Video";
import { Separator } from "./items/Separator";
import { Text } from "./items/Text/Text";
import { Social } from "./items/Social";
import { Rating } from "./items/Rating";
import Stepper from "./items/Stepper";
import Collapse from "./items/Collapse";
import { isHorizontal, elements } from "../constants/onsiteWidgets";
import { PhoneVerification } from "./items/PhoneVerification";
import { ClickPay } from "./items/ClickPay";

const elementByTypeMapping = {
  [elements.BUTTON]: Button,
  [elements.FORM]: Form,
  [elements.ORDER_FORM]: OrderForm,
  [elements.IMAGE]: Image,
  [elements.VIDEO]: Video,
  [elements.SEPARATOR]: Separator,
  [elements.TEXT]: Text,
  [elements.SOCIAL]: Social,
  [elements.RATING]: Rating,
  [elements.COLLAPSE]: Collapse,
  [elements.STEPPER]: Stepper,
  [elements.PHONE_VERIFICATION]: PhoneVerification,
  [elements.CLICK_PAY]: ClickPay,
  [elements.PRODUCT_CARD]: ProductCard,
};

const commonStyle = {
  display: "flex",
};

const getCommonItemStyle = (horizontal, additionStyles) =>
  horizontal
    ? {
        ...commonStyle,
        ...additionStyles,
        flexDirection: "row",
        marginRight: "10px",
      }
    : {
        ...commonStyle,
        ...additionStyles,
        flexDirection: "column",
        // marginBottom: "10px",
      };

export const ItemComponent = ({
  widgetType,
  layoutProps,
  type,
  id,
  alignItems,
  width,
  ...itemProps
}) => {
  const horizontal = isHorizontal(widgetType);
  const Item = elementByTypeMapping[type];

  if (!Item) {
    return null;
  }

  return (
    <Item
      itemId={id}
      layoutProps={layoutProps}
      commonStyle={getCommonItemStyle(horizontal, { alignItems, width })}
      widgetType={widgetType}
      alignItems={alignItems}
      width={width}
      {...itemProps}
    />
  );
};
