import set from 'lodash/set';
import { call, put, takeLatest, delay, cancel } from 'redux-saga/effects';
import {
  change,
  startAsyncValidation,
  stopAsyncValidation,
  touch
} from 'redux-form';
import {
  mapFormValuesToCpaLoyalty,
  mapFormValuesToCpaOffer,
  mapCpaLoyaltyResponseToFormValues,
  mapCpaOfferResponseToFormValues
} from '../mappers/cpaMappers';
import {
  cpa as cpaActions,
  snackbar as snackbarActions
} from '../redux/actions';
import api from '../api/cpa';
import { fileToB64 } from '../help';

function* createCpaLoyalty({ payload: { values } }) {
  try {
    yield put(cpaActions.createCpaLoyaltyRoutine.request());
    const transformed = mapFormValuesToCpaLoyalty(values);
    const { id } = values;

    const cpaLoyalty = yield call(api.postCpaLoyaltyCampaign, id, transformed);

    yield put(cpaActions.createCpaLoyaltyRoutine.success(cpaLoyalty));

    if (id) {
      yield put(
        snackbarActions.enqueueSnackbar({
          message: 'Loyalty campaign has been updated successfully!',
          options: { variant: 'success' }
        })
      );
    } else {
      yield put(
        snackbarActions.enqueueSnackbar({
          message: 'Loyalty campaign has been created successfully!',
          options: { variant: 'success' }
        })
      );
    }
  } catch (error) {
    yield put(cpaActions.createCpaLoyaltyRoutine.failure(error));
    yield put(
      snackbarActions.enqueueSnackbar({
        message: error.message,
        options: { variant: 'error' }
      })
    );
  } finally {
    yield put(cpaActions.createCpaLoyaltyRoutine.fulfill());
  }
}

function* createCpaOffer({ payload: { values } }) {
  try {
    const { id } = values;
    yield put(cpaActions.createCpaOfferRoutine.request());
    const [transformed, file, filename] = mapFormValuesToCpaOffer(values);

    const data = new FormData();
    data.append('form', JSON.stringify(transformed));
    if (file) {
      data.append('file', file, filename);
    }
    const cpaOffer = yield call(api.postCpaOfferCampaign, id, data);

    yield put(cpaActions.createCpaOfferRoutine.success(cpaOffer));
    if (id) {
      yield put(
        snackbarActions.enqueueSnackbar({
          message: 'Offer campaign has been updated successfully!',
          options: { variant: 'success' }
        })
      );
    } else {
      yield put(
        snackbarActions.enqueueSnackbar({
          message: 'Offer campaign has been created successfully!',
          options: { variant: 'success' }
        })
      );
    }
  } catch (error) {
    yield put(cpaActions.createCpaOfferRoutine.failure(error));
    yield put(
      snackbarActions.enqueueSnackbar({
        message: error.message,
        options: { variant: 'error' }
      })
    );
  } finally {
    yield put(cpaActions.createCpaOfferRoutine.fulfill());
  }
}

function* validateLoyaltyCampaignName({
  payload: { name, id, resolve, reject, field }
}) {
  try {
    if (name) {
      yield delay(500);

      const result = yield call(api.validateCpaLoyaltyCampaignName, name, id);

      if (result) {
        resolve();
      } else {
        reject({ offerWall: { campaignName: 'This name is already used' } });
      }
    }
  } catch (error) {
    reject();
    yield put(
      snackbarActions.enqueueSnackbar({
        message: 'Campaign name validation problem!',
        options: { variant: 'error' }
      })
    );
  }
}

function* validateOfferCampaignName({
  payload: { name, id, resolve, reject }
}) {
  try {
    if (name) {
      yield delay(500);

      const result = yield call(api.validateCpaOfferCampaignName, name, id);

      if (result) {
        resolve();
      } else {
        reject({ campaign_name: 'This name is already used' });
      }
    }
  } catch (error) {
    reject();
    yield put(
      snackbarActions.enqueueSnackbar({
        message: 'Campaign name validation problem!',
        options: { variant: 'error' }
      })
    );
  }
}

const STATUSES = {
  active: 1,
  onHold: 2,
  archived: 3
};

const INVERT_STATUSES = {
  1: 'active',
  2: 'onHold',
  3: 'archived'
};

const mapStatus = list =>
  list.map(item => ({ ...item, status: INVERT_STATUSES[item.status] }));

function* fetchCpaList({ payload: { page, perPage, status, search } }) {
  try {
    const [list, total] = yield call(api.fetchCampaignList, {
      page,
      perPage,
      status: STATUSES[status],
      search: search
    });

    yield put(cpaActions.putCpaCampaignList({ list: mapStatus(list), total }));
  } catch (error) {
    yield put(
      snackbarActions.enqueueSnackbar({
        message: 'Loading campaign list error occurred!',
        options: { variant: 'error' }
      })
    );
  }
}

function* fetchCpaOfferList({ payload: { page, perPage, status, search } }) {
  try {
    const [list, total] = yield call(api.fetchOfferList, {
      page,
      perPage,
      status: STATUSES[status],
      search: search
    });

    yield put(cpaActions.putCpaCampaignList({ list: mapStatus(list), total }));
  } catch (error) {
    yield put(
      snackbarActions.enqueueSnackbar({
        message: 'Loading campaign list error occurred!',
        options: { variant: 'error' }
      })
    );
  }
}



function* activateCpa({ payload: { id, status } }) {
  try {
    yield call(api.changeStatus, id, STATUSES.active);
    yield put(
      cpaActions.fetchCpaCampaignList({ page: 1, perPage: 10, status })
    );

    yield put(
      snackbarActions.enqueueSnackbar({
        message: 'Campaign status has been changed successfully!',
        options: { variant: 'success' }
      })
    );
  } catch (error) {
    const message = error.request && error.request.status === 403 ? 'You cannot set Active status!' : 'Change status error occurred!'
    yield put(
      snackbarActions.enqueueSnackbar({
        message,
        options: { variant: 'error' }
      })
    );
  }
}

function* holdCpa({ payload: { id, status } }) {
  try {
    yield call(api.changeStatus, id, STATUSES.onHold);
    yield put(
      cpaActions.fetchCpaCampaignList({ page: 1, perPage: 10, status })
    );

    yield put(
      snackbarActions.enqueueSnackbar({
        message: 'Campaign status has been changed successfully!',
        options: { variant: 'success' }
      })
    );
  } catch (error) {
    yield put(
      snackbarActions.enqueueSnackbar({
        message: 'Change status error occurred!',
        options: { variant: 'error' }
      })
    );
  }
}

function* activateOffer({ payload: { id, status } }) {
  try {
    yield call(api.changeStatusOffer, id, STATUSES.active);
    yield put(
      cpaActions.fetchCpaOfferList({ page: 1, perPage: 10, status })
    );

    yield put(
      snackbarActions.enqueueSnackbar({
        message: 'Offer status has been changed successfully!',
        options: { variant: 'success' }
      })
    );
  } catch (error) {
    const message = error.request && error.request.status === 403 ? 'You cannot set Active status!' : 'Change status error occurred!'
    yield put(
      snackbarActions.enqueueSnackbar({
        message,
        options: { variant: 'error' }
      })
    );
  }
}

function* holdOffer({ payload: { id, status } }) {
  try {
    yield call(api.changeStatusOffer, id, STATUSES.onHold);
    yield put(
      cpaActions.fetchCpaOfferList({ page: 1, perPage: 10, status })
    );

    yield put(
      snackbarActions.enqueueSnackbar({
        message: 'Offer status has been changed successfully!',
        options: { variant: 'success' }
      })
    );
  } catch (error) {
    yield put(
      snackbarActions.enqueueSnackbar({
        message: 'Change status error occurred!',
        options: { variant: 'error' }
      })
    );
  }
}

function* fetchCpaOffer({ payload: id }) {
  try {
    const offer = yield call(api.fetchCpaOfferCampaign, id);

    yield put(cpaActions.putCpaOffer(mapCpaOfferResponseToFormValues(offer)));
  } catch (error) {
    yield put(
      snackbarActions.enqueueSnackbar({
        message: 'Can not load the campaign!',
        options: { variant: 'error' }
      })
    );
  }
}

function* fetchCpaLoyalty({ payload: id }) {
  try {
    const loyalty = yield call(api.fetchCpaLoyaltyCampaign, id);

    yield put(
      cpaActions.putCpaLoyalty(mapCpaLoyaltyResponseToFormValues(loyalty))
    );
  } catch (error) {
    yield put(
      snackbarActions.enqueueSnackbar({
        message: 'Can not load the campaign!',
        options: { variant: 'error' }
      })
    );
  }
}

function* uploadPromoCodesFile({
  payload: { file, formName, field, fileFiled }
}) {
  try {
    yield put(startAsyncValidation(formName));
    const { success, count } = yield call(api.sendPromoCodesFile, file);

    if (!success) {
      yield put(touch(formName, field));
      yield put(
        stopAsyncValidation(
          formName,
          set({}, field, 'Error with file uploading')
        )
      );
      yield cancel();
    }

    const base64File = yield call(fileToB64, file);

    yield put(change(formName, field, count, true));
    yield put(
      change(formName, fileFiled, { file: base64File, name: file.name }, true)
    );
    yield put(stopAsyncValidation(formName));
  } catch (error) {
    yield put(touch(formName, field));
    yield put(
      stopAsyncValidation(formName, set({}, field, 'Error with file uploading'))
    );
  }
}

function* fetchCurrentCampaignNames({ payload: name = '' }) {
  try {
    const result = yield call(api.fetchCpaOfferCampaignNames, name);

    yield put(cpaActions.putCampaignNames(result));
  } catch (error) {}
}

function* fetchMsisdnStatistics({ payload: { value, page, perPage } }) {
  try {
    yield delay(500);

    const [list, total] = yield call(
      api.fetchMsisdnActivities,
      value,
      page,
      perPage
    );

    if (total === 0) {
      yield put(
        snackbarActions.enqueueSnackbar({
          message: 'There is no result for this MSISDN.',
          options: { variant: 'info' }
        })
      );

      return;
    }

    yield put(cpaActions.putCpaMsisdnStatistics({ list, total }));
  } catch (error) {
    yield put(
      snackbarActions.enqueueSnackbar({
        message: 'Request statistics error occurred!',
        options: { variant: 'error' }
      })
    );
  }
}

function* fetchMsisdnLogs({ payload: { text, dateFrom, dateTo } }) {
  try {
    const logs = yield call(api.fetchMsisdnLogs, text, dateFrom, dateTo);
    if (logs.length === 0) {
      yield put(cpaActions.putCpaMsisdnLogs(logs));
      yield put(
        snackbarActions.enqueueSnackbar({
          message: 'There is no result for this MSISDN for this period',
          options: { variant: 'info' }
        })
      );

      return;
    }

    yield put(cpaActions.putCpaMsisdnLogs(logs));
  } catch (error) {
    yield put(
      snackbarActions.enqueueSnackbar({
        message: 'Request logs error occurred!',
        options: { variant: 'error' }
      })
    );
  }
}

export default function* watcher() {
  yield takeLatest(
    cpaActions.createCpaLoyaltyRoutine.TRIGGER.toString(),
    createCpaLoyalty
  );
  yield takeLatest(
    cpaActions.createCpaOfferRoutine.TRIGGER.toString(),
    createCpaOffer
  );
  yield takeLatest(
    cpaActions.validateCpaLoyaltyCampaignName.toString(),
    validateLoyaltyCampaignName
  );
  yield takeLatest(
    cpaActions.validateCpaOfferCampaignName.toString(),
    validateOfferCampaignName
  );
  yield takeLatest(cpaActions.fetchCpaCampaignList.toString(), fetchCpaList);
  yield takeLatest(cpaActions.fetchCpaOfferList.toString(), fetchCpaOfferList);
  yield takeLatest(cpaActions.activateCpaCampaign.toString(), activateCpa);
  yield takeLatest(cpaActions.holdCpaCampaign.toString(), holdCpa);
  yield takeLatest(cpaActions.activateCpaOffer.toString(), activateOffer);
  yield takeLatest(cpaActions.holdCpaOffer.toString(), holdOffer);
  yield takeLatest(cpaActions.fetchCpaOffer.toString(), fetchCpaOffer);
  yield takeLatest(cpaActions.fetchCpaLoyalty.toString(), fetchCpaLoyalty);
  yield takeLatest(
    cpaActions.uploadPromoCodesFile.toString(),
    uploadPromoCodesFile
  );
  yield takeLatest(
    cpaActions.fetchCurrentCampaignNames.toString(),
    fetchCurrentCampaignNames
  );
  yield takeLatest(
    cpaActions.fetchCpaMsisdnStatistics.toString(),
    fetchMsisdnStatistics
  );
  yield takeLatest(cpaActions.fetchCpaMsisdnLogs.toString(), fetchMsisdnLogs);
}
