import set from 'lodash/set';
import { call, put, takeLatest, delay, cancel } from 'redux-saga/effects';
import {
  change,
  startAsyncValidation,
  stopAsyncValidation,
  touch
} from 'redux-form';
import { socialSharing as actions } from '../redux/actions';
import { socialSharing as api } from '../api';
import { fileToB64 } from '../help';

import {
  mapFormValuesToSocialSharing,
  mapSocialSharingToFormValues
} from '../mappers/socialSharingMappers';

function* createSocialSharing({ payload: { values } }) {
  try {
    yield put(actions.createSocialSharingRoutine.request());
    const [transformedValues, file, filename] = mapFormValuesToSocialSharing(
      values
    );

    const { id } = transformedValues;
    const data = new FormData();
    data.append('form', JSON.stringify(transformedValues));
    if (file) {
      data.append('file', file, filename);
    }
    const campaign = yield call(api.postCampaign, id, data);

    yield put(actions.createSocialSharingRoutine.success(campaign));
  } catch (error) {
    yield put(actions.createSocialSharingRoutine.failure(error));
  } finally {
    yield put(actions.createSocialSharingRoutine.fulfill());
  }
}

const STATUSES = {
  active: 1,
  onHold: 2,
  archived: 3
};

const INVERT_STATUSES = {
  1: 'active',
  2: 'onHold',
  3: 'archived'
};

function* activateSocialSharing({ payload: { id, pageView } }) {
  try {
    yield call(api.changeStatus, id, STATUSES.active);
    yield put(
      actions.fetchSocialSharingList({page: 1, perPage: 10, status: pageView})
    );
  } catch (error) {}
}

function* holdSocialSharing({ payload: { id, pageView } }) {
  try {
    yield call(api.changeStatus, id, STATUSES.onHold);
    yield put(
      actions.fetchSocialSharingList({page: 1, perPage: 10, status: pageView})
    );
  } catch (error) {}
}

function* fetchSocialSharing({ payload: id }) {
  try {
    const campaign = yield call(api.fetchCampaign, id);

    yield put(actions.putSocialSharing(mapSocialSharingToFormValues(campaign)));
  } catch (error) {}
}

const mapStatus = list =>
  list.map(item => ({ ...item, status: INVERT_STATUSES[item.status] }));

function* fetchSocialSharingList({ payload: { page, perPage, status, search } }) {
  try {
    const [list, total] = yield call(api.fetchCampaignList, {
      page,
      perPage,
      status: STATUSES[status],
      search: search
    });

    yield put(actions.putCampaignList({ list: mapStatus(list), total }));
  } catch (error) {}
}

function* fetchVisitors({ payload: { fields, formName } }) {
  try {
    yield delay(1000);
    const result = yield call(api.fetchVisitors, fields);

    yield put(actions.putVisitors(result));
  } catch (error) {}
}

function* fetchCurrentCampaignNames({ payload: name = '' }) {
  try {
    const result = yield call(api.fetchCampaignNames, name);

    yield put(actions.putCampaignNames(result));
  } catch (error) {}
}

function* validateCampaignName({ payload: { name, id, resolve, reject } }) {
  try {
    if (name) {
      yield delay(500);

      const result = yield call(api.validateCampaignName, name, id);

      if (result) {
        resolve();
      } else {
        reject({ campaign_name: 'This name is already used' });
      }
    }
  } catch (error) {
    reject();
  }
}

function* uploadPromoCodesFile({
  payload: { file, formName, field, fileFiled }
}) {
  try {
    yield put(startAsyncValidation(formName));
    const { success, count } = yield call(api.sendPromoCodesFile, file);

    if (!success) {
      yield put(touch(formName, field));
      yield put(
        stopAsyncValidation(
          formName,
          set({}, field, 'Error with file uploading')
        )
      );
      yield cancel();
    }

    const base64File = yield call(fileToB64, file);

    yield put(change(formName, field, count, true));
    yield put(
      change(formName, fileFiled, { file: base64File, name: file.name }, true)
    );
    yield put(stopAsyncValidation(formName));
  } catch (error) {
    yield put(touch(formName, field));
    yield put(
      stopAsyncValidation(formName, set({}, field, 'Error with file uploading'))
    );
  }
}

export default function* watcher() {
  yield takeLatest(
    actions.createSocialSharingRoutine.TRIGGER.toString(),
    createSocialSharing
  );
  yield takeLatest(
    actions.activateSocialSharing.toString(),
    activateSocialSharing
  );
  yield takeLatest(actions.holdSocialSharing.toString(), holdSocialSharing);
  yield takeLatest(actions.fetchSocialSharing.toString(), fetchSocialSharing);
  yield takeLatest(
    actions.fetchSocialSharingList.toString(),
    fetchSocialSharingList
  );
  yield takeLatest(actions.fetchVisitors.toString(), fetchVisitors);
  yield takeLatest(
    actions.fetchCurrentCampaignNames.toString(),
    fetchCurrentCampaignNames
  );
  yield takeLatest(
    actions.validateCampaignName.toString(),
    validateCampaignName
  );
  yield takeLatest(
    actions.uploadPromoCodesFile.toString(),
    uploadPromoCodesFile
  );
}
