import { handleActions } from 'redux-actions';

import {
  putDetails,
  putCustomers,
  putCustomersTags,
  putCampaignEvents,
  putChatHistory,
} from '../actions/customers';

const INITIAL_STATE = {
  list: [],
  total: 0,
  editedItem: null,
};

export default handleActions(
  {
    [putDetails]: (state, { payload }) => ({ ...state, details: payload }),
    [putCustomers]: (state, { payload: { list, total } }) => ({
      ...state,
      list,
      total,
    }),
    [putCustomersTags]: (state, { payload }) => ({
      ...state,
      tags: payload,
    }),
    [putCampaignEvents]: (state, { payload }) => ({
      ...state,
      campaignEvents: payload,
    }),
    [putChatHistory]: (state, { payload }) => ({
      ...state,
      chatHistory: payload,
    }),
  },
  INITIAL_STATE,
);
