import { getSelectors } from '../../constants/onsiteWidgetCSSClasses';
import { elements } from '../../constants/onsiteWidgets';
import { parseBg } from '../parseBg';

import parseHeight from './commonParsers/heightParser';
import parseTransition from './commonParsers/transitionParser';

/**
 *
 * @param {HTMLElement[]} steps
 * @param {string} id
 * @param {string} type
 * @param {boolean} horizontal
 * @param {*} childrenParser
 * @returns
 */
const parseSteps = (steps, id, type, horizontal, childrenParser) => {
  const stepsArray = [];
  Array.from(steps).forEach((step) => {
    let childrenElements = childrenParser(step.children, id, type, horizontal);

    // If the stepper is a child of a stepper or collapse element, it has no childrenElements key
    if (!childrenElements.childrenElements && !childrenElements[0]) {
      childrenElements = { childrenElements: [] };
    } else if (!childrenElements.childrenElements && childrenElements.length) {
      childrenElements = { childrenElements: [...childrenElements] };
    }

    stepsArray.push(childrenElements);
  });

  return stepsArray;
};

/**
 * @type {OnsiteWidget.HtmlParser}
 */
const stepperParser = (el, id, type, horizontal, options) => {
  const classes = getSelectors(elements.STEPPER);
  const root = el.firstElementChild;
  const bgFields = parseBg(root);
  const { friendlyId } = root.dataset;

  const stepsContainer = root.querySelector(classes.stepsContainer);
  const steps = parseSteps(stepsContainer.children, id, type, horizontal, options.childrenParser);

  const { heightOption, fixedHeight } = parseHeight(root.style);
  const { transitionDuration, transitionTimingFunction } = parseTransition(stepsContainer.children[0]?.style || {});

  const stepper = {
    type: 'stepper',
    ...bgFields,
    transitionTimingFunction,
    transitionDuration,
    heightOption,
    fixedHeight,
    droppable: true,
    activeStep: 0,
    friendlyId,
    steps,
    id,
  };

  return stepper;
};

export {
  stepperParser,
};
