import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import ColorField from "../../Fields/ColorField";

const defaultColor = "rgb(0, 0, 0)";

export const ColorComponent = (props) => {
  const { onChange, currentState } = props;
  const [currentColor, setCurrentColor] = useState(defaultColor);

  useEffect(() => {
    // currentState.color !== defaultColor ? setCurrentColor(currentState.color) : setCurrentColor(defaultColor)
    setCurrentColor(() => {
      if (defaultColor !== currentState.color) {
        return currentState.color;
      }
      return defaultColor;
    })
  }, [currentState.color]);

  const handleChange = (color) => {
    if (color) {
      onChange("color", color);
    }
  };

  return (
    <Box px={1}>
      <ColorField
        input={{
          onChange: handleChange,
          value: currentColor,
        }}
      />
    </Box>
  );
};
