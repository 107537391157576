import { get } from 'lodash'
import { getFormValues } from 'redux-form'
import { createSelector } from 'reselect'
import { FORM_SCHEMA_ONSITE } from '../../../../../constants'

export const moveOptionsSelectorCreator = (formName) =>
  createSelector(getFormValues(formName), (formValues) =>
    get(
      formValues,
      `${FORM_SCHEMA_ONSITE.EDITED_WIDGET}.${FORM_SCHEMA_ONSITE.PROPERTIES.CAROUSEL_MOVE_OPTION}`
    )
  )

export const carouselSlidesSelectorCreator = (formName) =>
  createSelector(getFormValues(formName), (formValues) =>
    get(
      formValues,
      `${FORM_SCHEMA_ONSITE.EDITED_WIDGET}.${FORM_SCHEMA_ONSITE.PROPERTIES.CAROUSEL_SLIDES}`
    )
  )

export const currentSlideSelectorCreator = (formName) =>
  createSelector(getFormValues(formName), (formValues) =>
    get(
      formValues,
      `${FORM_SCHEMA_ONSITE.EDITED_WIDGET}.${FORM_SCHEMA_ONSITE.PROPERTIES.CAROUSEL_CURRENT_SLIDE}`
    )
  )


export const carouselCurrentSlideSelectorCreator = (formName) =>
  createSelector(getFormValues(formName), (formValues) =>
    get(
      formValues,
      `${FORM_SCHEMA_ONSITE.EDITED_WIDGET}.${FORM_SCHEMA_ONSITE.PROPERTIES.CAROUSEL_CURRENT_SLIDE}`
    )

  )
