import { elements } from "./onsiteWidgets";

export const elementsClasses = {
    [elements.STEPPER]: {
        stepsContainer: "stepper_steps-container"
    }
}

/**
 * @param {string} type - element type 
 * @returns {object} classes
 */
export const getClasses = (type) => elementsClasses[type] || {}

/**
 * Transform classes to selectors.
 * 
 * @param {string} type - element type 
 * @returns {object} selectors
 */
export const getSelectors = (type) => {
    const classes = {...getClasses(type)}

    Object.entries(classes).forEach(([key, value]) => {
        if (typeof value !== 'string') return
        classes[key] = `.${value}`
    })

    return classes
}