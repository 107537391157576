import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import { fieldActions as actions, snackbar as snackbarActions } from '../redux/actions';
import { fieldData as api } from '../api';

function* fetchOperators() {
  try {
    const result = yield call(api.fetchOperators);

    yield put(actions.putOperators(result));
  } catch (error) {}
}

function* fetchTariffs() {
  try {
    const result = yield call(api.fetchTariffs);

    yield put(actions.putTariffs(result));
  } catch (error) {}
}

function* fetchLanguages() {
  try {
    const result = yield call(api.fetchLanguages);

    yield put(actions.putLanguages(result));
  } catch (error) {}
}

function* fetchCountries() {
  try {
    const result = yield call(api.fetchCountries);

    yield put(actions.putCountries({ countries: result }));
  } catch (error) {}
}

function* fetchRegions({ payload: { countryId } }) {
  try {
    const result = yield call(api.fetchRegions, countryId);

    yield put(actions.putRegions({ countryId, regions: result }));
  } catch (error) {}
}

function* fetchCities({ payload: { countryId, regionId } }) {
  try {
    const result = yield call(api.fetchCities, regionId);

    yield put(actions.putCities({ countryId, regionId, cities: result }));
  } catch (error) {}
}

function* fetchGeoConfig() {
  try {
    const result = yield call(api.fetchGeoConfig)

    yield put(actions.putGeoConfig(result));
  } catch (error) {}
}

function* fetchMnos() {
  try {
    const result = yield call(api.fetchMnos)

    yield put(actions.putMnos(result))
  } catch (error) {}
}

function* fetchTags({ payload: name }) {
  try {
    const tags = yield call(api.fetchTags, name || '')

    yield put(actions.putTags(tags))
  } catch (error) {
    yield put(
        snackbarActions.enqueueSnackbar({
          message: 'Loading cpa tags error occurred!',
          options: { variant: 'error' }
        })
    )
  }
}

function* fetchEmailCampaigns() {
  try {
    const list = yield call(api.fetchEmailCampaigns)
    yield put(actions.putEmailCampaigns(list))
  } catch (error) {
    yield put(
        snackbarActions.enqueueSnackbar({
          message: 'Loading email campaigns error occurred!',
          options: { variant: 'error' }
        })
    )
  }
}

function* fetchSmsCampaigns() {
  try {
    const list = yield call(api.fetchSmsCampaigns)
    yield put(actions.putSmsCampaigns(list))
  } catch (error) {
    yield put(
        snackbarActions.enqueueSnackbar({
          message: 'Loading sms campaigns error occurred!',
          options: { variant: 'error' }
        })
    )
  }
}

function* fetchOfferCampaigns() {
  try {
    const list = yield call(api.fetchOfferCampaigns)
    yield put(actions.putOfferCampaigns(list))
  } catch (error) {
    yield put(
        snackbarActions.enqueueSnackbar({
          message: 'Loading offer campaigns error occurred!',
          options: { variant: 'error' }
        })
    )
  }
}

function* fetchPromoCodeList() {
  try {
    const list = yield call(api.fetchPromoCodeUploads)
    yield put(actions.putPromoCodeList(list))
  } catch (error) {
    yield put(
        snackbarActions.enqueueSnackbar({
          message: 'Loading promo code uploads list error occurred!',
          options: { variant: 'error' }
        })
    )
  }
}

function* fetchTargetLanguages() {
  try {
    const list = yield call(api.fetchTargetLanguages)
    yield put(actions.putTargetLanguages(list))
  } catch (error) {
    yield put(
        snackbarActions.enqueueSnackbar({
          message: 'Loading target languages error occurred!',
          options: { variant: 'error' }
        })
    )
  }
}

function* fetchShortUrl({ payload: {objectId, objectClass} }) {
  try {
    const { url } = yield call(api.fetchShortUrl, objectId, objectClass)
    yield put(actions.putShortUrl(url))
  } catch (error) {
    yield put(
        snackbarActions.enqueueSnackbar({
          message: 'Getting short url error occurred!',
          options: { variant: 'error' }
        })
    )
  }
}

export default function* watcher() {
  yield takeLatest(actions.fetchOperators.toString(), fetchOperators);
  yield takeLatest(actions.fetchTariffs.toString(), fetchTariffs);
  yield takeLatest(actions.fetchLanguages.toString(), fetchLanguages);
  yield takeEvery(actions.fetchCountries.toString(), fetchCountries);
  yield takeEvery(actions.fetchRegions.toString(), fetchRegions);
  yield takeEvery(actions.fetchCities.toString(), fetchCities);
  yield takeEvery(actions.fetchGeoConfig.toString(), fetchGeoConfig);
  yield takeEvery(actions.fetchMnos.toString(), fetchMnos);
  yield takeEvery(actions.fetchTags.toString(), fetchTags);
  yield takeLatest(actions.fetchEmailCampaigns.toString(), fetchEmailCampaigns);
  yield takeLatest(actions.fetchTargetLanguages.toString(), fetchTargetLanguages);
  yield takeLatest(actions.fetchSmsCampaigns.toString(), fetchSmsCampaigns);
  yield takeLatest(actions.fetchOfferCampaigns.toString(), fetchOfferCampaigns);
  yield takeLatest(actions.fetchPromoCodeList.toString(), fetchPromoCodeList);
  yield takeLatest(actions.fetchShortUrl.toString(), fetchShortUrl);
}
