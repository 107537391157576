import { createAction } from 'redux-actions';
import { createRoutine, bindRoutineToReduxForm } from 'redux-saga-routines';

const CREATE_WEB_PUSH = '@webPush/CREATE_WEB_PUSH';
const FETCH_WEB_PUSH = '@webPush/FETCH_WEB_PUSH';
const PUT_WEB_PUSH = '@webPush/PUT_WEB_PUSH';
const UPDATE_WEB_PUSH = '@webPush/UPDATE_WEB_PUSH';
const HOLD_WEB_PUSH = '@webPush/HOLD_WEB_PUSH';
const ACTIVATE_WEB_PUSH = '@webPush/ACTIVATE_WEB_PUSH';
const FETCH_WEB_PUSH_LIST = '@webPush/FETCH_WEB_PUSH_LIST';
const PUT_WEB_PUSH_LIST = '@webPush/PUT_WEB_PUSH_LIST';
const FETCH_WEB_PUSH_VISITORS = '@webPush/FETCH_VISITORS';
const PUT_WEB_PUSH_VISITORS = '@webPush/PUT_VISITORS';
const FETCH_WEB_PUSH_CAMPAIGN_NAMES = '@webPush/FETCH_CURRENT_CAMPAIGN_NAMES';
const PUT_WEB_PUSH_CAMPAIGN_NAMES = '@webPush/PUT_CAMPAIGN_NAMES';
const VALIDATE_WEB_PUSH_CAMPAIGN_NAME = '@webPush/VALIDATE_CAMPAIGN_NAME';
const UPLOAD_WEB_PUSH_PROMO_CODES_FILE = '@webPush/UPLOAD_PROMO_CODES_FILE';

export const createWebPushRoutine = createRoutine(CREATE_WEB_PUSH);
export const createWebPushHandler = bindRoutineToReduxForm(
  createWebPushRoutine
);

export const activateWebPush = createAction(ACTIVATE_WEB_PUSH);
export const holdWebPush = createAction(HOLD_WEB_PUSH);

export const fetchWebPush = createAction(FETCH_WEB_PUSH);
export const putWebPush = createAction(PUT_WEB_PUSH);
export const updateWebPush = createAction(UPDATE_WEB_PUSH);

export const fetchWebPushList = createAction(FETCH_WEB_PUSH_LIST);
export const putWebPushList = createAction(PUT_WEB_PUSH_LIST);

export const fetchVisitors = createAction(FETCH_WEB_PUSH_VISITORS);
export const putVisitors = createAction(PUT_WEB_PUSH_VISITORS);

export const fetchCurrentCampaignNames = createAction(
  FETCH_WEB_PUSH_CAMPAIGN_NAMES
);
export const putCampaignNames = createAction(PUT_WEB_PUSH_CAMPAIGN_NAMES);

export const validateCampaignName = createAction(
  VALIDATE_WEB_PUSH_CAMPAIGN_NAME
);

export const uploadPromoCodesFile = createAction(
  UPLOAD_WEB_PUSH_PROMO_CODES_FILE
);
