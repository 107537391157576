import axios from './axios';
import {
  FETCH_COUNTRIES_ENDPOINT,
  FETCH_REGIONS_ENDPOINT,
  FETCH_CITIES_ENDPOINT,
  FETCH_OPERATORS_ENDPOINT,
  FETCH_TARIFFS_ENDPOINT,
  FETCH_MNOS_ENDPOINT,
  FETCH_LANGUAGES_ENDPOINT,
  TAGS_ENDPOINT,
  TAG_DEL_ENDPOINT,
  EMAIL_CAMPAIGNS_ENDPOINT,
  FETCH_GEO_CONFIG_ENDPOINT,
  SMS_CAMPAIGNS_ENDPOINT,
  TARGET_LANGUAGES_ENDPOINT,
  PROMO_CODE_LIST_ENDPOINT,
  GET_SHORT_URL_ENDPOINT, OFFER_CAMPAIGNS_ENDPOINT,
} from '../constants/endpoints';

const fetchCountries = async () => {
  const { data: countries } = await axios.get(FETCH_COUNTRIES_ENDPOINT);
  return countries;
};

const fetchRegions = async countryId => {
  const { data: regions } = await axios.get(
    `${FETCH_REGIONS_ENDPOINT}?country_id=${countryId}`
  );
  return regions;
};

const fetchCities = async regionId => {
  const { data: cities } = await axios.get(
    `${FETCH_CITIES_ENDPOINT}?region_id=${regionId}`
  );
  return cities;
};

const fetchGeoConfig = async () => {
  const { data: config } = await axios.get(`${FETCH_GEO_CONFIG_ENDPOINT}`);
  return config;
};

const fetchOperators = async () => {
  const { data: operators } = await axios.get(`${FETCH_OPERATORS_ENDPOINT}`);
  return operators;
};

const fetchTariffs = async () => {
  const { data: tariffs } = await axios.get(`${FETCH_TARIFFS_ENDPOINT}`);
  return tariffs;
};

const fetchLanguages = async () => {
  const { data: languages } = await axios.get(`${FETCH_LANGUAGES_ENDPOINT}`);
  return languages;
};

const fetchMnos = async () => {
  const { data } = await axios.get(`${FETCH_MNOS_ENDPOINT}`)
  return data
}

const fetchTags = async (name) => {
  const { data: tags } = await axios.get(`${TAGS_ENDPOINT}?name=${name}`)
  return tags;
}

const fetchEmailCampaigns = async () => {
  const { data: emailCampaigns } = await axios.get(`${EMAIL_CAMPAIGNS_ENDPOINT}`)
  return emailCampaigns;
}

const fetchPromoCodeUploads = async () => {
  const { data: list } = await axios.get(`${PROMO_CODE_LIST_ENDPOINT}`)
  return list;
}

const fetchSmsCampaigns = async () => {
  const { data: smsCampaigns } = await axios.get(`${SMS_CAMPAIGNS_ENDPOINT}`)
  return smsCampaigns;
}

const fetchOfferCampaigns = async () => {
  const { data: offerCampaigns } = await axios.get(`${OFFER_CAMPAIGNS_ENDPOINT}`)
  return offerCampaigns;
}

const fetchTargetLanguages = async () => {
  const { data: list } = await axios.get(`${TARGET_LANGUAGES_ENDPOINT}`)
  return list;
}

const postTagDel = async (id) => {
  const { data: result } = await axios.post(TAG_DEL_ENDPOINT, { id })
  return result;
}

const fetchShortUrl = async (objectId, objectClass) => {
  const { data: result } = await axios.post(GET_SHORT_URL_ENDPOINT, { object_id: objectId, object_class: objectClass })
  return result;
}

export default {
  fetchCountries,
  fetchRegions,
  fetchCities,
  fetchGeoConfig,
  fetchOperators,
  fetchTariffs,
  fetchMnos,
  fetchTags,
  fetchEmailCampaigns,
  fetchTargetLanguages,
  fetchSmsCampaigns,
  fetchOfferCampaigns,
  fetchPromoCodeUploads,
  postTagDel,
  fetchLanguages,
  fetchShortUrl,
};
