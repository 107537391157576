
const regex = /\.([^\s].+?{)/gm
const selfRegex = /.*(#self)/gm

/**
 * Add id to all css classes to encapsulate your css.
 * 
 * **CSS must follow the rules**
 * - CSS should be include only classes or #self
 * - selectors in one line ending with '{'
 * 
 * @callback CreateUniqueStyles
 * @param {string} css - css string
 * @param {string} id - unique identificator
 * @returns {string} css
 * 
 * @example
 * const sourceCss = '.class1 { backgroung: blue; } #self { color: red; }'
 * const encapsulatedCss = createUniqueStyles(str, 'myid')
 * log(encapsulatedCss) // '#myid .class1 { backgroung: blue; } #myid { color: red; }'
 * 
 */

/**
 * @type {CreateUniqueStyles} createUniqueStyles
 * @see {@link CreateUniqueStyles}
 */
const createUniqueStyles = (css, id) => css.replace(regex, `${id}.$1`).replaceAll(selfRegex, `#${id}`)

/**
 * Factory to create helper function for {@link CreateUniqueStyles}
 * 
 * @param {string} id 
 * @returns {(css: string) => string}
 * @see {@link CreateUniqueStyles}
 */
const createUniqueStylesFactory = (id) => {
    return (css) => createUniqueStyles(css, id ? `#${id} ` : '');
}

export default createUniqueStylesFactory