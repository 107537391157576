import React, { useRef } from 'react';
import { Button, Box, Typography, makeStyles } from '@material-ui/core';
import { resizeFile } from '../../help';

const readFile = file =>
  new Promise(resolve => {
    const reader = new FileReader();

    reader.addEventListener('load', () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });

// const prepareImage = async imageFile => {
//   if (imageFile) {
//     const type = imageFile.type.replace(/image\//, '') || 'jpeg';
//     const name = imageFile.name;
//
//     if (type === 'gif') {
//       const url = await readFile(imageFile);
//
//       return { url, type, name };
//     }
//
//     const { url } = await resizeFile(imageFile, type.toUpperCase());
//
//     return { url, type, name };
//   }
//
//   return { url: null, type: null, name: null };
// };

const prepareImage = async (imageFile, readAsDataURL, resize) => {
  if (imageFile) {
    if (!readAsDataURL) return imageFile
    
    const { name, type: rawType } = imageFile
    const type = rawType.replace(/image\//, '') || 'jpeg'
    let dataURL
    
    if (!resize) {
      dataURL = await readFile(imageFile)
    } else {
      dataURL = await resizeFile(imageFile, type.toUpperCase())
    }
    
    if (dataURL) {
      return { url: dataURL, type, name }
    }
  }

  return { url: null, type: null, name: null };
};

const useStyles = makeStyles(theme => ({
  hidden: {
    visibility: 'hidden'
  }
}));

const UploadImageField = ({
  input: { onChange, value },
  meta: { error },
  children,
  label = 'Upload image',
  accept = 'image/*,.png,.jpg,.gif,.jpeg',
  afterChange = () => {},
  readAsDataURL = false,
  ...props
}) => {
  const classes = useStyles();
  const inputRef = useRef();
  const onUploadFile = () => {
    inputRef.current.click();
  };

  return (
    <Box display="flex" alignItems="center" width="100%" flexWrap="wrap">
      <input
        style={{ width: 0 }}
        ref={inputRef}
        multiple
        accept={accept}
        formEncType='multipart/form-data'
        onChange={async event => {
          const { target } = event
          const { files } = target
          const image = [...files].shift()

          if (!readAsDataURL) {
            onChange(await prepareImage(files))
          } else {
            onChange(await prepareImage(image, readAsDataURL))
          }
          
          afterChange();
        }}
        onClick={event => {
          event.target.value = null;
        }}
        type="file"
        className={classes.hidden}
        {...props.input}
        {...props}
      />
      <Button onClick={onUploadFile}>{label}</Button>
      {error && (
        <Typography variant="body2" color="error">
          {error}
        </Typography>
      )}
      {!error && value && value.name && (
        <Typography variant="body2">{value.name}</Typography>
      )}
    </Box>
  );
};

export default UploadImageField;
