import { snakeCase, camelCase } from 'change-case';

function isArray(val) {
  return toString.call(val) === '[object Array]';
}

function isObject(val) {
  return val !== null && typeof val === 'object';
}

function arrayToMethod(array, method) {
  return array.reduce((acc, item) => {
    acc.push(isObject(item) ? method(item) : item);
    return acc;
  }, []);
}

function snakeToCamel(obj) {
  if (isObject(obj)) {
    if (isArray(obj)) return arrayToMethod(obj, snakeToCamel);
    return Object.keys(obj).reduce((acc, snakeKey) => {
      const camelKey = camelCase(snakeKey);
      acc[camelKey] = isObject(obj[snakeKey]) ? snakeToCamel(obj[snakeKey]) : obj[snakeKey];
      return acc;
    }, {});
  }
  throw new Error('Instance is not an object.');
}

function camelToSnake(obj) {
  if (isObject(obj)) {
    if (isArray(obj)) return arrayToMethod(obj, camelToSnake);
    return Object.keys(obj).reduce((acc, camelKey) => {
      const snakeKey = snakeCase(camelKey);
      acc[snakeKey] = isObject(obj[camelKey]) ? camelToSnake(obj[camelKey]) : obj[camelKey];
      return acc;
    }, {});
  }
  throw new Error('Instance is not an object.');
}

export default {
  camelToSnake,
  isArray,
  isObject,
  snakeToCamel,
};
