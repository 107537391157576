import axios from "./axios";
import {
  FETCH_MEDIA_ENDPOINT,
  POST_MEDIA_ENDPOINT,
  POST_MEDIA_VIDEO_ENDPOINT,
} from '../constants/endpoints'

const fetchGetAllMedia = async ({ skip, take, type }) => {
  const mediaList = await axios.get(
    `${FETCH_MEDIA_ENDPOINT}`,
    {
      params: {
        skip,
        take,
        type,
      }
    }
  );
  return mediaList;
};

const addNewMedia = async (formData) => {
  const mediaList = await axios.post(
    `${POST_MEDIA_ENDPOINT}`,
    formData,
    {headers: {'content-type': 'multipart/form-data'}}
  );
  return mediaList;
};

const addNewVideo = async (formData) => {
  const mediaList = await axios.post(
    `${POST_MEDIA_VIDEO_ENDPOINT}`,
    formData,
    {headers: {'content-type': 'multipart/form-data'}}
  );
  return mediaList;
};

export default {
  fetchGetAllMedia,
  addNewMedia,
  addNewVideo
};
