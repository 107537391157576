import classnames from 'classnames';
import React from 'react';

import TextEditorField from '../../components/OnsiteForm/WidgetFormsFields/TextEditorField';
import { CURRENT_EDITOR } from '../../constants/editor';
import { elements, formWidthOptions } from '../../constants/onsiteWidgets';
import { analyticConstants } from '../../help/analytics';
import { getUniqueIdWithPrefix } from '../../help/getUniqueIdWithPrefix';
import { openTextEditor } from '../../help/handleEditor';

import { Social } from './Social';


const successTextContainerStyle = {
  width: '100%',
  height: '100%',
  display: 'none',
  alignItems: 'center',
  justifyContent: 'center',
};

export const classNames = {
  form: 'phoneVerification',
  errorContainer: 'phoneVerification__error-text',
  phoneErrorContainer: 'phoneVerification__phone-error',
  otpErrorContainer: 'phoneVerification__otp-error',

  resultsSection: 'phoneVerification__results-section',

  input: 'phoneVerification__input',
  inputNoPlaceholder: 'phoneVerification__input-no-placeholder',
  inputHasText: 'has-text',
  spacing: 'spacing',
  spacing2: 'spacing-2',

  phoneSection: 'phoneVerification__phone-section',
  loaderSection: 'phoneVerification__loader-section',
  otpSection: 'phoneVerification__otp-section',

  sectionTitle: 'phoneVerification__section-title',
  link: 'phoneVerification__link',

  otpResend: 'phoneVerification__otp-resend',
  otpBack: 'phoneVerification__otp-back',
};

export const PhoneVerification = ({
  itemId,
  commonStyle,
  text,
  showTelegramButton,
  telegramButtonText,
  telegramButtonStyles,
  orientation = 'column',
  buttonStyles,
  showText,
  showSocial,
  isEditing,
  phoneInputLabel,
  phoneInputPlaceholder,
  phoneInputStyles,
  codeInputLabel,
  codeInputSubLabel,
  codeInputPlaceholder,
  codeInputStyles,
  resendLinkText,
  errorStyles,
  successText,
  backLinkText,
  socialProps,
  operator,
  service,
  mask,
  maskCodeList,
  code,
  phoneLength,
  validators,
  phonePlaceholder,
  id,
  activeTab,
  widthOption,
  maxWidth,
  position,
  setActiveEditor,
  focusTextEditor,
  activeElement,
  horizontal,
  values,
  section,
  form,
  events,
}) => {
  const buttonsWrapperStyle = {
    display: 'flex',
    flexDirection: orientation,
  };
  const buttonStyle = {
    border: 0,
    lineHeight: '1.1',
    background: buttonStyles.backgroundColor,
    WebkitAppearance: 'none',
    width: '100%',
    padding: '10px 0',
    ...buttonStyles,
    boxShadow: buttonStyles.boxShadow?.rawValue,
    pointerEvents: 'auto',
  };

  const telegramButtonStyle = {
    margin: orientation === 'column' ? '16px 0 0 0' : '0 0 0 16px',
    border: 0,
    lineHeight: '1.1',
    background: telegramButtonStyles.backgroundColor,
    WebkitAppearance: 'none',
    width: '100%',
    padding: '10px 0',
    ...telegramButtonStyles,
    boxShadow: telegramButtonStyles.boxShadow?.rawValue,
    pointerEvents: 'auto',
  };


  const phoneInputStyle = {
    ...phoneInputStyles,
  };

  const phoneLabelStyle = {
    color: phoneInputStyle.color,
    opacity: 0.8,
  };

  const codeInputStyle = {
    ...codeInputStyles,
  };

  const errorTextContainerStyle = {
    width: '100%',
    display: isEditing ? 'block' : 'none',
    margin: '0 auto 5px',
    maxWidth: '200px',
    ...errorStyles,
    fontSize: '13px',
    lineHeight: '14px',
  };

  const sectionStyles = {
    0: { display: activeTab === 0 || !isEditing ? 'block' : 'none' },
    1: { display: activeTab === 1 && isEditing ? 'block' : 'none' },
    2: { display: activeTab === 2 && isEditing ? 'flex' : 'none' },
  };

  const isShowText = isEditing && showText;

  const elementId = itemId || id;
  const phoneInputId = `phone-${elementId}`;
  const codeInputId = `code-${elementId}`;

  let maskCodeListStr = '';
  if (maskCodeList && Array.isArray(maskCodeList)) {
    maskCodeListStr = JSON.stringify(maskCodeList);
  }

  const formWidth = widthOption === formWidthOptions.fullWidth ? '100%' : maxWidth;

  const formPosition = {
    left: '0 auto 0 0',
    center: '0 auto',
    right: '0 0 0 auto',
  };

  const formStyle = {
    display: 'block',
    position: 'relative',
    margin: formPosition[position],
    width: '100%',
    maxWidth: formWidth,
  };

  return (
    <form
      id={elementId}
      style={{ ...commonStyle, ...formStyle }}
      data-width-option={widthOption}
      data-max-width={maxWidth}
      data-position={position}
      data-service={service}
      data-mno={operator}
      data-mask={mask || ''}
      data-mask-code-list={maskCodeListStr}
      data-code={code || ''}
      data-phone-length={phoneLength || ''}
      data-validators={validators || ''}
      data-placeholder={phonePlaceholder || ''}
      data-action="none"
      data-show-telegram-button={showTelegramButton}
      data-orientation={orientation}
      className={classnames(classNames.form, itemId || id)}
    >
      <div className={classNames.loaderSection} style={{ display: 'none' }} />
      <div className={classNames.phoneSection} style={sectionStyles[0]}>
        <label
          data-text-editor
          data-editor-name={CURRENT_EDITOR.PHONE_INPUT_LABEL}
          onClick={openTextEditor(setActiveEditor)}
          style={{ pointerEvents: 'auto' }}
          htmlFor={phoneInputId}
          className={classnames(classNames.spacing, classNames.sectionTitle)}
        >
          {focusTextEditor === CURRENT_EDITOR.PHONE_INPUT_LABEL && elementId === activeElement.id ? (
            <TextEditorField
              editorType="inline-editor"
              type={activeElement.element.type}
              path={activeElement.path}
              // TODO имена тут и в fields нужно вынести в константы
              name="phoneInputLabel"
              isHorizontal={horizontal}
              values={values}
              section={section}
              form={form}
              element={activeElement.element}
              events={events}
              focusTextEditor={focusTextEditor}
            />
          ) : (
            <span dangerouslySetInnerHTML={{ __html: phoneInputLabel }} />
          )}
        </label>

        <div className={classNames.spacing2}>
          <input
            type="text"
            inputMode="numeric"
            style={phoneInputStyle}
            placeholder={phonePlaceholder}
            className={classnames(
              classNames.input,
              showText && isEditing && classNames.inputHasText,
              !phoneInputPlaceholder && classNames.inputNoPlaceholder,
            )}
            id={phoneInputId}
          />
          <label
            htmlFor={phoneInputId}
            style={phoneLabelStyle}
          >
              {phoneInputPlaceholder}
            </label>
        </div>

        <div
          className={classnames(classNames.errorContainer, classNames.phoneErrorContainer)}
          style={errorTextContainerStyle}
        >
          {isShowText ? 'Here will be error text' : ''}
        </div>

        <div style={buttonsWrapperStyle}>
          <button
            id="get-code-btn"
            data-editor-name={CURRENT_EDITOR.PHONE_SUBMIT_TEXT}
            data-text-editor
            onClick={openTextEditor(setActiveEditor)}
            type={focusTextEditor === CURRENT_EDITOR.PHONE_SUBMIT_TEXT && elementId === activeElement.id ? 'button' : 'submit'}
            style={buttonStyle}
            {...analyticConstants.attributesToTrackClick}
          >
            {focusTextEditor === CURRENT_EDITOR.PHONE_SUBMIT_TEXT && elementId === activeElement.id
              ? (
                <TextEditorField
                  editorType="inline-editor"
                  type={activeElement.element.type}
                  path={activeElement.path}
                  isHorizontal={horizontal}
                  values={values}
                  section={section}
                  form={form}
                  element={activeElement.element}
                  events={events}
                  focusTextEditor={focusTextEditor}
                />
              )
              : (
                <span dangerouslySetInnerHTML={{ __html: text }} />
              )
            }
          </button>

          {showTelegramButton && (
            <button
              className="comfolks-telegram-btn"
              data-editor-name={CURRENT_EDITOR.TELEGRAM_BUTTON_TEXT}
              data-text-editor
              onClick={openTextEditor(setActiveEditor)}
              type="button"
              style={telegramButtonStyle}
              {...analyticConstants.attributesToTrackClick}
            >
              {focusTextEditor === CURRENT_EDITOR.TELEGRAM_BUTTON_TEXT && elementId === activeElement.id
                ? (
                  <TextEditorField
                    editorType="inline-editor"
                    name="telegramButtonText"
                    type={activeElement.element.type}
                    path={activeElement.path}
                    isHorizontal={horizontal}
                    values={values}
                    section={section}
                    form={form}
                    element={activeElement.element}
                    events={events}
                    focusTextEditor={focusTextEditor}
                  />
                )
                : (
                  <span dangerouslySetInnerHTML={{ __html: telegramButtonText }} />
                )
              }
            </button>
          )}
        </div>
      </div>

      <div className={classNames.otpSection} style={sectionStyles[1]}>
        <label
          style={{ pointerEvents: 'auto' }}
          onClick={openTextEditor(setActiveEditor)}
          data-editor-name={CURRENT_EDITOR.CODE_INPUT_LABEL}
          data-text-editor
          htmlFor={codeInputId}
          className={classnames(classNames.spacing, classNames.sectionTitle)}
        >
          {focusTextEditor === CURRENT_EDITOR.CODE_INPUT_LABEL && elementId === activeElement.id
            ? (
              <TextEditorField
                editorType="inline-editor"
                name="codeInputLabel"
                type={activeElement.element.type}
                path={activeElement.path}
                isHorizontal={horizontal}
                values={values}
                section={section}
                form={form}
                element={activeElement.element}
                events={events}
                focusTextEditor={focusTextEditor}
              />
            )
            : (
              <span dangerouslySetInnerHTML={{ __html: codeInputLabel }} />
            )
          }
        </label>
        <div
          style={{ pointerEvents: 'auto' }}
          onClick={openTextEditor(setActiveEditor)}
          data-editor-name={CURRENT_EDITOR.CODE_INPUT_SUB_LABEL}
          data-text-editor
          className={classnames(classNames.spacing)}
        >
          {focusTextEditor === CURRENT_EDITOR.CODE_INPUT_SUB_LABEL && elementId === activeElement.id
            ? (
              <TextEditorField
                editorType="inline-editor"
                name="codeInputSubLabel"
                type={activeElement.element.type}
                path={activeElement.path}
                isHorizontal={horizontal}
                values={values}
                section={section}
                form={form}
                element={activeElement.element}
                events={events}
                focusTextEditor={focusTextEditor}
              />
            )
            : (
              <span dangerouslySetInnerHTML={{ __html: codeInputSubLabel }} />
            )
          }
        </div>

        <div className={classNames.spacing2}>
          <input
            maxLength={4}
            style={codeInputStyle}
            autoComplete="one-time-code"
            pattern="^([0-9]+)$"
            type="text"
            inputMode="numeric"
            className={classnames(
              classNames.input,
              showText && classNames.inputHasText,
              !codeInputPlaceholder && classNames.inputNoPlaceholder,
            )}
            id={codeInputId}
          />
          <label htmlFor={codeInputId}>{codeInputPlaceholder}</label>
        </div>

        <div
          className={classnames(classNames.errorContainer, classNames.otpErrorContainer)}
          style={errorTextContainerStyle}
        >
          {isShowText ? 'Here will be error text' : ''}
        </div>

        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          href="#"
          style={{ pointerEvents: 'auto' }}
          onClick={openTextEditor(setActiveEditor)}
          data-text-editor
          data-editor-name={CURRENT_EDITOR.BACK_LINK_TEXT}
          className={classnames(classNames.link, classNames.otpBack)}
        >
          {focusTextEditor === CURRENT_EDITOR.BACK_LINK_TEXT && elementId === activeElement.id
            ? (
              <TextEditorField
                editorType="inline-editor"
                name="backLinkText"
                type={activeElement.element.type}
                path={activeElement.path}
                isHorizontal={horizontal}
                values={values}
                section={section}
                form={form}
                element={activeElement.element}
                events={events}
                focusTextEditor={focusTextEditor}
              />
            )
            : (
              <span dangerouslySetInnerHTML={{ __html: backLinkText }} />
            )
          }
        </a>

        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          href="#"
          data-text-editor
          style={{ pointerEvents: 'auto' }}
          onClick={openTextEditor(setActiveEditor)}
          data-editor-name={CURRENT_EDITOR.OTP_RESEND}
          className={classnames(classNames.link, classNames.otpResend)}
        >
          {focusTextEditor === CURRENT_EDITOR.OTP_RESEND && elementId === activeElement.id
            ? (
              <TextEditorField
                editorType="inline-editor"
                name="resendLinkText"
                type={activeElement.element.type}
                path={activeElement.path}
                isHorizontal={horizontal}
                values={values}
                section={section}
                form={form}
                element={activeElement.element}
                events={events}
                focusTextEditor={focusTextEditor}
              />
            )
            : (
              <span dangerouslySetInnerHTML={{ __html: resendLinkText }} />
            )
          }
        </a>
      </div>

      <div
        className={classNames.resultsSection}
        style={{ ...successTextContainerStyle, ...sectionStyles[2] }}
      >
        <div
          style={{ marginBottom: '10px', pointerEvents: 'auto' }}
          data-editor-name={CURRENT_EDITOR.SUCCESS_TEXT}
          data-text-editor
          onClick={openTextEditor(setActiveEditor)}
        >
          {focusTextEditor === CURRENT_EDITOR.SUCCESS_TEXT && elementId === activeElement.id
            ? (
              <TextEditorField
                editorType="inline-editor"
                name="successText"
                type={activeElement.element.type}
                path={activeElement.path}
                isHorizontal={horizontal}
                values={values}
                section={section}
                form={form}
                element={activeElement.element}
                events={events}
                focusTextEditor={focusTextEditor}
              />
            )
            : (
              <span dangerouslySetInnerHTML={{ __html: successText }} />
            )
          }
        </div>

        {showSocial && <Social itemId={getUniqueIdWithPrefix(elements.SOCIAL)} {...socialProps} />}
      </div>
    </form>
  );
};
