import { createAction } from 'redux-actions';
import { createRoutine, bindRoutineToReduxForm } from 'redux-saga-routines';

const CREATE_SMS = '@sms/CREATE_SMS';
const FETCH_SMS = '@sms/FETCH_SMS';
const PUT_SMS = '@sms/PUT_SMS';
const UPDATE_SMS = '@sms/UPDATE_SMS';
const HOLD_SMS = '@sms/HOLD_SMS';
const ACTIVATE_SMS = '@sms/ACTIVATE_SMS';
const FETCH_SMS_LIST = '@sms/FETCH_SMS_LIST';
const PUT_SMS_LIST = '@sms/PUT_SMS_LIST';
const FETCH_SMS_CAMPAIGN_NAMES = '@sms/FETCH_CURRENT_CAMPAIGN_NAMES';
const PUT_SMS_CAMPAIGN_NAMES = '@sms/PUT_CAMPAIGN_NAMES';
const VALIDATE_SMS_CAMPAIGN_NAME = '@sms/VALIDATE_CAMPAIGN_NAME';
const FETCH_SMS_VISITORS = '@sms/FETCH_VISITORS';
const PUT_SMS_VISITORS = '@sms/PUT_VISITORS';

export const createSmsRoutine = createRoutine(CREATE_SMS);
export const createSmsHandler = bindRoutineToReduxForm(
  createSmsRoutine,
);

export const activateSms = createAction(ACTIVATE_SMS);
export const holdSms = createAction(HOLD_SMS);

export const fetchSms = createAction(FETCH_SMS);
export const putSms = createAction(PUT_SMS);
export const updateSms = createAction(UPDATE_SMS);

export const fetchSmsList = createAction(FETCH_SMS_LIST);
export const putSmsList = createAction(PUT_SMS_LIST);

export const fetchVisitors = createAction(FETCH_SMS_VISITORS);
export const putVisitors = createAction(PUT_SMS_VISITORS);

export const fetchCurrentCampaignNames = createAction(
  FETCH_SMS_CAMPAIGN_NAMES,
);
export const putCampaignNames = createAction(PUT_SMS_CAMPAIGN_NAMES);

export const validateCampaignName = createAction(
  VALIDATE_SMS_CAMPAIGN_NAME,
);
