// persist actions
export { REHYDRATE } from 'redux-persist/es/constants';

// user's actions
export const USER_LOGOUT = '@auth/USER_LOGOUT';
export const USER_REMEMBER_ME = '@auth/USER_REMEMBER_ME';
export const USER_TIME_LOGIN = '@auth/USER_TIME_LOGIN';
export const USER_GET_ALLOWED_MODULES_REQUEST = '@auth/USER_GET_ALLOWED_MODULES_REQUEST';
export const USER_GET_ALLOWED_MODULES_SUCCESS = '@auth/USER_GET_ALLOWED_MODULES_SUCCESS';

// user's registration
export const USER_REGISTRATION_REQUEST = '@auth/USER_REGISTRATION_REQUEST';
export const USER_REGISTRATION_SUCCESS = '@auth/USER_REGISTRATION_SUCCESS';
export const USER_REGISTRATION_FAILURE = '@auth/USER_REGISTRATION_FAILURE';

export const USER_LOGIN_REQUEST = '@auth/USER_LOGIN_REQUEST';
export const USER_LOGIN_SUCCESS = '@auth/USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAILURE = '@auth/USER_LOGIN_FAILURE';
export const USER_API_KEY = '@auth/USER_API_KEY';

export const USER_LOGOUT_REQUEST = '@auth/USER_LOGOUT_REQUEST';
export const USER_LOGOUT_SUCCESS = '@auth/USER_LOGOUT_SUCCESS';
export const USER_LOGOUT_FAILURE = '@auth/USER_LOGOUT_FAILURE';

export const USER_PING = '@auth/USER_PING';
export const USER_PING_FAILURE = '@auth/USER_PING_FAILURE';
export const USER_PING_FAILURE_OTP = '@auth/USER_PING_FAILURE_OTP';

export const USER_OTP_REQUEST = '@auth/USER_OTP_REQUEST';
export const USER_OTP_SUCCESS = '@auth/USER_OTP_SUCCESS';
export const USER_OTP_FAILURE = '@auth/USER_OTP_FAILURE';
export const USER_OTP_RESEND = '@auth/USER_OTP_RESEND';
export const USER_OTP_RESEND_SUCCESS = '@auth/USER_OTP_RESEND_SUCCESS';

// snackbar
export const ENQUEUE_SNACKBAR = '@snackbar/ENQUEUE_SNACKBAR';
export const CLOSE_SNACKBAR = '@snackbar/CLOSE_SNACKBAR';
export const REMOVE_SNACKBAR = '@snackbar/REMOVE_SNACKBAR';

//text editor
export const TOGGLE_EDITOR_STYLE = 'TOGGLE_EDITOR_STYLE';
export const TOGGLE_EDITOR_BLOCK_STYLE = 'TOGGLE_EDITOR_BLOCK_STYLE';
export const SET_EDITOR_COLOR = 'SET_EDITOR_COLOR';
export const SET_EDITOR_FONTSIZE = 'SET_EDITOR_FONTSIZE';
export const SET_EDITOR_FONTFAMILY = 'SET_EDITOR_FONTFAMILY';
export const SET_EDITOR_FONTWEIGHT = 'SET_EDITOR_FONTWEIGHT';
export const SET_EDITOR_BORDER_COLOR = 'SET_EDITOR_BORDER_COLOR';
export const SET_EDITOR_BORDER = 'SET_EDITOR_BORDER';
export const UNSET_EDITOR_BORDER = 'UNSET_EDITOR_BORDER';
export const SET_BOLD_BUTTON_STATUS = 'SET_BOLD_BUTTON_STATUS';
export const SET_ITALIC_BUTTON_STATUS = 'SET_ITALIC_BUTTON_STATUS';
export const SET_UNDERLINED_BUTTON_STATUS = 'SET_UNDERLINED_BUTTON_STATUS';

// program state
export const OPEN_MODAL_SELECT_ITEM = '@programState/OPEN_MODAL_SELECT_ITEM';
export const OPEN_MODAL_DELETE = '@programState/OPEN_MODAL_DELETE';
export const CHANGE_ACTIVE_STEP = '@programState/CHANGE_ACTIVE_STEP';
export const OPEN_BASE_MENU = '@programState/OPEN_BASE_MENU';
export const OPEN_RIGHT_MENU = '@programState/OPEN_RIGHT_MENU';
export const SHOW_PICKER_COLOR = '@programState/SHOW_PICKER_COLOR';
