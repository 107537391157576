/* eslint-disable import/no-webpack-loader-syntax */
import React from 'react';


// Load compiled files
import collapseScript from 'raw-loader!../../../assets/js/compiled/collapse';
import orderScript from 'raw-loader!../../../assets/js/compiled/orderForm';
import phoneVerificationScript from 'raw-loader!../../../assets/js/compiled/phoneVerification';
import clickPayScript from 'raw-loader!../../../assets/js/compiled/clickPay';
import productCardsScript from 'raw-loader!../../../assets/js/compiled/productCards';
import stepperScript from 'raw-loader!../../../assets/js/compiled/stepper';
import videoScript from 'raw-loader!../../../assets/js/compiled/video';

import {
  elements as ELEMENTS,
} from '../../../constants/onsiteWidgets';
import { putCompiledScripts } from '../../../redux/actions/fields';
import { store } from '../../../redux/store';

const scriptsByTypeMapping = {
  [ELEMENTS.COLLAPSE]: [collapseScript],
  [ELEMENTS.STEPPER]: [stepperScript],
  [ELEMENTS.PHONE_VERIFICATION]: [phoneVerificationScript],
  [ELEMENTS.CLICK_PAY]: [clickPayScript],
  [ELEMENTS.VIDEO]: [videoScript],
  [ELEMENTS.ORDER_FORM]: [orderScript],
  [ELEMENTS.PRODUCT_CARD]: [productCardsScript],
};

const ElementScripts = ({ elements = [] }) => {
  let uniqueScripts = new Set();
  const uniqueTypes = new Set(elements.map((item) => item.type));
  const { compiledScripts } = store.getState().fieldData;

  uniqueTypes.forEach((type) => {
    if (!scriptsByTypeMapping[type]) return;
    if (compiledScripts.includes(type)) return;

    compiledScripts.push(type);
    store.dispatch(putCompiledScripts(compiledScripts));

    const scripts = [...scriptsByTypeMapping[type]].filter((code) => code);

    scripts.forEach((script) => uniqueScripts.add(script));
  });

  uniqueScripts = Array.from(uniqueScripts);

  return (
    <>
      {uniqueScripts.map((script, index) => (
        <script type="module" key={index} dangerouslySetInnerHTML={{ __html: script }} />
      ))}
    </>
  );
};

export default ElementScripts;
