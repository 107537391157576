import { handleActions } from 'redux-actions';

import {
    putSimNumberUploads,
    putSimNumberUploadsList,
    putSimSalesPointsUploads,
    putSimSalesPointsUploadsList,
    putServicesUploads,
    putServicesUploadsList
} from '../actions/simNumbers';

const INITIAL_STATE = {
  list: [],
  total: 0,
  editedItem: null,
  listPoints: [],
  totalPoints: 0,
  editedItemPoints: null,
};
export default handleActions(
  {
    [putSimNumberUploads]: (state, { payload }) => ({ ...state, editedItem: payload }),
    [putSimNumberUploadsList]: (state, { payload: { list, total } }) => ({
      ...state,
      list,
      total,
    }),
    [putSimSalesPointsUploads]: (state, { payload }) => ({ ...state, editedItemPoints: payload }),
    [putSimSalesPointsUploadsList]: (state, { payload: { listPoints, totalPoints } }) => ({
      ...state,
      listPoints,
      totalPoints,
    }),
    [putServicesUploads]: (state, { payload }) => ({ ...state, editedItemServices: payload }),
    [putServicesUploadsList]: (state, { payload: { listServices, totalServices } }) => ({
      ...state,
        listServices,
        totalServices,
    })
  },
  INITIAL_STATE,
);
