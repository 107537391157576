import { flexDirectionsMap, layoutDirection as layout } from "../constants/onsiteWidgets";

export const getFlexStyles = layoutDirection =>
  layoutDirection === layout.default || !(layoutDirection in flexDirectionsMap) ?
    {} : {
      display: 'flex',
      flexDirection: {
        horizontal: 'row',
        vertical: 'column'
      }[layoutDirection],
      justifyContent: 'center',
    }