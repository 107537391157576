import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';
import { parseStyles } from './commonParsers/commonStylesParser'
import linkAttributesParser from './commonParsers/linkAttributesParser';

const verticalPositions = {
  center: 'center',
  'flex-start': 'top',
  'flex-end': 'bottom'
};

const horizontalPositions = {
  center: 'center',
  'flex-start': 'left',
  'flex-end': 'right'
};

export const videoParser = (el, id, type, horizontal) => {
  const link = el.getElementsByTagName('a').item(0)
  const videoElement = el.getElementsByTagName('div').item(0)
  const { justifyContent } = el.style;

  if (videoElement) {
    const video = {
      url: videoElement.getElementsByTagName('source')[0].getAttribute('src'),
    };

    const poster = {
      url: videoElement.getElementsByTagName('video')[0].getAttribute('poster'),
    };

    const autoMoveMode = JSON.parse(videoElement.getElementsByTagName('video')[0].dataset.autoMoveMode || 'false');
    const controlledMode = JSON.parse(videoElement.getElementsByTagName('video')[0].dataset.controlledMode || 'false');

    const videoWidth = videoElement.style.width;
    const videoHeight = videoElement.style.height;
    const width = videoWidth === '100%' ? '0px' : videoWidth;
    const height = videoHeight === '100%' ? '0px' : videoHeight;
    const linkOptions = linkAttributesParser(link)

    if (horizontal) {
      const position = verticalPositions[justifyContent];

      return {
        type: 'video',
        height,
        position,
        video,
        poster,
        id,
        autoMoveMode,
        controlledMode,
        ...linkOptions,
      };
    }

    const position = horizontalPositions[justifyContent];
    const stylesOptions = parseStyles(videoElement.style);

    const imageOptions = {
      ...stylesOptions,
      ...linkOptions,
      type: 'video',
      width,
      height,
      position,
      video,
      poster,
      autoMoveMode,
      controlledMode,
      id,
    };

    return omitBy(imageOptions, isNil);
  }
};
