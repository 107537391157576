import Resizer from 'react-image-file-resizer'
import { v4 as uuidv4 } from 'uuid';
import {
  LIGHT,
  REGULAR,
  MEDIUM,
  BOLD,
  MAX_WIDTH_RESIZE,
  MAX_HEIGHT_RESIZE,
  WEP_PUSH_STEPS,
  EMAIL_STEPS,
  OFFER_CAMPAIGNS_STEPS,
  SMS_STEPS,
  CHAT_BOT_STEPS,
  SMS_TREE_STEPS,
  ONSITE_STEPS,
  SOCIAL_SHARING_STEPS,
  CPA_LOYALTY_STEPS,
  CPA_OFFER_STEPS,
  PROMO_CODE_UPLOADS_STEPS,
  SIM_NUMBER_UPLOADS_STEPS,
  BLACK_LIST_UPLOADS_STEPS,
  SIM_NUMBER_POINTS_STEPS,
  SERVICES_STEPS,
} from '../constants'
import {
  TEXT_DEFAULT_OBJECT,
  SEPARATOR_DEFAULT_OBJECT,
  IMAGE_DEFAULT_OBJECT,
  SOCIAL_DEFAULT_OBJECT,
  MAIN_CONTAINER_DIV_ID,
  ACTIVE_ITEM_DIV_ID
} from '../constants/objects'
import { DEFAULT, BOLD as BOLDSTYLE, INLINE_STYLE_MAP } from '../constants/editor'
import { elements, DEFAULT_ELEMENT_VALUES } from '../constants/onsiteWidgets'

export { default as object } from './object'

const fontWeightNumberToStringMapper = {
  [LIGHT.number]: LIGHT.string,
  [REGULAR.number]: REGULAR.string,
  [MEDIUM.number]: MEDIUM.string,
  [BOLD.number]: BOLD.string
}

function convertFontWeightToString(fontWeight) {
  return fontWeightNumberToStringMapper[fontWeight] || REGULAR.string
}

const fontWeightStringToNumberMapper = {
  [LIGHT.string]: LIGHT.number,
  [REGULAR.string]: REGULAR.number,
  [MEDIUM.string]: MEDIUM.number,
  [BOLD.string]: BOLD.number
}

function convertFontWeightToNumber(fontWeight) {
  return fontWeightStringToNumberMapper[fontWeight] || REGULAR.number
}

export function convertFontWeight(fontWeight, to = 'string') {
  switch (to) {
    case 'number': {
      return convertFontWeightToNumber(fontWeight)
    }
    case 'string':
    default:
      return convertFontWeightToString(fontWeight)
  }
}

export function getSteps() {
  return {
    ...WEP_PUSH_STEPS,
    ...ONSITE_STEPS,
    ...SOCIAL_SHARING_STEPS,
    ...CPA_LOYALTY_STEPS,
    ...CPA_OFFER_STEPS,
    ...PROMO_CODE_UPLOADS_STEPS,
    ...SIM_NUMBER_UPLOADS_STEPS,
    ...BLACK_LIST_UPLOADS_STEPS,
    ...SIM_NUMBER_POINTS_STEPS,
    ...SERVICES_STEPS,
    ...EMAIL_STEPS,
    ...OFFER_CAMPAIGNS_STEPS,
    ...SMS_STEPS,
    ...CHAT_BOT_STEPS,
    ...SMS_TREE_STEPS,
  };
}

export function resizeFile(file, type = 'JPEG', maxWidth = MAX_WIDTH_RESIZE, maxHeight = MAX_HEIGHT_RESIZE) {
  if (file) {
    return new Promise((resolve, reject) => {
      try {
        Resizer.imageFileResizer(
          file,
          maxWidth,
          maxHeight,
          type,
          80,
          0,
          (uri) =>
            resolve({
              file,
              url: uri,
              type
            }),
          'base64'
        )
      } catch (e) {
        reject(e)
      }
    })
  }
  throw new Error('Not specified file object in the function "resizeFile"')
}

export function b64ToBlob(Base64Image) {
  // SPLIT INTO TWO PARTS
  const parts = Base64Image.split(';base64,')
  // HOLD THE CONTENT TYPE
  const imageType = parts[0].split(':')[1]
  // DECODE BASE64 STRING
  const decodedData = window.atob(parts[1])
  // CREATE UNIT8ARRAY OF SIZE SAME AS ROW DATA LENGTH
  const uInt8Array = new Uint8Array(decodedData.length)
  // INSERT ALL CHARACTER CODE INTO UINT8ARRAY
  for (let i = 0; i < decodedData.length; i += 1) {
    uInt8Array[i] = decodedData.charCodeAt(i)
  }
  // RETURN BLOB IMAGE AFTER CONVERSION
  return new Blob([uInt8Array], { type: imageType })
}

export async function fileToB64(file) {
  const reader = new FileReader()

  const readFilePromise = new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort()
      reject(new Error('Problem with parsing input file'))
    }

    reader.onload = () => {
      resolve(reader.result)
    }

    reader.readAsBinaryString(file)
  })

  const binaryData = await readFilePromise
  const base64String = window.btoa(binaryData)

  return `data:${file.type};base64,${base64String}`
}

function colorToHex(c) {
  const hex = Number(c).toString(16)
  return hex.length === 1 ? `0${hex}` : hex
}

function rgbToHex(value) {
  const [r, g, b] = value.replace('rgb(', '').replace(')', '').split(',')
  return `#${colorToHex(r)}${colorToHex(g)}${colorToHex(b)}`
}

export function convertStringToWidget(campaignBody) {
  const itemsList = []
  const modalSetStyles = {}
  const editorAddStyles = {}
  const socialSet = SOCIAL_DEFAULT_OBJECT
  if (!campaignBody) {
    return {
      itemsList,
      modalSetStyles,
      socialSet,
      editorAddStyles
    }
  }
  const el = document.createElement('html')
  el.innerHTML = campaignBody

  // parse container styles - background image or color, border
  // Todo: use selector constants on generation
  const mainContainerEl = el.querySelectorAll(`#${MAIN_CONTAINER_DIV_ID}`)
  modalSetStyles.backgroundColor = mainContainerEl[0].style.backgroundColor
  modalSetStyles.backgroundImage = mainContainerEl[0].style.backgroundImage
  modalSetStyles.backgroundRepeat = mainContainerEl[0].style.backgroundRepeat
  // modalSetStyles.file = mainContainerEl[0].style.file;
  modalSetStyles.backgroundSize = mainContainerEl[0].style.backgroundSize
  modalSetStyles.borderColor = mainContainerEl[0].style.borderColor
  modalSetStyles.file = { file: File, url: `#${MAIN_CONTAINER_DIV_ID}`.src }

  // Parse items (text, separator, image) and its properties
  const activeItemDivs = el.querySelectorAll(`#${ACTIVE_ITEM_DIV_ID}`)
  activeItemDivs.forEach((div) => {
    let resultObject = { ...TEXT_DEFAULT_OBJECT }
    if (div.firstElementChild.nodeName === 'DIV') {
      if (
        div.firstElementChild.firstElementChild &&
        div.firstElementChild.firstElementChild.nodeName === 'HR'
      ) {
        resultObject = { ...SEPARATOR_DEFAULT_OBJECT }
        resultObject.style = {
          ...SEPARATOR_DEFAULT_OBJECT.style,
          border: 'none',
          width: div.firstElementChild.firstElementChild.style.width,
          borderBottomStyle: div.firstElementChild.firstElementChild.style.borderBottomStyle,
          borderBottomWidth: div.firstElementChild.firstElementChild.style.borderBottomWidth,
          borderBottomColor: div.firstElementChild.firstElementChild.style.borderBottomColor
        }
        resultObject.styleWrapper = {
          ...SEPARATOR_DEFAULT_OBJECT.styleWrapper,
          marginTop: div.firstElementChild.style.marginTop,
          marginBottom: div.firstElementChild.style.marginBottom,
          justifyContent: div.firstElementChild.style.justifyContent
        }
      } else if (div.firstElementChild.className === 'rdw-editor-wrapper') {
        const editorContainer = div.firstElementChild
        const blocks = editorContainer.querySelectorAll("[data-block='true']")
        const contentBlocks = []
        blocks.forEach((block) => {
          const type = block.className ? block.className.toLowerCase() : 'unstyled'
          const { offsetKey } = block.dataset
          const key = offsetKey.split('-')[0]
          const text = block.textContent
          const inlineStyleRanges = [
            {
              offset: 0,
              length: text.length,
              style: DEFAULT
            }
          ]
          const spans = block.firstElementChild.querySelectorAll('span[data-offset-key]')
          let offsetCounter = 0
          spans.forEach((span) => {
            const keyStyles = [
              'color',
              'fontFamily',
              'fontSize',
              'fontWeight',
              'fontVariant',
              'textDecoration',
              'fontStyle',
              'borderColor',
              'borderStyle',
              'borderWidth'
            ]
            const styleNames = []
            let value
            keyStyles.forEach((keyStyle) => {
              switch (keyStyle) {
                case 'color':
                  value = rgbToHex(span.style[keyStyle])
                  styleNames.push(`color${value}`)
                  editorAddStyles[`color${value}`] = { [keyStyle]: value }
                  break
                case 'fontWeight':
                  value = span.style[keyStyle]
                  if (value.toUpperCase() === BOLDSTYLE) {
                    styleNames.push(BOLDSTYLE)
                  } else if (value) {
                    editorAddStyles[`fontWeight${value}`] = {
                      [keyStyle]: value
                    }
                  }
                  break
                default: {
                  value = span.style[keyStyle]
                  if (value) {
                    editorAddStyles[`${keyStyle}${value}`] = {
                      [keyStyle]: value
                    }
                    styleNames.push(`${keyStyle}${value}`)
                  }
                }
              }
            })
            styleNames.forEach((name) => {
              inlineStyleRanges.push({
                offset: offsetCounter,
                length: span.textContent.length,
                style: name
              })
            })
            offsetCounter += span.textContent.length
          })
          contentBlocks.push({
            key,
            type,
            text,
            inlineStyleRanges,
            depth: 0
          })
        })
        const content = {
          blocks: contentBlocks,
          entityMap: []
        }
        resultObject.content = content
      } else {
        resultObject = { ...IMAGE_DEFAULT_OBJECT }
        resultObject.style = {
          ...IMAGE_DEFAULT_OBJECT.style,
          width: div.firstElementChild.firstElementChild.style.width
        }
        resultObject.styleWrapper = {
          ...IMAGE_DEFAULT_OBJECT.styleWrapper,
          justifyContent: div.firstElementChild.style.justifyContent
        }
        resultObject.file = {
          file: File,
          url: div.firstElementChild.firstElementChild.src
        }
      }
    } else {
      const { style } = div.firstElementChild
      resultObject.style = {
        ...TEXT_DEFAULT_OBJECT.style,
        fontFamily: style.fontFamily,
        textAlign: style.textAlign,
        fontSize: style.fontSize,
        fontWeight: style.fontWeight,
        textDecoration: style.textDecoration,
        fontStyle: style.fontStyle,
        color: style.color,
        borderColor: style.borderColor,
        borderWidth: style.borderWidth,
        borderStyle: style.borderStyle
      }
      resultObject.value = div.firstElementChild.textContent
    }
    resultObject.id = uuidv4()
    itemsList.push(resultObject)
  })

  // Parse social links present
  // TODO: change social icons selector
  const anchors = el.querySelectorAll('a')
  if (anchors[0]) {
    socialSet.styleLink = {
      ...socialSet.styleLink,
      backgroundColor: anchors[0].style.backgroundColor,
      borderColor: anchors[0].style.borderColor,
      borderWidth: anchors[0].style.borderWidth,
      borderStyle: anchors[0].style.borderStyle
    }
  }
  const fillRegex = /fill="#[0-9a-fA-F]{6}"/
  const socialFill = anchors[0].firstElementChild.innerHTML.match(fillRegex)
  if (socialFill) {
    socialSet.styleSvg.fill = socialFill[0].match(/#[0-9a-fA-F]{6}/)[0]
  }
  const socialKeywords = ['twitter.com', 'facebook.com', 'ok.ru', 'vk.com']
  socialKeywords.forEach((keyword) => {
    let exist = false
    anchors.forEach((a) => {
      if (!exist) {
        exist = new RegExp(keyword).test(a.href)
      }
    })
    const propertyKey = keyword.split('.')[0]
    socialSet.checkboxes[propertyKey] = exist
  })
  socialSet.checkboxes = {
    ...socialSet.checkboxes,
    fill: true,
    border: true,
    iconColor: true
  }
  return {
    itemsList,
    modalSetStyles,
    socialSet,
    editorAddStyles
  }
}

export function filterStyles(type, currentRawBlock, currentValue) {
  if (currentRawBlock) {
    const typeStyles = currentRawBlock.inlineStyleRanges.filter((styleRange) => {
      const isTypeStyle = new RegExp(type).test(styleRange.style)
      if (isTypeStyle) {
        let value = false
        if (type === 'color') {
          const colorMatch = styleRange.style[2].match(/#[0-9a-fA-F]{6}/)
          value = colorMatch ? colorMatch[0] : false
        }
        if (type === 'fontSize') {
          const valueMatch = styleRange.style[1].match(/#[0-9]/)
          value = valueMatch ? valueMatch[0] : false
        }
        if (type === 'fontFamily' || type === 'border') {
          const valueMatch = styleRange.style[0].split('-')
          value = valueMatch ? valueMatch[1] : false
        }
        return value !== currentValue
      }
      return isTypeStyle
    })
    return typeStyles.map((styleSet) => styleSet.style)
  }
  return []
}

export function setTextInlineStyle(el, textAlign) {
  const texts = el.querySelectorAll(`.${textAlign}`)
  texts.forEach((text) => {
    text.style.setProperty('text-align', INLINE_STYLE_MAP[textAlign])
    text.style.setProperty('display', 'inline-block')
    text.style.setProperty('width', '100%')
    text.firstElementChild.style.setProperty('text-align', INLINE_STYLE_MAP[textAlign])
  })
  return el
}

export const getBorderProperty = (borderProperty) => {
  if (!borderProperty) {
    const socialSharingDefaultProperties = DEFAULT_ELEMENT_VALUES[elements.SOCIAL]
    const defaultBorderColor = socialSharingDefaultProperties.borderColor

    return { borderWidth: '0px', borderColor: defaultBorderColor }
  }

  // ddpx. return array, take first value
  const borderPxs = borderProperty.match(/[1-9][0-9]*px/)
  const borderWidth = borderPxs ? borderPxs[0] : undefined
  // rgba(dd, dd, dd, d.d)
  const first = (/rgba/.exec(borderProperty) || /rgb/.exec(borderProperty)).index
  const last = /\)/.exec(borderProperty).index
  const borderColor = borderProperty.substr(first, last - first + 1)

  return { borderWidth, borderColor }
}
