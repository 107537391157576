import {
  call, put, takeLatest, delay,
} from 'redux-saga/effects';

import { simNumbers as api } from '../api';
import {
  simNumbers as simNumbersActions,
  snackbar as snackbarActions,
} from '../redux/actions';

const STATUSES = {
  active: 1,
  onHold: 2,
  archived: 3
};

const INVERT_STATUSES = {
  1: 'active',
  2: 'onHold',
  3: 'archived',
};

const mapStatus = list =>
    list.map(item => ({ ...item, status: INVERT_STATUSES[item.status] }));

function* createSimNumberUpload({ payload: { values } }) {
  const { simNumber, name } = values;
  try {
    yield put(simNumbersActions.createSimNumberUploadRoutine.request());

    const { id } = values;
    const data = new FormData();

    data.append('file', ...simNumber);
    data.append('name', name);

    const upload = yield call(api.postSimNumberUpload, id, data);

    yield put(simNumbersActions.createSimNumberUploadRoutine.success(upload));
  } catch (error) {
    yield put(simNumbersActions.createSimNumberUploadRoutine.failure(error));
  } finally {
    yield put(simNumbersActions.createSimNumberUploadRoutine.fulfill());
  }
}
function* createSimPointsUpload({ payload: { values } }) {
  const { simPoint, name } = values;
  try {
    yield put(simNumbersActions.createSimSalesPointsUploadRoutine.request());

    const { id } = values;
    const data = new FormData();

    data.append('file', ...simPoint);
    data.append('name', name);

    const upload = yield call(api.postSimPointsUpload, id, data);

    yield put(simNumbersActions.createSimSalesPointsUploadRoutine.success(upload));
  } catch (error) {
    yield put(simNumbersActions.createSimSalesPointsUploadRoutine.failure(error));
  } finally {
    yield put(simNumbersActions.createSimSalesPointsUploadRoutine.fulfill());
  }
}
function* createServiceUpload({ payload: { values } }) {
  const { service, name } = values;
  try {
    yield put(simNumbersActions.createServicesUploadRoutine.request());

    const { id } = values;
    const data = new FormData();

    data.append('file', ...service);
    data.append('name', name);

    const upload = yield call(api.postServicesUpload, id, data);

    yield put(simNumbersActions.createServicesUploadRoutine.success(upload));
  } catch (error) {
    yield put(simNumbersActions.createServicesUploadRoutine.failure(error));
  } finally {
    yield put(simNumbersActions.createServicesUploadRoutine.fulfill());
  }
}

function* activateSimNumberUploads({ payload: { id, pageView } }) {
  try {
    yield call(api.changeStatus, id, STATUSES.active);
    yield put(simNumbersActions.fetchSimNumberUploadsList({ page: 1, perPage: 10, status: pageView }));
  } catch (error) {
    const message = error.request && error.request.status === 403 ? 'You cannot set Active status!' : 'Change status error occurred!';
    yield put(
      snackbarActions.enqueueSnackbar({
        message,
        options: { variant: 'error' },
      }),
    );
  }
}

function* activateSimPointsUploads({ payload: { id, pageView } }) {
  try {
    yield call(api.changeStatusPoints, id, STATUSES.active);
    yield put(simNumbersActions.fetchSimSalesPointsUploadsList({ page: 1, perPage: 10, status: pageView }));
  } catch (error) {
    const message = error.request && error.request.status === 403 ? 'You cannot set Active status!' : 'Change status error occurred!';
    yield put(
      snackbarActions.enqueueSnackbar({
        message,
        options: { variant: 'error' },
      }),
    );
  }
}

function* activateServicesUploads({ payload: { id, pageView } }) {
  try {
    yield call(api.changeStatusServices, id, STATUSES.active);
    yield put(simNumbersActions.fetchServicesUploadsList({ page: 1, perPage: 10, status: pageView }));
  } catch (error) {
    const message = error.request && error.request.status === 403 ? 'You cannot set Active status!' : 'Change status error occurred!';
    yield put(
      snackbarActions.enqueueSnackbar({
        message,
        options: { variant: 'error' },
      }),
    );
  }
}

function* holdSimNumberUploads({ payload: { id, pageView } }) {
  try {
    yield call(api.changeStatus, id, STATUSES.onHold);
    yield put(simNumbersActions.fetchSimNumberUploadsList({ page: 1, perPage: 10, status: pageView }));
  } catch (error) {
    yield put(
      snackbarActions.enqueueSnackbar({
        message: 'Error while set Hold status',
        options: { variant: 'error' },
      }),
    );
  }
}

function* holdSimPointsUploads({ payload: { id, pageView } }) {
  try {
    yield call(api.changeStatusPoints, id, STATUSES.onHold);
    yield put(simNumbersActions.fetchSimSalesPointsUploadsList({ page: 1, perPage: 10, status: pageView }));
  } catch (error) {
    yield put(
      snackbarActions.enqueueSnackbar({
        message: 'Error while set Hold status',
        options: { variant: 'error' },
      }),
    );
  }
}

function* holdServicesUploads({ payload: { id, pageView } }) {
  try {
    yield call(api.changeStatusServices, id, STATUSES.onHold);
    yield put(simNumbersActions.fetchServicesUploadsList({ page: 1, perPage: 10, status: pageView }));
  } catch (error) {
    yield put(
      snackbarActions.enqueueSnackbar({
        message: 'Error while set Hold status',
        options: { variant: 'error' },
      }),
    );
  }
}

function* fetchSimNumberUploads({ payload: { id } }) {
  try {
    const item = yield call(api.fetchCampaign, id);
    yield put(simNumbersActions.putSimNumberUploads(item));
  } catch (error) {
    yield put(
      snackbarActions.enqueueSnackbar({
        message: 'Error while fetch sim number upload',
        options: { variant: 'error' },
      }),
    );
  }
}

function* fetchSimPointsUploads({ payload: { id } }) {
  try {
    const item = yield call(api.fetchCampaignPoints, id);
    yield put(simNumbersActions.putSimSalesPointsUploads(item));
  } catch (error) {
    yield put(
      snackbarActions.enqueueSnackbar({
        message: 'Error while fetch sim points upload',
        options: { variant: 'error' },
      }),
    );
  }
}

function* fetchServicesUploads({ payload: { id } }) {
  try {
    const item = yield call(api.fetchCampaignServices, id);
    yield put(simNumbersActions.putServicesUploads(item));
  } catch (error) {
    yield put(
      snackbarActions.enqueueSnackbar({
        message: 'Error while fetch services upload',
        options: { variant: 'error' },
      }),
    );
  }
}

function* fetchSimNumberUploadsList({
  payload: {
    page, perPage, status, search,
  },
}) {
  try {
    const [list, total] = yield call(api.fetchCampaignList, {
      page,
      perPage,
      status: STATUSES[status],
      search,
    });

    yield put(simNumbersActions.putSimNumberUploadsList({ list: mapStatus(list), total }));
  } catch (error) {
    yield put(
      snackbarActions.enqueueSnackbar({
        message: 'Error while fetch list of promo code uploads',
        options: { variant: 'error' },
      }),
    );
  }
}

function* fetchSimPointsUploadsList({
  payload: {
    page, perPage, status, search,
  },
}) {
  try {
    const [list, totalPoints] = yield call(api.fetchCampaignPointsList, {
      page,
      perPage,
      status: STATUSES[status],
      search,
    });

    yield put(simNumbersActions.putSimSalesPointsUploadsList({ listPoints: mapStatus(list), totalPoints }));
  } catch (error) {
    yield put(
      snackbarActions.enqueueSnackbar({
        message: 'Error while fetch list of promo code uploads',
        options: { variant: 'error' },
      }),
    );
  }
}

function* fetchServicesUploadsList({
  payload: {
    page, perPage, status, search,
  },
}) {
  try {
    const [list, totalServices] = yield call(api.fetchCampaignServicesList, {
      page,
      perPage,
      status: STATUSES[status],
      search,
    });

    yield put(simNumbersActions.putServicesUploadsList({ listServices: mapStatus(list), totalServices }));
  } catch (error) {
    yield put(
      snackbarActions.enqueueSnackbar({
        message: 'Error while fetch list of services uploads',
        options: { variant: 'error' },
      }),
    );
  }
}

function* validateCampaignName({
  payload: {
    name, id, resolve, reject,
  },
}) {
  try {
    if (name) {
      yield delay(500);

      const result = yield call(api.validateCampaignName, name, id);

      if (result) {
        resolve();
      } else {
        reject({ campaign_name: 'This name is already used' });
      }
    }
  } catch (error) {
    reject();
    yield put(
      snackbarActions.enqueueSnackbar({
        message: 'Upload name validation problem!',
        options: { variant: 'error' },
      }),
    );
  }
}

function* validatePointsCampaignName({
  payload: {
    name, id, resolve, reject,
  },
}) {
  try {
    if (name) {
      yield delay(500);

      const result = yield call(api.validatePointsCampaignName, name, id);

      if (result) {
        resolve();
      } else {
        reject({ campaign_name: 'This name is already used' });
      }
    }
  } catch (error) {
    reject();
    yield put(
      snackbarActions.enqueueSnackbar({
        message: 'Upload name validation problem!',
        options: { variant: 'error' },
      }),
    );
  }
}

function* validateServicesCampaignName({
  payload: {
    name, id, resolve, reject,
  },
}) {
  try {
    if (name) {
      yield delay(500);

      const result = yield call(api.validateServicesCampaignName, name, id);

      if (result) {
        resolve();
      } else {
        reject({ campaign_name: 'This name is already used' });
      }
    }
  } catch (error) {
    reject();
    yield put(
      snackbarActions.enqueueSnackbar({
        message: 'Upload name validation problem!',
        options: { variant: 'error' },
      }),
    );
  }
}

function* fetchFileReport({payload}) {
  try {
    yield call(api.fetchFileReport, payload);
  } catch (error) {
    console.error(error);
  }
}

function* fetchPointsFileReport({payload}) {
  try {
    yield call(api.fetchPointsFileReport, payload);
  } catch (error) {
    console.error(error);
  }
}

function* fetchServicesFileReport({payload}) {
  try {
    yield call(api.fetchServicesFileReport, payload);
  } catch (error) {
    console.error(error);
  }
}

export default function* watcher() {
  yield takeLatest(simNumbersActions.createSimNumberUploadRoutine.TRIGGER, createSimNumberUpload);
  yield takeLatest(simNumbersActions.createSimSalesPointsUploadRoutine.TRIGGER, createSimPointsUpload);
  yield takeLatest(simNumbersActions.createServicesUploadRoutine.TRIGGER, createServiceUpload);
  yield takeLatest(simNumbersActions.activateSimNumberUploads.toString(), activateSimNumberUploads);
  yield takeLatest(simNumbersActions.activateSimSalesPointsUploads.toString(), activateSimPointsUploads);
  yield takeLatest(simNumbersActions.activateServicesUploads.toString(), activateServicesUploads);
  yield takeLatest(simNumbersActions.holdSimNumberUploads.toString(), holdSimNumberUploads);
  yield takeLatest(simNumbersActions.holdSimSalesPointsUploads.toString(), holdSimPointsUploads);
  yield takeLatest(simNumbersActions.holdServicesUploads.toString(), holdServicesUploads);
  yield takeLatest(simNumbersActions.fetchSimNumberUploads.toString(), fetchSimNumberUploads);
  yield takeLatest(simNumbersActions.fetchSimSalesPointsUploads.toString(), fetchSimPointsUploads);
  yield takeLatest(simNumbersActions.fetchServicesUploads.toString(), fetchServicesUploads);
  yield takeLatest(simNumbersActions.fetchSimNumberUploadsList.toString(), fetchSimNumberUploadsList);
  yield takeLatest(simNumbersActions.fetchSimSalesPointsUploadsList.toString(), fetchSimPointsUploadsList);
  yield takeLatest(simNumbersActions.fetchServicesUploadsList.toString(), fetchServicesUploadsList);
  yield takeLatest(simNumbersActions.validateSimNumberUploadName.toString(), validateCampaignName);
  yield takeLatest(simNumbersActions.validateSimSalesPointsUploadName.toString(), validatePointsCampaignName);
  yield takeLatest(simNumbersActions.validateServicesUploadName.toString(), validateServicesCampaignName);
  yield takeLatest(simNumbersActions.fetchFileReport.toString(), fetchFileReport);
  yield takeLatest(simNumbersActions.fetchFileReportPoints.toString(), fetchPointsFileReport);
  yield takeLatest(simNumbersActions.fetchFileReportServices.toString(), fetchServicesFileReport);
}
