export const mapFormValuesToBonus = fields => ({
  ...fields.bonus,
  mode:
    fields.bonus.mode === 'uploadedDiscountList'
      ? 'currentDiscountList'
      : fields.bonus.mode,
  currentDiscountList:
    fields.bonus.mode === 'uploadedDiscountList'
      ? fields.campaign_name
      : fields.bonus.currentDiscountList
});

export const mapBonusToFormValues = res => res;
