import React, { useState, useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { OfferWall } from "../../components/OfferWall";
import { Loading } from "../../components/OfferWall/Loading";
import api from "../../api/offerWall";
import { mapResponseToCpaWall } from "../../mappers/cpaMappers";
import { snackbar as snackbarActions } from "../../redux/actions";
import { EventEmitter } from "../../help/eventEmitter";
import { OfferWallContext } from "./OfferWallContext";
import { ON_READY } from "./constants";
import { ROUTE_CPA_WALL } from "../../constants/routes";

export const OfferWallController = ({ location, match, history }) => {
  const dispatch = useDispatch();
  const { hash } = match.params;
  const campaignHash = hash.slice(8);
  const cfApiKey = hash.slice(0, 8);
  const { wallState, setWallState, setOffers } = useContext(OfferWallContext);
  const query = location.search;

  const {
    logo,
    backgroundColor,
    headerColor,
    headerTextColor,
    textStyles,
    username,
    companyName,
    offers,
    settings
  } = wallState;

  console.log('wallState', wallState)

  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const fetch = () =>
      api
        .fetchCampaignWallInfo(campaignHash)
        .then((info) => {
          setWallState(mapResponseToCpaWall(info));
          setLoading(false);
        })
        .catch((error) => {
          dispatch(
            snackbarActions.enqueueSnackbar({
              message: "Offer wall loading error occurred",
              options: { variant: "error" },
            })
          );
        });

    EventEmitter.subscribe(ON_READY, fetch);
  }, [campaignHash, cfApiKey, dispatch, setWallState]);

  const onTeaserSelected = (offer) => {
    history.push(`${ROUTE_CPA_WALL}/${hash}/${offer.id}${query}`);
  };

  return isLoading ? (
    <Loading />
  ) : (
    <OfferWall
      hash={campaignHash}
      cfApiKey={cfApiKey}
      logo={logo}
      settings={settings}
      backgroundColor={backgroundColor}
      headerColor={headerColor}
      headerTextColor={headerTextColor}
      username={username}
      companyName={companyName}
      offers={offers}
      onTeaserSelected={onTeaserSelected}
      setOffers={setOffers}
      textStyles={textStyles}
    />
  );
};
