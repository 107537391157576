import {
  call, put, takeLatest,
} from 'redux-saga/effects';

import { customers as api } from '../api';
import { customers as customersActions, snackbar as snackbarActions } from '../redux/actions';

function* fetchDetails({ payload: customerId }) {
  try {
    const details = yield call(api.fetchDetails, customerId);
    yield put(customersActions.putDetails(details));
  } catch (error) {
    yield put(
      snackbarActions.enqueueSnackbar({
        message: 'Fetch customers details error occurred!',
        options: { variant: 'error' },
      }),
    );
  }
}

function* fetchCampaignEvents({ payload }) {
  try {
    const campaignEvents = yield call(api.fetchCampaignEvents, payload.customerId, payload.date);
    yield put(customersActions.putCampaignEvents(campaignEvents));
  } catch (error) {
    yield put(
      snackbarActions.enqueueSnackbar({
        message: 'Fetch campaigns events error occurred!',
        options: { variant: 'error' },
      }),
    );
  }
}

function* fetchChatHistory({ payload }) {
  try {
    const chatHistory = yield call(api.fetchChatHistory, payload.customerId, payload.date);
    yield put(customersActions.putChatHistory(chatHistory));
  } catch (error) {
    yield put(
      snackbarActions.enqueueSnackbar({
        message: 'Fetch chat history error occurred!',
        options: { variant: 'error' },
      }),
    );
  }
}

function* fetchCustomersList({
  payload: {
    page, perPage, filters, sort,
  },
}) {
  try {
    const [customers, total] = yield call(api.fetchCustomers, {
      page,
      perPage,
      filters,
      sort,
    });

    yield put(customersActions.putCustomers({ list: customers, total }));
  } catch (error) {
    yield put(
      snackbarActions.enqueueSnackbar({
        message: 'Fetch customers list error occurred!',
        options: { variant: 'error' },
      }),
    );
  }
}

function* fetchCustomersTags() {
  try {
    const tags = yield call(api.fetchTags);

    yield put(customersActions.putCustomersTags(tags));
  } catch (error) {
    yield put(
      snackbarActions.enqueueSnackbar({
        message: 'Fetch customers tags error occurred!',
        options: { variant: 'error' },
      }),
    );
  }
}

function* runCampaignsByTag({
  payload: {
    campaignId,
    campaignType,
    customerIds,
    filters,
  },
}) {
  try {
    const result = yield call(api.runCampaignsByTag, campaignId, campaignType, customerIds, filters);

    yield put(
      snackbarActions.enqueueSnackbar({
        message: `Run campaigns: ${result}`,
        options: { variant: 'success' },
      }),
    );
  } catch (error) {
    yield put(
      snackbarActions.enqueueSnackbar({
        message: 'Run campaigns by tags error occurred!',
        options: { variant: 'error' },
      }),
    );
  }
}

export default function* watcher() {
  yield takeLatest(customersActions.fetchDetails.toString(), fetchDetails);
  yield takeLatest(customersActions.fetchCampaignEvents.toString(), fetchCampaignEvents);
  yield takeLatest(customersActions.fetchChatHistory.toString(), fetchChatHistory);
  yield takeLatest(
    customersActions.fetchCustomers.toString(),
    fetchCustomersList,
  );
  yield takeLatest(customersActions.fetchCustomersTags.toString(), fetchCustomersTags);
  yield takeLatest(customersActions.runCampaignsByTag.toString(), runCampaignsByTag);
}
