import React, { PureComponent, useRef } from 'react';
import { useClickAway } from 'react-use';
import isNil from 'lodash/isNil';
import styled from 'styled-components';
import { useToggleLayer, anchor } from 'react-laag';
import html2canvas from 'html2canvas';
import getCanvasPixelColor from 'get-canvas-pixel-color';
import { Box, IconButton, FormLabel } from '@material-ui/core';
import PickIcon from '@material-ui/icons/Colorize';
import { ChromePicker } from 'react-color';
import parse from 'color-parse';

const Root = styled.div`
  position: relative;
  display: ${({ inline }) => inline ? 'flex' : 'block'};
  align-items: ${({ inline }) => inline ? 'center' : 'unset'};
  flex-direction: ${({ inline }) => inline ? 'row-reverse' : 'unset'};
  justify-content: ${({ inline }) => inline ? 'flex-end' : 'unset'};
`;

const Color = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid white;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2);
  background-color: ${({ bg }) => bg} !important;
`;

const parseColorToRgba = color => {
  const colorStructure = parse(color);

  return `rgba(${colorStructure.values[0]}, ${colorStructure.values[1]}, ${colorStructure.values[2]}, ${colorStructure.alpha})`;
};

class EyeDropper extends PureComponent {
  eyeDropper = e => {
    const root = document.getElementById('eye-dropper');

    const { onChangeColor } = this.props;
    const removeEventListener = () => {
      root.removeEventListener('click', this.eyeDropper);
    };

    html2canvas(document.getElementById('root')).then(canvas => {
      const x = isNil(e.offsetX) ? e.layerX : e.offsetX;
      const y = isNil(e.offsetY) ? e.layerY : e.offsetY;
      const { r, g, b, a } = getCanvasPixelColor(canvas, x, y);
      a === 0
        ? onChangeColor({ r: 255, g: 255, b: 255 })
        : onChangeColor({ r, g, b });
      removeEventListener();
    });
    root.style.cursor = 'default';
    root.style.pointerEvents = 'none';
  };

  initEyeDropper = event => {
    const root = document.getElementById('eye-dropper');
    root.style.cursor = 'crosshair';
    root.style.pointerEvents = 'auto';
    root.addEventListener('click', this.eyeDropper);
  };

  render() {
    return (
      <IconButton size="small" onClick={this.initEyeDropper}>
        <PickIcon />
      </IconButton>
    );
  }
}

const ColorPickerRoot = styled.div`
  & > div {
    position: relative;
    z-index: 2000;
  }
`;

const ColorPicker = ({ value, onChange }) => (
  <ColorPickerRoot>
    <ChromePicker color={value} onChange={onChange} />
  </ColorPickerRoot>
);

const ColorField = ({ input, label, inline = false }) => {
  const handleChange = ({ rgb }) => {
    const rgbaString = `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${rgb.a})`;
    input.onChange(rgbaString);
  };

  const handleEyeDropColor = ({ r, g, b }) => {
    const rgbaString = `rgba(${r}, ${g}, ${b}, 1)`;
    input.onChange(rgbaString);
  };

  const ref = useRef(null);

  const [element, toggleLayerProps] = useToggleLayer(
    ({ layerProps, isOpen }) =>
      isOpen && (
        <div {...layerProps}>
          <div ref={ref}>
            <ColorPicker
              value={parseColorToRgba(input.value)}
              onChange={handleChange}
            />
          </div>
        </div>
      ),
    {
      placement: {
        anchor: anchor.LEFT_BOTTOM,
        autoAdjust: true,
        triggerOffset: 12,
        scrollOffset: 16,
        snapToAnchor: true
      }
    }
  );

  useClickAway(ref, () => {
    toggleLayerProps.close();
  });

  return (
    <Root inline={inline}>
      <FormLabel>{label}</FormLabel>
      <Box display="flex" alignItems="center" mb={1} mt={1}>
        <Box mr={1} zIndex={100}>
          <EyeDropper onChangeColor={handleEyeDropColor} />
        </Box>
        <Box>
          <IconButton
            size="small"
            onClick={toggleLayerProps.openFromMouseEvent}
          >
            <Color bg={input.value} />
          </IconButton>
        </Box>
      </Box>
      {element}
    </Root>
  );
};

export default ColorField;
