import { makeStyles } from '@material-ui/core/styles';
import cn from 'classnames';
import cond from 'lodash/fp/cond';
import equals from 'lodash/fp/equals';
import React from 'react';

import TextEditorField from '../../components/OnsiteForm/WidgetFormsFields/TextEditorField';
import { CURRENT_EDITOR } from '../../constants/editor';
import {
  buttonActionTypes,
  buttonWidthOptions,
  CLOSE_WIDGET_CLASS_NAME,
  SHOW_WIDGET_CLASS_NAME,
  ANIMATIONS_STYLE,
} from '../../constants/onsiteWidgets';
import { lastFormId } from '../../help/actions';
import { analyticConstants, analyticHtml } from '../../help/analytics';
import { createEventString } from '../../help/element';
import { fromPx } from '../../help/format';
import { openTextEditor } from '../../help/handleEditor';
import { toPx } from '../../help/parse';

const trackAttributes = analyticConstants.attributesToTrackClick;
const trackAttributesHtml = analyticHtml.getHtmlAttributesToTrackClick();

const getWidthProperties = cond([
  [equals(buttonWidthOptions.fullWidth), () => ({ width: '100%' })],
  [equals(buttonWidthOptions.fixed), (_, width) => ({ width })],
  [equals(buttonWidthOptions.dependingOnText), () => ({ width: 'max-content' })],
]);

const DefaultButton = (props) => {
  const {
    id,
    type,
    style,
    text,
    itemId,
    layoutStyle,
    eventsByElementIdMap,
    classNames,
    handleOpenTextEditor,
    activeElement,
    horizontal,
    values,
    section,
    form,
    events,
    focusTextEditor,
    animationClassNames,
    rotateDeg,
    rotateVerticalOrigin,
    rotateHorizontalOrigin,
    horizontalTranslate,
    verticalTranslate,
    animation,
  } = props;
  const actionString = createEventString(eventsByElementIdMap[itemId || id], 'click touchend');
  const formId = lastFormId(eventsByElementIdMap[itemId || id] || []);
  return (
    <div className={classNames} id={itemId} style={{ ...layoutStyle, width: style.width }}>
      <button
        data-text-editor
        data-editor-name={CURRENT_EDITOR.BUTTON_TEXT}
        data-animation={animation}
        data-rotate-deg={rotateDeg}
        data-rotate-vertical-origin={rotateVerticalOrigin}
        data-rotate-horizontal-origin={rotateHorizontalOrigin}
        data-horizontal-translate={horizontalTranslate}
        data-vertical-translate={verticalTranslate}
        onClick={handleOpenTextEditor}
        form={formId}
        className={`${classNames} ${animationClassNames}`}
        data-event-trigger={actionString}
        type={type}
        style={style}
      >
        {focusTextEditor === CURRENT_EDITOR.BUTTON_TEXT && id === activeElement.id ? (
          <TextEditorField
            editorType="inline-editor"
            type={activeElement.element.type}
            path={activeElement.path}
            isHorizontal={horizontal}
            values={values}
            section={section}
            form={form}
            element={activeElement.element}
            events={events}
            focusTextEditor={focusTextEditor}
          />
        ) : (
          <span dangerouslySetInnerHTML={{ __html: text }} />
        )
        }
      </button>
    </div>
  );
};

const CloseButton = ({
  id,
  type,
  style,
  text,
  itemId,
  layoutStyle,
  action,
  classNames,
  handleOpenTextEditor,
  focusTextEditor,
  activeElement,
  horizontal,
  values,
  section,
  form,
  events,
  animationClassNames,
  rotateDeg,
  rotateVerticalOrigin,
  rotateHorizontalOrigin,
  horizontalTranslate,
  verticalTranslate,
  animation,
}) => (
  <div id={itemId} style={{ ...layoutStyle, width: style.width }} data-action={action} className={classNames}>
    <button
      data-text-editor
      data-editor-name={CURRENT_EDITOR.BUTTON_TEXT}
      data-animation={animation}
      data-rotate-deg={rotateDeg}
      data-rotate-vertical-origin={rotateVerticalOrigin}
      data-rotate-horizontal-origin={rotateHorizontalOrigin}
      data-horizontal-translate={horizontalTranslate}
      data-vertical-translate={verticalTranslate}
      onClick={handleOpenTextEditor}
      type={type}
      style={style}
      className={`${CLOSE_WIDGET_CLASS_NAME} ${animationClassNames} ${classNames}`}
    >
      {focusTextEditor === CURRENT_EDITOR.BUTTON_TEXT && id === activeElement.id ? (
        <TextEditorField
          editorType="inline-editor"
          type={activeElement.element.type}
          path={activeElement.path}
          isHorizontal={horizontal}
          values={values}
          section={section}
          form={form}
          element={activeElement.element}
          events={events}
          focusTextEditor={focusTextEditor}
        />
      ) : (
        <span dangerouslySetInnerHTML={{ __html: text }} />
      )
      }
    </button>
  </div>
);

const WidgetShowButton = ({
  id,
  type,
  style,
  text,
  itemId,
  layoutStyle,
  action,
  classNames,
  handleOpenTextEditor,
  focusTextEditor,
  activeElement,
  horizontal,
  values,
  section,
  form,
  events,
  animationClassNames,
  rotateDeg,
  rotateVerticalOrigin,
  rotateHorizontalOrigin,
  horizontalTranslate,
  verticalTranslate,
  animation,
}) => {
  return (
    <div id={itemId} style={{ ...layoutStyle, width: style.width }} data-action={action} className={classNames}>
      <button
        data-text-editor
        data-editor-name={CURRENT_EDITOR.BUTTON_TEXT}
        data-animation={animation}
        data-rotate-deg={rotateDeg}
        data-rotate-vertical-origin={rotateVerticalOrigin}
        data-rotate-horizontal-origin={rotateHorizontalOrigin}
        data-horizontal-translate={horizontalTranslate}
        data-vertical-translate={verticalTranslate}
        onClick={handleOpenTextEditor}
        type={type}
        style={style}
        className={`${SHOW_WIDGET_CLASS_NAME} ${animationClassNames} ${classNames}`}
      >
        {focusTextEditor === CURRENT_EDITOR.BUTTON_TEXT && id === activeElement.id ? (
          <TextEditorField
            editorType="inline-editor"
            type={activeElement.element.type}
            path={activeElement.path}
            isHorizontal={horizontal}
            values={values}
            section={section}
            form={form}
            element={activeElement.element}
            events={events}
            focusTextEditor={focusTextEditor}
          />
        ) : (
          <span dangerouslySetInnerHTML={{ __html: text }} />
        )
        }
      </button>
    </div>
  );
};

const SubscribeButton = ({
  id,
  type,
  style,
  text,
  itemId,
  layoutStyle,
  action,
  classNames,
  handleOpenTextEditor,
  focusTextEditor,
  activeElement,
  horizontal,
  values,
  section,
  form,
  events,
  animationClassNames,
  rotateDeg,
  rotateVerticalOrigin,
  rotateHorizontalOrigin,
  horizontalTranslate,
  verticalTranslate,
  animation,
}) => {
  const styleObject = {
    background: style.background,
    border: style.border,
    borderRadius: style.borderRadius,
    boxShadow: style.boxShadow,
    boxSizing: style.boxSizing,
    color: style.color,
    fontFamily: style.fontFamily,
    fontSize: style.fontSize,
    fontStyle: style.fontStyle,
    fontWeight: style.fontWeight,
    lineHeight: style.lineHeight,
    minWidth: style.minWidth,
    paddingTop: style.paddingTop,
    paddingBottom: style.paddingBottom,
    paddingLeft: style.paddingLeft,
    paddingRight: style.paddingRight,
    textDecoration: style.textDecoration,
    width: 'inherit',
  };
  return (
    <>
      {focusTextEditor === CURRENT_EDITOR.BUTTON_TEXT && id === activeElement.id ? (
        <div
          id={itemId}
          style={{ ...layoutStyle, width: style.width, pointerEvents: 'auto' }}
          className={`${classNames} ${animationClassNames}`}
          data-action={action}
          onClick={handleOpenTextEditor}
          data-text-editor
          data-editor-name={CURRENT_EDITOR.BUTTON_TEXT}
          data-animation={animation}
          data-rotate-deg={rotateDeg}
          data-rotate-vertical-origin={rotateVerticalOrigin}
          data-rotate-horizontal-origin={rotateHorizontalOrigin}
          data-horizontal-translate={horizontalTranslate}
          data-vertical-translate={verticalTranslate}
        >
          <span style={styleObject}>
            <TextEditorField
              editorType="inline-editor"
              type={activeElement.element.type}
              path={activeElement.path}
              isHorizontal={horizontal}
              values={values}
              section={section}
              form={form}
              element={activeElement.element}
              events={events}
              focusTextEditor={focusTextEditor}
            />
          </span>
        </div>
      ) : (
        <div
          id={itemId}
          style={{ ...layoutStyle, width: style.width, pointerEvents: 'auto' }}
          data-action={action}
          onClick={handleOpenTextEditor}
          data-text-editor
          data-editor-name={CURRENT_EDITOR.BUTTON_TEXT}
          dangerouslySetInnerHTML={{
            __html: `
            <button
              ${trackAttributesHtml}
              class=${`${classNames} ${animationClassNames}`}
              type="${type}"
              data-width="${style.width}"
              style="background: ${style.background}; border: ${style.border}; border-radius: ${style.borderRadius}; box-shadow: ${style.boxShadow}; box-sizing: ${style.boxSizing}; color: ${style.color}; font-family: ${style.fontFamily}; font-size: ${style.fontSize}; font-style: ${style.fontStyle}; font-weight: ${style.fontWeight}; line-height: ${style.lineHeight}; min-width: ${style.minWidth}; padding-top: ${style.paddingTop}; padding-right: ${style.paddingRight}; padding-bottom: ${style.paddingBottom}; padding-left: ${style.paddingLeft}; text-decoration: ${style.textDecoration}; width: inherit;"
              onClick="comfolks.webPushSubscribeRequest()"
              data-animation="${animation}"
              data-rotate-deg="${rotateDeg}"
              data-rotate-vertical-origin="${rotateVerticalOrigin}"
              data-rotate-horizontal-origin="${rotateHorizontalOrigin}"
              data-horizontal-translate="${horizontalTranslate}"
              data-vertical-translate="${verticalTranslate}">
                ${text}
              </button>
          `,
          }}
        />
      )
      }
    </>
  );
};

const LinkButton = ({
  id,
  link,
  openLinkInNewWindow,
  text,
  style,
  itemId,
  layoutStyle,
  action,
  classNames,
  handleOpenTextEditor,
  focusTextEditor,
  activeElement,
  horizontal,
  values,
  section,
  form,
  events,
  animationClassNames,
  rotateDeg,
  rotateVerticalOrigin,
  rotateHorizontalOrigin,
  horizontalTranslate,
  verticalTranslate,
  animation,
}) => {
  const linkStyle = {
    ...style,
    display: 'inline-block',
    textAlign: 'center',
  };

  return (
    <div id={itemId} style={layoutStyle} data-action={action} className={classNames}>
      <a
        onClick={handleOpenTextEditor}
        data-text-editor
        data-editor-name={CURRENT_EDITOR.BUTTON_TEXT}
        data-animation={animation}
        data-rotate-deg={rotateDeg}
        data-rotate-vertical-origin={rotateVerticalOrigin}
        data-rotate-horizontal-origin={rotateHorizontalOrigin}
        data-horizontal-translate={horizontalTranslate}
        data-vertical-translate={verticalTranslate}
        href={link}
        style={linkStyle}
        target={openLinkInNewWindow ? '_blank' : undefined}
        className={`${CLOSE_WIDGET_CLASS_NAME} ${animationClassNames} ${classNames}`}
        {...trackAttributes}
      >
        {focusTextEditor === CURRENT_EDITOR.BUTTON_TEXT && id === activeElement.id ? (
          <TextEditorField
            editorType="inline-editor"
            type={activeElement.element.type}
            path={activeElement.path}
            isHorizontal={horizontal}
            values={values}
            section={section}
            form={form}
            element={activeElement.element}
            events={events}
            focusTextEditor={focusTextEditor}
          />
        ) : (
          <span dangerouslySetInnerHTML={{ __html: text }} />
        )
        }
      </a>
    </div>
  );
};

const actionToButtonComponentMapping = {
  [buttonActionTypes.linkType]: LinkButton,
  [buttonActionTypes.closeType]: CloseButton,
  [buttonActionTypes.subscribe]: SubscribeButton,
  [buttonActionTypes.widgetShowType]: WidgetShowButton,
};

export const Button = ({
  itemId,
  commonStyle,
  action,
  horizontal,
  backgroundColor,
  color,
  widthOption,
  fixedWidth,
  borderRadius,
  fontSize,
  lineHeight,
  fontFamily,
  fontStyle,
  fontWeight,
  textDecoration,
  layoutProps,
  sizes,
  boxShadow,
  setActiveEditor,
  focusTextEditor,
  activeElement,
  values,
  section,
  form,
  events,
  paddingLeft,
  paddingRight,
  rotateDeg = '0deg',
  rotateVerticalOrigin = 'center',
  rotateHorizontalOrigin = 'bottom',
  horizontalTranslate = '0px',
  verticalTranslate = '0px',
  animation = 'unset',
  isEditing,
  ...buttonProps
}) => {
  const { padding } = layoutProps || {};
  const widgetHeight = toPx(fromPx(sizes.height) - fromPx(padding) * 2);
  const widthProperties = getWidthProperties(widthOption, fixedWidth);

  const useStyles = makeStyles((theme) => ({
    fullWidth: () => ({
      width: '100%',
    }),
    fixed: ({ width }) => ({
      width,
    }),
    dependingOnText: () => ({
      width: 'auto!important',
      [theme.breakpoints.up('sm')]: {
        width: 'max-content!important',
      },
    }),
  }));

  const classes = useStyles({ widthProperties });

  const animationClassNames = cn({
    'comfolks-animated': animation !== 'unset' && !isEditing,
  });

  const layoutStyle = {
    ...commonStyle,
    ...(horizontal ? { height: widgetHeight } : {}),
  };

  const rotateStyles = {
    transform: `rotate(-${rotateDeg}) translate(${horizontalTranslate}, ${verticalTranslate})`,
    transformOrigin: `${rotateHorizontalOrigin} ${rotateVerticalOrigin}`,
  };

  const currentAnimation = ANIMATIONS_STYLE[animation];

  const style = {
    border: 0,
    boxShadow: boxShadow?.rawValue || 'none',
    boxSizing: 'border-box',
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingLeft: paddingLeft || '6px',
    paddingRight: paddingRight || '6px',
    background: backgroundColor,
    WebkitAppearance: 'none',
    margin: (boxShadow.spreadRadius > 0 && boxShadow.blurRadius === 0) ? `${`${boxShadow.spreadRadius}px`} 0` : (boxShadow.blurRadius > 0 ? `${`${boxShadow.blurRadius - 5}px`} 0` : ''),
    borderRadius,
    color,
    fontSize,
    fontFamily,
    fontStyle,
    fontWeight,
    lineHeight,
    textDecoration,
    ...(horizontal ? { width: 'max-content' } : { width: '100%' }),
    ...widthProperties,
    ...rotateStyles,
    pointerEvents: 'auto',
    animation: animation !== 'unset' ? currentAnimation : 'unset',
  };
  let ButtonComponent = DefaultButton;
  if (action && action.startsWith(buttonActionTypes.widgetShowType)) {
    ButtonComponent = WidgetShowButton;
  } else {
    ButtonComponent = actionToButtonComponentMapping[action] || DefaultButton;
  }

  return (
    <ButtonComponent
      handleOpenTextEditor={openTextEditor(setActiveEditor)}
      focusTextEditor={focusTextEditor}
      horizontal={horizontal}
      activeElement={activeElement}
      values={values}
      section={section}
      form={form}
      events={events}
      className={classes[widthOption]}
      animationClassNames={animationClassNames}
      itemId={itemId}
      layoutStyle={layoutStyle}
      action={action}
      style={style}
      rotateDeg={rotateDeg}
      rotateVerticalOrigin={rotateVerticalOrigin}
      rotateHorizontalOrigin={rotateHorizontalOrigin}
      horizontalTranslate={horizontalTranslate}
      verticalTranslate={verticalTranslate}
      animation={animation}
      {...buttonProps}
    />
  );
};
