import axios from "./axios";
import {
  CAMPAIGN_OFFER_ENDPOINT,
  CAMPAIGN_OFFER_STATUS_ENDPOINT,
  CAMPAIGN_OFFER_VERIFY_CAMPAIGN_NAME_ENDPOINT,
  CAMPAIGN_OFFER_SEND_PROMOCODES_FILE_ENDPOINT
} from "../constants/endpoints";

const postCampaignOffer = async (id, CampaignOfferFormData) => {
  const { data: createdCampaignOffer } = await axios.post(
    id ? `${CAMPAIGN_OFFER_ENDPOINT}/${id}` : CAMPAIGN_OFFER_ENDPOINT,
    CampaignOfferFormData
  );
  return createdCampaignOffer;
};

const changeStatusCampaignOffer = async (CampaignOfferId, setObjectStatus) => {
  const { data } = await axios.post(
    `${CAMPAIGN_OFFER_STATUS_ENDPOINT}/${CampaignOfferId}`,
    { status: setObjectStatus }
  );
  return data;
};

const fetchCampaignOffer = async CampaignOfferId => {
  const { data: CampaignOffer } = await axios.get(
    `${CAMPAIGN_OFFER_ENDPOINT}/${CampaignOfferId}`
  );

  return CampaignOffer;
};

const fetchCampaignOfferList = async ({ status = "", perPage = 10, page = 1, search = '' }) => {
  const {
    data: { campaigns, total }
  } = await axios.get(
    `${CAMPAIGN_OFFER_ENDPOINT}?status=${status || ""}&page=${page}&per_page=${perPage}&search=${search || ''}`
  );

  return [campaigns, total];
};

const validateCampaignName = async (name, id) => {
  const { data } = await axios.get(
    `${CAMPAIGN_OFFER_VERIFY_CAMPAIGN_NAME_ENDPOINT}?name=${name}${
      id ? `&id=${id}` : ""
    }`
  );
  return data;
};

const sendPromoCodesFile = async file => {
  const data = new FormData();
  data.append("file", file);

  const {
    data: { success, count }
  } = await axios.post(CAMPAIGN_OFFER_SEND_PROMOCODES_FILE_ENDPOINT, data);

  return { success, count };
};

export default {
  postCampaignOffer,
  changeStatusCampaignOffer,
  fetchCampaignOffer,
  fetchCampaignOfferList,
  validateCampaignName,
  sendPromoCodesFile
};
