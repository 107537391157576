import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const style = () => ({
  base: {
    display: 'flex',
    flex: '1 1 auto',
    justifyContent: 'center',
    alignItems: 'center',
  },
});


function Page({ classes }) {
  return (
      <div className={classes.base}>
        <h1>
          <span>The module isn't available for this domain</span>
        </h1>
      </div>
  );
}

Page.propTypes = {
  classes: PropTypes.shape().isRequired,
};

export default memo(withStyles(style)(Page));
