import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { AVATAR_ICON } from '../../constants/image';
import { ROUTE_SHARING_NEW_CAMPAIGN } from '../../constants';
import SettingsIcon from '../svgIcons/SettingsIcon';
import PaymentIcon from '../svgIcons/PaymentIcon';
import SupportIcon from '../svgIcons/SupportIcon';
import history from '../../navigation/history';
import { Link } from '@material-ui/core';

const styles = theme => ({
  settingsGroup: {},
  avatar: {
    position: 'absolute',
    width: '40px!important',
    height: 40,
    top: 25,
    right: 50,
    zIndex: 4,
  },
  settingsMenu: {
    position: 'absolute',
    top: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'row',
  },
  menu: {
    maxWidth: 400,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 200,
    },
  },
  list: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  item: {
    display: 'flex',
    color: theme.palette.grey['400'],
    fontSize: '.8rem',
  },
  icon: {
    marginRight: 10,
  },
  link: {
    '&:hover': {
      textDecoration: 'none'
    }
  }
});

const LIST_SETTINGS = [
  {
    name: 'PAYMENT',
    icon: PaymentIcon,
    link: ''
  }, {
    name: 'SUPPORT',
    icon: SupportIcon,
    link: 'mailto:support@comfolks.com'
  }, {
    name: 'SETTINGS',
    icon: SettingsIcon,
    link: ''
  }];


class SettingsMenu extends PureComponent {
  state = {
    anchorEl: null,
    path: history.location.pathname,
  };

  componentDidMount() {
    this.unlisten = history.listen(this.onRouteChange);
  }

  componentWillUnmount() {
    this.unlisten();
  }

  onRouteChange = ({ pathname: path }) => {
    this.setState(({ path: prevPath }) => (path === prevPath ? null : { path }));
  };

  onCloseSettings = () => {
    this.setState({ anchorEl: null });
  };

  onOpenSettings = (e) => {
    this.setState({ anchorEl: e.currentTarget });
  };

  render() {
    const { anchorEl, path } = this.state;
    const { classes, theme } = this.props;
    const { palette: { grey } } = theme;
    if (path === ROUTE_SHARING_NEW_CAMPAIGN) return null;

    return (
      <div className={classes.settingsGroup}>
        <Avatar
          alt="avatar"
          src={AVATAR_ICON}
          className={classes.avatar}
          onClick={this.onOpenSettings}
          aria-owns={anchorEl ? 'settings-menu' : undefined}
          aria-haspopup="true"
        />
        <Menu
          id="settings-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.onCloseSettings}
          classes={{
            paper: classes.menu,
            list: classes.list,
          }}
        >
          {LIST_SETTINGS.map(({ name, icon: Icon, link }) => (
            link ? (
              <Link href={link} className={classes.link} key={`${name}`}>
                <MenuItem
                  onClick={this.onCloseSettings}
                  classes={{
                    root: classes.item,
                  }}
                >
                  <Icon className={classes.icon} fillColor={grey[400]} />
                  <span>{name}</span>
                </MenuItem>
              </Link>
            ) : (
              <MenuItem
                key={`${name}`}
                onClick={this.onCloseSettings}
                classes={{
                  root: classes.item,
                }}
              >
                <Icon className={classes.icon} fillColor={grey[400]} />
                <span>{name}</span>
              </MenuItem>
            )
          ))}
        </Menu>
      </div>
    );
  }
}

SettingsMenu.propTypes = {
  classes: PropTypes.shape(),
  theme: PropTypes.shape().isRequired,
};
SettingsMenu.defaultProps = {
  classes: {},
};

export default withTheme(withStyles(styles)(SettingsMenu));
