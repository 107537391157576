import React, { useContext } from 'react';


import ElementStyles from '../../components/OnsiteForm/DesignStep/ElementStyles';
import { FormContext } from '../../components/OnsiteForm/DesignStep/FormContext';
import { ItemComponent } from '../../generator/itemComponents';
import { getFlexStyles } from '../flex';

import { DraggableElements } from './DraggableElements';

export const ChildrenElements = ({
  isEditing,
  childrenElements = [],
  onDragEnd,
  onBeforeDragStart,
  droppableId,
  layoutDirection,
  ...other
}) => {
  const { activeElement, changeFormField } = useContext(FormContext) || {};

  const handleDragEnd = (result) => {
    if (onDragEnd) {
      onDragEnd(result);
      return;
    }

    if (!result.destination) return;
    const { draggableId } = result;
    const target = childrenElements.find((item) => item.id === draggableId);
    const newChildsArray = [...childrenElements];

    newChildsArray.splice(result.source.index, 1);
    newChildsArray.splice(result.destination.index, 0, target);

    changeFormField(activeElement.parentPath, newChildsArray);
  };

  const handleBeforeDragStart = (result) => {
    onBeforeDragStart && onBeforeDragStart(result);
  };

  return isEditing
    ? (
      <DraggableElements
        elements={childrenElements}
        onDragEnd={handleDragEnd}
        onBeforeDragStart={handleBeforeDragStart}
        droppableId={droppableId}
        layoutDirection={layoutDirection}
        {...other}
      />
    )
    : (
      <div style={getFlexStyles(layoutDirection)}>
        {
                childrenElements?.map((element) => (
                  <>
                    <ItemComponent
                      key={element.id}
                      {...other}
                      {...element}
                    />
                    <ElementStyles key={`${element.type}-${element.id}`} type={element.type} />
                  </>
                ))
        }

      </div>
    );
};
