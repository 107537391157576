import compose from 'lodash/fp/compose';
import omit from 'lodash/fp/omit';
import omitBy from 'lodash/fp/omitBy';
import isNil from 'lodash/fp/isNil';
import isEmpty from 'lodash/fp/isEmpty';
import pick from 'lodash/fp/pick';
import equals from 'lodash/fp/equals';
import { convertToSecond, convertFromSecond } from './helpers';

export const transformTrigger = compose([
  omitBy(isNil),
  pick([
    'option',
    'duration',
    'timeAppearance',
    'timeout',
    'timeClose',
    'closeAfter',
    'timeFrame',
    'timeFrameOnlyInFrom',
    'timeFrameOnlyInTo',
    'repeat',
    'url'
  ]),
  v =>
    equals(v.option, 'duration')
      ? {
          ...v,
          duration: convertToSecond[v.durationDimension](v.durationValue)
        }
      : v,
  v =>
    equals(v.timeAppearance, 'delay')
      ? {
          ...v,
          timeout: convertToSecond[v.timeAppearanceDelayDimension](
            v.timeAppearanceDelayValue
          )
        }
      : v,
  v =>
    equals(v.timeClose, 'close')
      ? {
          ...v,
          closeAfter: convertToSecond[v.timeCloseDelayDimension](
            v.timeCloseDelayValue
          )
        }
      : v
]);

export const transformToTrigger = compose([
  omitBy(isNil),
  v => (isEmpty(v.option) ? { ...v, option: 'null' } : v),
  omit(['duration', 'timeout']),
  v => {
    if (equals(v.option, 'duration')) {
      const { value, dimension } = convertFromSecond(v.duration);

      return { ...v, durationDimension: dimension, durationValue: value };
    }

    return v;
  },
  v => {
    if (equals(v.timeAppearance, 'delay')) {
      const { value, dimension } = convertFromSecond(v.timeout);

      return {
        ...v,
        timeAppearanceDelayDimension: dimension,
        timeAppearanceDelayValue: value
      };
    }

    return v;
  },
  v => {
    if (equals(v.timeClose, 'close')) {
      const { value, dimension } = convertFromSecond(v.closeAfter);

      return {
        ...v,
        timeCloseDelayDimension: dimension,
        timeCloseDelayValue: value
      };
    }

    return v;
  }
]);
