import {
  CHAT_BOT_ENDPOINT,
  CHAT_BOT_STATUS_ENDPOINT,
  CHAT_BOT_VERIFY_CAMPAIGN_NAME_ENDPOINT,
  CHAT_BOT_INFO_ENDPOINT,
} from '../constants/endpoints';

import axios from './axios';

const postChatBot = async (id, ChatBotFormData) => {
  const { data: createdChatBot } = await axios.post(
    id ? `${CHAT_BOT_ENDPOINT}/${id}` : CHAT_BOT_ENDPOINT,
    ChatBotFormData,
  );
  return createdChatBot;
};

const changeStatusChatBot = async (SmsId, setObjectStatus) => {
  const { data } = await axios.post(
    `${CHAT_BOT_STATUS_ENDPOINT}/${SmsId}`,
    { status: setObjectStatus },
  );
  return data;
};

const fetchChatBot = async (ChatBotId) => {
  const { data: ChatBot } = await axios.get(
    `${CHAT_BOT_ENDPOINT}/${ChatBotId}`,
  );

  return ChatBot;
};

const fetchChatBotList = async ({
  status = '', perPage = 10, page = 1, search = '',
}) => {
  const {
    data: { campaigns, total },
  } = await axios.get(
    `${CHAT_BOT_ENDPOINT}?status=${status || ''}&page=${page}&per_page=${perPage}&search=${search || ''}`,
  );

  return [campaigns, total];
};

const fetchDownloadStat = async id => {
  await axios.get(`${CHAT_BOT_INFO_ENDPOINT}/${id}`, { responseType: 'blob' }).then((response) => {
    const href = URL.createObjectURL(response.data);
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', `${id}_stat_more.xlsx`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  });
};

const validateCampaignName = async (name, id) => {
  const { data } = await axios.get(
    `${CHAT_BOT_VERIFY_CAMPAIGN_NAME_ENDPOINT}?name=${name}${id ? `&id=${id}` : ''
    }`,
  );
  return data;
};

export default {
  postChatBot,
  fetchChatBot,
  fetchChatBotList,
  fetchDownloadStat,
  changeStatusChatBot,
  validateCampaignName,
};
