import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import makeStyle from "@material-ui/styles/makeStyles";

const useStyles = makeStyle((theme) => ({
  input: {
    color: (props) => props.textColor || "#000000",
    "&::before": {
      borderColor: (props) => props.textColor || "#000000",
    },
  },
  label: {
    color: (props) => props.textColor || "#000000",
  },
  button: {
    color: (props) => props.textColor || "#000000",
    borderColor: (props) => props.textColor || "#000000",
    "&:disabled": {
      opacity: 0.9,
      cursor: "not-allowed",
    },
  },
  notice: {
    color: (props) => props.textColor || "#000000",
  },
  link: {
    color: (props) => props.textColor || "#000000",
  },
}));

export const ECommerceFields = ({
  username = "",
  onAccept,
  onShowFullDescription,
  textColor,
}) => {
  const classes = useStyles({ textColor });
  const [usernameValue, setUsernameValue] = useState(username);
  const [identifier, setIdentifier] = useState("");
  const [checked, setChecked] = useState(false);

  const [usernameError, setUsernameError] = useState();
  const [identifiedError, setIdentifierError] = useState();

  const resetErrors = () => {
    setUsernameError(undefined);
    setIdentifierError(undefined);
  };

  const onUsernameValueChange = (event) => {
    resetErrors();
    setUsernameValue(event.target.value);
  };

  const onIdentifierChange = (event) => {
    resetErrors();
    setIdentifier(event.target.value);
  };

  const onAgreementChange = (event) => {
    resetErrors();
    setChecked(event.target.checked);
  };

  const handleAccept = () => {
    let hasErrors = false;
    if (!usernameValue) {
      setUsernameError(true);
      hasErrors = true;
    }

    if (!identifier) {
      setIdentifierError(true);
      hasErrors = true;
    }

    if (!checked) {
      hasErrors = true;
    }

    if (!hasErrors) {
      onAccept({ username: usernameValue, email: identifier });
    }
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TextField
          value={usernameValue}
          onChange={onUsernameValueChange}
          fullWidth
          label="Your Name"
          error={usernameError}
          InputLabelProps={{ className: classes.label }}
          InputProps={{ className: classes.input }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          value={identifier}
          onChange={onIdentifierChange}
          fullWidth
          label="Your E-mail or Phone"
          error={identifiedError}
          InputLabelProps={{ className: classes.label }}
          InputProps={{ className: classes.input }}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              size="small"
              checked={checked}
              onChange={onAgreementChange}
              name="agree"
              style={{ color: textColor }}
            />
          }
          label={
            <Typography variant="caption" className={classes.notice}>
              I have read consent form and{" "}
              <Link
                component="button"
                className={classes.link}
                onClick={onShowFullDescription}
              >
                offer description
              </Link>
            </Typography>
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          fullWidth
          size="large"
          variant="outlined"
          onClick={handleAccept}
          className={classes.button}
          disabled={!identifier || !checked}
        >
          Accept offer
        </Button>
      </Grid>
    </Grid>
  );
};
