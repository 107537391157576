import { createAction } from 'redux-actions';
import { createRoutine, bindRoutineToReduxForm } from 'redux-saga-routines';

const ACTIVATE_CPA_CAMPAIGN = '@cpa/ACTIVATE_CPA_CAMPAIGN';
const ACTIVATE_CPA_OFFER = '@cpa/ACTIVATE_CPA_OFFER';
const HOLD_CPA_CAMPAIGN = '@cpa/HOLD_CPA_CAMPAIGN';
const HOLD_CPA_OFFER = '@cpa/HOLD_CPA_OFFER';
const FETCH_CPA_CAMPAIGN_LIST = '@cpa/FETCH_CPA_CAMPAIGN_LIST';
const FETCH_CPA_OFFER_LIST = '@cpa/FETCH_CPA_OFFER_LIST';
const PUT_CPA_CAMPAIGN_LIST = '@cpa/PUT_CPA_CAMPAIGN_LIST';
const PUT_CPA_OFFER_LIST = '@cpa/PUT_CPA_OFFER_LIST';

const CREATE_CPA_LOYALTY = '@cpa/CREATE_CPA_LOYALTY';
const FETCH_CPA_LOYALTY = '@cpa/FETCH_CPA_LOYALTY';
const PUT_CPA_LOYALTY = '@cpa/PUT_CPA_LOYALTY';
const REMOVE_CPA_LOYALTY = '@cpa/REMOVE_CPA_LOYALTY';
const UPDATE_CPA_LOYALTY = '@cpa/UPDATE_CPA_LOYALTY';

const CREATE_CPA_OFFER = '@cpa/CREATE_CPA_OFFER';
const FETCH_CPA_OFFER = '@cpa/FETCH_CPA_OFFER';
const PUT_CPA_OFFER = '@cpa/PUT_CPA_OFFER';
const REMOVE_CPA_OFFER = '@cpa/REMOVE_CPA_OFFER';
const UPDATE_CPA_OFFER = '@cpa/UPDATE_CPA_OFFER';

const FETCH_CPA_OFFER_CAMPAIGN_NAMES = '@cpa/FETCH_CPA_OFFER_CAMPAIGN_NAMES';
const PUT_CPA_OFFER_CAMPAIGN_NAMES = '@cpa/PUT_CAMPAIGN_NAMES';
const VALIDATE_CPA_LOYALTY_CAMPAIGN_NAME =
  '@cpa/VALIDATE_CPA_LOYALTY_CAMPAIGN_NAME';
const VALIDATE_CPA_OFFER_CAMPAIGN_NAME =
  '@cpa/VALIDATE_CPA_OFFER_CAMPAIGN_NAME';
const UPLOAD_CPA_OFFER_PROMO_CODES_FILE = '@cpa/UPLOAD_PROMO_CODES_FILE';

const FETCH_CPA_MSISDN_STATISTICS = '@cpa/FETCH_CPA_MSISDN_STATISTICS';
const PUT_CPA_MSISDN_STATISTICS = '@cpa/PUT_CPA_MSISDN_STATISTICS';

const FETCH_CPA_MSISDN_LOGS = '@cpa/FETCH_CPA_MSISDN_LOGS';
const PUT_CPA_MSISDN_LOGS= '@cpa/PUT_CPA_MSISDN_LOGS';

export const createCpaOfferRoutine = createRoutine(CREATE_CPA_OFFER);
export const createCpaOfferHandler = bindRoutineToReduxForm(
  createCpaOfferRoutine
);

export const createCpaLoyaltyRoutine = createRoutine(CREATE_CPA_LOYALTY);
export const createCpaLoyaltyHandler = bindRoutineToReduxForm(
  createCpaLoyaltyRoutine
);

export const activateCpaCampaign = createAction(ACTIVATE_CPA_CAMPAIGN);
export const activateCpaOffer = createAction(ACTIVATE_CPA_OFFER);
export const holdCpaCampaign = createAction(HOLD_CPA_CAMPAIGN);
export const holdCpaOffer = createAction(HOLD_CPA_OFFER);
export const fetchCpaCampaignList = createAction(FETCH_CPA_CAMPAIGN_LIST);
export const fetchCpaOfferList = createAction(FETCH_CPA_OFFER_LIST);
export const putCpaCampaignList = createAction(PUT_CPA_CAMPAIGN_LIST);
export const putCpaOfferList = createAction(PUT_CPA_OFFER_LIST);

export const fetchCpaLoyalty = createAction(FETCH_CPA_LOYALTY);
export const putCpaLoyalty = createAction(PUT_CPA_LOYALTY);
export const removeCpaLoyalty = createAction(REMOVE_CPA_LOYALTY);
export const updateCpaLoyalty = createAction(UPDATE_CPA_LOYALTY);

export const fetchCpaOffer = createAction(FETCH_CPA_OFFER);
export const putCpaOffer = createAction(PUT_CPA_OFFER);
export const removeCpaOffer = createAction(REMOVE_CPA_OFFER);
export const updateCpaOffer = createAction(UPDATE_CPA_OFFER);

export const fetchCurrentCampaignNames = createAction(
  FETCH_CPA_OFFER_CAMPAIGN_NAMES
);
export const putCampaignNames = createAction(PUT_CPA_OFFER_CAMPAIGN_NAMES);

export const validateCpaLoyaltyCampaignName = createAction(
  VALIDATE_CPA_LOYALTY_CAMPAIGN_NAME
);
export const validateCpaOfferCampaignName = createAction(
  VALIDATE_CPA_OFFER_CAMPAIGN_NAME
);

export const uploadPromoCodesFile = createAction(
  UPLOAD_CPA_OFFER_PROMO_CODES_FILE
);

export const fetchCpaMsisdnStatistics = createAction(
  FETCH_CPA_MSISDN_STATISTICS
);

export const putCpaMsisdnStatistics = createAction(PUT_CPA_MSISDN_STATISTICS);

export const fetchCpaMsisdnLogs = createAction(FETCH_CPA_MSISDN_LOGS);

export const putCpaMsisdnLogs = createAction(PUT_CPA_MSISDN_LOGS);
