import {
  USER_LOGOUT,
  USER_LOGIN_REQUEST,
  USER_LOGOUT_REQUEST,
  USER_REGISTRATION_REQUEST,
  USER_REMEMBER_ME,
  USER_API_KEY,
  USER_GET_ALLOWED_MODULES_REQUEST,
  USER_PING,
  USER_PING_FAILURE,
  USER_PING_FAILURE_OTP,
  USER_OTP_REQUEST,
  USER_OTP_SUCCESS,
  USER_OTP_FAILURE,
  USER_OTP_RESEND, 
  USER_OTP_RESEND_SUCCESS,
} from '../actionTypes';


export const logout = payload => ({ type: USER_LOGOUT, payload });

export const userLoginRequest = payload => ({ type: USER_LOGIN_REQUEST, payload });

export const userOtpRequest = payload => ({ type: USER_OTP_REQUEST, payload });
export const userOtpSuccess = payload => ({ type: USER_OTP_SUCCESS, payload });
export const userOtpFailure = payload => ({ type: USER_OTP_FAILURE, payload });
export const userOtpResend = payload => ({ type: USER_OTP_RESEND, payload });
export const userOtpResendSuccess = payload => ({ type: USER_OTP_RESEND_SUCCESS, payload });

export const userLogoutRequest = payload => ({ type: USER_LOGOUT_REQUEST, payload });

export const userRegistrationRequest = payload => ({ type: USER_REGISTRATION_REQUEST, payload });

export const userRememberMe = payload => ({ type: USER_REMEMBER_ME, payload });

export const userApiKey = payload => ({ type: USER_API_KEY, payload });

export const userGetAllowedModules = payload => ({ type: USER_GET_ALLOWED_MODULES_REQUEST, payload });

export const ping = payload => ({ type: USER_PING, payload });
export const pingFailure = payload => ({ type: USER_PING_FAILURE, payload });
export const pingFailureOTP = payload => ({ type: USER_PING_FAILURE_OTP, payload });
