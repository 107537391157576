import { Grid, MenuItem } from '@material-ui/core';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form';

import { FORM_SCHEMA_ONSITE } from '../../../../../constants';
import {
  COOKIE, INTEGRATED,
  OVERLAY,
  POPUP,
  FULL,
  RIBBON,
} from '../../../../../constants/onsiteWidgets';
import {
  bePositiveNumber,
} from '../../../../../help/normalizers';
import { toPx } from '../../../../../help/parse';
import {
  PixelField,
  SelectField,
  TextField,
  ColorField,
} from '../../../../Fields';
import CarouselSettings from '../../commonSettings/CarouselSettings';

import {
  carouselSlidesSelectorCreator,
  moveOptionsSelectorCreator,
} from './selectors';


export const CarouselFields = ({ onChangeCarouselSlideCount, form }) => {
  const selectMoveOption = useMemo(() => moveOptionsSelectorCreator(form), [form]);
  const selectSlides = useMemo(() => carouselSlidesSelectorCreator(form), [form]);

  const selectedMoveOption = useSelector(selectMoveOption);
  const slides = useSelector(selectSlides);

  return (
    <Grid container>
      {isCarouselAutoMove(selectedMoveOption) && <CarouselAutoMoveFields />}
      {isCarouselWithMoveControls(selectedMoveOption) && <CarouselMoveControlsFields />}
      {isCarouselHover(selectedMoveOption) && <CarouselHoverFields />}
      <CarouselSlideFields onChangeCarouselSlideCount={onChangeCarouselSlideCount} slides={slides} />
    </Grid>
  );
};

const isCarouselAutoMove = (moveVariant) => moveVariant === CAROUSEL_MOVE_VARIANTS.AUTO;
const CarouselAutoMoveFields = () => <></>;

const isCarouselWithMoveControls = (moveVariant) => moveVariant === CAROUSEL_MOVE_VARIANTS.CONTROLS;
const CarouselMoveControlsFields = () => <></>;

const isCarouselHover = (moveVariant) => moveVariant === CAROUSEL_MOVE_VARIANTS.HOVER;
const CarouselHoverFields = () => <></>;

const IntegrateOption = () => (
  <Field
    label="Integrate option"
    component={SelectField}
    fullWidth
    name={FORM_SCHEMA_ONSITE.PROPERTIES.CAROUSEL_INTEGRATED_OPTIONS}
  >
    <MenuItem value={INTEGRATED}>integrated</MenuItem>
    <MenuItem value={FULL}>Full</MenuItem>
    <MenuItem value={POPUP}>pop-up</MenuItem>
    <MenuItem value={OVERLAY}>overlay</MenuItem>
    <MenuItem value={COOKIE}>cookie</MenuItem>
    <MenuItem value={RIBBON}>ribbon</MenuItem>
  </Field>
);

const IntegratedClass = () => {
  if (INTEGRATED) {
    return (
      <Field
        id="integrated class"
        name="settings.integrated_class"
        label="Integrated class"
        fullWidth
        component={TextField}
        required
      />
    );
  }
  return '';
};


const maxOption = 50;
const menuOption = [];

for (let i = 1; i <= maxOption; i++) {
  menuOption.push(i);
}


const CarouselSlideFields = ({ onChangeCarouselSlideCount, slides = [] }) => (
  <Grid container spacing={4}>
    <Grid item xs={6}>
      <Field
        fullWidth
        label="Move option"
        component={SelectField}
        name={FORM_SCHEMA_ONSITE.PROPERTIES.CAROUSEL_MOVE_OPTION}
      >
        {CarouselSelectMoveOptionsFragment}
      </Field>
    </Grid>
    <Grid item xs={6}>
      <Field
        fullWidth
        label="Common URL"
        component={TextField}
        name={FORM_SCHEMA_ONSITE.PROPERTIES.CAROUSEL_COMMON_URL}
      />
    </Grid>
    <Grid item xs={6}>
      <Field
        fullWidth
        label="Effect"
        component={SelectField}
        name={FORM_SCHEMA_ONSITE.PROPERTIES.CAROUSEL_EFFECT}
      >
        {CarouselSelectEffectFragment}
      </Field>
    </Grid>
    <Grid item xs={6}>
      <Field
        fullWidth
        label="Slide count"
        type="number"
        normalize={bePositiveNumber}
        component={SelectField}
        name={FORM_SCHEMA_ONSITE.PROPERTIES.CAROUSEL_SLIDE_COUNT}
        onChange={onChangeCarouselSlideCount}
      >
        { menuOption.map((number) => (
          <MenuItem
            key={number}
            value={number}
          >
            {number}
          </MenuItem>
        ))}
      </Field>
    </Grid>
    <Grid item xs={6}>
      <Field
        fullWidth
        label="Slide width"
        type="number"
        parse={toPx}
        format={bePositiveNumber}
        component={PixelField}
        name={FORM_SCHEMA_ONSITE.PROPERTIES.CAROUSEL_SLIDE_WIDTH}
      />
    </Grid>
    <Grid item xs={6}>
      <IntegrateOption />
    </Grid>
    <Grid item xs={6}>
      <IntegratedClass />
    </Grid>
    <CarouselSettings />
    <Grid item xs={6}>
      <Field
        fullWidth
        label="Vertical margin"
        type="number"
        parse={toPx}
        format={bePositiveNumber}
        component={PixelField}
        name={FORM_SCHEMA_ONSITE.PROPERTIES.CAROUSEL_VERTICAL_MARGIN}
      />
    </Grid>
    <Grid item xs={6}>
      <Field name={FORM_SCHEMA_ONSITE.PROPERTIES.CAROUSEL_CONTROL_COLOR} component={ColorField} label="Control color" />
    </Grid>
    <Grid item xs={6}>
      <Field
        fullWidth
        label="Type slide"
        component={SelectField}
        name={FORM_SCHEMA_ONSITE.PROPERTIES.CAROUSEL_TYPE}
      >
        {CAROUSEL_TYPES_OPTIONS.map((item) => (
          <MenuItem
            key={item.value}
            value={item.value}
          >
            {item.label}
          </MenuItem>
        ))}
      </Field>
    </Grid>
  </Grid>
);

export const CAROUSEL_MOVE_VARIANTS = {
  AUTO: 'automove',
  PAUSE: 'pause',
  CONTROLS: 'controls',
  HOVER: 'hover',
};

export const CAROUSEL_MOVE_OPTIONS = [
  {
    value: CAROUSEL_MOVE_VARIANTS.AUTO,
    label: 'Automove',
  },
  {
    value: CAROUSEL_MOVE_VARIANTS.PAUSE,
    label: 'Automove pause mode',
  },
  {
    value: CAROUSEL_MOVE_VARIANTS.CONTROLS,
    label: 'With controls',
  },
  {
    value: CAROUSEL_MOVE_VARIANTS.HOVER,
    label: 'Hover mode',
  },
];

export const CAROUSEL_EFFECT_VARIANTS = {
  SLIDE: 'slide',
  FADE: 'fade',
};

export const CAROUSEL_EFFECT = [
  {
    value: CAROUSEL_EFFECT_VARIANTS.SLIDE,
    label: 'Slide',
  },
  {
    value: CAROUSEL_EFFECT_VARIANTS.FADE,
    label: 'Fade',
  },
];


export const CAROUSEL_TYPES = {
  FULL_SCREEN_CAROUSEL: 'fullScreenCarousel',
  HISTORY_CAROUSEL: 'historyCarousel',
};


export const CAROUSEL_TYPES_OPTIONS = [
  {
    value: CAROUSEL_TYPES.FULL_SCREEN_CAROUSEL,
    label: 'Full screen carousel',
  },
  {
    value: CAROUSEL_TYPES.HISTORY_CAROUSEL,
    label: 'History carousel',
  },
];


const CarouselSelectMoveOptionsFragment = CAROUSEL_MOVE_OPTIONS.map(({ value, label }) => (
  <MenuItem key={value} value={value}>{label}</MenuItem>
));

const CarouselSelectEffectFragment = CAROUSEL_EFFECT.map(({ value, label }) => (
  <MenuItem key={value} value={value}>{label}</MenuItem>
));
