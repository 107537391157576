import { handleActions } from 'redux-actions';
import {
    putCpaCampaignList,
    putCpaLoyalty,
    updateCpaLoyalty,
    removeCpaLoyalty,
    putCpaOffer,
    updateCpaOffer,
    removeCpaOffer,
    putCpaMsisdnStatistics, putCpaMsisdnLogs
} from '../actions/cpa';

const INITIAL_STATE = {
  list: [],
  total: 0,
  editedLoyalties: {},
  editedOffers: {},
  logs: [],
  statistics: {
    list: [],
    total: 0
  },
};

export default handleActions(
  {
    [putCpaCampaignList]: (state, { payload: { list, total } }) => ({
      ...state,
      list,
      total
    }),
    [putCpaMsisdnStatistics]: (state, { payload: { list, total } }) => ({
      ...state,
      statistics: {
        list,
        total
      }
    }),
    [putCpaMsisdnLogs]: (state, { payload: list }) => ({
      ...state,
      logs: list
    }),
    [putCpaLoyalty]: (state, { payload }) => ({
      ...state,
      editedLoyalties: { ...state.editedLoyalties, [payload.id]: payload }
    }),
    [updateCpaLoyalty]: (state, { payload }) => ({
      ...state,
      editedLoyalties: {
        ...state.editedLoyalties,
        [payload.id]: { ...state.editedLoyalties[payload.id], ...payload }
      }
    }),
    [removeCpaLoyalty]: (state, { payload: id }) => ({
      ...state,
      editedLoyalties: {
        ...state.editedLoyalties,
        [id]: undefined
      }
    }),
    [putCpaOffer]: (state, { payload }) => ({
      ...state,
      editedOffers: { ...state.editedOffers, [payload.id]: payload }
    }),
    [updateCpaOffer]: (state, { payload }) => ({
      ...state,
      editedOffers: {
        ...state.editedOffers,
        [payload.id]: { ...state.editedOffers[payload.id], ...payload }
      }
    }),
    [removeCpaOffer]: (state, { payload: id }) => ({
      ...state,
      editedOffers: {
        ...state.editedOffers,
        [id]: undefined
      }
    })
  },
  INITIAL_STATE
);
