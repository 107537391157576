/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

const ShareIcon = ({ fillColor, ...props }) => (
  <svg width={16} height={14.222} {...props}>
    <path
      d="M15.609 4.39l-4-4a1.334 1.334 0 0 0-2.276.943v1.59a13.447 13.447 0 0 0-3.354.5A5.087 5.087 0 0 0 3.676 4.8a3.968 3.968 0 0 0-1.009 2.754 4.7 4.7 0 0 0 2.357 4.021A1.334 1.334 0 0 0 7 10.026c-.431-1.309-.477-1.97 2.336-2.188v1.495a1.334 1.334 0 0 0 2.276.943l4-4a1.333 1.333 0 0 0-.003-1.886zm-4.942 4.943V6.449c-3.575.053-6.042.644-4.936 4A3.4 3.4 0 0 1 4 7.554c0-3.037 3.587-3.3 6.667-3.329V1.333l4 4zm.687 2.347a2.3 2.3 0 0 0 .583-.258.333.333 0 0 1 .508.284v1.183a1.333 1.333 0 0 1-1.333 1.333H1.333A1.333 1.333 0 0 1 0 12.889V3.111a1.333 1.333 0 0 1 1.333-1.333H5a.333.333 0 0 1 .333.333v.125a.332.332 0 0 1-.21.31 6.81 6.81 0 0 0-1.057.516.337.337 0 0 1-.174.049H1.5a.167.167 0 0 0-.167.167v9.444a.167.167 0 0 0 .167.167h9.444a.167.167 0 0 0 .167-.167V12a.334.334 0 0 1 .243-.32z"
      fill={fillColor}
    />
  </svg>
);

ShareIcon.propTypes = {
  fillColor: PropTypes.string.isRequired,
};

export default ShareIcon;
