import React from 'react';
import PropTypes from 'prop-types';

const PushIcon = ({ fillColor, ...props }) => (
  <svg width={17} height={14} {...props}>
    <g fill="none" stroke={fillColor} strokeWidth={1.3}>
      <g transform="translate(3)">
        <rect width={14} height={9} rx={2} stroke="none" />
        <rect x={0.65} y={0.65} width={12.7} height={7.7} rx={1.35} />
      </g>
      <g transform="translate(0 5)">
        <rect width={14} height={9} rx={2} stroke="none" />
        <rect x={0.65} y={0.65} width={12.7} height={7.7} rx={1.35} />
      </g>
    </g>
  </svg>
);

PushIcon.propTypes = {
  fillColor: PropTypes.string.isRequired,
};

export default PushIcon;
