import cond from 'lodash/fp/cond';
import equals from 'lodash/fp/equals';
import { heightOptions } from '../../../constants/onsiteWidgets';

const getHeightFieldOptionValue = cond([
    [equals('100%'), () => heightOptions.full],
    [equals('unset'), () => heightOptions.auto],
    [equals('auto'), () => heightOptions.auto],
    [v => v === '', () => heightOptions.auto],
    [() => true, () => heightOptions.fixed]
]);

export const getHeightProperties = cond([
    [equals(heightOptions.full), () => ({ height: "100%" })],
    [equals(heightOptions.fixed), (_, height) => ({ height })],
    [equals(heightOptions.auto), () => ({ height: "auto" })],
    [() => true, () => ({ height: "auto" })]
  ]);

/**
 * @typedef {object} HeightOptions
 * @property {string} heightOption
 * @property {string} fixedHeight
 */

/**
 * 
 * @param {CSSStyleDeclaration} styles 
 * @returns {HeightOptions} result
 */
const parseHeight = (styles) => {
    const heightOption = getHeightFieldOptionValue(styles.height)
    const fixedHeight = heightOption === heightOptions.fixed ? styles.height : '0px'

    return {
        heightOption,
        fixedHeight,
    }
}

export default parseHeight