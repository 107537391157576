/**
 * eventTrigger from data attribute to action values for form
 *
 * @param {actionS} Object { collapse-23d3: [{action_1: { target: ..., ... }}, {action_2: { target: ..., ... }}] }
 * @returns {Object} {action_1: "show-collapse-collapse-058e9b", action_2: "show-collapse-collapse-058e9b" }
 */
export const parseAction = (actionS) => {
  const events = Array.isArray(actionS.event) ? actionS.event : [{ action: actionS.event }];
  return events.reduce((acc, cur) => {
    const key = Object.keys(cur)[0];
    acc[key] = cur[key].id;
    return acc;
  }, {});
};


/**
 * eventTrigger from data attribute to action condition for form
 *
 * @param {actionS} Object { collapse-23d3: [{action_1: { target: ..., ... }}, {action_2: { target: ..., ... }}] }
 * @returns {Object} {condition_1: 1, condition_2: 2}
 */
export const parseCondition = (actionS) => (
  actionS.event.reduce((acc, obj) => {
    const [key] = Object.keys(obj);
    const postfix = key.split('_')[1];
    const value = obj[key].condition;
    acc[`condition_${postfix}`] = value;
    return acc;
  }, {}));

/**
 * Take the conditions corresponding to the actions and return the actions with the conditions
 *
 * @param {Object} actions
 * @param {Object} props
 * @param  {string} applyCondition
 * @returns {Object}
 */
export const actionWithConditions = (actions, applyCondition = '', props) => {
  if (!actions) return [];
  return actions.map((item) => {
    const actionKey = Object.keys(item)[0];
    const actionValue = item[actionKey];
    const newActionValue = {
      ...actionValue,
      condition: props ? props[`condition_${actionKey.split('_')[1]}`] : 'empty',
      applyCondition,
    };
    return { [actionKey]: newActionValue };
  });
};

/**
 * Search in actions target - form, if there is, then save in the acc id
 *
 * @param {Object} actions
 * @returns {String} id
 */
export const lastFormId = (actions) => actions.reduce((acc, obj) => {
  const { target } = Object.values(obj)[0];
  const { id } = Object.values(obj)[0];
  if (target.startsWith('form')) {
    acc = id;
  }
  return acc;
}, '');
