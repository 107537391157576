import compose from 'lodash/fp/compose'
import get from 'lodash/fp/get'
import omit from 'lodash/fp/omit'
import isNil from 'lodash/fp/isNil'
import { b64ToBlob } from '../help'
import { transformTarget, transformToTarget } from './targetMappers'
import { transformTrigger, transformToTrigger } from './triggerMappers'
import { OnsiteFormHelper } from '../help/formSchema'
import { convertWidget, parseWidget, WidgetsHelper } from '../help/widgets'
import { getUniqueIdWithPrefix } from '../help/getUniqueIdWithPrefix'

export const mapFormValuesToOnsite = (fields) => {
  const fileFields = get('bonus.promoCodesFile', fields)
  const { timeClose } = OnsiteFormHelper.getTriggers(fields)
  const widgetsList = [...OnsiteFormHelper.getWidgets(fields)]
  const editedWidget = OnsiteFormHelper.getEditedWidget(fields)
  const editedWidgetIndex = widgetsList.findIndex((widget) => widget.id === editedWidget.id)
  if (editedWidgetIndex >= 0) {
    widgetsList.splice(editedWidgetIndex, 1, editedWidget)
  }
  widgetsList.forEach(widget => {
    widget.timeClose = timeClose
  })

  const transformedFields = compose([
    (v) => ({
      ...v,
      bonus: {
        ...v.bonus,
        mode: v.bonus.mode === 'uploadedDiscountList' ? 'currentDiscountList' : v.bonus.mode,
        currentDiscountList:
          v.bonus.mode === 'uploadedDiscountList' ? v.campaign_name : v.bonus.currentDiscountList
      }
    }),
    (v) => ({
      ...v,
      widgets: widgetsList.map(convertWidget)
    }),
    (v) => ({
      ...v,
      targets: {
        ...v.targets,
        targets: v.targets && v.targets.targets ? v.targets.targets.map(transformTarget) : []
      }
    }),
    (v) => ({ ...v, trigger: transformTrigger(v.trigger) }),
    omit(['campaignName', 'bonus.promoCodesFile', 'editedWidget'])
  ])(fields)

  if (fileFields) {
    const filename = fileFields.name
    const fileBlob = b64ToBlob(fileFields.file)

    return [transformedFields, fileBlob, filename]
  }

  return [transformedFields]
}

export const mapCampaignOnsiteToTableRow = (wp) => {
  return {
    ...wp,
    delivered: wp.delivered || 0,
    text: wp.text || 'No text',
    sentTo: wp.sentTo || 0,
    seenTo: wp.seen[0] || 0,
    clickTo: wp.ctr[0] || 0,
    ctrTo: wp.ctr[1] || 0,
    seen: `${wp.seen[0] || 0} (${wp.seen[1] || 0}%)`,
    ctr: `${wp.ctr[0] || 0} (${wp.ctr[1] || 0}%)`,
    unsubscribed: `${wp.unsubscribed[0] || 0} (${wp.unsubscribed[1] || 0}%)`
  }
}

export const mapOnsiteToFormValues = (wp, isArchived) => {
  const notNil = (v) => !isNil(v)
  const trigger = transformToTrigger(wp.trigger)
  const widgets = wp.widgets.map((widget) => {
    let parsedWidget = widget;
    if (isArchived) {
      parsedWidget = WidgetsHelper.cloneWidget(widget, { settings: { ...widget.settings }, id: getUniqueIdWithPrefix() }, true);
    }
    return parseWidget(parsedWidget, false, trigger.timeClose);
  });

  const result = {
    ...wp,
    targets: {
      match_all: wp.targets.match_all,
      targets: wp.targets.targets.filter(notNil).map(transformToTarget)
    },
    trigger: trigger,
    widgets: widgets,
    editedWidget: widgets[0]
  }

  return result
}
