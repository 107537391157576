import React from 'react';
import { Field } from 'redux-form';

import TextEditor from '../TextEditor';

const TextEditorField = ({ path, name = 'text', ...rest }) => {
  let fieldName = name;
  if (path) {
    fieldName = `${path}.${name}`;
  }

  return (
    <Field name={fieldName} component={TextEditor} className="inline-editor" {...rest} />
  );
};

export default TextEditorField;
