import { createAction } from 'redux-actions';

const FETCH_OPERATORS = '@fields/FETCH_OPERATORS';
const PUT_OPERATORS = '@fields/PUT_OPERATORS';
const FETCH_TARIFFS = '@fields/FETCH_TARIFFS';
const PUT_TARIFFS = '@fields/PUT_TARIFFS';
const FETCH_COUNTRIES = '@fields/FETCH_COUNTRIES';
const PUT_COUNTRIES = '@fields/PUT_COUNTRIES';
const FETCH_REGIONS = '@fields/FETCH_REGIONS';
const PUT_REGIONS = '@fields/PUT_REGIONS';
const FETCH_CITIES = '@fields/FETCH_CITIES';
const PUT_CITIES = '@fields/PUT_CITIES';
const RESET_FIELDS_DATA = '@fields/RESET_FIELDS_DATA';
const FETCH_MNOS = '@fields/FETCH_MNOS';
const PUT_MNOS = '@fields/PUT_MNOS';
const FETCH_LANGUAGES = '@fields/FETCH_LANGUAGES';
const PUT_LANGUAGES = '@fields/PUT_LANGUAGES';
const FETCH_TARGET_LANGUAGES = '@fields/FETCH_TARGET_LANGUAGES';
const PUT_TARGET_LANGUAGES = '@fields/PUT_TARGET_LANGUAGES';
const FETCH_TAGS = '@cpa/FETCH_TAGS';
const PUT_TAGS = '@cpa/PUT_TAGS';
const PUT_COMPILED_SCRIPTS = '@cpa/PUT_COMPILED_SCRIPTS';
const FETCH_EMAIL_CAMPAIGNS = '@fields/FETCH_EMAIL_CAMPAIGNS';
const PUT_EMAIL_CAMPAIGNS = '@fields/PUT_EMAIL_CAMPAIGNS';
const FETCH_SMS_CAMPAIGNS = '@fields/FETCH_SMS_CAMPAIGNS';
const PUT_SMS_CAMPAIGNS = '@fields/PUT_SMS_CAMPAIGNS';
const FETCH_OFFER_CAMPAIGNS = '@fields/FETCH_OFFER_CAMPAIGNS';
const PUT_OFFER_CAMPAIGNS = '@fields/PUT_OFFER_CAMPAIGNS';
const FETCH_GEO_CONFIG = '@cpa/FETCH_GEO_CONFIG';
const PUT_GEO_CONFIG = '@cpa/PUT_GEO_CONFIG';
const FETCH_PROMO_CODE_LIST = '@fields/FETCH_PROMO_CODE_LIST';
const PUT_PROMO_CODE_LIST = '@fields/PUT_PROMO_CODE_LIST';
const FETCH_SHORT_URL = '@fields/FETCH_SHORT_URL';
const PUT_SHORT_URL = '@fields/PUT_SHORT_URL';

export const fetchOperators = createAction(FETCH_OPERATORS);
export const putOperators = createAction(PUT_OPERATORS);

export const fetchTariffs = createAction(FETCH_TARIFFS);
export const putTariffs = createAction(PUT_TARIFFS);

export const fetchLanguages = createAction(FETCH_LANGUAGES);
export const putLanguages = createAction(PUT_LANGUAGES);
export const fetchTargetLanguages = createAction(FETCH_TARGET_LANGUAGES);
export const putTargetLanguages = createAction(PUT_TARGET_LANGUAGES);

export const fetchCountries = createAction(FETCH_COUNTRIES);
export const putCountries = createAction(PUT_COUNTRIES);

export const fetchRegions = createAction(FETCH_REGIONS);
export const putRegions = createAction(PUT_REGIONS);

export const fetchCities = createAction(FETCH_CITIES);
export const putCities = createAction(PUT_CITIES);

export const fetchGeoConfig = createAction(FETCH_GEO_CONFIG);
export const putGeoConfig = createAction(PUT_GEO_CONFIG);

export const resetFieldsData = createAction(RESET_FIELDS_DATA);

export const fetchMnos = createAction(FETCH_MNOS)
export const putMnos = createAction(PUT_MNOS)

export const fetchTags = createAction(FETCH_TAGS);
export const putTags = createAction(PUT_TAGS);

export const fetchEmailCampaigns = createAction(FETCH_EMAIL_CAMPAIGNS)
export const putEmailCampaigns = createAction(PUT_EMAIL_CAMPAIGNS)

export const fetchSmsCampaigns = createAction(FETCH_SMS_CAMPAIGNS)
export const putSmsCampaigns = createAction(PUT_SMS_CAMPAIGNS)

export const fetchOfferCampaigns = createAction(FETCH_OFFER_CAMPAIGNS)
export const putOfferCampaigns = createAction(PUT_OFFER_CAMPAIGNS)

export const fetchPromoCodeList = createAction(FETCH_PROMO_CODE_LIST)
export const putPromoCodeList = createAction(PUT_PROMO_CODE_LIST)

export const putCompiledScripts = createAction(PUT_COMPILED_SCRIPTS);

export const fetchShortUrl = createAction(FETCH_SHORT_URL)
export const putShortUrl = createAction(PUT_SHORT_URL)