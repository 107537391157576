const getTitleFields = (el) => {
  const {
    fontSize,
    fontFamily,
    fontWeight,
    textDecoration,
    fontStyle,
    color,
  } = el.style;

  return {
    titleStyles: {
      fontSize,
      fontFamily: fontFamily === '"Times New Roman"' ? 'Times New Roman' : fontFamily,
      fontStyle,
      fontWeight,
      textDecoration,
      color,
    },
  };
};

const getOldPriceFields = (el) => {
  const {
    fontSize,
    fontFamily,
    fontWeight,
    textDecoration,
    fontStyle,
    color,
  } = el.style;

  return {
    oldPriceStyles: {
      fontSize,
      fontFamily: fontFamily === '"Times New Roman"' ? 'Times New Roman' : fontFamily,
      fontStyle,
      fontWeight,
      textDecoration,
      color,
    },
  };
};

const getPriceFields = (el) => {
  const {
    fontSize,
    fontFamily,
    fontWeight,
    textDecoration,
    fontStyle,
    color,
  } = el.style;

  return {
    priceStyles: {
      fontSize,
      fontFamily: fontFamily === '"Times New Roman"' ? 'Times New Roman' : fontFamily,
      fontStyle,
      fontWeight,
      textDecoration,
      color,
    },
  };
};

const getLabelPriceFields = (el) => {
  const {
    fontSize,
    fontFamily,
    fontWeight,
    textDecoration,
    fontStyle,
    color,
    backgroundColor,
  } = el.style;

  return {
    oldPriceLabelStyles: {
      fontSize,
      fontFamily: fontFamily === '"Times New Roman"' ? 'Times New Roman' : fontFamily,
      fontStyle,
      fontWeight,
      textDecoration,
      backgroundColor,
      color,
    },
  };
};

const getFormFields = (el) => {
  const { flexDirection } = el.style;
  const orientation = flexDirection;

  const {
    widthOption,
    maxWidth,
    position,
    campaignTitle,
  } = el.dataset;


  return {
    orientation,
    widthOption,
    maxWidth,
    position,
    campaignTitle,
  };
};

export const productCardParser = (el, id) => {
  const formFields = getFormFields(el);
  const titleFields = getTitleFields(el.querySelector('.comfolks-product-card-title'));
  const oldPriceFields = getOldPriceFields(el.querySelector('.comfolks-product-card-old-price'));
  const priceFields = getPriceFields(el.querySelector('.comfolks-product-card-price'));
  const labelPriceFields = getLabelPriceFields(el.querySelector('.comfolks-product-card-sale-label'));

  return {
    type: 'productCard',
    ...formFields,
    ...titleFields,
    ...oldPriceFields,
    ...priceFields,
    ...labelPriceFields,
    id,
  };
};
