import React from 'react'
import Box from '@material-ui/core/Box'
import Modal from '@material-ui/core/Modal'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import makeStyle from '@material-ui/styles/makeStyles'
import CardGiftcardOutlinedIcon from '@material-ui/icons/CardGiftcardOutlined'
import { Link } from '@material-ui/core'

const useStyles = makeStyle((theme) => ({
  paper: {
    width: '100%',
    maxWidth: '500px',
    padding: theme.spacing(3),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(3)
  },
  logo: {
    width: '100%'
  }
}))

export const OfferAccepted = ({ open, onBack, redirectUrl }) => {
  const classes = useStyles()
  const message = 'Check your inbox'

  return (
    <Modal open={open} className={classes.modal} BackdropProps={{ timeout: 300 }} onClose={onBack}>
      <Paper square className={classes.paper}>
        <Box pt={1} width='100%' display='flex' justifyContent='center'>
          <CardGiftcardOutlinedIcon fontSize='large' />
        </Box>
        <Box pt={3} pb={3}>
          <Typography align='center' variant='h5'>
            Offer was successfully accepted!
          </Typography>
          <Typography align='center' variant='subtitle2' color='textSecondary'>
            {message}
          </Typography>
        </Box>
        {redirectUrl && (
          <Link href={redirectUrl} display='block' variant='body2' align='center' target='_blank'>
            Open site
          </Link>
        )}
      </Paper>
    </Modal>
  )
}
