import { createAction } from 'redux-actions';
import { createRoutine, bindRoutineToReduxForm } from 'redux-saga-routines';

const FETCH_SIM_NUMBER_UPLOADS = '@sim/FETCH_SIM_NUMBER_UPLOADS';
const FETCH_SIM_NUMBER_UPLOADS_LIST = '@sim/FETCH_SIM_NUMBER_UPLOADS_LIST';
const PUT_SIM_NUMBER_UPLOADS = '@sim/PUT_SIM_NUMBER_UPLOADS';
const PUT_SIM_NUMBER_UPLOADS_LIST = '@sim/PUT_SIM_NUMBER_UPLOADS_LIST';
const ACTIVATE_SIM_NUMBER_UPLOADS = '@sim/ACTIVATE_SIM_NUMBER_UPLOADS';
const HOLD_SIM_NUMBER_UPLOADS = '@sim/HOLD_SIM_NUMBER_UPLOADS';
const VALIDATE_SIM_NUMBER_UPLOADS_NAME = '@sim/VALIDATE_SIM_NUMBER_UPLOADS_NAME';
const CREATE_SIM_NUMBER_UPLOADS = '@sim/CREATE_SIM_NUMBER_UPLOADS';
const FETCH_FILE_REPORT_SIM = '@sim/FETCH_FILE_REPORT_SIM';

export const fetchSimNumberUploads = createAction(FETCH_SIM_NUMBER_UPLOADS);
export const fetchSimNumberUploadsList = createAction(FETCH_SIM_NUMBER_UPLOADS_LIST);
export const putSimNumberUploads = createAction(PUT_SIM_NUMBER_UPLOADS);
export const putSimNumberUploadsList = createAction(PUT_SIM_NUMBER_UPLOADS_LIST);
export const activateSimNumberUploads = createAction(ACTIVATE_SIM_NUMBER_UPLOADS);
export const holdSimNumberUploads = createAction(HOLD_SIM_NUMBER_UPLOADS);
export const validateSimNumberUploadName = createAction(VALIDATE_SIM_NUMBER_UPLOADS_NAME);
export const fetchFileReport = createAction(FETCH_FILE_REPORT_SIM);

export const createSimNumberUploadRoutine = createRoutine(CREATE_SIM_NUMBER_UPLOADS);
export const createSimNumberUploadHandler = bindRoutineToReduxForm(
    createSimNumberUploadRoutine
);

const FETCH_SIM_SALES_POINTS_UPLOADS = '@sim/FETCH_SIM_SALES_POINTS_UPLOADS';
const FETCH_SIM_SALES_POINTS_UPLOADS_LIST = '@sim/FETCH_SIM_SALES_POINTS_UPLOADS_LIST';
const PUT_SIM_SALES_POINTS_UPLOADS = '@sim/PUT_SIM_SALES_POINTS_UPLOADS';
const PUT_SIM_SALES_POINTS_UPLOADS_LIST = '@sim/PUT_SIM_SALES_POINTS_UPLOADS_LIST';
const ACTIVATE_SIM_SALES_POINTS_UPLOADS = '@sim/ACTIVATE_SIM_SALES_POINTS_UPLOADS';
const HOLD_SIM_SALES_POINTS_UPLOADS = '@sim/HOLD_SIM_SALES_POINTS_UPLOADS';
const VALIDATE_SIM_SALES_POINTS_UPLOADS_NAME = '@sim/VALIDATE_SIM_SALES_POINTS_UPLOADS_NAME';
const CREATE_SIM_SALES_POINTS_UPLOADS = '@sim/CREATE_SIM_SALES_POINTS_UPLOADS';
const FETCH_FILE_REPORT_SIM_POINTS = '@sim/FETCH_FILE_REPORT_SIM_POINTS';

export const fetchSimSalesPointsUploads = createAction(FETCH_SIM_SALES_POINTS_UPLOADS);
export const fetchSimSalesPointsUploadsList = createAction(FETCH_SIM_SALES_POINTS_UPLOADS_LIST);
export const putSimSalesPointsUploads = createAction(PUT_SIM_SALES_POINTS_UPLOADS);
export const putSimSalesPointsUploadsList = createAction(PUT_SIM_SALES_POINTS_UPLOADS_LIST);
export const activateSimSalesPointsUploads = createAction(ACTIVATE_SIM_SALES_POINTS_UPLOADS);
export const holdSimSalesPointsUploads = createAction(HOLD_SIM_SALES_POINTS_UPLOADS);
export const validateSimSalesPointsUploadName = createAction(VALIDATE_SIM_SALES_POINTS_UPLOADS_NAME);
export const fetchFileReportPoints = createAction(FETCH_FILE_REPORT_SIM_POINTS);

export const createSimSalesPointsUploadRoutine = createRoutine(CREATE_SIM_SALES_POINTS_UPLOADS);
export const createSimSalesPointsUploadHandler = bindRoutineToReduxForm(
    createSimSalesPointsUploadRoutine
);

const FETCH_SERVICES_UPLOADS = '@sim/FETCH_SERVICES_UPLOADS';
const FETCH_SERVICES_UPLOADS_LIST = '@sim/FETCH_SERVICES_UPLOADS_LIST';
const PUT_SERVICES_UPLOADS = '@sim/PUT_SERVICES_UPLOADS';
const PUT_SERVICES_UPLOADS_LIST = '@sim/PUT_SERVICES_UPLOADS_LIST';
const ACTIVATE_SERVICES_UPLOADS = '@sim/ACTIVATE_SERVICES_UPLOADS';
const HOLD_SERVICES_UPLOADS = '@sim/HOLD_SERVICES_UPLOADS';
const VALIDATE_SERVICES_UPLOADS_NAME = '@sim/VALIDATE_SERVICES_UPLOADS_NAME';
const CREATE_SERVICES_UPLOADS = '@sim/CREATE_SERVICES_UPLOADS';
const FETCH_FILE_REPORT_SERVICES = '@sim/FETCH_FILE_REPORT_SERVICES';

export const fetchServicesUploads = createAction(FETCH_SERVICES_UPLOADS);
export const fetchServicesUploadsList = createAction(FETCH_SERVICES_UPLOADS_LIST);
export const putServicesUploads = createAction(PUT_SERVICES_UPLOADS);
export const putServicesUploadsList = createAction(PUT_SERVICES_UPLOADS_LIST);
export const activateServicesUploads = createAction(ACTIVATE_SERVICES_UPLOADS);
export const holdServicesUploads = createAction(HOLD_SERVICES_UPLOADS);
export const validateServicesUploadName = createAction(VALIDATE_SERVICES_UPLOADS_NAME);
export const fetchFileReportServices = createAction(FETCH_FILE_REPORT_SERVICES);

export const createServicesUploadRoutine = createRoutine(CREATE_SERVICES_UPLOADS);
export const createServicesUploadHandler = bindRoutineToReduxForm(
    createServicesUploadRoutine
);