import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';
import { parseStyles } from './commonParsers/commonStylesParser'
import linkAttributesParser from './commonParsers/linkAttributesParser';

/*
<div> -- el
  <img src="..." alt="..." />
</div>
*/
const verticalPositions = {
  center: 'center',
  'flex-start': 'top',
  'flex-end': 'bottom'
};

const horizontalPositions = {
  center: 'center',
  'flex-start': 'left',
  'flex-end': 'right'
};

export const imageParser = (el, id, type, horizontal) => {
  const link = el.getElementsByTagName('a').item(0)
  const imageElement = el.getElementsByTagName('img').item(0);
  const { justifyContent } = el.style;

  if (imageElement) {
    const rotateDeg = el.dataset.rotateDeg || '0deg';
    const horizontalTranslate = el.dataset.horizontalTranslate || '0px';
    const verticalTranslate = el.dataset.verticalTranslate || '0px';
    const rotateVerticalOrigin = el.dataset.rotateVerticalOrigin || 'bottom';
    const rotateHorizontalOrigin = el.dataset.rotateHorizontalOrigin || 'right';
    const animation = el.dataset.animation || 'unset';
    const image = { url: imageElement.getAttribute('src') };
    const alt = imageElement.getAttribute('alt');
    const imageWidth = imageElement.style.width;
    const width = imageWidth === '100%' ? '0px' : imageWidth;
    const linkOptions = linkAttributesParser(link)

    if (horizontal) {
      const height = imageElement.style.height;
      const position = verticalPositions[justifyContent];

      return {
        type: 'image',
        height,
        position,
        image,
        alt,
        id,
        ...linkOptions,
        rotateDeg,
        horizontalTranslate,
        verticalTranslate,
        rotateVerticalOrigin,
        rotateHorizontalOrigin,
        animation,
      };
    }

    const position = horizontalPositions[justifyContent];
    const stylesOptions = parseStyles(imageElement.style)

    const imageOptions = {
      ...stylesOptions,
      ...linkOptions,
      type: 'image',
      width,
      position,
      image,
      alt,
      id,
      rotateDeg,
      horizontalTranslate,
      verticalTranslate,
      rotateVerticalOrigin,
      rotateHorizontalOrigin,
      animation,
    };

    return omitBy(imageOptions, isNil);
  }
};
