import compose from 'lodash/fp/compose'
import get from 'lodash/fp/get'
import omit from 'lodash/fp/omit'
import isNil from 'lodash/fp/isNil'
import { b64ToBlob } from '../help'
import { transformTarget, transformToTarget } from './targetMappers'
import { transformTrigger, transformToTrigger } from './triggerMappers'
import { getFirstWidgetValue } from './cpaMappers'

export const mapFormValuesToCampaignOffer = (fields) => {
  const [transformed, fileBlob, filename] = mapFormValuesToCampaignOfferOld(fields)

  const transformedFields = compose([
    omit(['notification']),
    v => ({
      ...v,
      widgets: [{
        id: fields.widgetId,
        campaign_id: fields.id,
        html: v.notification
      }]
    })
  ])(transformed)

  return [transformedFields, fileBlob, filename]
}

export const mapFormValuesToCampaignOfferOld = (fields) => {
  const fileFields = get('bonus.promoCodesFile', fields)
  const transformedFields = compose([
    (v) => ({
      ...v,
      bonus: {
        ...v.bonus,
        mode: v.bonus.mode === 'uploadedDiscountList' ? 'currentDiscountList' : v.bonus.mode,
        currentDiscountList:
          v.bonus.mode === 'uploadedDiscountList' ? v.campaign_name : v.bonus.currentDiscountList
      }
    }),
    (v) => ({
      ...v,
      targets: {
        ...v.targets,
        targets: v.targets && v.targets.targets ? v.targets.targets.map(transformTarget) : []
      }
    }),
    (v) => ({ ...v, trigger: transformTrigger(v.trigger) }),
    omit(['campaignName', 'bonus.promoCodesFile'])
  ])(fields)

  if (fileFields) {
    const filename = fileFields.name
    const fileBlob = b64ToBlob(fileFields.file)

    return [transformedFields, fileBlob, filename]
  }

  return [transformedFields]
}

export const mapCampaignOfferToTableRow = (wp) => {
  return {
    ...wp,
    delivered: wp.delivered || 0,
    text: wp.text || 'No text',
    sentTo: wp.sentTo || 0,
    seenTo: wp.seen[0] || 0,
    clickTo: wp.ctr[0] || 0,
    ctrTo: wp.ctr[1] || 0,
    seen: `${wp.seen[0] || 0} (${wp.seen[1] || 0}%)`,
    ctr: `${wp.ctr[0] || 0} (${wp.ctr[1] || 0}%)`,
    unsubscribed: `${wp.unsubscribed[0] || 0} (${wp.unsubscribed[1] || 0}%)`
  }
}

export const mapCampaignOfferToFormValues = (wp) => {
  return compose([
    (v) => ({
      ...v,
      widgetId: getFirstWidgetValue('id', v, {}),
      notification: getFirstWidgetValue('html', v, {}),
      targets: {
        match_all: v.targets.match_all,
        targets: v.targets.targets.filter((v) => !isNil(v)).map(transformToTarget)
      }
    }),
    (v) => ({
      ...v,
      trigger: {
        ...transformToTrigger(v.trigger)
      }
    })
  ])(wp)
}
