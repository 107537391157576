import get from 'lodash/get'
import isNil from 'lodash/isNil'
import isNumber from 'lodash/isNumber'
import { FORM_SCHEMA_ONSITE } from '../constants'
import { OnsiteFormHelper } from './formSchema'
import { getActualWidgtesFromForm } from './widgets'

export const required = (value) => (isNil(value) || value === '' ? 'Required' : undefined)

export const maxLength = (maxLength) => (value) =>
  get(value, 'length', 0) > maxLength ? `${maxLength - get(value, 'length', 0)}` : undefined

export const number = (value) => (!isNumber(value) ? 'Must be a number' : undefined)

export const notNegative = (value) => (value < 0 ? 'Must not be a negative' : undefined)

export const onlyPositive = (value) => (value <= 0 ? 'Must be a positive' : undefined)

export const isEmail = (value) => {
  if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
    return undefined
  }

  return 'Incorrect email address!'
}

/**
 *
 * @param {string} value
 * @returns {boolean}
 */
export const isLink = (value) =>
  /http(s)?:\/\//.test(value) || !value ? undefined : 'Incorrect link. Should start with http or https'

/**
 * Get all items in widget and check selected property to be unique.
 *
 * @param {string} propName
 * @returns {string | undefined}
 */
export const isUniqueProperty = (propName) => (value, values, props) => {
  const items = OnsiteFormHelper.getPropertyValue(values, FORM_SCHEMA_ONSITE.PROPERTIES.ITEMS, [])
  let count = 0

  items.forEach((item) => {
    if (item[propName] === value) {
      count++
    }
  })

  return count > 1 ? 'Must be a unique' : undefined
}

export const maxHundredPercentChance = (value, values) => {
  const widgets = getActualWidgtesFromForm(values)
  const count = widgets.reduce((sum, widget) => sum + parseInt(widget.settings.chance), 0)

  return count > 100 ? `Chance is more than 100 by ${count - 100}` : undefined
}

/**
 * Check element to parent element.
 *
 * @param {import('../constants/onsiteWidgets').Element} element
 * @returns {boolean}
 */
export const hasParentElement = (element) => {
  return Boolean(element?.parentElement?.id)
}

/**
 * Check string to including `<code>` substring
 *
 * @param {string} value
 * @returns {string | undefined}
 */
export const hasCodeTag = (value) => {
  if (value.includes('<code>')) return
  return 'Must include <code> string'
}

/**
 * Check string to including `<code>` substring
 *
 * @param {string} value
 * @returns {string | undefined}
 */
export const hasOtpTag = (value) => {
  if (!value || value.includes(':otp')) return
  return 'Must include ":otp" string'
}

/**
 * Check string to including `{{link_confirm_email}}` substring
 *
 * @param {string} value
 * @returns {string | undefined}
 */
export const hasLinkEmailConfirmTag = (value) => {
  if (!value || value.includes('{{link_confirm_email}}')) return
  return 'Must include "{{link_confirm_email}}" string'
}

/**
 * Check string to including `{{email_otp}}` substring
 *
 * @param {string} value
 * @returns {string | undefined}
 */
export const hasEmailOtpTag = (value) => {
  if (!value || value.includes('{{email_otp}}')) return
  return 'Must include "{{email_otp}}" string'
}
