import { handleActions } from 'redux-actions';

import { putPromoCodeUploads, putPromoCodeUploadsList } from '../actions/promoCodes';

const INITIAL_STATE = {
  list: [],
  total: 0,
  editedItem: null,
};
export default handleActions(
  {
    [putPromoCodeUploads]: (state, { payload }) => ({ ...state, editedItem: payload }),
    [putPromoCodeUploadsList]: (state, { payload: { list, total } }) => ({
      ...state,
      list,
      total,
    }),
  },
  INITIAL_STATE,
);
