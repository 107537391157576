import React, { createContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { change, getFormValues } from 'redux-form';

import { WIDGET_LAYOUT_TYPES, elements as ELEMENTS } from '../../../constants/onsiteWidgets';
import { findElement, findElementByPath } from '../../../help/element';
import { OnsiteFormHelper } from '../../../help/formSchema';
import { useClickOutside } from '../../../help/hooks';
import { selectOnsiteWidgetEvents } from '../../../redux/selectors';

export const FormContext = createContext();

export const FormContextProvider = ({
  children,
  form,
  section,
  horizontal,
  timeClose,
  widgetType,
  sizeType,
  layoutDirection,
  columnQuantity,
  singleWidgetType,
  elements,
}) => {
  const dispatch = useDispatch();
  const values = useSelector(getFormValues(form));
  const events = useSelector(selectOnsiteWidgetEvents);
  const editedWidget = OnsiteFormHelper.getEditedWidget(values);

  const [activeElement, setActiveElement] = useState({
    id: undefined,
    path: '',
    itemsKey: '',
    itemIndex: 0,
    element: null,
  });


  const [selectedElementToInsert, selectElementToInsert] = useState({
    path: '',
    value: null,
  });


  const changeFormField = (path, value, customSection = '') => {
    let fullPath = `${customSection || section}`;
    fullPath += path ? `.${path}` : '';
    console.log(fullPath);

    const action = change(form, fullPath, value);
    dispatch(action);
  };

  const changeActiveElement = (path, value) => {
    const fullPath = `${activeElement.path}.${path}`;
    changeFormField(fullPath, value);
  };
  const isCarousel = editedWidget.type === WIDGET_LAYOUT_TYPES.CAROUSEL;

  const setActiveElementId = (id) => {
    const {
      path, parentPath, itemIndex, itemsKey, element,
    } = isCarousel
      ? findElementByPath(id, values, selectedElementToInsert.path) : findElement(id, values);
    setActiveElement({
      id,
      path,
      parentPath,
      itemIndex,
      itemsKey,
      element,
    });
  };

  const [focusTextEditor, setFocusTextEditor] = useState('');

  const setActiveEditor = (editorName) => {
    setFocusTextEditor(editorName);
  };

  useClickOutside('[data-text-editor]', () => setFocusTextEditor(''));

  useEffect(() => {
    if (activeElement.id) {
      setActiveElementId(activeElement.id);
    }
  }, [values]);

  return (
    <FormContext.Provider
      value={{
        activeElement,
        activeElementId: activeElement.id,
        setActiveElementId,
        setActiveEditor,
        focusTextEditor,
        changeFormField,
        changeActiveElement,
        form,
        timeClose,
        section,
        horizontal,
        widgetType,
        sizeType,
        layoutDirection,
        columnQuantity,
        singleWidgetType,
        elements,
        selectedElementToInsert,
        selectElementToInsert,
        values,
        events,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};
