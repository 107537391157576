import PropTypes from 'prop-types';
import React from 'react';

const BotIcon = ({ fillColor, ...props }) => (
  <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" {...props}>
    <path fill={fillColor} d="M10 1c4.971 0 9 3.029 9 7s-4.029 7-9 7c-1.669 0-3.232-.428-4.619-1.178l-4.381 1.139 1.293-3.801c-1.581-1.528-2.293-3.623-2.293-5.16 0-3.971 4.029-7 9-7zm0 13c4.418 0 8-2.686 8-6s-3.582-6-8-6-8 2.686-8 6 3.582 6 8 6zm-1-8h2v4h-2v-4zm0 5h2v2h-2v-2z" />
  </svg>
);

BotIcon.propTypes = {
  fillColor: PropTypes.string.isRequired,
};

export default BotIcon;
