/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

const SmsIcon = ({ fillColor, ...props }) => (
  <svg width={16} height={12} {...props}>
    <defs>
      <style>{'.sms_svg__c{stroke:none}'}</style>
    </defs>
    <g stroke={fillColor} strokeWidth={1.3} fill="none">
      <rect className="sms_svg__c" width={16} height={12} rx={2} />
      <rect x={0.65} y={0.65} width={14.7} height={10.7} rx={1.35} />
    </g>
    <g fill="none">
      <path
        className="sms_svg__c"
        d="M6.587 6.785L.493.685a2 2 0 0 1 1.51-.684h12a2 2 0 0 1 1.506.684l-6.094 6.1a2 2 0 0 1-2.826 0z"
      />
      <path
        d="M8 6.07a.694.694 0 0 0 .494-.203l4.56-4.563H2.947l4.56 4.56c.131.134.307.206.493.206m0 1.3c-.51 0-1.022-.195-1.413-.585L.494.687a2.003 2.003 0 0 1 1.51-.683H14c.602 0 1.14.265 1.506.683L9.414 6.785c-.391.39-.903.586-1.414.586z"
        fill={fillColor}
      />
    </g>
  </svg>
);

SmsIcon.propTypes = {
  fillColor: PropTypes.string.isRequired,
};

export default SmsIcon;
