import React from 'react';

import TextEditorField from '../../components/OnsiteForm/WidgetFormsFields/TextEditorField';
import { CURRENT_EDITOR } from '../../constants/editor';
import {
  buttonActionTypes,
  formWidthOptions,
  CLOSE_WIDGET_CLASS_NAME,
} from '../../constants/onsiteWidgets';
import { actionWithConditions } from '../../help/actions';
import { analyticConstants } from '../../help/analytics';
import { createEventString } from '../../help/element';
import { fromPx } from '../../help/format';
import { openTextEditor } from '../../help/handleEditor';
import { toPx } from '../../help/parse';

export const Form = ({
  itemId,
  id,
  sizes,
  commonStyle,
  text,
  horizontal,
  placeholder,
  inputStyles,
  buttonStyles,
  orientation,
  selector,
  defaultValue,
  showText,
  isEditing,
  layoutProps,
  boxShadow,
  eventsByElementIdMap,
  action,
  showButton,
  widthOption,
  maxWidth,
  position,
  setActiveEditor,
  activeElement,
  values,
  section,
  form,
  events,
  focusTextEditor,
}) => {
  const { padding } = layoutProps || {};
  const isFormHorizontal = orientation === 'row';
  const widgetHeight = toPx(fromPx(sizes.height) - fromPx(padding) * 2);

  const buttonStyle = {
    display: `${showButton ? 'initial' : 'none'}`,
    border: 0,
    lineHeight: '1.1',
    background: buttonStyles.backgroundColor,
    WebkitAppearance: 'none',
    ...(isFormHorizontal
      ? { width: '30%', padding: '10px' }
      : { width: '100%', padding: '10px 0' }),
    ...buttonStyles,
    boxShadow: buttonStyles.boxShadow?.rawValue,
    pointerEvents: 'auto',
  };

  const inputStyle = {
    boxSizing: 'border-box',
    minHeight: '35px',
    border: '1px solid',
    padding: '10px',
    lineHeight: '1.1',
    ...(isFormHorizontal
      ? { width: '70%', marginBottom: '0', marginRight: '10px' }
      : { width: '100%', marginBottom: '10px', marginRight: '0' }),
    ...inputStyles,
  };

  const textAreaStyle = {
    outline: 'none',
    resize: 'none',
    ...inputStyle,
  };

  const formWidth = widthOption === formWidthOptions.fullWidth ? '100%' : maxWidth;

  const formPosition = {
    left: '0 auto 0 0',
    center: '0 auto',
    right: '0 0 0 auto',
  };

  const formStyle = {
    boxShadow: boxShadow?.rawValue || 'unset',
    width: '100%',
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    flexDirection: isFormHorizontal ? 'row' : 'column',
    margin: formPosition[position],
    ...(horizontal
      ? { width: '100%', height: widgetHeight }
      : { maxWidth: formWidth }),
  };

  const applyCondition = selector === 'email'
    ? 'return (root) => root.querySelector(\'.comfolks-form-input[name="email"]\').value.match(/^[^\\s@]+@[^\\s@]+\\.[^\\s@]+$/)'
    : 'return (root) => root.querySelector(\'.comfolks-form-input\').value.length > 0';


  const actionString = createEventString(actionWithConditions(
    eventsByElementIdMap[itemId || id],
    applyCondition,
  ),
  'submit');


  return (
    <form
      data-position={position}
      data-width-option={widthOption}
      data-max-width={maxWidth}
      data-show-button={showButton}
      data-event-trigger={actionString}
      id={itemId}
      data-id={selector}
      style={{ ...commonStyle, ...formStyle }}
      className={action === buttonActionTypes.closeType && CLOSE_WIDGET_CLASS_NAME}
    >
      {selector === 'textarea' ? (
        <textarea
          className="comfolks-form-input form-input"
          name={selector}
          defaultValue={defaultValue}
          placeholder={placeholder}
          value={(isEditing && showText && 'John Doe jd01@example.com') || ''}
          onChange={() => {}}
          style={textAreaStyle}
        />
      ) : (
        <input
          className="comfolks-form-input form-input"
          name={selector}
          type="text"
          defaultValue={defaultValue}
          placeholder={placeholder}
          value={(isEditing && showText && 'John Doe jd01@example.com') || ''}
          onChange={() => {}}
          style={inputStyle}
        />
      )}
      <button
        data-editor-name={CURRENT_EDITOR.FORM_BUTTON_TEXT}
        data-text-editor
        onClick={openTextEditor(setActiveEditor)}
        type={focusTextEditor === CURRENT_EDITOR.FORM_BUTTON_TEXT && id === activeElement.id ? 'button' : 'submit'}
        style={buttonStyle}
        {...analyticConstants.attributesToTrackClick}
      >
        {focusTextEditor === CURRENT_EDITOR.FORM_BUTTON_TEXT && id === activeElement.id ? (
          <TextEditorField
            editorType="inline-editor"
            type={activeElement.element.type}
            path={activeElement.path}
            isHorizontal={horizontal}
            values={values}
            section={section}
            form={form}
            element={activeElement.element}
            events={events}
            focusTextEditor={focusTextEditor}
          />
        ) : (
          <span dangerouslySetInnerHTML={{ __html: text }} />
        )
        }
      </button>
    </form>
  );
};
