import {
  call, put, takeLatest, delay,
} from 'redux-saga/effects';

import { blackList as api } from '../api';
import {
  blackList as blackListActions,
  snackbar as snackbarActions,
} from '../redux/actions';

const STATUSES = {
  active: 1,
  onHold: 2,
  archived: 3
};

const INVERT_STATUSES = {
  1: 'active',
  2: 'onHold',
  3: 'archived',
};

const mapStatus = list =>
    list.map(item => ({ ...item, status: INVERT_STATUSES[item.status] }));

function* createBlackListUpload({ payload: { values } }) {
  const { blackList, name } = values;
  try {
    yield put(blackListActions.createBlackListUploadRoutine.request());

    const { id } = values;
    const data = new FormData();

    data.append('file', ...blackList);
    data.append('name', name);

    const upload = yield call(api.postBlackListUpload, id, data);

    yield put(blackListActions.createBlackListUploadRoutine.success(upload));
  } catch (error) {
    yield put(blackListActions.createBlackListUploadRoutine.failure(error));
  } finally {
    yield put(blackListActions.createBlackListUploadRoutine.fulfill());
  }
}

function* activateBlackListUploads({ payload: { id, pageView } }) {
  try {
    yield call(api.changeStatus, id, STATUSES.active);
    yield put(blackListActions.fetchBlackListUploadsList({ page: 1, perPage: 10, status: pageView }));
  } catch (error) {
    const message = error.request && error.request.status === 403 ? 'You cannot set Active status!' : 'Change status error occurred!';
    yield put(
      snackbarActions.enqueueSnackbar({
        message,
        options: { variant: 'error' },
      }),
    );
  }
}

function* holdBlackListUploads({ payload: { id, pageView } }) {
  try {
    yield call(api.changeStatus, id, STATUSES.onHold);
    yield put(blackListActions.fetchBlackListUploadsList({ page: 1, perPage: 10, status: pageView }));
  } catch (error) {
    yield put(
      snackbarActions.enqueueSnackbar({
        message: 'Error while set Hold status',
        options: { variant: 'error' },
      }),
    );
  }
}

function* fetchBlackListUploads({ payload: { id } }) {
  try {
    const item = yield call(api.fetchCampaign, id);
    yield put(blackListActions.putBlackListUploads(item));
  } catch (error) {
    yield put(
      snackbarActions.enqueueSnackbar({
        message: 'Error while fetch black list upload',
        options: { variant: 'error' },
      }),
    );
  }
}

function* fetchBlackListUploadsList({
  payload: {
    page, perPage, status, search,
  },
}) {
  try {
    const [list, total] = yield call(api.fetchCampaignList, {
      page,
      perPage,
      status: STATUSES[status],
      search,
    });

    yield put(blackListActions.putBlackListUploadsList({ list: mapStatus(list), total }));
  } catch (error) {
    yield put(
      snackbarActions.enqueueSnackbar({
        message: 'Error while fetch list of promo code uploads',
        options: { variant: 'error' },
      }),
    );
  }
}

function* validateCampaignName({
  payload: {
    name, id, resolve, reject,
  },
}) {
  try {
    if (name) {
      yield delay(500);

      const result = yield call(api.validateCampaignName, name, id);

      if (result) {
        resolve();
      } else {
        reject({ campaign_name: 'This name is already used' });
      }
    }
  } catch (error) {
    reject();
    yield put(
      snackbarActions.enqueueSnackbar({
        message: 'Upload name validation problem!',
        options: { variant: 'error' },
      }),
    );
  }
}

function* fetchFileReport({payload}) {
  try {
    yield call(api.fetchFileReport, payload);
  } catch (error) {
    console.error(error);
  }
}

function* fetchBlackListSelectList() {
  try {
    const list = yield call(api.fetchBlackListSelectList)
    yield put(blackListActions.putBlackListSelectList(list))
  } catch (error) {
    yield put(
      snackbarActions.enqueueSnackbar({
        message: 'Loading promo code uploads list error occurred!',
        options: { variant: 'error' }
      })
    )
  }
}

export default function* watcher() {
  yield takeLatest(blackListActions.createBlackListUploadRoutine.TRIGGER, createBlackListUpload);
  yield takeLatest(blackListActions.activateBlackListUploads.toString(), activateBlackListUploads);
  yield takeLatest(blackListActions.holdBlackListUploads.toString(), holdBlackListUploads);
  yield takeLatest(blackListActions.fetchBlackListUploads.toString(), fetchBlackListUploads);
  yield takeLatest(blackListActions.fetchBlackListUploadsList.toString(), fetchBlackListUploadsList);
  yield takeLatest(blackListActions.validateBlackListUploadName.toString(), validateCampaignName);
  yield takeLatest(blackListActions.fetchFileReport.toString(), fetchFileReport);
  yield takeLatest(blackListActions.fetchBlackListSelectList.toString(), fetchBlackListSelectList);
}
