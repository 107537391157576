import { handleActions } from 'redux-actions';

import {
  fetchMedia, addMedia1, getMedia, emptyMedia,
} from '../actions/media';

const INITIAL_STATE = {
  media: [],
  total: 0,
};

export default handleActions({
  [addMedia1]: (state) => ({
    ...state,
  }),
  [fetchMedia]: (state, { payload }) => ({
    ...state,
    ...payload,
  }),
  [getMedia]: (state, { payload: { data } }) => ({ ...state, media: data.data, total: data.total }),
  [emptyMedia]: () => ({
    ...INITIAL_STATE,
  }),
}, INITIAL_STATE);
