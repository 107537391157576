export const OFFER_TYPES = {
  TELECOM: 'telecom',
  ECOM: 'e-comm'
};

export const CPA_TYPE = {
  LOYALTY: 'loyalty',
  OFFER: 'offer'
};


