export function openTextEditor(handleDoubleClick) {
  return (e) => {
    const target = e.target.closest('[data-editor-name]');
    const isEditor = e.target.closest('.inline-editor');
    if (target) {
      e.preventDefault();
    }
    if (target && !isEditor && handleDoubleClick) {
      const { editorName } = target.dataset;
      handleDoubleClick(editorName);
    }
  };
}
