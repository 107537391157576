import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import { routinePromiseWatcherSaga } from 'redux-saga-routines';
import createSagaMiddleware from 'redux-saga';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web and AsyncStorage for react-native

import migrations from './migrations';
import rootReducer from '../reducers';
import rootSaga from '../../saga';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  blacklist: ['form', 'webPush'],
  migrate: migrations
};

const sagas = [rootSaga, routinePromiseWatcherSaga];

const sagaMiddleware = createSagaMiddleware();
const persistedReducer = persistReducer(persistConfig, rootReducer);
/* eslint-disable no-underscore-dangle */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // for redux dev tools
/* eslint-enable */

export const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware))
);
export const persistor = persistStore(store);

sagas.forEach(saga => sagaMiddleware.run(saga));
