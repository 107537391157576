import compose from 'lodash/fp/compose';
import get from 'lodash/fp/get';
import isNil from 'lodash/fp/isNil';
import omit from 'lodash/fp/omit';

import chatbotTemplate from '../assets/js/compiled/chatbotTemplate';
import { RECAPTCHA_KEY_ID } from '../constants/onsiteWidgets';
import { b64ToBlob } from '../help';

import { transformTarget, transformToTarget } from './targetMappers';
import { transformTrigger, transformToTrigger } from './triggerMappers';

export const mapFormValuesToChatBot = (fields) => {
  const [transformed, fileBlob, filename] = mapFormValuesToChatBotOld(fields);


  const prepareChatbotTemplate = (template, settings) => {
    let newTemplate = template;
    newTemplate = newTemplate.replace(/const RECAPTCHA_KEY_ID = '';/, `const RECAPTCHA_KEY_ID = '${RECAPTCHA_KEY_ID}';`);
    if (settings.firstColor) {
      newTemplate = newTemplate.replace(/--comfolks-color-primary: .*;/, `--comfolks-color-primary: ${settings.firstColor};`);
    }
    if (settings.secondColor) {
      newTemplate = newTemplate.replace(/--comfolks-color-header: .*;/, `--comfolks-color-header: ${settings.secondColor};`);
    }
    if (settings.title) {
      newTemplate = newTemplate.replace(/<span id="comfolks-chat-title">.*<\/span>/, `<span id="comfolks-chat-title">${settings.title}</span>`);
    }
    if (settings.telegramUrl) {
      newTemplate = newTemplate.replace(/https:\/\/t.me\/ComfolksBot/, `${settings.telegramUrl}`);
    }
    if (settings.globalFontSize) {
      newTemplate = newTemplate.replace(/--comfolks-font-size: .*;/, `--comfolks-font-size: ${settings.globalFontSize};`);
    }
    if (settings.icon?.url) {
      newTemplate = newTemplate.replace(
        /<svg id="comfolks-chat-icon".*?<\/svg>/, `<image src="${settings.icon.url}">`,
      );
    }
    return newTemplate;
  };

  const transformedFields = compose([
    omit(['notification']),
    (v) => ({
      ...v,
      settings: v.settings,
      widgets: {
        blocks: v.notification,
        html: prepareChatbotTemplate(chatbotTemplate, v.settings),
      },
    }),
  ])(transformed);

  return [transformedFields, fileBlob, filename];
};


export const mapFormValuesToChatBotOld = (fields) => {
  const fileFields = get('bonus.promoCodesFile', fields);
  const transformedFields = compose([
    (v) => ({
      ...v,
      bonus: {
        ...v.bonus,
        mode: v.bonus.mode === 'uploadedDiscountList' ? 'currentDiscountList' : v.bonus.mode,
        currentDiscountList: false,
      },
    }),
    (v) => ({
      ...v,
      targets: {
        ...v.targets,
        targets: v.targets && v.targets.targets ? v.targets.targets.map(transformTarget) : [],
      },
    }),
    (v) => ({ ...v, trigger: transformTrigger(v.trigger) }),
    omit(['campaignName', 'bonus.promoCodesFile']),
  ])(fields);

  if (fileFields) {
    const filename = fileFields.name;
    const fileBlob = b64ToBlob(fileFields.file);

    return [transformedFields, fileBlob, filename];
  }

  return [transformedFields];
};

export const mapCampaignChatBotToTableRow = (wp) => ({
  ...wp,
  delivered: wp.delivered || 0,
  text: wp.text || 'No text',
  sentTo: wp.sentTo || 0,
  seenTo: wp.seen?.[0] || 0,
  clickTo: wp.ctr?.[0] || 0,
  ctrTo: wp.ctr?.[1] || 0,
  seen: `${wp.seen?.[0] || 0} (${wp.seen?.[1] || 0}%)`,
  ctr: `${wp.ctr?.[0] || 0} (${wp.ctr?.[1] || 0}%)`,
  unsubscribed: `${wp.unsubscribed?.[0] || 0} (${wp.unsubscribed?.[1] || 0}%)`,
});

export const mapChatBotToFormValues = (wp) => compose([
  (v) => ({
    ...v,
    settings: v.settings,
    notification: v.widgets,
    targets: {
      match_all: v.targets.match_all,
      targets: v.targets.targets.filter((v) => !isNil(v)).map(transformToTarget),
    },
  }),
  (v) => ({
    ...v,
    trigger: {
      ...transformToTrigger(v.trigger),
    },
  }),
])(wp);
