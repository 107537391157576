/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

const EmailIcon = ({ fillColor, ...props }) => (
  <svg width={16} height={15.977} {...props}>
    <path
      d="M7.678 4.21a3.155 3.155 0 0 1 1.437.3 2.4 2.4 0 0 1 .859.578l.228.28V4.316h1.262v4.8c0 1.069.421 1.613 1.262 1.613a1.753 1.753 0 0 0 1.3-.754 4.312 4.312 0 0 0 .526-2.4 5.96 5.96 0 0 0-1.806-4.505 6.574 6.574 0 0 0-4.752-1.752A6.4 6.4 0 0 0 3.3 3.211a6.556 6.556 0 0 0-1.932 4.786A6.556 6.556 0 0 0 3.3 12.783 6.363 6.363 0 0 0 8 14.729a7.012 7.012 0 0 0 2.121-.333 5.259 5.259 0 0 0 1.437-.684l.438-.351.736.947-.193.175a6.153 6.153 0 0 1-.6.4 8.2 8.2 0 0 1-.947.526 7.5 7.5 0 0 1-1.315.368 7.713 7.713 0 0 1-7.293-2.175A7.626 7.626 0 0 1 0 7.997a7.626 7.626 0 0 1 2.384-5.61A7.626 7.626 0 0 1 7.994.002a7.854 7.854 0 0 1 5.68 2.226 7.152 7.152 0 0 1 2.314 5.347 5.1 5.1 0 0 1-.912 3.278 2.945 2.945 0 0 1-2.349 1.139 2.524 2.524 0 0 1-.894-.158 1.753 1.753 0 0 1-.666-.368 3.243 3.243 0 0 1-.421-.438 1.841 1.841 0 0 1-.228-.368v-.14a.771.771 0 0 1-.088.158 2.91 2.91 0 0 1-.3.333 2.594 2.594 0 0 1-.526.456 2.875 2.875 0 0 1-.841.368 3.313 3.313 0 0 1-1.052.158 3.366 3.366 0 0 1-2.452-1.121 3.944 3.944 0 0 1-1.069-2.77 3.909 3.909 0 0 1 1.069-2.84A3.313 3.313 0 0 1 7.678 4.21zm.105 1.262a2.1 2.1 0 0 0-1.63.736 2.665 2.665 0 0 0-.683 1.894 2.665 2.665 0 0 0 .684 1.893 2.1 2.1 0 0 0 1.63.736 2.314 2.314 0 0 0 1.753-.736 2.665 2.665 0 0 0 .683-1.893 2.665 2.665 0 0 0-.684-1.893 2.314 2.314 0 0 0-1.753-.736z"
      fill={fillColor}
    />
  </svg>
);

EmailIcon.propTypes = {
  fillColor: PropTypes.string.isRequired,
};

export default EmailIcon;
