import { FORM_WIDGET_ELEMENT_CHILDREN_KEY } from '../../constants';
import { heightOptions, layoutDirection as layout, flexDirectionsMap } from '../../constants/onsiteWidgets';
import { parseBg } from '../parseBg';
import { parseStyles } from './commonParsers/commonStylesParser';
import parseHeight from './commonParsers/heightParser';
import parseTransition from './commonParsers/transitionParser';

const parseEventOptions = (el) => {
  try {
    return {  eventOptions: JSON.parse(el.dataset.eventOptions) }
  } catch (error) {
    return { eventOptions: {} }
  }
}

/**
 * @type {OnsiteWidget.HtmlParser} 
 */
export const collapseParser = (el, id, type, horizontal, options) => {
  const root = el.firstElementChild;
  //console.log('root', root.dataset.show)
  const bgFields = parseBg(root);
  const friendlyId = root.dataset.friendlyId
  const initHeight = root.dataset.height
  const { widthOption, maxWidth } = root.dataset;
  const [openingCondition, openingConditionTime] = (root.dataset.openCondition || '').split(':')
  const { heightOption, fixedHeight } = parseHeight(root.style)
  const { transitionDuration, transitionTimingFunction } = parseTransition(root.style)
  const stylesOptions = parseStyles(root.style)
  const { padding, borderRadius } = root.style
  const eventOptions = parseEventOptions(root)
  let children = options.childrenParser && options.childrenParser([...root.children].shift().children, id, type, horizontal)
  if (options.childrenParser && children && children.childrenElements && children.childrenElements.length === 0) {
      children = options.childrenParser(root.children, id, type, horizontal, false)
  }

  const showCollapse = root.dataset.show !== 'false' ? true : false;

  const innerContainerStyle = [...root.children].shift().style || {}
  const layoutDirection = !innerContainerStyle.display ?
    layout.default :
    (
      innerContainerStyle['flex-direction'] === flexDirectionsMap.vertical ?
      layout.vertical : layout.horizontal
    )

  if (Array.isArray(children)) {
    children = { [FORM_WIDGET_ELEMENT_CHILDREN_KEY]: children }
  }

  return {
    ...bgFields,
    ...stylesOptions,
    ...children,
    ...eventOptions,
    type: 'collapse',
    initHeight,
    // We need this conditions because in builder Collapse allways is visible
    fixedHeight: parseInt(fixedHeight) === 0 && initHeight !== heightOptions.auto
      ? initHeight
      : fixedHeight,
    heightOption: initHeight === heightOptions.auto ? initHeight : heightOption,
    widthOption,
    maxWidth,
    padding,
    borderRadius,
    transitionTimingFunction,
    transitionDuration,
    layoutDirection,
    show: showCollapse,
    friendlyId,
    droppable: true,
    openingCondition,
    openingConditionTime,
    id
  }
};
