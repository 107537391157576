import merge from 'lodash/merge';
import { createMigrate } from 'redux-persist';

import {
  WEP_PUSH_STEPS,
  ONSITE_STEPS,
  SOCIAL_SHARING_STEPS,
  CPA_LOYALTY_STEPS,
  CPA_OFFER_STEPS,
  EMAIL_STEPS,
  SMS_STEPS,
  PROMO_CODE_UPLOADS_STEPS,
  SMS_TREE_STEPS,
  CHAT_BOT_STEPS,
  SIM_NUMBER_UPLOADS_STEPS, 
  SIM_NUMBER_POINTS_STEPS,
  SERVICES_STEPS,
} from '../../constants';

const migrations = {
  0: (state) => {
    const changes = {
      programState: {
        steppers: {
          steps: {
            ...WEP_PUSH_STEPS,
            ...EMAIL_STEPS,
            ...SMS_STEPS,
            ...SMS_TREE_STEPS,
            ...CHAT_BOT_STEPS,
            ...ONSITE_STEPS,
            ...SOCIAL_SHARING_STEPS,
            ...PROMO_CODE_UPLOADS_STEPS,
            ...SIM_NUMBER_UPLOADS_STEPS,
            ...SIM_NUMBER_POINTS_STEPS,
            ...SERVICES_STEPS,
          },
        },
      },
    };

    return merge(state, changes);
  },
  1: (state) => {
    const changes = {
      programState: {
        steppers: {
          steps: {
            ...WEP_PUSH_STEPS,
            ...EMAIL_STEPS,
            ...SMS_STEPS,
            ...SMS_TREE_STEPS,
            ...CHAT_BOT_STEPS,
            ...ONSITE_STEPS,
            ...SOCIAL_SHARING_STEPS,
            ...CPA_LOYALTY_STEPS,
            ...CPA_OFFER_STEPS,
            ...PROMO_CODE_UPLOADS_STEPS,
            ...SIM_NUMBER_UPLOADS_STEPS,
            ...SIM_NUMBER_POINTS_STEPS,
            ...SERVICES_STEPS,
          },
        },
      },
    };

    return merge(state, changes);
  },
};

export default createMigrate(migrations, {
  debug: process.env.NODE_ENV !== 'production',
});
