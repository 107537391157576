import { handleActions } from 'redux-actions';

import {
  putChatBot,
  putChatBotList,
  updateChatBot,
  createBlock,
  deleteBlock,
  updateBlock,
  createResponse,
  updateResponse,
  deleteResponse,
  linkResponse,
  unlinkResponse,
} from '../actions/chatBot';

const INITIAL_STATE = {
  list: [],
  total: 0,
  editedItem: null,
  blocks: [],
};

export default handleActions(
  {
    [putChatBot]: (state, { payload }) => {
      const blocks = (payload?.notification || payload?.widget) ? (payload.notification || payload.widget) : [];
      return { ...state, editedItem: payload, blocks };
    },
    [putChatBotList]: (state, { payload: { list, total } }) => ({
      ...state,
      list,
      total,
    }),
    [updateChatBot]: (state, { payload }) => ({
      ...state,
      editedItem: { ...state.editedItem, ...payload },
    }),
    [createBlock]: (state, { payload }) => {
      const newBlock = {
        id: payload.id,
        type: payload.type,
        content: payload.content,
        tags: payload.tags,
        settings: {
          isSearchable: payload.isSearchable,
          gptChat: payload.module === 'gptChat',
          gptImages: payload.module === 'gptImages',
          gptPrompt: payload.module === 'gptPrompt',
          supportChat: payload.module === 'supportChat',
          emailCampaignId: payload.emailCampaignId,
          feedbackFields: payload.module === 'feedbackFields',
          orderFields: payload.module === 'orderFields',
          otpFields: payload.module === 'otpFields',
          feedbackForm: {
            emailLabelText: payload.emailLabelText,
            phoneLabelText: payload.phoneLabelText,
            nameLabelText: payload.nameLabelText,
            messageLabelText: payload.messageLabelText,
            sendButtonText: payload.sendButtonText,
            errorFilledFields: payload.errorFilledFields,
            successMessageText: payload.successMessageText,
          },
          orderForm: {
            orderEmailLabelText: payload.orderEmailLabelText,
            orderPhoneLabelText: payload.orderPhoneLabelText,
            orderNameLabelText: payload.orderNameLabelText,
            orderSimNumberLabelText: payload.orderSimNumberLabelText,
            orderCityLabelText: payload.orderCityLabelText,
            orderSalesPointLabelText: payload.orderSalesPointLabelText,
            orderTariffLabelText: payload.orderTariffLabelText,
            orderSendButtonText: payload.orderSendButtonText,
            orderBackButtonText: payload.orderBackButtonText,
            orderErrorFilledFields: payload.orderErrorFilledFields,
            orderSuccessMessageText: payload.orderSuccessMessageText,
          },
          defaultSearchResult: payload.defaultSearchResult,
          title: payload.title,
          image: payload.image,
          imageWidth: payload.imageWidth,
          otp: {
            operator: payload.operator,
            service: payload.service,
            mask: payload.mask,
            maskCodeList: payload.maskCodeList,
            code: payload.code,
            phoneLength: payload.phoneLength,
            validators: payload.validators,
            phonePlaceholder: payload.phonePlaceholder,
            getCodeButtonText: payload.getCodeButtonText,
            changeButtonText: payload.changeButtonText,
            resendButtonText: payload.resendButtonText,
            errorMessageText: payload.errorMessageText,
            successMessageText: payload.successMessageText,
          },
          smsCampaignId: payload.message,
        },
        responses: [],
      };

      // Если нет блоков, создаем первую колонку
      if (!state.blocks || state.blocks.length === 0) {
        return {
          ...state,
          blocks: [[newBlock]],
        };
      }

      // Если создается блок в существующей колонке
      if (payload.column !== null) {
        return {
          ...state,
          blocks: state.blocks.map((column, index) => (index === payload.column
            ? [...column, newBlock]
            : column)),
        };
      }

      // Если создается новая колонка с блоком
      return {
        ...state,
        blocks: [...state.blocks, [newBlock]],
      };
    },

    [deleteBlock]: (state, { payload }) => {
      const newBlocks = state.blocks.map((column) => column.filter((block) => block.id !== payload.id));
      const cleanedBlocks = newBlocks.filter((column) => column.length > 0);
      return {
        ...state,
        blocks: cleanedBlocks,
      };
    },

    [updateBlock]: (state, { payload }) => {
      return {
        ...state,
        blocks: state.blocks.map((column) => column.map((block) => (block.id === payload.id
          ? {
            ...block,
            type: payload.type,
            content: payload.content,
            tags: payload.tags,
            settings: {
              ...block.settings,
              title: payload.title,
              image: payload.image,
              imageWidth: payload.imageWidth,
              isSearchable: payload.isSearchable,
              gptChat: payload.module === 'gptChat',
              gptImages: payload.module === 'gptImages',
              gptPrompt: payload.module === 'gptPrompt',
              supportChat: payload.module === 'supportChat',
              emailCampaignId: payload.emailCampaignId,
              feedbackFields: payload.module === 'feedbackFields',
              orderFields: payload.module === 'orderFields',
              otpFields: payload.module === 'otpFields',
              defaultSearchResult: payload.defaultSearchResult,
              feedbackForm: {
                emailLabelText: payload.emailLabelText,
                phoneLabelText: payload.phoneLabelText,
                nameLabelText: payload.nameLabelText,
                messageLabelText: payload.messageLabelText,
                sendButtonText: payload.sendButtonText,
                errorFilledFields: payload.errorFilledFields,
                successMessageText: payload.successMessageText,
              },
              orderForm: {
                orderEmailLabelText: payload.orderEmailLabelText,
                orderPhoneLabelText: payload.orderPhoneLabelText,
                orderNameLabelText: payload.orderNameLabelText,
                orderSimNumberLabelText: payload.orderSimNumberLabelText,
                orderCityLabelText: payload.orderCityLabelText,
                orderSalesPointLabelText: payload.orderSalesPointLabelText,
                orderTariffLabelText: payload.orderTariffLabelText,
                orderSendButtonText: payload.orderSendButtonText,
                orderBackButtonText: payload.orderBackButtonText,
                orderErrorFilledFields: payload.orderErrorFilledFields,
                orderSuccessMessageText: payload.orderSuccessMessageText,
              },
              otp: {
                operator: payload.operator,
                service: payload.service,
                mask: payload.mask,
                maskCodeList: payload.maskCodeList,
                code: payload.code,
                phoneLength: payload.phoneLength,
                validators: payload.validators,
                phonePlaceholder: payload.phonePlaceholder,
                getCodeButtonText: payload.getCodeButtonText,
                changeButtonText: payload.changeButtonText,
                resendButtonText: payload.resendButtonText,
                errorMessageText: payload.errorMessageText,
                successMessageText: payload.successMessageText,
              },
              smsCampaignId: payload.message,
            },
          }
          : block))),
      };
    },

    [createResponse]: (state, { payload }) => ({
      ...state,
      blocks: state.blocks.map((column) => column.map((block) => (block.id === payload.blockId
        ? { ...block, responses: [...block.responses, { ...payload.response, next_id: null }] }
        : block))),
    }),

    [updateResponse]: (state, { payload }) => ({
      ...state,
      blocks: state.blocks.map((column) => column.map((block) => (block.id === payload.blockId
        ? {
          ...block,
          responses: block.responses.map((response) => (response.id === payload.responseId
            ? { ...response, content: payload.updatedContent }
            : response)),
        }
        : block))),
    }),

    [deleteResponse]: (state, { payload }) => ({
      ...state,
      blocks: state.blocks.map((column) => column.map((block) => (block.id === payload.blockId
        ? { ...block, responses: block.responses.filter((response) => response.id !== payload.responseId) }
        : block))),
    }),

    [linkResponse]: (state, { payload }) => {
      const updatedBlocks = state.blocks.map((column) => column.map((block) => (block.id === payload.blockId
        ? {
          ...block,
          responses: block.responses.map((response) => (response.id === payload.responseId
            ? { ...response, next_id: payload.next_id }
            : response)),
        }
        : block)));
      return {
        ...state,
        blocks: updatedBlocks,
      };
    },

    [unlinkResponse]: (state, { payload }) => {
      const updatedBlocks = state.blocks.map((column) => column.map((block) => (block.id === payload.blockId
        ? {
          ...block,
          responses: block.responses.map((response) => (response.id === payload.responseId
            ? { ...response, next_id: null }
            : response)),
        }
        : block)));
      return {
        ...state,
        blocks: updatedBlocks,
      };
    },
  },
  INITIAL_STATE,
);
