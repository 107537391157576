import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAlt';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import StarIcon from '@material-ui/icons/Star';
import React from 'react';

import ElementStyles from '../../components/OnsiteForm/DesignStep/ElementStyles';
import { elements } from '../../constants/onsiteWidgets';
import { actionWithConditions } from '../../help/actions';
import { createEventString } from '../../help/element';

export const Rating = (props) => {
  const {
    itemId,
    disabledColor,
    enabledColor,
    size,
    spacing,
    numberOf,
    mode,
    eventsByElementIdMap,
    id,
    range,
  } = props;
  const EMOJI_LIST = [
    {
      icon: SentimentVeryDissatisfiedIcon,
      value: 1,
    },
    {
      icon: SentimentDissatisfiedIcon,
      value: 2,
    },
    {
      icon: SentimentSatisfiedIcon,
      value: 3,
    },
    {
      icon: SentimentSatisfiedAltIcon,
      value: 4,
    },
    {
      icon: SentimentVerySatisfiedIcon,
      value: 5,
    },
  ];

  const actionString = createEventString(actionWithConditions(
    eventsByElementIdMap[itemId || id],
    'return () => Number(document.querySelector(".comfolks-rating-input:checked").value) === currentValue',
    props,
  ), 'change');

  const stars = Array.from({ length: numberOf }, (_, index) => (
    <label
      style={{ marginRight: index === numberOf - 1 ? 0 : spacing }}
      key={index}
      htmlFor={`radio-${index + 1}`}
    >
      <input
        className="comfolks-rating-input rating-radio"
        type="radio"
        id={`radio-${index + 1}`}
        name="radio-group"
        value={index + 1}
        style={{ display: 'none' }}
      />
      <div
        style={{
          maxWidth: size,
          maxHeight: size,
        }}
      >
        <StarIcon
          className="star star_empty"
          fill={enabledColor}
          stroke={disabledColor}
          strokeWidth={1}
          style={{
            fontSize: size,
            width: size,
            height: size,
            fill: enabledColor,
            stroke: disabledColor,
            cursor: 'pointer',
          }}
        />
      </div>
    </label>
  ));

  const emoji = EMOJI_LIST.map((item, index) => {
    const Icon = item.icon;

    return (
      <label
        style={{ marginRight: index === 4 ? 0 : spacing }}
        key={item.value}
        htmlFor={`radio-${index + 1}`}
      >
        <input
          type="radio"
          value={index + 1}
          style={{ display: 'none' }}
          className="comfolks-rating-input emoji-radio"
          id={`radio-${index + 1}`}
          name="radio-group"
        />
        <div
          style={{
            maxWidth: size,
            maxHeight: size,
          }}
        >
          <Icon
            className="emoji emoji_empty"
            fill={disabledColor}
            style={{
              fill: disabledColor,
              borderRadius: '100%',
              backgroundColor: enabledColor,
              fontSize: size,
              width: size,
              height: size,
              cursor: 'pointer',
            }}
          />
        </div>
      </label>
    );
  });

  return (
    <form
      data-mode={mode}
      data-disabled-color={disabledColor}
      data-enabled-color={enabledColor}
      data-event-trigger={actionString}
      data-event-range={range}
      id={itemId}
      className="star-rating"
      style={{ textAlign: 'center', maxWidth: '100%' }}
    >
      <fieldset
        style={{
          border: 'none',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {mode === 'stars' ? stars : emoji}
      </fieldset>
    </form>
  );
};

/*
////////js code for widget wrapper///////////

let ratingValue = undefined
const stars = document.querySelectorAll('.star')
const radios = document.querySelectorAll('.rating-radio')
stars.forEach(element => element.style.fill = 'none')
stars.forEach(element => element.classList.add('star_empty'))
radios.forEach(element => element.addEventListener('click', (e) => onRadioChange(e.target.value)))

function onRadioChange(value) {
    ratingValue = value
    console.log(ratingValue)

    stars.forEach((element, index) => {
      if (ratingValue >= (index + 1)) {
       element.classList.remove('star_empty')
      } else {
        element.classList.add('star_empty')
        }
    })
}

////////css code for widget wrapper///////////
.star_empty {fill: 'none'}
*/
