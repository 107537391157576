/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

const SvgOk = ({ fillColor, ...props }) => (
  <svg width={24} height={24} {...props}>
    <path
      fill={fillColor}
      d="M12 5c1.11 0 2.012.902 2.012 2.012A2.013 2.013 0 0 1 12 9.02a2.013 2.013 0 0 1-2.012-2.008C9.988 5.902 10.891 5 12 5zm0 7.008a4.998 4.998 0 0 0 0-9.996c-2.758 0-5 2.242-5 5a5.004 5.004 0 0 0 5 4.996zm1.965 3.82a8.975 8.975 0 0 0 2.82-1.168 1.423 1.423 0 0 0-1.515-2.406 6.17 6.17 0 0 1-6.54 0 1.423 1.423 0 1 0-1.515 2.406 8.937 8.937 0 0 0 2.82 1.168l-2.719 2.719a1.42 1.42 0 0 0 .004 2.012 1.417 1.417 0 0 0 2.012 0L12 17.89l2.672 2.668a1.424 1.424 0 0 0 2.012 0 1.424 1.424 0 0 0 0-2.012z"
    />
  </svg>
);

SvgOk.propTypes = {
  fillColor: PropTypes.string,
};

SvgOk.defaultProps = {
  fillColor: '#ffffff',
};

export default SvgOk;
