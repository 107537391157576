export const convertAnalyticsFiltersToQueryString = (filters) => {
  let queryString = '';
  for (const key in filters) {
    if (filters[key].length > 0) {
      queryString += `&filters[${key}][]=`;
      queryString += filters[key].join(`&filters[${key}][]=`);
    }
  }
  return queryString;
};

export const convertFiltersToQueryString = (filters) => {
  let queryString = '';
  for (const key in filters) {
    if (key === 'tags') {
      continue; // Skip 'tags' as we handle it separately
    }
    const value = filters[key];
    if (Array.isArray(value)) {
      if (value.length > 0) {
        queryString += value.map((v) => `&filters[${key}][]=${encodeURIComponent(v)}`).join('');
      }
    } else if (value) {
      queryString += `&filters[${key}]=${encodeURIComponent(value)}`;
    }
  }
  return queryString;
};

export const convertSortToQueryString = (sort) => {
  let queryString = '';
  for (const key in sort) {
    if (key && sort[key]) {
      queryString += `&sort[${key}]=${encodeURIComponent(sort[key])}`;
    }
  }
  return queryString;
};
