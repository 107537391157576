import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';

import { formWidthOptions, elements } from '../../constants/onsiteWidgets';
import { fromPx } from '../../help/format';
import { toPx } from '../../help/parse';

import ElementStyles from '../../components/OnsiteForm/DesignStep/ElementStyles';

export const ProductCard = ({
  itemId,
  sizes,
  commonStyle,
  isEditing,
  horizontal,
  orientation,
  selector,
  layoutProps,
  boxShadow,
  showButton,
  widthOption,
  maxWidth,
  position,
  titleStyles,
  oldPriceStyles,
  priceStyles,
  oldPriceLabelStyles,
  campaignTitle,
}) => {
  const { padding } = layoutProps || {};
  const isFormHorizontal = orientation === 'row';
  const widgetHeight = toPx(fromPx(sizes.height) - fromPx(padding) * 2);

  const formWidth = widthOption === formWidthOptions.fullWidth ? '100%' : maxWidth;

  const formPosition = {
    left: '0 auto 0 0',
    center: '0 auto',
    right: '0 0 0 auto',
  };

  const formStyle = {
    position: 'relative',
    boxShadow: boxShadow?.rawValue,
    display: 'flex',
    flexDirection: isFormHorizontal ? 'row' : 'column',
    margin: formPosition[position],
    ...(horizontal
      ? { width: '320px', height: widgetHeight }
      : { maxWidth: formWidth }),
  };

  const imgWrStyles = {
    width: '100%',
    height: '162px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 0 20px',
  };

  const imgStyles = {
    maxWidth: '100%',
    maxHeight: '162px',
    margin: 'auto',
  };

  const titleStyle = {
    color: '#111',
    fontSize: '16px',
    fontWeight: '700',
    lineHeight: '19px',
    margin: '0 auto 12px',
    ...titleStyles,
  };

  const oldPriceStyle = {
    color: '#666',
    fontSize: '12px',
    fontWeight: '700',
    textDecoration: 'line-through',
    textDecorationColor: '#bf9600',
    ...oldPriceStyles,
  };

  const oldPriceLabelStyle = {
    background: '#bf9600',
    color: '#fff',
    borderRadius: '5px',
    fontWeight: 700,
    fontSize: '12px',
    height: '18px',
    justifyContent: 'center',
    marginRight: '5px',
    padding: '0 5px',
    ...oldPriceLabelStyles,
  };

  const priceStyle = {
    color: '#006480',
    fontWeight: 700,
    wordBreak: 'break-all',
    fontSize: '18px',
    lineHeight: '16px',
    ...priceStyles,
  };

  const svgString = encodeURIComponent(renderToStaticMarkup(
    <ShoppingCartIcon />,
  ));

  const fillTitle = () => {
    if (isEditing) {
      return campaignTitle || 'New title';
    }
    return `${itemId}-title`;
  };

  return (
    <a
      href={isEditing ? '#comfolks-product-card' : `${itemId}-url`}
      data-position={position}
      data-width-option={widthOption}
      data-max-width={maxWidth}
      data-show-button={showButton}
      data-campaign-title={campaignTitle || 'New title'}
      id={itemId}
      data-id={selector}
      style={{ ...commonStyle, ...formStyle }}
      className="comfolks-product-card"
      target="_blank"
      rel="noreferrer"
      title={fillTitle()}
    >
      <div style={imgWrStyles}>
        <img
          className="comfolks-product-card-img"
          src={isEditing ? `data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>${svgString}</svg>` : `${itemId}-photo`}
          style={imgStyles}
          alt="Product cart"
        />
      </div>
      <div className="comfolks-product-card-title" style={titleStyle}>
        {fillTitle()}
      </div>
      <div className="comfolks-product-card-sale" style={{ marginBottom: '5px' }}>
        <span className="comfolks-product-card-sale-label" style={oldPriceLabelStyle}>
          {isEditing ? '- 10 %' : `${itemId}-old-percent`}
        </span>
        <span className="comfolks-product-card-old-price" style={oldPriceStyle}>
          {isEditing ? '2 950 000 UZS' : `${itemId}-old-price`}
        </span>
      </div>
      <div className="comfolks-product-card-price" style={priceStyle}>
        {isEditing ? '2 750 000 UZS' : `${itemId}-price`}
      </div>
    </a>
  );
};
