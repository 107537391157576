import { handleActions } from 'redux-actions';

import {
    putBlackListUploads,
    putBlackListUploadsList,
    putBlackListSelectList,
} from '../actions/blackList';

const INITIAL_STATE = {
  list: [],
  total: 0,
  selectList: [],
  editedItem: null,
};
export default handleActions(
  {
    [putBlackListUploads]: (state, { payload }) => ({ ...state, editedItem: payload }),
    [putBlackListSelectList]: (state, { payload }) => ({ ...state, selectList: payload }),
    [putBlackListUploadsList]: (state, { payload: { list, total } }) => ({
      ...state,
      list,
      total,
    })
  },
  INITIAL_STATE,
);
