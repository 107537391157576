import React from "react";
import TwitterIcon from "../../components/svgIcons/TwitterIcon";
import FacebookIcon from "../../components/svgIcons/FacebookIcon";
import OkIcon from "../../components/svgIcons/OkIcon";
import VKIcon from "../../components/svgIcons/VKIcon";
import { analyticConstants } from "../../help/analytics";

export const Social = ({
  itemId,
  backgroundColor,
  borderWidth,
  borderColor,
  padding,
  color,
  twitterLink,
  facebookLink,
  enableTwitterLink,
  enableFacebookLink,
  enableOkLink,
  enableVkLink,
  vkLink,
  okLink,
  commonStyle,
}) => {
  const containerStyles = {
    ...commonStyle,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    flexDirection: "row",
  };

  const linkStyles = {
    display: "flex",
    position: "relative",
    backgroundColor,
    color,
    borderRadius: "50%",
    alignItems: "center",
    justifyContent: "center",
    width: "24px",
    height: "24px",
    padding,
    border: `${borderWidth} solid ${borderColor}`,
  };

  const iconStyles = {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  return (
    <div id={itemId} style={containerStyles}>
      {enableTwitterLink && (
        <a
          className="twitterLink"
          style={linkStyles}
          href={twitterLink}
          rel="noreferrer"
          target="_blank"
          {...analyticConstants.attributesToTrackClick}
        >
          <div style={iconStyles}>
            <TwitterIcon fillColor={color} />
          </div>
        </a>
      )}
      {enableFacebookLink && (
        <a
          className="facebookLink"
          style={linkStyles}
          href={facebookLink}
          rel="noreferrer"
          target="_blank"
          {...analyticConstants.attributesToTrackClick}
        >
          <div style={iconStyles}>
            <FacebookIcon fillColor={color} />
          </div>
        </a>
      )}
      {enableOkLink && (
        <a
          className="okLink"
          style={linkStyles}
          href={okLink}
          rel="noreferrer"
          target="_blank"
          {...analyticConstants.attributesToTrackClick}
        >
          <div style={iconStyles}>
            <OkIcon fillColor={color} />
          </div>
        </a>
      )}
      {enableVkLink && (
        <a
          className="vkLink"
          style={linkStyles}
          href={vkLink}
          rel="noreferrer"
          target="_blank"
          {...analyticConstants.attributesToTrackClick}
        >
          <div style={iconStyles}>
            <VKIcon fillColor={color} />
          </div>
        </a>
      )}
    </div>
  );
};
