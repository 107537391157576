import cond from 'lodash/fp/cond';
import equals from 'lodash/fp/equals';
import omitBy from 'lodash/fp/omitBy';
import isNil from 'lodash/isNil';

import {
  buttonWidthOptions,
  buttonActionTypes,
} from '../../constants/onsiteWidgets';
import { parseAction } from '../../help/actions';

import { parseStyles } from './commonParsers/commonStylesParser';

const omit = omitBy((v) => isNil(v) || v === '');

/*
  <div class="close">
    <button style="font-size:11px;color:#ffffff;background-color:#000000">Text</button>
  </div>

  <div class="link">
    <a href="www.link.to" style="color:#ffffff;background-color:#000000">Text</a>
  </div>
*/

const getWidthFieldOptionValue = cond([
  [equals('100%'), () => buttonWidthOptions.fullWidth],
  [equals('max-content'), () => buttonWidthOptions.dependingOnText],
  [(v) => v === '', () => buttonWidthOptions.dependingOnText],
  [() => true, () => buttonWidthOptions.fixed],
]);

const getCommonButtonFields = (el) => {
  const text = el.firstElementChild.innerHTML;
  const { color } = el.style;
  const { backgroundColor } = el.style;
  const { borderRadius } = el.style;
  const { fontSize } = el.style;
  const { lineHeight } = el.style;
  const { fontFamily } = el.style;
  const { fontStyle } = el.style;
  const { fontWeight } = el.style;
  const { textDecoration } = el.style;
  const { paddingTop } = el.style;
  const { paddingRight } = el.style;
  const { paddingBottom } = el.style;
  const { paddingLeft } = el.style;

  let { width } = el.style;
  if (width === 'inherit') {
    let parentEl = el.parentNode;
    while (parentEl && parentEl !== document.body) {
      const parentElWidth = parentEl.style.width;
      if (parentElWidth !== 'inherit') {
        width = parentElWidth;
        break;
      }
      parentEl = parentEl.parentNode;
    }
  }
  const widthOption = getWidthFieldOptionValue(width);
  const fixedWidth = width;

  // if (widthOption === buttonWidthOptions.fixed) {
  //   fixedWidth = width;
  // }

  return omit({
    text,
    color,
    backgroundColor,
    borderRadius,
    fontSize,
    lineHeight,
    paddingTop,
    paddingRight,
    paddingBottom,
    paddingLeft,
    fontFamily:
    fontFamily === '"Times New Roman"' ? 'Times New Roman' : fontFamily,
    fontStyle,
    fontWeight,
    textDecoration,
    widthOption,
    fixedWidth,
  });
};

const getLink = (href = '') => {
  if (href.match(new RegExp(`${window.origin}/sharing-edit-campaign/*`, 'i'))) {
    return href.replace(`${window.origin}/sharing-edit-campaign/`, '');
  }

  if (
    href.match(
      new RegExp(`${window.origin}/onsite-edit-onsite-campaign/*`, 'i'),
    )
  ) {
    return href.replace(`${window.origin}/onsite-edit-onsite-campaign/`, '');
  }

  if (href.match(new RegExp(`${window.origin}*`, 'i'))) {
    return href.replace(window.origin, '');
  }

  return href;
};

const linkButtonParser = (el) => {
  const linkButtonElement = el.getElementsByTagName('a').item(0);
  if (linkButtonElement) {
    const link = getLink(linkButtonElement.href);
    const openLinkInNewWindow = linkButtonElement.target === '_blank';
    const commonFields = getCommonButtonFields(linkButtonElement);

    return {
      type: 'button',
      ...commonFields,
      link,
      openLinkInNewWindow,
    };
  }

  return {};
};

const closeButtonParser = (el) => {
  const buttonElement = el.getElementsByTagName('button').item(0);
  if (buttonElement) {
    const commonFields = getCommonButtonFields(buttonElement);

    return {
      type: 'button',
      ...commonFields,
    };
  }

  return {};
};

const subscribeButtonParser = (el) => {
  const buttonElement = el.getElementsByTagName('button').item(0);
  if (buttonElement) {
    const commonFields = getCommonButtonFields(buttonElement);
    const text = buttonElement.innerHTML;

    return {
      type: 'button',
      ...commonFields,
      text,
    };
  }

  return {};
};

/**
 * @param {HTMLElement} el
 */
const defaultButtonParser = (el) => {
  const buttonElement = el.getElementsByTagName('button').item(0);
  const actionString = buttonElement.getAttribute('data-event-trigger');
  let action = {};
  if (actionString) {
    const actionS = JSON.parse(actionString);
    action = parseAction(actionS);
  }

  if (buttonElement) {
    const commonFields = getCommonButtonFields(buttonElement);

    return {
      type: 'button',
      ...action,
      ...commonFields,
    };
  }

  return {};
};

const actionToParserMapping = {
  [buttonActionTypes.linkType]: linkButtonParser,
  [buttonActionTypes.closeType]: closeButtonParser,
  [buttonActionTypes.subscribe]: subscribeButtonParser,
};

export const buttonParser = (el, id) => {
  const { action } = el.dataset;
  const buttonElement = el.getElementsByTagName('button').item(0) || el.getElementsByTagName('a').item(0);
  let parser = defaultButtonParser;
  if (action && action.startsWith(buttonActionTypes.widgetShowType)) {
    parser = closeButtonParser;
  } else {
    parser = actionToParserMapping[action] || defaultButtonParser;
  }
  const stylesProps = parseStyles(el.getElementsByTagName('button').item(0)?.style);
  const rotateDeg = buttonElement?.dataset.rotateDeg || '0deg';
  const horizontalTranslate = buttonElement?.dataset.horizontalTranslate || '0px';
  const verticalTranslate = buttonElement?.dataset.verticalTranslate || '0px';
  const rotateVerticalOrigin = buttonElement?.dataset.rotateVerticalOrigin || 'bottom';
  const rotateHorizontalOrigin = buttonElement?.dataset.rotateHorizontalOrigin || 'right';
  const animation = buttonElement?.dataset.animation || 'unset';

  if (parser) {
    const props = parser(el);
    return {
      ...props,
      ...stylesProps,
      id,
      action: props.action ? props.action : action,
      rotateDeg,
      horizontalTranslate,
      verticalTranslate,
      rotateVerticalOrigin,
      rotateHorizontalOrigin,
      animation,
    };
  }

  return {};
};
