import { createAction } from "redux-actions";

const FETCH_ANALYTICS_SEPARATE = "@analytics/FETCH_ANALYTICS_SEPARATE";
const FETCH_ANALYTICS = "@analytics/FETCH_ANALYTICS";
const PUT_ANALYTICS = "@analytics/PUT_ANALYTICS";
const PUT_ANALYTICS_SEPARATE = "@analytics/PUT_ANALYTICS_SEPARATE";
const FETCH_RETENTION_RATE_CHART = "@analytics/FETCH_RETENTION_RATE_CHART";
const PUT_RETENTION_RATE_CHART = "@analytics/PUT_RETENTION_RATE_CHART";
const FETCH_METRICS_FILTERS = "@analytics/FETCH_METRICS_FILTERS";
const PUT_METRICS_FILTERS = "@analytics/PUT_METRICS_FILTERS";
const FETCH_METRICS_DETAILS = "@analytics/FETCH_METRICS_DETAILS";
const FETCH_DOWNLOAD_REPORT = "@analytics/FETCH_DOWNLOAD_REPORT";
const PUT_METRICS_DETAILS = "@analytics/PUT_METRICS_DETAILS";
const FETCH_HEATMAP = "@analytics/FETCH_HEATMAP";
const PUT_HEATMAP = "@analytics/PUT_HEATMAP";

export const fetchAnalyticsSeparate = createAction(FETCH_ANALYTICS_SEPARATE);
export const fetchAnalytics = createAction(FETCH_ANALYTICS);
export const fetchRetentionRateChart = createAction(FETCH_RETENTION_RATE_CHART);
export const putAnalytics = createAction(PUT_ANALYTICS);
export const putAnalyticsSeparate = createAction(PUT_ANALYTICS_SEPARATE);
export const putRetentionRateChart = createAction(PUT_RETENTION_RATE_CHART);

export const fetchMetricsFilters = createAction(FETCH_METRICS_FILTERS);
export const putMetricsFilters = createAction(PUT_METRICS_FILTERS);

export const fetchMetricsDetails = createAction(FETCH_METRICS_DETAILS);
export const fetchDownloadReport = createAction(FETCH_DOWNLOAD_REPORT);
export const putMetricsDetails = createAction(PUT_METRICS_DETAILS);

export const fetchHeatmap = createAction(FETCH_HEATMAP);
export const putHeatmap = createAction(PUT_HEATMAP);
