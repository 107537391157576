import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components'
import bgStyles from '../help/bgStyles'
import {
  ADVICE,
  FULL,
  INTEGRATED,
  isHorizontal, isPositionedOnLeft,
  NOTIFICATION, PULLED,
  WIDGET_LAYOUT_TYPES
} from '../constants/onsiteWidgets'
import { fromPx } from "../help/format";

const combineBorderProperties = (borderColor = 'black', borderWidth = '0') =>
  borderColor && borderWidth
    ? {
      boxShadow: `0 0 0 ${borderWidth} ${borderColor}`
    }
    : {}

const triggerButtonPulse = `
  @keyframes triggerButtonPulse {
    0% {
      opacity: 0
    }
    49% {
      opacity: 0
    }
    50% {
      -webkit-transform: scale(1, 1);
      transform: scale(1, 1);
      opacity: 1
    }
    100% {
      -webkit-transform: scale(2, 2);
      transform: scale(2, 2);
      opacity: 0
    }
  }
`

const triggerButtonBump = `
  @keyframes triggerButtonBump {
    50% {
      -webkit-transform: scale(1, 1);
      transform: scale(1, 1);
    }
    75% {
      -webkit-transform: scale(1.1, 1.1);
      transform: scale(1.1, 1.1);
    }
  }
`

const hideUntilTriggered = `
  .cfreset .not-triggered {
    visibility: hidden;
  }
  
  .cfreset .triggered {
    visibility: visible;
  }
`

const showNotificationScript = `
const initTrigger = (container) => {
  var triggerButton = container.querySelector('#notification_button')
  if (triggerButton) {
    triggerButton.addEventListener('click', () => {
      triggerButton.nextElementSibling.classList.remove('not-triggered')
    })
  }
}
const campaignRootElements = document.querySelectorAll('[data-campaign-id][data-widget-id]');
campaignRootElements.forEach((campaignRootElement) => {
  const companyId = campaignRootElement.dataset.campaignId
  const cfModalShadowRoot = campaignRootElement.shadowRoot.querySelector('.cf-modal-' + companyId);
  initTrigger(cfModalShadowRoot);
});
`

const useStyles = makeStyles((theme) => ({
  inner: ({ flexDirection, justifyContent, twoColumned }) => ({
    display: 'flex',
    width: '100%',
    flexDirection,
    justifyContent,
    [theme.breakpoints.up('sm')]: {
      width: twoColumned ? '48%' : '100%',
    }
  })
}));

export const Column = ({ children, flexDirection, justifyContent, alignItems, twoColumned, innerRef }) => {

  const classes = useStyles({ flexDirection, justifyContent, twoColumned });

  return (
    <div ref={innerRef} className={classes.inner}>
      {children}
    </div>
  )
}

const getSizeProperties = (horizontal, sizes) => {
  if (horizontal) {
    return { minWidth: sizes?.width || '80%', minHeight: sizes?.height || '80%' }
  }

  return { maxWidth: sizes?.width || '80%', minHeight: sizes?.height || '80%', width: '100%' }
}

export const LayoutComponent = ({
  widgetType,
  sizeType,
  sizes,
  timeClose,
  backgroundColor,
  buttonColor,
  buttonIcon,
  buttonSize,
  backgroundImageUrl,
  backgroundOption,
  padding,
  borderRadius,
  borderColor,
  borderWidth,
  specificWidgetTypeProperties,
  width,
  height,
  position,
  children,
  isEditing,
  layoutId,
  isExport,
  layoutProperties,
}) => {
  const isOnLeft = isPositionedOnLeft(position)

  const resolvedSizeType = useMemo(
    () => widgetType === WIDGET_LAYOUT_TYPES.CAROUSEL ?
      specificWidgetTypeProperties.carousel.carouselIntegratedOptions : sizeType,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [widgetType]
  )

  const formattedButtonIcon = useMemo(() => {
    if (!buttonIcon) {
      return undefined
    }
    if (typeof buttonIcon === 'string') {
      return buttonIcon
    }
    return `url(${buttonIcon.url})`
  }, [buttonIcon])
  const horizontal = isHorizontal(resolvedSizeType)
  const sizeProperties = getSizeProperties(horizontal, sizes)
  const outerLayoutStyle = useMemo(
    () => {
      const base = {
        display: 'flex',
        justifyContent: 'space-between',
        boxSizing: 'border-box',
        flexWrap: 'wrap',
        padding,
        borderRadius,
        ...sizeProperties
      }

      let borderOffset

      if (buttonSize) {
        borderOffset = fromPx(buttonSize) + 40
      }

      if (borderOffset) {
        if (isOnLeft) {
          base.left = borderOffset
        } else {
          base.right = borderOffset
        }
      }

      if (sizeType === WIDGET_LAYOUT_TYPES.CAROUSEL) {
        return base
      }

      return {
        ...base,
        ...bgStyles({
          color: backgroundColor,
          option: backgroundOption,
          image: backgroundImageUrl
        }),
        ...combineBorderProperties(borderColor, borderWidth),

      }

    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      backgroundColor,
      backgroundImageUrl,
      backgroundOption,
      borderColor,
      borderRadius,
      borderWidth,
      buttonSize,
      padding,
      sizeProperties,
    ]
  )


  const integrationSizeStyles = useMemo(
    () => ({
      maxWidth: sizeType === WIDGET_LAYOUT_TYPES.CAROUSEL ? '100%' : width || sizeProperties.maxWidth,
      // maxWidth: width || sizeProperties.maxWidth,
      minHeight: height || sizeProperties.minHeight
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [width, height, sizeProperties]
  )

  const types = [INTEGRATED, PULLED]

  const style = types.includes(resolvedSizeType) ? { ...outerLayoutStyle, ...integrationSizeStyles } : outerLayoutStyle

  const triggerButtonWrapperStyle = useMemo(() => {
    return {
      animation: 'triggerButtonPulse infinite 1.5s',
      border: `1px solid black`,
      borderColor: buttonColor,
      borderRadius: '50%',
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
    }
  }, [buttonColor])

  const notificationButtonStyle = useMemo(() => {
    return {
      animation: 'triggerButtonBump infinite 1.5s',
      width: buttonSize || 63,
      height: buttonSize || 63,
      backgroundColor: buttonColor,
      backgroundImage: formattedButtonIcon,
      boxShadow: '0 1px 7px 1px rgba(0, 0, 0, 0.1)',
      borderRadius: '50%',
      overflow: 'hidden',
      backgroundSize: 'contain',
      ...(isOnLeft ? { left: 20 } : { right: 20 })
    }
  }, [buttonColor, buttonSize, formattedButtonIcon])

  const className = useMemo(() => {
    if (resolvedSizeType === FULL) {
      return position + ' full-widget'
    }

    if (resolvedSizeType === ADVICE || resolvedSizeType === NOTIFICATION) {
      return `${position || ''} not-triggered`
    }

    return position
  }, [position, resolvedSizeType])




  const closeElement = useMemo(() => {
    if (resolvedSizeType === INTEGRATED) {
      return null
    }

    if (timeClose === 'close') {
      return (
        <div className='cf_close-wrapper'>
          <div className='cf_move-block' id='close-loader' />
          <div className='cf_close-wrapper-text'>
            <p className='cf_close-text'>
              Close after <span className='cf_close-count-time' id='close-timer-text' /> sec.
            </p>
          </div>
          <div className='cf_close-button cf_button-modal__close' />
        </div>
      )
    }
    return <div className='cf_close-button cf_button-modal__close' />
  }, [resolvedSizeType, timeClose])






  return (
    <>
      {(resolvedSizeType === ADVICE || resolvedSizeType === NOTIFICATION) && (
        <>
          <style children={[hideUntilTriggered, triggerButtonBump, triggerButtonPulse]} />
          <div
            id='notification_button'
            style={{
              position: isEditing ? 'relative' : 'absolute',
              ...(isEditing ? { marginRight: '20px', alignSelf: 'baseline' } : {})
            }}
            className={position}
          >
            <div style={triggerButtonWrapperStyle} />
            <div style={notificationButtonStyle} />
          </div>
          <script dangerouslySetInnerHTML={{ __html: showNotificationScript }} />
        </>
      )}

      <div id={resolvedSizeType} className={className} style={style}>
        {closeElement}
        {children}
      </div>
    </>
  )
}
