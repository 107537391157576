import api from '../api/media';
import { put, call, takeLatest } from 'redux-saga/effects';
import { media as actions} from '../redux/actions'
import {MEDIA_TYPES} from "../constants/media";

function* handleAddMedia({payload}) {
  try {
    if(payload.type === MEDIA_TYPES.VIDEO) {
      yield call(api.addNewVideo, payload.payload);
    } else {
      yield call(api.addNewMedia, payload.payload);
    }
    yield put(actions.fetchMedia({skip: 0, take: 10, type: payload.type}));
  } catch (error) {}
}

function* getAllMediaFiles(payload) {
  try {
    const data = yield call(api.fetchGetAllMedia, payload.payload);
    yield put(actions.getMedia(data));
  }
  catch (error) {}
}

export default function* watcher() {
  yield takeLatest(actions.fetchMedia.toString(), getAllMediaFiles);
  yield takeLatest(actions.addMedia1.toString(), handleAddMedia);
}
