import {
  Box,
  FormControl,
  FormHelperText,
  FormControlLabel,
  Checkbox,
  Switch,
  FormLabel,
  InputLabel,
  RadioGroup as MuiRadioGroup,
  Select,
  TextField as MuiTextField,
  Slider,
  Tabs,
  InputAdornment,
} from '@material-ui/core';
import { Autocomplete, ToggleButtonGroup } from '@material-ui/lab';
import { KeyboardDatePicker } from '@material-ui/pickers';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import React, { useRef, useState, useEffect } from 'react';

import { convertToNumber } from '../../help/normalizers';

export { default as ColorField } from './ColorField';
export { default as UploadImageField } from './UploadImageField';

export const FromHelper = ({ touched, error }) => {
  if (!(touched && error)) {
    return null;
  }
  return <FormHelperText>{touched && error}</FormHelperText>;
};

const getLetterCount = (maxLetters, input) => maxLetters - get(input, 'value.length', 0) || '0';

export const TextField = ({
  label,
  input,
  variant = 'outlined',
  margin = 'normal',
  showLetterCount = false,
  maxLetters = 0,
  validHint = null,
  meta: {
    asyncValidating, touched, error, valid,
  },
  ...custom
}) => (
  <MuiTextField
    label={label}
    placeholder={label}
    error={Boolean(touched && error) || (showLetterCount && error && error !== 'Required')}
    helperText={
      (showLetterCount
          && ((error !== 'Required' ? error : touched && error) || getLetterCount(maxLetters, input)))
        || (!asyncValidating && valid && validHint)
        || (touched && error)
    }
    variant={variant}
    margin={margin}
    {...input}
    {...custom}
  />
);

export const SelectField = ({
  id,
  input,
  label,
  variant = 'outlined',
  margin = 'normal',
  meta: { touched, error },
  children,
  required,
  name,
  fullWidth,
  ...custom
}) => {
  const selectRef = useRef(null);
  const [labelWidth, setLabelWidth] = useState(0);
  useEffect(() => {
    setLabelWidth(selectRef.current.offsetWidth);
  }, []);

  return (
    <FormControl
      required={required}
      fullWidth={fullWidth}
      error={Boolean(touched && error)}
      component="div"
      variant={variant}
      margin={margin}
    >
      <InputLabel ref={selectRef} htmlFor={id}>
        {label}
      </InputLabel>
      <Select
        {...input}
        {...custom}
        labelWidth={labelWidth}
        inputProps={{
          name,
          id,
        }}
      >
        {children}
      </Select>
      {FromHelper({ touched, error })}
    </FormControl>
  );
};

export const AutocompleteField = ({
  input,
  meta, // add meta here
  callBack,
  label,
  ...others
}) => {
  return (
    <Autocomplete
      {...input}
      {...others}
      onChange={(event, value) => {
        if (value) {
          input.onChange(value.id);
          callBack(value.id);
        } else {
          input.onChange(null);
          callBack(null);
        }
      }}
      onBlur={() => input.onBlur(input.value)}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          meta={meta}
          error={!!(meta.touched && meta.error)}
          helperText={meta.touched && meta.error ? meta.error : null}
        />
      )}
      disabled={meta.submitting}
    />
  );
};

export const RadioGroup = ({
  input, label, id, required, children, ...rest
}) => (
  <FormControl component="div" required={required}>
    {label && (
      <FormLabel component="label" htmlFor={id} required={required}>
        {label}
      </FormLabel>
    )}
    <MuiRadioGroup id={id} {...input} row {...rest}>
      {children}
    </MuiRadioGroup>
  </FormControl>
);

export const SwitchControl = ({
  input, label, id, ...rest
}) => (
  <FormControlLabel control={<Switch checked={Boolean(input.value)} {...input} {...rest} />} label={label} />
);

export const DateField = ({
  input,
  meta,
  format = 'DD-MM-YYYY',
  margin = 'dense',
  variant = 'inline',
  disableToolbar = true,
  inputVariant = 'outlined',
  disablePast = true,
  initialDate = '',
  ...rest
}) => {
  useEffect(() => {
    if (isNil(input.value)) {
      input.onChange(new Date(initialDate));
    }
  }, [input, initialDate]);

  return (
    <KeyboardDatePicker
      disableToolbar={disableToolbar}
      variant={variant}
      format={format}
      margin={margin}
      value={input.value}
      onChange={input.onChange}
      inputVariant={inputVariant}
      disablePast={disablePast}
      InputLabelProps={{
        shrink: true,
      }}
      {...rest}
    />
  );
};

export const SliderField = ({
  id, label, step = 1, input, max, min, track,
}) => (
  <Box width={1}>
    {label && <FormLabel htmlFor={id}>{label}</FormLabel>}
    <Slider
      id={id}
      min={min}
      step={step}
      max={max}
      marks={[
        { value: min, label: min },
        { value: max, label: max },
      ]}
      valueLabelDisplay="auto"
      value={convertToNumber(input.value)}
      onChange={(event, value) => {
        input.onChange(value);
      }}
      onChangeCommitted={(event, value) => {
        input.onChange(value);
      }}
      track={track}
    />
  </Box>
);

export const CheckboxField = ({ input, label, disabled }) => (label ? (
  <FormControlLabel
    control={
      <Checkbox checked={!!input.value} onChange={input.onChange} disabled={disabled} />
    }
    label={label}
  />
) : (
  <Checkbox checked={!!input.value} onChange={input.onChange} disabled={disabled} />
));

export const ToggleButtonGroupField = ({ input, children, ...other }) => (
  <ToggleButtonGroup
    value={input.value}
    onChange={(_, value) => {
      if (value !== null) {
        input.onChange(value);
      }
    }}
    exclusive
    {...other}
  >
    {children}
  </ToggleButtonGroup>
);

export const TabsField = ({ input, children, tabProps }) => (
  <Tabs
    value={input.value}
    onChange={(_, value) => {
      input.onChange(value);
    }}
    {...tabProps}
  >
    {children}
  </Tabs>
);

export const PercentField = (props) => (
  <TextField
    {...props}
    InputProps={{
      endAdornment: <InputAdornment position="end">%</InputAdornment>,
    }}
  />
);

export const PixelField = (props) => (
  <TextField
    {...props}
    InputProps={{
      endAdornment: <InputAdornment position="end">px</InputAdornment>,
    }}
  />
);

export const FileInput = (props) => {
  const {
    input: { value, onChange, ...inputProps },
    meta,
    ...restProps
  } = props;

  const handleChange = (event) => {
    onChange(event.target.files[0]);
  };

  return (
    <TextField
      inputProps={{
        ...inputProps,
        type: 'file',
        onChange: handleChange,
      }}
      meta={meta}
      {...restProps}
    />
  );
};
