import set from 'lodash/set';
import {
  change,
  startAsyncValidation,
  stopAsyncValidation,
  touch,
} from 'redux-form';
import {
  call, put, takeLatest, delay, cancel,
} from 'redux-saga/effects';

import { email as api } from '../api';
import { fileToB64 } from '../help';
import {
  mapFormValuesToEmail,
  mapEmailToFormValues,
} from '../mappers/emailMappers';
import { email as emailActions, snackbar as snackbarActions } from '../redux/actions';

function* createEmail({ payload: { values } }) {
  try {
    yield put(emailActions.createEmailRoutine.request());

    const [transformedValues, file, filename] = mapFormValuesToEmail(values);
    const { id } = transformedValues;
    const data = new FormData();

    data.append('form', JSON.stringify(transformedValues));
    if (file) {
      data.append('promocodes', file, filename);
    }

    const email = yield call(api.postEmail, id, data);

    yield put(emailActions.createEmailRoutine.success(email));
  } catch (error) {
    yield put(emailActions.createEmailRoutine.failure(error));
  } finally {
    yield put(emailActions.createEmailRoutine.fulfill());
  }
}

const STATUSES = {
  active: 1,
  onHold: 2,
  archived: 3,
};

const INVERT_STATUSES = {
  1: 'active',
  2: 'onHold',
  3: 'archived',
};

function* activateEmail({ payload: { id, pageView } }) {
  try {
    yield call(api.changeStatusEmail, id, STATUSES.active);
    yield put(
      emailActions.fetchEmailList({ page: 1, perPage: 10, status: pageView }),
    );
  } catch (error) {
    const message = error.request && error.request.status === 403 ? 'You cannot set Active status!' : 'Change status error occurred!';
    yield put(
      snackbarActions.enqueueSnackbar({
        message,
        options: { variant: 'error' },
      }),
    );
  }
}

function* holdEmail({ payload: { id, pageView } }) {
  try {
    yield call(api.changeStatusEmail, id, STATUSES.onHold);
    yield put(
      emailActions.fetchEmailList({ page: 1, perPage: 10, status: pageView }),
    );
  } catch (error) {}
}

function* fetchEmail({ payload: emailId }) {
  try {
    const email = yield call(api.fetchEmail, emailId);

    yield put(emailActions.putEmail(mapEmailToFormValues(email)));
  } catch (error) {}
}

const mapStatus = (list) => list.map((item) => ({ ...item, status: INVERT_STATUSES[item.status] }));

function* fetchEmailList({
  payload: {
    page, perPage, status, search,
  },
}) {
  try {
    const [list, total] = yield call(api.fetchEmailList, {
      page,
      perPage,
      status: STATUSES[status],
      search,
    });

    yield put(emailActions.putEmailList({ list: mapStatus(list), total }));
  } catch (error) {}
}

function* fetchVisitors({ payload: { fields, formName } }) {
  try {
    yield delay(1000);
    const result = yield call(api.fetchVisitors, fields);

    yield put(emailActions.putVisitors(result));
  } catch (error) {}
}

function* fetchCurrentCampaignNames({ payload: name = '' }) {
  try {
    const result = yield call(api.fetchCampaignNames, name);

    yield put(emailActions.putCampaignNames(result));
  } catch (error) {}
}

function* validateCampaignName({
  payload: {
    name, id, resolve, reject,
  },
}) {
  try {
    if (name) {
      yield delay(500);

      const result = yield call(api.validateCampaignName, name, id);
      if (result) {
        resolve();
      } else {
        reject({ campaign_name: 'This name is already used' });
      }
    }
  } catch (error) {
    reject();
  }
}

function* fetchDownloadStat({payload}) {
  try {
    yield call(api.fetchDownloadStat, payload);
  } catch (error) {
    console.error(error);
  }
}

function* uploadPromoCodesFile({
  payload: {
    file, formName, field, fileFiled,
  },
}) {
  try {
    yield put(startAsyncValidation(formName));
    const { success, count } = yield call(api.sendPromoCodesFile, file);

    if (!success) {
      yield put(touch(formName, field));
      yield put(
        stopAsyncValidation(
          formName,
          set({}, field, 'Error with file uploading'),
        ),
      );
      yield cancel();
    }

    const base64File = yield call(fileToB64, file);

    yield put(change(formName, field, count, true));
    yield put(
      change(formName, fileFiled, { file: base64File, name: file.name }, true),
    );
    yield put(stopAsyncValidation(formName));
  } catch (error) {
    yield put(touch(formName, field));
    yield put(
      stopAsyncValidation(formName, set({}, field, 'Error with file uploading')),
    );
  }
}

function* postTemplate({ payload: { template, id } }) {
  try {
    yield call(api.postTemplate, template, id);
  } catch (error) {}
}

function* deleteTemplate({ payload: id }) {
  try {
    yield call(api.deleteTemplate, id);
  } catch (error) {}
}

function* fetchTemplateList({ payload: type }) {
  try {
    const templates = yield call(api.fetchTemplateList, type);
    yield put(emailActions.putTemplateList(templates));
  } catch (error) {}
}

export default function* watcher() {
  yield takeLatest(emailActions.createEmailRoutine.TRIGGER, createEmail);
  yield takeLatest(emailActions.activateEmail.toString(), activateEmail);
  yield takeLatest(emailActions.holdEmail.toString(), holdEmail);
  yield takeLatest(emailActions.fetchEmail.toString(), fetchEmail);
  yield takeLatest(emailActions.postTemplate.toString(), postTemplate);
  yield takeLatest(emailActions.deleteTemplate.toString(), deleteTemplate);
  yield takeLatest(emailActions.fetchTemplateList.toString(), fetchTemplateList);
  yield takeLatest(emailActions.fetchDownloadStat.toString(), fetchDownloadStat);
  yield takeLatest(
    emailActions.fetchEmailList.toString(),
    fetchEmailList,
  );
  yield takeLatest(emailActions.fetchVisitors.toString(), fetchVisitors);
  yield takeLatest(
    emailActions.fetchCurrentCampaignNames.toString(),
    fetchCurrentCampaignNames,
  );
  yield takeLatest(
    emailActions.validateCampaignName.toString(),
    validateCampaignName,
  );
  yield takeLatest(
    emailActions.uploadPromoCodesFile.toString(),
    uploadPromoCodesFile,
  );
}
