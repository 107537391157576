/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

const OffersIcon = ({ fillColor, ...props }) => (
  <svg width={16} height={15.977} {...props}>
    <path
      d="M12.5,0.5h-9C2.672,0.5,2,1.172,2,2v10c0,0.828,0.672,1.5,1.5,1.5h9c0.828,0,1.5-0.672,1.5-1.5V2C14,1.172,13.328,0.5,12.5,0.5z M13,12c0,0.275-0.225,0.5-0.5,0.5h-9C3.225,12.5,3,12.275,3,12V2c0-0.275,0.225-0.5,0.5-0.5h9c0.275,0,0.5,0.225,0.5,0.5V12z M7.5,3C6.121,3,5,4.121,5,5.5S6.121,8,7.5,8S10,6.879,10,5.5S8.879,3,7.5,3z M7.5,7C6.672,7,6,6.328,6,5.5S6.672,4,7.5,4S9,4.672,9,5.5S8.328,7,7.5,7z"
      fill={fillColor}
    />
  </svg>
);

OffersIcon.propTypes = {
  fillColor: PropTypes.string.isRequired,
};

export default OffersIcon;
