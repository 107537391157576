import React, { useEffect } from "react";
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { StringParam, useQueryParams } from "use-query-params";
import { useDispatch } from 'react-redux'
import Menu from '../Menu';
import {USER_LOGIN_SUCCESS} from "../../redux/actionTypes";
import history from '../../navigation/history';
import {user} from "../../redux/actions";
import {ROUTE_OTP} from "../../constants";

function PrivateRoute({ isLoggedIn, path, moduleId, ping, ...props }) {
  const { allowedModules } = props
  
  const isOtpRoute = path.startsWith(ROUTE_OTP);

  const dispatch = useDispatch();
  // http://my.comfolks.test/analytics-all?apiKey=84gGbKEh&token=296|DqqctTcHBavSy3vF1raQ2qfsmqinyr1AhRFbNlwd&dateFrom=2023-06-01&dateTo=2023-07-01
  // http://my.comfolks.test/analytics-metrics?apiKey=84gGbKEh&token=296|DqqctTcHBavSy3vF1raQ2qfsmqinyr1AhRFbNlwd&dateFrom=2023-10-02%2000%3A00%3A00&dateTo=2023-11-02%2023%3A59%3A59&fields=%5B%22uniq_customers%22%2C%22uniq_sessions%22%2C%22count_sent_all%22%2C%22count_seen_all%22%2C%22count_click_all%22%2C%22count_subscriptions_all_success%22%2C%22count_subscriptions_all_error%22%5D&filters=%7B%22group_by%22%3A%5B%22all%22%5D%2C%22onsite_campaign_id%22%3A%5B%225eb0b008-2459-4e76-86eb-5bda28f8a577%22%2C%22c895f575-9cc6-4abc-905f-b3b34c6b3f7b%22%5D%7D&page=1&perPage=10
  const [query, setQuery] = useQueryParams({
    apiKey: StringParam,
    token: StringParam,
    dateFrom: StringParam,
    dateTo: StringParam,
    heatmapOff: StringParam,
    fields: StringParam,
    filters: StringParam,
    page: StringParam,
    perPage: StringParam,
  });
  useEffect(() => {
    if (query.apiKey && query.token && query.dateFrom && query.dateTo) {
      dispatch({ type: USER_LOGIN_SUCCESS, payload: query })
      let search = '';
      for (const [key, value] of Object.entries(query)) {
        if (key === 'apiKey' || key === 'token' || !value) {
          continue;
        }
        search += '&' + key + '=' + value;
      }
      history.push({
        pathname: path,
        search: '?' + search,
      })
    } else if (!isOtpRoute) {
      console.log('ping start', path);
      ping();
    }
  }, [dispatch, query, ping, path]);

  if (isLoggedIn && path !== '/authorization') {
    if(moduleId && !allowedModules.includes(moduleId)) {
     // return <Redirect to={ROUTE_ERROR} />
     // todo поменять getAllowedModules
    }
    return (
      <>
        {!isOtpRoute && <Menu />}
        <Route path={path} {...props} />
      </>
    );
  }
  if (isLoggedIn && path === '/authorization') {
    return <Redirect to="/" />;
  }
  return <Redirect to="/authorization" />;
}

PrivateRoute.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  path: PropTypes.string.isRequired,
  moduleId: PropTypes.number
};

const mapStateToProps = ({ user = {} }) => ({ isLoggedIn: user.isLoggedIn, allowedModules: user.allowedModules});
const mapDispatchToProps = {
  ping: user.ping,
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
