import get from 'lodash/fp/get';
import cond from 'lodash/fp/cond';
import equals from 'lodash/fp/equals';
import T from 'lodash/fp/T';

export const eq = type => target => equals(type, get('type', target));

// TODO: incorrect name
export const convertToSecond = {
  seconds: v => v,
  minutes: v => v * 60,
  hours: v => v * 3600,
  days: v => v * 86400
};

export const convertFromSecond = cond([
  [v => v % 86400 === 0, v => ({ value: v / 86400, dimension: 'days' })],
  [v => v % 3600 === 0, v => ({ value: v / 3600, dimension: 'hours' })],
  [v => v % 60 === 0, v => ({ value: v / 60, dimension: 'minutes' })],
  [T, v => ({ value: v, dimension: 'seconds' })]
]);
