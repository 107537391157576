import compose from 'lodash/fp/compose';
import get from 'lodash/fp/get';
import omit from 'lodash/fp/omit';
import isNil from 'lodash/fp/isNil';
import { b64ToBlob } from '../help';
import { transformTarget, transformToTarget } from './targetMappers';
import { transformTrigger, transformToTrigger } from './triggerMappers';
import { convertWidget, parseWidget } from '../help/widgets';
import { OnsiteFormHelper } from '../help/formSchema';

export const mapFormValuesToSocialSharing = fields => {
  const fileFields = get('bonus.promoCodesFile', fields);

  const widgetsList = [...OnsiteFormHelper.getWidgets(fields)]
  const editedWidget = OnsiteFormHelper.getEditedWidget(fields)
  const editedWidgetIndex = widgetsList.findIndex((widget) => widget.id === editedWidget.id)

  if (editedWidgetIndex >= 0) {
    widgetsList.splice(editedWidgetIndex, 1, editedWidget)
  }

  // eslint-disable-next-line array-callback-return
  widgetsList.every(widget => {
    widget.settings = {
      ...(widget.settings || {}),
      media_post: {
        title: fields.post.title,
        text: fields.post.text
      }
    }
  })

  const transformedFields = compose([
    omit(['campaignName', 'bonus.promoCodesFile', 'post']),
    v => ({
      ...v,
      bonus: {
        ...v.bonus,
        mode:
          v.bonus.mode === 'uploadedDiscountList'
            ? 'currentDiscountList'
            : v.bonus.mode,
        currentDiscountList:
          v.bonus.mode === 'uploadedDiscountList'
            ? v.campaign_name
            : v.bonus.currentDiscountList
      }
    }),
    v => ({
      ...v,
      widgets: widgetsList.map(convertWidget)
    }),
    v => ({
      ...v,
      targets: {
        ...v.targets,
        targets:
          v.targets && v.targets.targets
            ? v.targets.targets.map(transformTarget)
            : []
      }
    }),
    v => ({ ...v, trigger: transformTrigger(v.trigger) })
  ])(fields);

  if (fileFields) {
    const filename = fileFields.name;
    const fileBlob = b64ToBlob(fileFields.file);

    return [transformedFields, fileBlob, filename];
  }

  return [transformedFields];
};

export const mapCampaignOnsiteToTableRow = wp => {
  return {
    ...wp,
    delivered: wp.delivered || 0,
    text: wp.text || 'No text',
    sentTo: wp.sentTo || 0,
    seen: `${wp.seen[0] || 0} (${wp.seen[1] || 0}%)`,
    ctr: `${wp.ctr[0] || 0} (${wp.ctr[1] || 0}%)`,
    unsubscribed: `${wp.unsubscribed[0] || 0} (${wp.unsubscribed[1] || 0}%)`
  };
};

export const mapSocialSharingToFormValues = wp => {
  const firstWidget = wp.widgets[0]
  const firstWidgetWithMediaPost = wp.widgets.find(w => w?.settings?.media_post)
  const mediaPost = firstWidgetWithMediaPost?.settings?.media_post || { title: '', text: '' }

  const result = compose([
    v => ({
      ...v,
      targets: {
        match_all: v.targets.match_all,
        targets: v.targets.targets.filter(v => !isNil(v)).map(transformToTarget)
      },
      trigger: {
        ...transformToTrigger(v.trigger)
      },
      post: mediaPost,
      widgets: v.widgets.map(parseWidget),
      editedWidget: parseWidget(firstWidget)
    })
  ])(wp);

  return result;
};
