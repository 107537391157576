/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

const OnsiteIcon = ({ fillColor, ...props }) => (
  <svg fill={fillColor} width={18} height={18} {...props}>
    <path d="M 8.992188 1.5 C 4.851562 1.5 1.5 4.859375 1.5 9 C 1.5 13.140625 4.851562 16.5 8.992188 16.5 C 13.140625 16.5 16.5 13.140625 16.5 9 C 16.5 4.859375 13.140625 1.5 8.992188 1.5 Z M 14.191406 6 L 11.976562 6 C 11.738281 5.0625 11.390625 4.164062 10.941406 3.328125 C 12.324219 3.800781 13.46875 4.761719 14.191406 6 Z M 9 3.03125 C 9.621094 3.929688 10.109375 4.925781 10.433594 6 L 7.566406 6 C 7.890625 4.925781 8.378906 3.929688 9 3.03125 Z M 3.195312 10.5 C 3.074219 10.019531 3 9.515625 3 9 C 3 8.484375 3.074219 7.980469 3.195312 7.5 L 5.730469 7.5 C 5.671875 7.996094 5.625 8.488281 5.625 9 C 5.625 9.511719 5.671875 10.003906 5.730469 10.5 Z M 3.808594 12 L 6.023438 12 C 6.261719 12.9375 6.609375 13.835938 7.058594 14.671875 C 5.675781 14.199219 4.53125 13.246094 3.808594 12 Z M 6.023438 6 L 3.808594 6 C 4.53125 4.753906 5.675781 3.800781 7.058594 3.328125 C 6.609375 4.164062 6.261719 5.0625 6.023438 6 Z M 9 14.96875 C 8.378906 14.070312 7.890625 13.074219 7.566406 12 L 10.433594 12 C 10.109375 13.074219 9.621094 14.070312 9 14.96875 Z M 10.753906 10.5 L 7.246094 10.5 C 7.175781 10.003906 7.125 9.511719 7.125 9 C 7.125 8.488281 7.175781 7.988281 7.246094 7.5 L 10.753906 7.5 C 10.824219 7.988281 10.875 8.488281 10.875 9 C 10.875 9.511719 10.824219 10.003906 10.753906 10.5 Z M 10.941406 14.671875 C 11.390625 13.835938 11.738281 12.9375 11.976562 12 L 14.191406 12 C 13.46875 13.238281 12.324219 14.199219 10.941406 14.671875 Z M 12.269531 10.5 C 12.328125 10.003906 12.375 9.511719 12.375 9 C 12.375 8.488281 12.328125 7.996094 12.269531 7.5 L 14.804688 7.5 C 14.925781 7.980469 15 8.484375 15 9 C 15 9.515625 14.925781 10.019531 14.804688 10.5 Z M 12.269531 10.5 "/>
  </svg>
);

OnsiteIcon.propTypes = {
  fillColor: PropTypes.string.isRequired,
};

export default OnsiteIcon;
