import set from 'lodash/set';
import { call, put, takeLatest, delay, cancel } from 'redux-saga/effects';
import {
  change,
  startAsyncValidation,
  stopAsyncValidation,
  touch
} from 'redux-form';
import {snackbar as snackbarActions, webPush as webPushActions} from '../redux/actions';
import { webPush as api } from '../api';
import { fileToB64 } from '../help';

import {
  mapFormValuesToWebPush,
  mapWebPushToFormValues
} from '../mappers/webPushMappers';

function* createWebPush({ payload: { values } }) {
  try {
    yield put(webPushActions.createWebPushRoutine.request());

    const [transformedValues, file, filename] = mapFormValuesToWebPush(values);
    const { id } = transformedValues;
    const data = new FormData();

    data.append('form', JSON.stringify(transformedValues));
    if (file) {
      data.append('promocodes', file, filename);
    }

    const webPush = yield call(api.postWebPush, id, data);

    yield put(webPushActions.createWebPushRoutine.success(webPush));
  } catch (error) {
    yield put(webPushActions.createWebPushRoutine.failure(error));
  } finally {
    yield put(webPushActions.createWebPushRoutine.fulfill());
  }
}

const STATUSES = {
  active: 1,
  onHold: 2,
  archived: 3
};

const INVERT_STATUSES = {
  1: 'active',
  2: 'onHold',
  3: 'archived'
};

function* activateWebPush({ payload: { id, pageView } }) {
  try {
    yield call(api.changeStatusWebPush, id, STATUSES.active);
    yield put(
      webPushActions.fetchWebPushList({ page: 1, perPage: 10, status: pageView })
    );
  } catch (error) {
    const message = error.request && error.request.status === 403 ? 'You cannot set Active status!' : 'Change status error occurred!'
    yield put(
        snackbarActions.enqueueSnackbar({
          message,
          options: { variant: 'error' }
        })
    );
  }
}

function* holdWebPush({ payload: { id, pageView } }) {
  try {
    yield call(api.changeStatusWebPush, id, STATUSES.onHold);
    yield put(
      webPushActions.fetchWebPushList({ page: 1, perPage: 10, status: pageView })
    );
  } catch (error) {}
}

function* fetchWebPush({ payload: webPushId }) {
  try {
    const webPush = yield call(api.fetchWebPush, webPushId);

    yield put(webPushActions.putWebPush(mapWebPushToFormValues(webPush)));
  } catch (error) {}
}

const mapStatus = list =>
  list.map(item => ({ ...item, status: INVERT_STATUSES[item.status] }));

function* fetchWebPushList({ payload: { page, perPage, status, search } }) {
  try {
    const [list, total] = yield call(api.fetchWebPushList, {
      page,
      perPage,
      status: STATUSES[status],
      search: search
    });

    yield put(webPushActions.putWebPushList({ list: mapStatus(list), total }));
  } catch (error) {}
}

function* fetchVisitors({ payload: { fields, formName } }) {
  try {
    yield delay(1000);
    const result = yield call(api.fetchVisitors, fields);

    yield put(webPushActions.putVisitors(result));
  } catch (error) {}
}

function* fetchCurrentCampaignNames({ payload: name = '' }) {
  try {
    const result = yield call(api.fetchCampaignNames, name);

    yield put(webPushActions.putCampaignNames(result));
  } catch (error) {}
}

function* validateCampaignName({ payload: { name, id, resolve, reject } }) {
  try {
    if (name) {
      yield delay(500);

      const result = yield call(api.validateCampaignName, name, id);
      if (result) {
        resolve();
      } else {
        reject({ campaign_name: 'This name is already used' });
      }
    }
  } catch (error) {
    reject();
  }
}

function* uploadPromoCodesFile({
  payload: { file, formName, field, fileFiled }
}) {
  try {
    yield put(startAsyncValidation(formName));
    const { success, count } = yield call(api.sendPromoCodesFile, file);

    if (!success) {
      yield put(touch(formName, field));
      yield put(
        stopAsyncValidation(
          formName,
          set({}, field, 'Error with file uploading')
        )
      );
      yield cancel();
    }

    const base64File = yield call(fileToB64, file);

    yield put(change(formName, field, count, true));
    yield put(
      change(formName, fileFiled, { file: base64File, name: file.name }, true)
    );
    yield put(stopAsyncValidation(formName));
  } catch (error) {
    yield put(touch(formName, field));
    yield put(
      stopAsyncValidation(formName, set({}, field, 'Error with file uploading'))
    );
  }
}

export default function* watcher() {
  yield takeLatest(webPushActions.createWebPushRoutine.TRIGGER, createWebPush);
  yield takeLatest(webPushActions.activateWebPush.toString(), activateWebPush);
  yield takeLatest(webPushActions.holdWebPush.toString(), holdWebPush);
  yield takeLatest(webPushActions.fetchWebPush.toString(), fetchWebPush);
  yield takeLatest(
    webPushActions.fetchWebPushList.toString(),
    fetchWebPushList
  );
  yield takeLatest(webPushActions.fetchVisitors.toString(), fetchVisitors);
  yield takeLatest(
    webPushActions.fetchCurrentCampaignNames.toString(),
    fetchCurrentCampaignNames
  );
  yield takeLatest(
    webPushActions.validateCampaignName.toString(),
    validateCampaignName
  );
  yield takeLatest(
    webPushActions.uploadPromoCodesFile.toString(),
    uploadPromoCodesFile
  );
}
