import React, { useContext } from 'react';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { FormContext } from '../../components/OnsiteForm/DesignStep/FormContext';
import Item from '../../components/OnsiteForm/DesignStep/Item';
import { getFlexStyles } from '../flex'

export const DraggableElements = ({
    onDragEnd,
    onBeforeDragStart,
    droppableId,
    elements = [],
    layoutDirection,
    ...other
}) => {
    const { activeElement, setActiveElementId } = useContext(FormContext) || {};

    const handleClickItem = (e, element) => {
        e.stopPropagation()
        setActiveElementId(element.id)
    }

    return (
        <DragDropContext onDragEnd={onDragEnd} onBeforeDragStart={onBeforeDragStart}>
            <Droppable droppableId={droppableId}>
                {(droppableProvided) => (
                    <div 
                        ref={droppableProvided.innerRef}
                        style={{
                            width: '100%',
                            height: '100%',
                            ...getFlexStyles(layoutDirection)
                        }}
                    >
                        {elements.map((element, index) => (
                            <Draggable
                                key={element.id}
                                draggableId={element.id}
                                index={index}
                            >
                            {(draggableProvided) => (
                                <Item
                                    item={element}
                                    onClick={(e) => handleClickItem(e, element)}
                                    active={element.id === activeElement.id}
                                    innerRef={draggableProvided.innerRef}
                                    disableEvents={false}
                                    {...other}
                                    {...draggableProvided.draggableProps}
                                    {...draggableProvided.dragHandleProps}
                                />
                            )}
                            </Draggable>
                        ))}
                        {droppableProvided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    )
}