import React, { useState, useCallback, useEffect } from "react";
import Box from "@material-ui/core/Box";
import { Header } from "./Header";
import { OfferList } from "./OfferList";
import InfiniteScroll from "react-infinite-scroller";
import CircularProgress from "@material-ui/core/CircularProgress";
import makeStyles from "@material-ui/styles/makeStyles";
import api from "../../api/offerWall";
import { snackbar as snackbarActions } from "../../redux/actions";
import { useDispatch } from "react-redux";
import { Container } from "@material-ui/core";
import { mapResponseToOffer } from "../../mappers/cpaMappers";
import cx from "classnames";

function getStep() {
  if (window.innerWidth >= 1798) {
    return 16;
  }
  if (window.innerWidth >= 960) {
    return 12;
  }
  if (window.innerWidth >= 600) {
    return 9;
  }
  return 5;
}

const useStyles = makeStyles((theme) => ({
  loader: {
    display: "block",
    margin: "auto",
    color: (props) => props.headerColor,
  },
  loader_full: {
    marginTop: 300,
  },
  loader__wrapper: {
    padding: "15px 0 0 0",
  },
  container: {
    maxWidth: '980px',
    width: '100%',
    margin: '0 auto',
    height: 'auto',
    padding: '96px 20px 0',
  }
}));

export const OfferWall = ({
  hash,
  cfApiKey,
  isPreviewMode,
  logo,
  backgroundColor,
  headerColor,
  animationMode,
  headerTextColor,
  username,
  companyName,
  onTeaserSelected,
  offers = [],
  setOffers,
  isHorizontal,
  campaignHeader,
  settings = {},
}) => {
  const classes = useStyles({ headerColor });
  const dispatch = useDispatch();
  const [comments, setComments] = useState(isPreviewMode ? offers : []);
  const [offerWallSettings, setOfferWallSettings] = useState( {});
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const step = getStep();

  useEffect(() => {
    if (!isPreviewMode) {
      setOffers(comments);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comments]);

  const fetchOffers = useCallback(
    (hash, cfApiKey, skip, take) => {
      setIsLoading(true);
      api
        .fetchOfferWallOffers(hash, cfApiKey, skip, take)
        .then((result) => {
          setOfferWallSettings(result.settings)
          setComments(
            comments.concat(
              result.offers.map((item) => mapResponseToOffer(item))
            )
          );
          if (skip + take >= result.total.count) {
            setHasMore(false);
          }
          setIsLoading(false);
        })
        .catch(() => {
          dispatch(
            snackbarActions.enqueueSnackbar({
              message: "Offer wall loading error occurred",
              options: { variant: "error" },
            })
          );
          setIsLoading(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [comments, dispatch, setOffers, setComments]
  );


  return (
    <Box width="100%" bgcolor={backgroundColor}>
      <Header
        logo={logo}
        companyName={companyName}
        campaignHeader={campaignHeader}
        username={username}
        headerBgColor={headerColor}
        headerTextColor={headerTextColor}
        isPreviewMode={isPreviewMode}
      />
      {!isPreviewMode ? (
        <div>
          <InfiniteScroll
            pageStart={0}
            loadMore={() => fetchOffers(hash, cfApiKey, comments.length, step)}
            hasMore={!isLoading && hasMore}
            useWindow={false}
            className={classes.container}
          >
            <OfferList
              offers={comments}
              bgColor={headerColor}
              color={headerTextColor}
              animationMode={animationMode}
              onTeaserClick={onTeaserSelected}
              isHorizontal={isHorizontal}
              settings={offerWallSettings}
            />
            {isLoading && (
              <div className={classes.loader__wrapper} key={0}>
                <CircularProgress
                  size={40}
                  className={cx(
                    classes.loader,
                    comments.length === 0 && classes.loader_full
                  )}
                />
              </div>
            )}
          </InfiniteScroll>
        </div>
      ) : (
        <Container maxWidth="lg"  className={classes.container}>
          <Box pt={12} pb={4}>
            <OfferList
              offers={offers}
              animationMode={animationMode}
              bgColor={headerColor}
              color={headerTextColor}
              settings={settings}
              isPreviewMode
            />
          </Box>
        </Container>
      )}
    </Box>
  );
};
