export const MODULE_ONSITE_ID = 1;
export const MODULE_WEBPUSH_ID = 2;
export const MODULE_SOCIAL_SHARING_ID = 3;
export const MODULE_CPA_ID = 4;
export const MODULE_EMAIL_ID = 5;
export const MODULE_SMS_ID = 6;
export const MODULE_ANALYTICS_ID = 7;
export const MODULE_CHAT_BOT_ID = 8;
export const MODULE_PROMO_CODES_ID = 9;
export const MODULE_SIM_NUMBERS_ID = 10;
export const MODULE_OFFER_CAMPAIGNS_ID = 11;
export const MODULE_CUSTOMERS = 12;
export const MODULE_BLACK_LIST_ID = 13;
