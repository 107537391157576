export const toPx = (number = 0) => `${number}px`;

export const toDeg = (number = 0) => `${number}deg`;

export const toPercentage = (number = 0) => `${number}%`;

/**
 * To milliseconds.
 *
 * @param {number} number
 * @returns {string} string
 */
export const toMs = (number = 0) => `${number}ms`;
