import React from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Modal from "@material-ui/core/Modal";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import makeStyle from "@material-ui/styles/makeStyles";
import moment from "moment";
import ReactCountryFlag from 'react-country-flag'
import { CURRENT_EDITOR } from '../../constants/editor';
import { openTextEditor } from '../../help/handleEditor';

import { required } from '../../help/validators';

import TextEditorField from '../OnsiteForm/WidgetFormsFields/TextEditorField';

const useStyles = makeStyle((theme) => ({
  paper: {
    maxWidth: "720px",
    width: "100%",
    height: "100%",
    overflow: "auto",
    backgroundColor: (props) => props.background || "#ffffff",
    [theme.breakpoints.up("sm")]: {
      height: "unset",
    },

    '& p': {
      margin: 0,
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  teaser: {
    width: "100%",
    maxWidth: (props) => props.teaserWidth || "100%",
    marginBottom: "12px",
  },
  logo: {
    width: "100%",
    maxWidth: (props) => props.logoWidth || "100%",
  },
  bonus: {
    fontWeight: 700,

    '& p, & div': {
      display: 'inline-block',
      verticalAlign: 'middle',
    },
  },
  backButton: {
    display: "block",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  description: {
    wordBreak: "break-word",
  },
  offerLoyalty: {
    width: "auto",
  },
  text_small: {
    fontSize: 10,
  },
}));

const POSITIONS = {
  left: "flex-start",
  center: "center",
  right: "flex-end",
};

export const GiftCardUI = ({
  children,
  companyName,
  bonus,
  logo,
  teaserLogo,
  fullDescription,
  shortDescription,
  showFullDescription,
  acceptedPeople,
  onBack,
  validUntil,
  teaserWidth,
  logoWidth,
  teaserPosition,
  logoPosition,
  background,
  textColor,
  description,
  language,
  setActiveEditor,
  focusTextEditor,
}) => {
  const classes = useStyles({ teaserWidth, logoWidth, background, textColor });
  const validUntilFormattedDate = validUntil
    ? moment(new Date(validUntil)).format("MMMM Do YYYY, kk:mm")
    : undefined;

  return (
    <Paper square className={classes.paper}>
      <Box p={4}>
        <Grid container spacing={4}>
          {description && (
            <Grid item xs={10} style={{ paddingBottom: 5, lineHeight: "14px" }}>
              <Typography
                component="span"
                variant="body2"
                className={classes.text_small}
                style={{ color: textColor }}
                data-editor-name={CURRENT_EDITOR.CPA_OW_HEADER_DESCRIPTION}
                data-text-editor
                onClick={openTextEditor(setActiveEditor)}
              >
                {focusTextEditor === CURRENT_EDITOR.CPA_OW_HEADER_DESCRIPTION ? (
                  <TextEditorField
                    editorType="inline-editor"
                    label="Header description"
                    name="description"
                    fullWidth
                    multiline
                    rows={3}
                    focusTextEditor={focusTextEditor}
                  />
                ) : (
                  <span dangerouslySetInnerHTML={{ __html: description }} />
                )}
              </Typography>
            </Grid>
          )}
          {language && <Grid item xs={2} style={{ textAlign: 'right' }}>
            <ReactCountryFlag countryCode={language.code} svg style={{ width: 25 }} />
          </Grid>}
          <Grid item xs={12}>
            <Typography variant="h5" style={{ color: textColor }}>
              {companyName}:{" "}
              <Typography
                component="span"
                variant="h5"
                className={classes.bonus}
                style={{ color: textColor }}
                data-editor-name={CURRENT_EDITOR.CPA_OW_BONUS}
                data-text-editor
                onClick={openTextEditor(setActiveEditor)}
              >
                {focusTextEditor === CURRENT_EDITOR.CPA_OW_BONUS ? (
                  <TextEditorField
                    editorType="inline-editor"
                    label="Bonus Teaser"
                    name="bonus"
                    fullWidth
                    multiline
                    required
                    focusTextEditor={focusTextEditor}
                  />
                ) : (
                  <span dangerouslySetInnerHTML={{ __html: bonus }} />
                )}
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems={teaserPosition ? POSITIONS[teaserPosition] : "center"}
            >
              <img className={classes.teaser} src={teaserLogo} alt="" />
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              alignItems={logoPosition ? POSITIONS[logoPosition] : "center"}
            >
              <img className={classes.logo} src={logo} alt="" />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            {children}
          </Grid>
          {validUntil && (
            <Grid item xs={12}>
              <Typography
                color="textSecondary"
                variant="body1"
                style={{ color: textColor }}
              >
                Valid until {validUntilFormattedDate}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <div
              data-editor-name={CURRENT_EDITOR.CPA_OW_SHORT_DESCRIPTION}
              data-text-editor
              onClick={openTextEditor(setActiveEditor)}
            >
              {focusTextEditor === CURRENT_EDITOR.CPA_OW_SHORT_DESCRIPTION ? (
                <TextEditorField
                  editorType="inline-editor"
                  label="Short Description"
                  name="shortDescription"
                  fullWidth
                  showLetterCount
                  maxLetters={500}
                  required
                  multiline
                  rows={4}
                  focusTextEditor={focusTextEditor}
                />
              ) : (
                <Typography
                  variant="body2"
                  className={classes.description}
                  dangerouslySetInnerHTML={{ __html: shortDescription }}
                />
              )}
            </div>

            {showFullDescription && (
              <Typography
                variant="body2"
                className={classes.description}
                data-editor-name={CURRENT_EDITOR.CPA_OW_FULL_DESCRIPTION}
                data-text-editor
                onClick={openTextEditor(setActiveEditor)}
              >
                {focusTextEditor === CURRENT_EDITOR.CPA_OW_FULL_DESCRIPTION ? (
                  <TextEditorField
                    editorType="inline-editor"
                    label="Full Description"
                    name="fullDescription"
                    fullWidth
                    showLetterCount
                    maxLetters={1000}
                    multiline
                    rows={4}
                    required
                    focusTextEditor={focusTextEditor}
                  />
                ) : (
                  <span dangerouslySetInnerHTML={{ __html: fullDescription }} />
                )}
              </Typography>
            )}
          </Grid>
          {acceptedPeople && (
            <Grid item xs={12}>
              <Typography
                align="center"
                component="p"
                variant="h6"
                style={{ color: textColor }}
              >
                Today this offer was accepted by {acceptedPeople} people
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <Button
              onClick={onBack}
              className={classes.backButton}
              style={{ color: textColor }}
            >
              Back
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export const GiftCard = ({ open, onBack, ...uiCardProps }) => {
  const classes = useStyles();
  return (
    <Modal open={open} onClose={onBack} className={classes.modal}>
      <GiftCardUI onBack={onBack} {...uiCardProps} />
    </Modal>
  );
};
