import MomentUtils from '@date-io/moment';

const moment = new MomentUtils();

/**
* Corrects dateFrom if the interval between dateFrom and dateTo is greater than 30 days
*/
const adjustDateFrom = (maxRange, dateFrom, dateTo) => {
  const dateFromObj = moment.date(dateFrom);
  const dateToObj = moment.date(dateTo);
  const daysDifference = dateToObj.diff(dateFromObj, 'days');

  if (daysDifference > maxRange) {
    const adjustedDateFrom = dateToObj.clone().subtract(30, 'days');
    return adjustedDateFrom.format('YYYY-MM-DD');
  }

  return dateFrom;
};

export default adjustDateFrom;
