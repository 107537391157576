import get from 'lodash/fp/get';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

import { CAROUSEL_MOVE_OPTIONS } from '../components/OnsiteForm/WidgetFormsFields/MainFields/FieldsByWidgetType/CarouselFields';
import { getUniqueIdWithPrefix } from '../help/getUniqueIdWithPrefix';
import { parseBoxShadowString } from '../help/itemStyles/boxShadow';

import { TARGET_LIST } from './linkAttributes';

export const RECAPTCHA_KEY_ID = '6LdIf5YoAAAAAD1n_9NispK8cy_HsO3OhsqY_yyY';

export const POPUP = 'popup';
export const POPUP_1 = `${POPUP}_1`;
export const OVERLAY = 'overlay';
export const OVERLAY_1 = `${OVERLAY}_1`;
export const OVERLAY_2 = `${OVERLAY}_2`;
export const COMMENT = 'comment';
export const COMMENT_1 = `${COMMENT}_1`;
export const COMMENT_LEFT = `${COMMENT}_left`;
export const RIBBON = 'ribbon';
export const ADVICE = 'advice';
export const ADVICE_LEFT = `${ADVICE}_left`;
export const COOKIE = 'cookie';
export const INTEGRATED = 'integrated';
export const NOTIFICATION = 'notification';
export const NOTIFICATION_LEFT = `${NOTIFICATION}_left`;
export const FULL = 'full';
export const PULLED = 'pulled';

export const WIDGET_LAYOUT_TYPES = {
  CAROUSEL: 'carousel',
  FULL_CAROUSEL: 'full-carousel',
  HISTORY_CAROUSEL: 'history-carousel',
};

export const FONT_SIZES = [
  '8px',
  '9px',
  '10px',
  '11px',
  '12px',
  '14px',
  '16px',
  '18px',
  '24px',
  '30px',
  '36px',
  '48px',
];

export const FONT_FAMILY_TYPES = {
  MONTSERRAT: 'Montserrat',
  ROBOTO: 'Roboto',
  ARIAL: 'Arial',
  GEORGIA: 'Georgia',
  IMPACT: 'Impact',
  TAHOMA: 'Tahoma',
  TIMES: 'Times New Roman',
  VERDANA: 'Verdana',
  HELVETICA: 'Helvetica',
  PRAGMATICA: 'Pragmatica',
  WALSHEIM: 'Walsheim',
};

export const fontTypeToFontFamiliesMapping = {
  [FONT_FAMILY_TYPES.MONTSERRAT]: 'CF_MONTSERRAT,sans-serif',
  [FONT_FAMILY_TYPES.ROBOTO]: 'CF_ROBOTO,sans-serif',
  [FONT_FAMILY_TYPES.ARIAL]: 'СF_ARIAL,sans-serif',
  [FONT_FAMILY_TYPES.GEORGIA]: 'CF_GEORGIA,serif',
  [FONT_FAMILY_TYPES.IMPACT]: 'CF_IMPACT,sans-serif',
  [FONT_FAMILY_TYPES.TAHOMA]: 'CF_TAHOMA,sans-serif',
  [FONT_FAMILY_TYPES.TIMES]: 'CF_TIMES_NEW_ROMAN,serif',
  [FONT_FAMILY_TYPES.VERDANA]: 'CF_VERDANA,sans-serif',
  [FONT_FAMILY_TYPES.HELVETICA]: '\'Helvetica Neue\',Helvetica,Arial,sans-serif',
  [FONT_FAMILY_TYPES.PRAGMATICA]: 'CF_PRAGMATICA, sans-serif',
  [FONT_FAMILY_TYPES.WALSHEIM]: 'CF_WALSHEIM, sans-serif',
};

export const fontFamilyToFontTypesMapping = {
  [fontTypeToFontFamiliesMapping[FONT_FAMILY_TYPES.MONTSERRAT]]: FONT_FAMILY_TYPES.MONTSERRAT,
  [fontTypeToFontFamiliesMapping[FONT_FAMILY_TYPES.ROBOTO]]: FONT_FAMILY_TYPES.ROBOTO,
  [fontTypeToFontFamiliesMapping[FONT_FAMILY_TYPES.ARIAL]]: FONT_FAMILY_TYPES.ARIAL,
  [fontTypeToFontFamiliesMapping[FONT_FAMILY_TYPES.GEORGIA]]: FONT_FAMILY_TYPES.GEORGIA,
  [fontTypeToFontFamiliesMapping[FONT_FAMILY_TYPES.IMPACT]]: FONT_FAMILY_TYPES.IMPACT,
  [fontTypeToFontFamiliesMapping[FONT_FAMILY_TYPES.TAHOMA]]: FONT_FAMILY_TYPES.TAHOMA,
  [fontTypeToFontFamiliesMapping[FONT_FAMILY_TYPES.TIMES]]: FONT_FAMILY_TYPES.TIMES,
  [fontTypeToFontFamiliesMapping[FONT_FAMILY_TYPES.VERDANA]]: FONT_FAMILY_TYPES.VERDANA,
  [fontTypeToFontFamiliesMapping[FONT_FAMILY_TYPES.HELVETICA]]: FONT_FAMILY_TYPES.HELVETICA,
  [fontTypeToFontFamiliesMapping[FONT_FAMILY_TYPES.PRAGMATICA]]: FONT_FAMILY_TYPES.PRAGMATICA,
  [fontTypeToFontFamiliesMapping[FONT_FAMILY_TYPES.WALSHEIM]]: FONT_FAMILY_TYPES.WALSHEIM,
};

export const isHorizontal = (type) => [RIBBON, COOKIE, COMMENT_1].includes(type);

export const isPositionedOnLeft = (type) => [ADVICE_LEFT, COMMENT_LEFT, NOTIFICATION_LEFT].includes(type);

export const directions = {
  COLUMN: 'column',
  ROW: 'row',
};

export const buttonActionTypes = {
  closeType: 'close-type',
  linkType: 'link-type',
  subscribe: 'subscribe',
  widgetShowType: 'show-widget',
};

export const CLOSE_WIDGET_CLASS_NAME = 'cf_button-modal__close';
export const SHOW_WIDGET_CLASS_NAME = 'cf_button-widget__show';

export const buttonWidthOptions = {
  fullWidth: 'fullWidth',
  fixed: 'fixed',
  dependingOnText: 'dependingOnText',
};

export const formWidthOptions = {
  fullWidth: 'fullWidth',
  maxWidth: 'maxWidth',
};

export const bgImageOptions = {
  repeat: 'repeat',
  fill: 'fill',
  stretch: 'stretch',
  center: 'center',
};

export const justifyContent = {
  start: 'flex-start',
  end: 'flex-end',
  center: 'center',
  spaceBetween: 'space-between',
  spaceAround: 'space-around',
};

export const alignText = {
  left: 'left',
  right: 'right',
  center: 'center',
};

export const alignItems = {
  start: 'flex-start',
  end: 'flex-end',
  center: 'center',
};

export const offerWallTextPlaces = {
  brand_name: 'Brand Name',
  company_name: 'Company Name',
  description: 'Description',
};

export const heightOptions = {
  fixed: 'fixed',
  auto: 'auto',
  full: 'full',
};

export const openingConditions = {
  none: 'none',
  timer: 'timer',
  scrollTo: 'scrollTo',
};

export const layoutDirection = {
  default: 'default',
  horizontal: 'horizontal',
  vertical: 'vertical',
};

export const flexDirectionsMap = {
  horizontal: 'row',
  vertical: 'column',
};


export const transitionTimingFunctions = {
  linear: 'linear',
  easeInSine: 'cubic-bezier(0.12, 0, 0.39, 0)',
  easeInOutBack: 'cubic-bezier(0.68, -0.6, 0.32, 1.6)',
  easeOutBack: 'cubic-bezier(0.34, 1.56, 0.64, 1)',
  easeOutExpo: 'cubic-bezier(0.16, 1, 0.3, 1)',
};

export const ANIMATIONS_STYLE = {
  easyRightLeft: 'easyRightLeft 1s ease-out forwards',
  easyBottomTop: 'easyBottomTop 1s ease-out forwards',
  marqueeLeftRight: 'marqueeLeftRight 5s linear infinite',
};

export const sizeByTypeMapping = {
  [POPUP]: { width: '400px', height: '300px' },
  [POPUP_1]: { width: '600px', height: '300px' },
  [OVERLAY]: { width: '400px', height: '300px' },
  [OVERLAY_1]: { width: '600px', height: '300px' },
  [OVERLAY_2]: { width: '985px', height: '500px' },
  [COMMENT]: { width: '240px', height: '120px' },
  [COMMENT_1]: { width: '240px', height: '120px' },
  [ADVICE]: { width: '250px', height: '300px' },
  [RIBBON]: { width: '100%', height: '100px' },
  [COOKIE]: { width: '100%', height: '80px' },
  [INTEGRATED]: { width: '250px', height: '400px' },
  [NOTIFICATION]: { width: '250px', height: '100px' },
  [FULL]: { width: '1024px', height: '550px' },
  [WIDGET_LAYOUT_TYPES.CAROUSEL]: { width: '400px', height: '300px' },
  [WIDGET_LAYOUT_TYPES.FULL_CAROUSEL]: { width: '100%', height: '400px' },
  [WIDGET_LAYOUT_TYPES.HISTORY_CAROUSEL]: { width: '90px', height: '90px' },
  [PULLED]: { width: '400px', height: '800px' },
};

export const elements = {
  BUTTON: 'button',
  IMAGE: 'image',
  VIDEO: 'video',
  FORM: 'form',
  ORDER_FORM: 'orderForm',
  SEPARATOR: 'separator',
  TEXT: 'text',
  SOCIAL: 'social',
  RATING: 'rating',
  COLLAPSE: 'collapse',
  STEPPER: 'stepper',
  PHONE_VERIFICATION: 'phoneVerification',
  CLICK_PAY: 'clickPay',
  ERROR_BOUNDARY: 'errorBoundary',
  PRODUCT_CARD: 'productCard',
};

export const droppableElements = [elements.COLLAPSE, elements.STEPPER];

/**
 * @type {object} elementEvents
 * @property {funcntion} elementName
 * @returns {OnsiteWidget.Event}
 */
export const elementEvents = {
  [elements.COLLAPSE]: (collapse) => [
    {
      target: collapse.id,
      id: `show-collapse-${collapse.id}`,
      name: 'collapse::show',
      label: `Show collapse: ${collapse.friendlyId}`,
      data: {
        id: collapse.id,
        ...collapse.eventOptions,
      },
    },
    {
      target: collapse.id,
      id: `hide-collapse-${collapse.id}`,
      name: 'collapse::hide',
      label: `Hide collapse: ${collapse.friendlyId}`,
      data: {
        id: collapse.id,
        ...collapse.eventOptions,
      },
    },
    {
      target: collapse.id,
      id: `toggle-collapse-${collapse.id}`,
      name: 'collapse::toggle',
      label: `Toggle collapse: ${collapse.friendlyId}`,
      data: {
        id: collapse.id,
        ...collapse.eventOptions,
      },
    },
  ],
  [elements.STEPPER]: (stepper) => {
    const commonEvents = [
      {
        target: stepper.id,
        id: `next-${stepper.id}`,
        name: 'stepper::next-step',
        label: `Next step: ${stepper.friendlyId}`,
        data: stepper.id,
      },
      {
        target: stepper.id,
        id: `prev-${stepper.id}`,
        name: 'stepper::prev-step',
        label: `Prev step: ${stepper.friendlyId}`,
        data: stepper.id,
      },
    ];

    const goToStepEvents = stepper.steps.map((_, index) => ({
      target: stepper.id,
      id: `go-to-step-${stepper.id}-${index}`,
      name: 'stepper::go-to-step',
      label: `Go to step ${index + 1}: ${stepper.friendlyId}`,
      data: {
        stepperId: stepper.id,
        stepIndex: index,
      },
    }));

    return [...commonEvents, ...goToStepEvents];
  },
  [elements.FORM]: (form) => [
    {
      target: form.id,
      id: `${form.id}`,
      name: 'form::submit',
      label: `Submit form: ${form.id}`,
      data: form.id,
    },
  ],
  [elements.ORDER_FORM]: (orderForm) => [
    {
      target: orderForm.id,
      id: `${orderForm.id}`,
      name: 'orderForm::submit',
      label: `Submit order form: ${orderForm.id}`,
      data: orderForm.id,
    },
  ],
};

const socialProps = {
  backgroundColor: '#4A90E2',
  color: '#ffffff',
  borderColor: '#4A90E2',
  padding: '6px',
  borderWidth: '1px',
  twitterLink: 'https://twitter.com/intent/tweet',
  facebookLink: 'https://www.facebook.com/sharer/sharer.php',
  vkLink: 'https://vk.com/share.php',
  okLink: 'https://connect.ok.ru/offer',
  enableTwitterLink: true,
  enableFacebookLink: false,
  enableOkLink: false,
  enableVkLink: false,
};

export const HORIZONTAL_OPTIONS = [
  { value: 'top', label: 'Top' },
  { value: 'center', label: 'Center' },
  { value: 'bottom', label: 'Bottom' },
];

export const VERTICAL_OPTIONS = [
  { value: 'left', label: 'Left' },
  { value: 'center', label: 'Center' },
  { value: 'right', label: 'Right' },
];

export const ORDER_TABS_OPTIONS = [
  { value: 'personal', label: 'Personal section' },
  { value: 'tariff', label: 'Tariff section' },
  { value: 'delivery', label: 'Delivery success' },
];

export const CLICK_PAY_TABS_OPTIONS = [
  { value: 'phone', label: 'Phone section' },
  { value: 'otp', label: 'Otp section' },
  { value: 'success', label: 'Success section' },
];

export const DEFAULT_ELEMENT_VALUES = {
  [elements.BUTTON]: {
    action: buttonActionTypes.closeType,
    widthOption: 'fullWidth',
    fixedWidth: '100px',
    text: 'Click me!',
    color: '#ffffff',
    backgroundColor: '#4A90E2',
    borderRadius: '4px',
    fontSize: '14px',
    lineHeight: '16px',
    paddingLeft: '6px',
    paddingRight: '6px',
    rotateDeg: '0deg',
    horizontalTranslate: '0px',
    verticalTranslate: '0px',
    rotateHorizontalOrigin: 'right',
    rotateVerticalOrigin: 'bottom',
    animation: 'unset',
    fontFamily: FONT_FAMILY_TYPES.ROBOTO,
    ...parseBoxShadowString('0 0 0 rgba(0, 0, 0, 0.3)'),
  },
  [elements.IMAGE]: {
    position: 'center',
    rotateDeg: '0deg',
    horizontalTranslate: '0px',
    verticalTranslate: '0px',
    rotateHorizontalOrigin: 'right',
    rotateVerticalOrigin: 'bottom',
    animation: 'unset',
    linkOptions: {
      target: TARGET_LIST[0].value,
    },
  },
  [elements.VIDEO]: {
    position: 'center',
    autoMoveMode: false,
    controlledMode: false,
    linkOptions: {
      target: TARGET_LIST[0].value,
    },
  },
  [elements.FORM]: {
    position: 'center',
    orientation: 'column',
    widthOption: 'fullWidth',
    maxWidth: '220px',
    action: buttonActionTypes.closeType,
    inputStyles: {
      borderRadius: '4px',
      color: '#313131',
      backgroundColor: '#ffffff',
      borderColor: '#e4e4e4',
      fontSize: '14px',
      height: '38px',
      fontFamily: FONT_FAMILY_TYPES.ROBOTO,
    },
    selector: 'email',
    placeholder: 'Write something...',
    showButton: true,
    buttonStyles: {
      borderRadius: '4px',
      backgroundColor: '#4A90E2',
      color: '#ffffff',
      fontSize: '14px',
      fontFamily: FONT_FAMILY_TYPES.ROBOTO,
      ...parseBoxShadowString('0 0 0 rgba(0, 0, 0, 0.3)'),
    },
    text: 'Send me!',
  },
  [elements.ORDER_FORM]: {
    position: 'center',
    orientation: 'column',
    widthOption: 'fullWidth',
    maxWidth: '220px',
    action: buttonActionTypes.closeType,
    inputStyles: {
      borderRadius: '4px',
      color: '#313131',
      backgroundColor: '#ffffff',
      borderColor: '#e4e4e4',
      fontSize: '14px',
      height: '38px',
      fontFamily: FONT_FAMILY_TYPES.ROBOTO,
    },
    selector: 'email',
    placeholder: 'Write something...',
    showButton: true,
    buttonStyles: {
      borderRadius: '4px',
      backgroundColor: '#4A90E2',
      color: '#ffffff',
      fontSize: '14px',
      fontFamily: FONT_FAMILY_TYPES.ROBOTO,
      ...parseBoxShadowString('0 0 0 rgba(0, 0, 0, 0.3)'),
    },
    placeholderEmail: 'Введите email',
    placeholderPhone: 'Ваш телефон',
    placeholderName: 'Ваше имя',
    simCardPlaceholder: 'Выберете номер',
    tariffPlaceholder: 'Выберете тариф',
    cityPlaceholder: 'Ваш город',
    salePointPlaceholder: 'Пункт выдачи',
    successText: 'Спасибо, мы свяжемся с вами в ближайшее время',
    errorText: 'Пожалуйста, заполните все поля',
    text: 'Send me!',
    nextButtontext: 'Next',
    nextSecondButtonText: 'Next',
    tariffInfoText: 'Описание тарифа',
    pointInfoText: 'Время работы',
    backButtonText: 'back',
    activeTab: 'personal',
  },
  [elements.PRODUCT_CARD]: {
    position: 'center',
    orientation: 'column',
    widthOption: 'fullWidth',
    maxWidth: '220px',
    titleStyles: {
      color: '#111111',
      fontSize: '16px',
      fontFamily: FONT_FAMILY_TYPES.ROBOTO,
    },
    oldPriceLabelStyles: {
      backgroundColor: '#bf9600',
      color: '#ffffff',
      fontSize: '12px',
      fontFamily: FONT_FAMILY_TYPES.ROBOTO,
    },
    oldPriceStyles: {
      color: '#666666',
      fontSize: '12px',
      fontFamily: FONT_FAMILY_TYPES.ROBOTO,
    },
    priceStyles: {
      color: '#111111',
      fontSize: '18px',
      fontFamily: FONT_FAMILY_TYPES.ROBOTO,
    },
  },
  [elements.SEPARATOR]: {
    color: '#9B9B9B',
    length: '40px',
    size: '1px',
    margins: '10px',
    fullLength: false,
  },
  [elements.TEXT]: {
    rotateDeg: '0deg',
    horizontalTranslate: '0px',
    verticalTranslate: '0px',
    rotateHorizontalOrigin: 'right',
    rotateVerticalOrigin: 'bottom',
    direction: 'horizontal',
    duration: '1200ms',
    animationDelay: '800ms',
    alignItems: 'center',
    transitionTimingFunction: 'linear',
    animation: 'unset',
    text:
      '<p style="text-align:left;"><span style="color: rgb(0,0,0);font-size: 16px;font-family: Arial;">Text!</span></p>',
  },
  [elements.RATING]: {
    action: buttonActionTypes.closeType,
    disabledColor: '#FFBB00',
    enabledColor: '#FFBB00',
    size: '29px',
    spacing: '10px',
    numberOf: '5',
    mode: 'stars',
  },
  [elements.SOCIAL]: socialProps,
  [elements.COLLAPSE]: ({ id }) => ({
    show: true,
    friendlyId: id,
    fixedHeight: '150px',
    widthOption: 'fullWidth',
    maxWidth: '220px',
    heightOption: heightOptions.fixed,
    padding: '0px',
    borderRadius: '0px',
    transitionTimingFunction: transitionTimingFunctions.easeInOutBack,
    transitionDuration: '500ms',
    openingCondition: openingConditions.none,
    openingConditionTime: 0,
    eventOptions: {
      hideOther: false,
    },
    layoutDirection: layoutDirection.default,
  }),
  [elements.STEPPER]: ({ id }) => ({
    friendlyId: id,
    activeStep: 0,
    heightOption: heightOptions.fixed,
    fixedHeight: '150px',
    transitionTimingFunction: transitionTimingFunctions.linear,
    transitionDuration: '200ms',
    steps: [{}, {}, {}],
  }),
  [elements.PHONE_VERIFICATION]: {
    phoneInputStyles: {
      borderRadius: '4px',
      color: '#313131',
      backgroundColor: 'rgb(213 213 213 / 30%)',
      borderColor: 'transparent',
      borderWidth: '1px',
      fontSize: '16px',
      padding: '25px 14px 8px',
      position: 'relative',
      fontFamily: FONT_FAMILY_TYPES.ROBOTO,
    },
    codeInputStyles: {
      borderRadius: '4px',
      color: '#313131',
      backgroundColor: 'rgb(213 213 213 / 30%)',
      borderColor: 'transparent',
      borderWidth: '1px',
      fontSize: '16px',
      padding: '25px 14px 8px',
      position: 'relative',
      fontFamily: FONT_FAMILY_TYPES.ROBOTO,
    },
    buttonStyles: {
      borderRadius: '4px',
      backgroundColor: '#4A90E2',
      color: '#ffffff',
      fontSize: '14px',
      fontFamily: FONT_FAMILY_TYPES.ROBOTO,
      ...parseBoxShadowString('0 0 0 rgba(0, 0, 0, 0.3)'),
    },
    showTelegramButton: false,
    telegramButtonStyles: {
      borderRadius: '4px',
      backgroundColor: '#4A90E2',
      color: '#ffffff',
      fontSize: '14px',
      fontFamily: FONT_FAMILY_TYPES.ROBOTO,
      ...parseBoxShadowString('0 0 0 rgba(0, 0, 0, 0.3)'),
    },
    errorStyles: {
      fontSize: '14px',
      color: '#ff0000',
    },
    widthOption: 'fullWidth',
    maxWidth: '220px',
    position: 'center',
    orientation: 'column',
    text: 'Get code',
    telegramButtonText: 'Telegram',
    phoneInputLabel: 'Enter your number',
    phoneInputPlaceholder: 'Your number',
    codeInputLabel: 'Enter your code',
    codeInputSubLabel: 'We send code to your phone',
    codeInputPlaceholder: 'Enter code',
    backLinkText: 'Change phone',
    resendLinkText: 'Resend',
    successText: 'Thank you, successful!',
    socialProps,
    activeTab: 0,
  },
  [elements.CLICK_PAY]: {
    amountInputStyles: {
      borderRadius: '4px',
      color: '#c5c5c5',
      backgroundColor: 'rgb(213 213 213 / 30%)',
      borderColor: 'transparent',
      borderWidth: '1px',
      fontSize: '16px',
      padding: '25px 14px 8px',
      position: 'relative',
      fontFamily: FONT_FAMILY_TYPES.ROBOTO,
    },
    rowStyles: {
      marginHorizontal: '0',
      marginVertical: '5px',
    },
    cardInputStyles: {
      borderRadius: '4px',
      color: '#313131',
      backgroundColor: '#ffffff',
      borderColor: 'transparent',
      borderWidth: '1px',
      fontSize: '16px',
      padding: '25px 14px 8px',
      position: 'relative',
      fontFamily: FONT_FAMILY_TYPES.ROBOTO,
    },
    cardStyles: {
      backgroundColor: 'rgb(213 213 213 / 30%)',
      borderRadius: '4px',
      paddingHorizontal: '2px',
      paddingVertical: '3px',
      marginHorizontal: '5px',
      marginVertical: '5px',
    },
    cardInputPlaceholder: 'Your card',
    cardMonthInputPlaceholder: 'Month',
    cardYearInputPlaceholder: 'Year',
    badgeStyles: {
      color: '#6e6e6e',
      backgroundColor: 'rgb(213 213 213 / 30%)',
      borderRadius: '4px',
      paddingHorizontal: '2px',
      paddingVertical: '3px',
      fontSize: '12px',
      fontFamily: FONT_FAMILY_TYPES.ROBOTO,
    },
    badgeTexts: {
      badgeText1: '20 000',
      badgeText2: '30 000',
      badgeText3: '40 000',
    },
    imageStyles: {
      height: '30px',
      width: '50px',
    },
    buttonStyles: {
      borderRadius: '4px',
      backgroundColor: '#4A90E2',
      color: '#ffffff',
      fontSize: '14px',
      fontFamily: FONT_FAMILY_TYPES.ROBOTO,
      paddingVertical: '10px',
      ...parseBoxShadowString('0 0 0 rgba(0, 0, 0, 0.3)'),
    },
    errorStyles: {
      fontSize: '14px',
      color: '#ff0000',
    },
    widthOption: 'fullWidth',
    widthButtonOption: 'fullWidth',
    maxWidth: '220px',
    position: 'center',
    orientation: 'column',
    text: 'Pay',
    phoneInputLabel: 'Enter your number',
    clickPayOtpLabel: 'Enter otp code',
    clickPaySuccessText: 'Payment Successful',
    phoneInputPlaceholder: 'Your number',
    amountInputPlaceholder: 'Your amount',
    otpInputPlaceholder: 'Input otp code',
    helperText: 'Helper text',
    clickPayOtpSendBtn: 'Send Code',
    activeTab: 'phone',
    clickBackButtonText: 'Back',
  },
  [elements.ERROR_BOUNDARY]: {
    text: '',
    targetType: '',
  },
};

/**
 * Create a new element with default values from DEFAULT_ELEMENT_VALUES.
 *
 * @param {string} type    - element type
 * @param {object} [props] - element properties
 * @returns {OnsiteWidget.Element} element
 */
export const createElement = (type, props = {}) => {
  const getElementDefaultValues = typeof DEFAULT_ELEMENT_VALUES[type] !== 'function'
    ? () => DEFAULT_ELEMENT_VALUES[type]
    : DEFAULT_ELEMENT_VALUES[type];

  const id = getUniqueIdWithPrefix(type);
  const defaultValues = getElementDefaultValues({ id });
  return {
    type, id, ...defaultValues, ...props,
  };
};

export const createErrorBoundary = (props) => createElement(elements.ERROR_BOUNDARY, props);

/**
 * Create a new element with default values from DEFAULT_ELEMENT_VALUES with parent Element.
 *
 * @param {string} type      - element type
 * @param {OnsiteWidget.Element} parentElement  - parent element
 * @param {object} metaData  - some data to using in elements
 * @returns {OnsiteWidget.Element} element
 */
export const createElementWithParent = (type, parentElement = {}, metaData = {}) => {
  if (!parentElement) return null;
  const index = parentElement.childCount || 0;

  return createElement(type, {
    parentElement: {
      id: parentElement.id,
      index: index === 0 ? index : index,
      ...metaData,
    },
  });
};

/**
 *
 * @param {OnsiteWidget.Element} element
 * @returns {OnsiteWidget.Event[]}
 */
export const createElementEvents = (element) => {
  if (!elementEvents[element.type]) return [];
  return elementEvents[element.type](element);
};

export const COLUMN_QUANTITY = {
  ONE_COLUMN: 'one-column',
  TWO_COLUMNS: 'two-columns',
};

export const DRAGGABLE_VALUES = {
  DROP_TYPE: 'onsiteWidgets',
  NESTED_DRAG_KEY: 'nested',
};

const WIDGET = {
  id: 'new-widget',
  type: POPUP,
  sizeType: POPUP,
  items: [],
  events: [],
  items2: [],
  html: null,
  backgroundColor: '#ffffff',
  buttonColor: '#FFF8E8',
  buttonSize: '63px',
  borderRadius: '0px',
  borderColor: 'rgba(0, 0, 0, 0.3)',
  borderWidth: '0px',
  padding: '0',
  justifyContent: justifyContent.start,
  alignItems: alignItems.center,
  flexDirection: directions.COLUMN,
  columnQuantity: COLUMN_QUANTITY.ONE_COLUMN,
  width: '100%',
  height: '20px',
  elementsOptions: {},
  settings: { chance: 100, main_widget: false },
  carouselMoveOption: CAROUSEL_MOVE_OPTIONS[0].value,
  carouselCommonUrl: '',
  carouselSlideCount: 3,
  carouselSlideWidth: '90px',
  carouselEffect: 'slide',
  carouselControlColor: 'rgba(115, 25, 130, 1)',
  carouselVerticalMargin: '0px',
  carouselSlideSpaceWidth: 5,
  carouselCurrentSlide: 0,
  carouselDuration: '1000ms',
  carouselDelay: '5000ms',
  carouselAnimationFunction: 'linear',
  carouselType: 'fullScreenCarousel',
  carouselIntegratedOptions: 'integrated',
  carouselSlides: [
    {
      id: getUniqueIdWithPrefix('slide'),
      items: [],
      backgroundColor: '#fff',
      backgroundImage: 'url("")',
      borderColor: 'transparent',
    },
    {
      id: getUniqueIdWithPrefix('slide'),
      items: [],
      backgroundColor: '#fff',
      backgroundImage: 'url("")',
      borderColor: 'transparent',
    },
    {
      id: getUniqueIdWithPrefix('slide'),
      items: [],
      backgroundColor: '#fff',
      backgroundImage: 'url("")',
      borderColor: 'transparent',
    },
  ],
};

export const INITIAL_VALUES = {
  widgets: [WIDGET],
  editedWidget: WIDGET,
};

export const isWidgetPristine = (wValues) => {
  // TODO: Check this method
  return true;
  // eslint-disable-next-line no-unreachable
  const wIV = INITIAL_VALUES.widget;
  const horizontal = isHorizontal(wValues.sizeType);
  const twoColumned = wValues.type === POPUP_1 || wValues.type === OVERLAY_1;

  return (
    isEmpty(get('items', wValues))
    && isEmpty(get('items2', wValues))
    && isNil(get('backgroundImage.url', wValues))
    && get('backgroundColor', wValues) === wIV.backgroundColor
    && get('buttonBackground', wValues) === wIV.buttonBackground
    && get('borderRadius', wValues) === wIV.borderRadius
    && get('borderColor', wValues) === wIV.borderColor
    && get('borderWidth', wValues) === wIV.borderWidth
    && get('padding', wValues) === wIV.padding
    && get('justifyContent', wValues) === wIV.justifyContent
    && get('alignItems', wValues) === wIV.alignItems
    && get('columnQuantity', wValues) === wIV.columnQuantity
    && !twoColumned
    && ((horizontal && get('flexDirection', wValues) === directions.ROW)
      || (!horizontal && get('flexDirection', wValues) === directions.COLUMN))
  );
};

export const NORMAL_SOCIAL_BUTTONS_PADDINGS = {
  1: 40,
  2: 30,
  3: 20,
  4: 14,
};

export const SMALL_SOCIAL_BUTTONS_PADDINGS = {
  1: 34,
  2: 24,
  3: 14,
  4: 6,
};
