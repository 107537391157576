import React from 'react';

const styles = {
    root: {
        padding: '1rem'
    },
    title: {
        margin: '0 0 1rem',
        textTransform: 'capitalize'
    }
}

const ErrorBoundary = ({
  text,
  targetType,
  itemId,
}) => {
  const readableType = targetType.replace(/([A-Z])/, ' $1')

  return (
    <div id={itemId} style={styles.root}>
        <h3 style={styles.title}>{readableType}</h3>
        <div>{text.toString()}</div>
    </div>
  );
};

export default ErrorBoundary