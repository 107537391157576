import get from 'lodash/get';
import React, { useRef } from 'react';

import { heightOptions, formWidthOptions } from '../../constants/onsiteWidgets';
import bgStyles from '../../help/bgStyles';
import { ChildrenElements } from '../../help/draggable/ChildrenElements';
import { getHeightProperties } from '../parsers/commonParsers/heightParser';

const Collapse = ({
  itemId,
  id,
  commonStyle,
  backgroundColor,
  backgroundImage,
  backgroundOption,
  show,
  friendlyId,
  fixedHeight,
  heightOption,
  transitionDuration,
  transitionTimingFunction,
  isEditing,
  openingCondition,
  openingConditionTime,
  layoutDirection,
  childrenElements,
  boxShadow,
  eventOptions = {},
  widthOption,
  maxWidth,
  padding,
  borderRadius,
  ...other
}) => {
  const collapseRef = useRef(null);
  const { height: initHeight } = getHeightProperties(heightOption, fixedHeight);
  const transition = `height ${transitionDuration} ${transitionTimingFunction}`;
  const rootBgStyle = {
    ...commonStyle,
  };

  const previewStyles = isEditing ? {
    border: '1px dashed black',
  } : {};

  const height = isEditing ? initHeight : (heightOption === heightOptions.fixed ? (show ? fixedHeight : 0) : (show ? 'auto' : 0));

  const currentWidth = widthOption === formWidthOptions.fullWidth ? '100%' : maxWidth;

  const styles = {
    ...previewStyles,
    transition,
    maxWidth: currentWidth,
    width: '100%',
    padding,
    borderRadius,
    ...bgStyles({
      color: backgroundColor,
      option: backgroundOption,
      image: get(backgroundImage, 'url'),
    }),
    overflow: 'hidden',
    height,
    boxShadow: boxShadow?.rawValue,
  };

  return (
    <div id={itemId} style={rootBgStyle}>
      <div
        data-element-id={itemId || id}
        data-friendly-id={friendlyId}
        data-width-option={widthOption}
        data-max-width={maxWidth}
        data-height={initHeight}
        data-show={show}
        data-open-condition={`${openingCondition}:${openingConditionTime}`}
        data-event-options={JSON.stringify(eventOptions)}
        style={styles}
        ref={collapseRef}
      >
        <ChildrenElements
          isEditing={isEditing}
          childrenElements={childrenElements}
          droppableId={(itemId || id)}
          layoutDirection={layoutDirection}
          {...other}
        />
      </div>
    </div>
  );
};

export default Collapse;
