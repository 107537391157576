import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  appBar: {
    boxShadow: theme.shadows[5],
    position: props => (props.isPreviewMode ? 'absolute' : 'fixed'),
    backgroundColor: props => props.headerBgColor,
  },
  root: {
    width: '100%',
    height: '60px',
    background: '#fff',
    padding: '0',
    margin: '0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignContent: 'center',
    position: 'fixed',
    zIndex: '10',
    top: '0',
  },
  container: {
    width: '100%',
    maxWidth: '980px',
    margin: '0 auto',
    padding: '0 20px',
    minHeight: '60px',
  },

  logo: {
    maxWidth: '150px',
    display: 'block',
    position: 'relative',
    boxSizing: 'border-box',
    float: 'left',
    textAlign: 'center',
  },
  title: {
    textAlign: 'center',
    display: 'block',
    color: props => props.headerTextColor,
    padding: '0',
    maxWidth: '700px',
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%)',
    lineHeight: '1.2',
    fontSize: '1.3rem',
    '@media (max-width: 690px)': {
      fontSize: '1rem',
      lineHeight: '1.1',
    },
    '@media (max-width: 390px)': {
      fontSize: '0.8rem',
      lineHeight: '1',
    },
    '@media (max-width: 290px)': {
      fontSize: '0.7rem',
      lineHeight: '1',
    }
  }
}));

export const Header = ({
  logo,
  companyName,
  campaignHeader,
  username,
  headerBgColor = 'rgba(236, 236, 236, 1)',
  headerTextColor = 'rgba(236, 236, 236, 1)',
  isPreviewMode
}) => {
  const classes = useStyles({ headerBgColor, headerTextColor, isPreviewMode });

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.container}>
          <Avatar className={classes.logo} alt={`${companyName} logo`} src={logo} />
          <Typography
            className={classes.title}
            color="inherit"
            align="center"
            component="h1"
            variant="h6"
          >
            {campaignHeader}
          </Typography>
        </Toolbar>
      </AppBar>
    </div>
  );
};
