import { parseAction, parseCondition } from '../../help/actions';

export const ratingParser = (el, id) => {
  const mode = el.dataset.mode || 'stars';
  const disabledColor = el.dataset.disabledColor || '#FFBB00';
  const enabledColor = el.dataset.enabledColor || '#FFBB00';
  const svg = el.getElementsByTagName('svg').item(0);
  const label = el.getElementsByTagName('label').item(0);
  const numberOf = el.getElementsByTagName('label').length;
  const size = svg.style.width;
  const spacing = label.style.marginRight;

  const actionString = el.dataset.eventTrigger;
  let action = {};
  let condition = {};
  if (actionString) {
    const actionS = JSON.parse(actionString);
    action = parseAction(actionS);
    condition = parseCondition(actionS);
  }

  return {
    id,
    size,
    spacing,
    disabledColor,
    enabledColor,
    type: 'rating',
    numberOf,
    mode,
    ...action,
    ...condition,
  };
};
