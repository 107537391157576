import { attributesToTrackClick, attributesToTrackClickKeys } from "./constants"

const createHtmlAttributeString = (key, value) => `${key}="${value}"`

/**
 * Create html attributes
 * 
 * @returns {string}
 */
export const getHtmlAttributesToTrackClick = () => {
    const result = attributesToTrackClickKeys.reduce((acc, key) => {
        const html = createHtmlAttributeString(key, attributesToTrackClick[key])
        return `${html} `
    }, '')

    return result
}