import { createAction } from 'redux-actions';

const FETCH_CUSTOMERS = '@customers/FETCH_CUSTOMERS';
const PUT_CUSTOMERS = '@customers/PUT_CUSTOMERS';

const FETCH_DETAILS = '@customers/FETCH_DETAILS';
const PUT_DETAILS = '@customers/PUT_DETAILS';

const FETCH_CAMPAIGN_EVENTS = '@customers/FETCH_CAMPAIGN_EVENTS';
const PUT_CAMPAIGN_EVENTS = '@customers/PUT_CAMPAIGN_EVENTS';

const FETCH_CHAT_HISTORY = '@customers/FETCH_CHAT_HISTORY';
const PUT_CHAT_HISTORY = '@customers/PUT_CHAT_HISTORY';

const FETCH_CUSTOMERS_TAGS = '@customers/FETCH_CUSTOMERS_TAGS';
const PUT_CUSTOMERS_TAGS = '@customers/PUT_CUSTOMERS_TAGS';

const RUN_CAMPAIGNS_BY_TAG = '@customers/RUN_CAMPAIGNS_BY_TAG';

export const fetchCustomers = createAction(FETCH_CUSTOMERS);
export const putCustomers = createAction(PUT_CUSTOMERS);

export const fetchDetails = createAction(FETCH_DETAILS);
export const putDetails = createAction(PUT_DETAILS);

export const fetchCampaignEvents = createAction(FETCH_CAMPAIGN_EVENTS);
export const putCampaignEvents = createAction(PUT_CAMPAIGN_EVENTS);

export const fetchChatHistory = createAction(FETCH_CHAT_HISTORY);
export const putChatHistory = createAction(PUT_CHAT_HISTORY);

export const fetchCustomersTags = createAction(FETCH_CUSTOMERS_TAGS);
export const putCustomersTags = createAction(PUT_CUSTOMERS_TAGS);

export const runCampaignsByTag = createAction(RUN_CAMPAIGNS_BY_TAG);
