export { SNACK_VARIANTS, DEFAULT_VARIANT_MESSSAGE } from './snackbar';
export * from './colors';
export * from './routes';
export * from './forms';
export * from './formSchemas';
export { LIST } from './menu';

// tag
export const DIV = 'div';
export const TEXT = 'text';
export const SEPARATOR = 'separator';
export const MARGIN = 'margin';
export const VIDEO = 'video';
export const IMAGE = 'image';

export const FULL_WIDTH = 'fullWidth';
export const MAX_WIDTH = 600;
export const MIN_WIDTH = 200;
export const MIN_IMAGE_WIDTH = 10;
export const DEFAULT_IMAGE_WIDTH = 50;
export const MIN_SEPARATOR_WIDTH = 10;
export const DEFAULT_SEPARATOR_WIDTH = 50;
export const DEFAULT_VIDEO_WIDTH = 200;
export const MAX_SEPARATOR_WIDTH = 514;
// align text
export const ALIGN_CENTER_TEXT = 'center';
export const ALIGN_LEFT_TEXT = 'left';
export const ALIGN_RIGHT_TEXT = 'right';

// align component
export const ALIGN_CENTER = 'center';
export const ALIGN_LEFT = 'flex-start';
export const ALIGN_RIGHT = 'flex-end';

// fonts
export const LIGHT = {
  number: 400,
  string: 'Light',
};
export const REGULAR = {
  number: 500,
  string: 'Regular',
};
export const MEDIUM = {
  number: 700,
  string: 'Medium',
};
export const BOLD = {
  number: 700,
  string: 'Bold',
};
export const FONT_SIZE = new Array(100).fill(1).map((_, i) => i + 1);
export const FONT = [{
  name: 'Montserrat',
  weight: ['Light', 'Regular'],
}, {
  name: 'Roboto',
  weight: ['Light', 'Regular', 'Medium', 'Bold'],
}, {
  name: 'Arial',
  weight: ['Light', 'Regular', 'Medium', 'Bold'],
}, {
  name: 'Georgia',
  weight: ['Light', 'Regular', 'Medium', 'Bold'],
}, {
  name: 'Impact',
  weight: ['Light', 'Regular', 'Medium', 'Bold'],
}, {
  name: 'Tahoma',
  weight: ['Light', 'Regular', 'Medium', 'Bold'],
}, {
  name: 'Times New Roman',
  weight: ['Light', 'Regular', 'Medium', 'Bold'],
}, {
  name: 'Verdana',
  weight: ['Light', 'Regular', 'Medium', 'Bold'],
},
];

// resize constant

export const MAX_WIDTH_RESIZE = 900;
export const MAX_HEIGHT_RESIZE = 600;
