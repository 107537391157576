import {
  call, put, takeLatest, delay,
} from 'redux-saga/effects';

import { promoCodes as api } from '../api';
import {
  promoCodes as promoCodesActions,
  snackbar as snackbarActions,
} from '../redux/actions';

const STATUSES = {
  active: 1,
  onHold: 2,
  archived: 3
};

const INVERT_STATUSES = {
  1: 'active',
  2: 'onHold',
  3: 'archived',
};

const mapStatus = list =>
    list.map(item => ({ ...item, status: INVERT_STATUSES[item.status] }));

function* createPromoCodeUpload({ payload: { values } }) {
  const { promoCode, name } = values;
  try {
    yield put(promoCodesActions.createPromoCodeUploadRoutine.request());

    const { id } = values;
    const data = new FormData();

    data.append('file', ...promoCode);
    data.append('name', name);

    const upload = yield call(api.postPromoCodeUpload, id, data);

    yield put(promoCodesActions.createPromoCodeUploadRoutine.success(upload));
  } catch (error) {
    yield put(promoCodesActions.createPromoCodeUploadRoutine.failure(error));
  } finally {
    yield put(promoCodesActions.createPromoCodeUploadRoutine.fulfill());
  }
}

function* activatePromoCodeUploads({ payload: { id } }) {
  try {
    yield call(api.changeStatus, id, STATUSES.active);
    yield put(promoCodesActions.fetchPromoCodeUploads({ id }));
  } catch (error) {
    const message = error.request && error.request.status === 403 ? 'You cannot set Active status!' : 'Change status error occurred!';
    yield put(
      snackbarActions.enqueueSnackbar({
        message,
        options: { variant: 'error' },
      }),
    );
  }
}

function* holdPromoCodeUploads({ payload: { id, pageView } }) {
  try {
    yield call(api.changeStatus, id, STATUSES.onHold);
    yield put(promoCodesActions.fetchPromoCodeUploads({ id }));
  } catch (error) {
    yield put(
      snackbarActions.enqueueSnackbar({
        message: 'Error while set Hold status',
        options: { variant: 'error' },
      }),
    );
  }
}

function* fetchPromoCodeUploads({ payload: { id } }) {
  try {
    const item = yield call(api.fetchCampaign, id);
    yield put(promoCodesActions.putPromoCodeUploads(item));
  } catch (error) {
    yield put(
      snackbarActions.enqueueSnackbar({
        message: 'Error while fetch promo code upload',
        options: { variant: 'error' },
      }),
    );
  }
}

function* fetchPromoCodeUploadsList({
  payload: {
    page, perPage, status, search,
  },
}) {
  try {
    const [list, total] = yield call(api.fetchCampaignList, {
      page,
      perPage,
      status: STATUSES[status],
      search,
    });

    yield put(promoCodesActions.putPromoCodeUploadsList({ list: mapStatus(list), total }));
  } catch (error) {
    yield put(
      snackbarActions.enqueueSnackbar({
        message: 'Error while fetch list of promo code uploads',
        options: { variant: 'error' },
      }),
    );
  }
}

function* validateCampaignName({
  payload: {
    name, id, resolve, reject,
  },
}) {
  try {
    if (name) {
      yield delay(500);

      const result = yield call(api.validateCampaignName, name, id);

      if (result) {
        resolve();
      } else {
        reject({ campaign_name: 'This name is already used' });
      }
    }
  } catch (error) {
    reject();
    yield put(
      snackbarActions.enqueueSnackbar({
        message: 'Upload name validation problem!',
        options: { variant: 'error' },
      }),
    );
  }
}

function* fetchFileReport({payload}) {
  try {
    yield call(api.fetchFileReport, payload);
  } catch (error) {
    console.error(error);
  }
}

export default function* watcher() {
  yield takeLatest(promoCodesActions.createPromoCodeUploadRoutine.TRIGGER, createPromoCodeUpload);
  yield takeLatest(promoCodesActions.activatePromoCodeUploads.toString(), activatePromoCodeUploads);
  yield takeLatest(promoCodesActions.holdPromoCodeUploads.toString(), holdPromoCodeUploads);
  yield takeLatest(promoCodesActions.fetchPromoCodeUploads.toString(), fetchPromoCodeUploads);
  yield takeLatest(promoCodesActions.fetchPromoCodeUploadsList.toString(), fetchPromoCodeUploadsList);
  yield takeLatest(promoCodesActions.validatePromoCodeUploadName.toString(), validateCampaignName);
  yield takeLatest(promoCodesActions.fetchFileReport.toString(), fetchFileReport);
}
