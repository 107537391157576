/* eslint-disable max-len */
import PropTypes from 'prop-types';
import React from 'react';

const CustomersIcon = ({ fillColor }) => (
  <svg width={16} height={16} viewBox="0 0 24 24" fill="none" stroke={fillColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <path d="M18 21a8 8 0 0 0-16 0" />
    <circle cx="10" cy="8" r="5" />
    <path d="M22 20c0-3.37-2-6.5-4-8a5 5 0 0 0-.45-8.3" />
  </svg>
);

CustomersIcon.propTypes = {
  fillColor: PropTypes.string.isRequired,
};

export default CustomersIcon;
