import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
import cn from 'classnames';
import React from 'react';

import { WIDGET_LAYOUT_TYPES } from '../../constants/onsiteWidgets';
import { ItemComponent } from '../../generator/itemComponents';
import bgStyles from '../../help/bgStyles';
import ElementScripts from '../OnsiteForm/DesignStep/ElementScripts';
import ElementStyles from '../OnsiteForm/DesignStep/ElementStyles';

/* eslint-disable import/no-webpack-loader-syntax */
import modal from '!!raw-loader!../../assets/css/modals.css';
import slider from '!!raw-loader!../../assets/css/slider.css';
import swiper from '!!raw-loader!../../assets/css/swiper.css';

//= ======================================================
// HistoryCarousel
//= ======================================================

export const HistoryCarousel = ({
  slides,
  id,
  moveOption,
  effect,
  controlColor,
  verticalMargin,
  widgetType,
  carouselDuration,
  carouselDelay,
  carouselAnimationFunction,
  carouselType,
  slideWidth,
  layoutProperties,
  allElements,
  styleInjectionPlaceholder,
  eventsByElementIdMap,
}) => {
  const widgetSizes = WIDGET_LAYOUT_TYPES.CAROUSEL;
  const rootId = `${id}__${widgetType}`;
  const usedTypes = new Set();

  return (
    <>
      <style dangerouslySetInnerHTML={{ __html: swiper }} />
      <style dangerouslySetInnerHTML={{ __html: slider }} />
      <style dangerouslySetInnerHTML={{ __html: modal }} />
      <div
        id={rootId}
        className={`${widgetType}__wrapper`}
        data-layout-id={widgetType}
        data-slides-length={slides.length}
        data-slide-width={slideWidth}
        data-container={`swiper-container-${rootId}`}
        data-button-next={`swiper-button-next-${rootId}`}
        data-button-prev={`swiper-button-prev-${rootId}`}
        data-pagination={`swiper-pagination-${rootId}`}
        data-move-option={moveOption}
        data-effect={effect}
        data-control-color={controlColor}
        data-vertical-margin={verticalMargin}
        data-slider-box-id={`${rootId}__box`}
        data-carousel-animation-function={carouselAnimationFunction}
        data-carousel-delay={carouselDelay}
        data-carousel-duration={carouselDuration}
        data-carousel-type={carouselType}
        style={{ margin: `${verticalMargin || '0px'} auto` }}
      >
        <div className={cn(`swiper-container-${rootId}`, 'carousel__swiper-container')}>
          <div
            id={`${rootId}__box`}
            className="swiper-wrapper"
          >
            {slides.map((slide) => {
              const style = {
                ...bgStyles({
                  color: slide.backgroundColor,
                  option: slide.backgroundOption,
                  image: slide.backgroundImage?.url,
                }),
                width: slideWidth,
                padding: layoutProperties.padding,
                borderRadius: layoutProperties.borderRadius,
                borderWidth: layoutProperties.borderWidth,
                borderColor: layoutProperties.borderColor,
                borderStyle: 'solid',
              };
              return (
                <div
                  className={cn('carousel__swiper-slide', 'swiper-slide', `${widgetType}__history-item`)}
                  key={slide.id}
                  style={style}
                  data-hash={`${rootId}_${slide.id}`}
                >
                  <ElementScripts elements={allElements} />
                  <ElementStyles type="common" />
                  <style>{`\n${styleInjectionPlaceholder}\n`}</style>
                  {slide.items.map(({
                    id, type, droppable, ...itemProps
                  }) => {
                    const hasType = usedTypes.has(type);
                    usedTypes.add(type);
                    return (
                      <>
                        <ItemComponent
                          layoutProps={layoutProperties}
                          id={id}
                          key={id}
                          type={type}
                          sizes={widgetSizes}
                          widgetType={WIDGET_LAYOUT_TYPES.CAROUSEL}
                          horizontal={false}
                          alignItems="center"
                          {...itemProps}
                          eventsByElementIdMap={eventsByElementIdMap}
                        />
                        {hasType ? null : (
                          <ElementStyles key={`${type}-${id}`} type={type} />
                        )}
                      </>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
        <div className={cn('carousel__paginator', 'swiper-pagination', `swiper-pagination-${rootId}`)} />
        <div className={cn('swiper-button-next', `${widgetType}__left-btn`, 'swiper__btn-next', `swiper-button-next-${rootId}`)}>
          <ArrowForwardIos className={`${widgetType}__arrow-style`} />
        </div>
        <div className={cn('swiper-button-prev', `${widgetType}__right-btn`, 'swiper__btn-prev', `swiper-button-prev-${rootId}`)}>
          <ArrowBackIos className={`${widgetType}__arrow-style`} />
        </div>
      </div>
    </>
  );
};

//= ======================================================
// Big slider
//= ======================================================

export const FullScreenCarousel = ({
  slides,
  id,
  moveOption,
  effect,
  controlColor,
  verticalMargin,
  commonUrl,
  widgetType,
  moveSlideDistance = 100,
  carouselDuration,
  carouselDelay,
  carouselAnimationFunction,
  carouselType,
  layoutProperties,
  allElements,
  styleInjectionPlaceholder,
  eventsByElementIdMap,
}) => {
  const widgetSizes = WIDGET_LAYOUT_TYPES.CAROUSEL;
  const rootId = `${id}__${widgetType}`;
  const usedTypes = new Set();

  return (
    <>
      <style dangerouslySetInnerHTML={{ __html: swiper }} />
      <style dangerouslySetInnerHTML={{ __html: slider }} />
      <style dangerouslySetInnerHTML={{ __html: modal }} />
      <div
        id={rootId}
        data-layout-id={widgetType}
        data-slides-length={slides.length}
        data-container={`swiper-container-${rootId}`}
        data-button-next={`swiper-button-next-${rootId}`}
        data-button-prev={`swiper-button-prev-${rootId}`}
        data-pagination={`swiper-pagination-${rootId}`}
        data-move-slider-distance={moveSlideDistance}
        data-move-option={moveOption}
        data-effect={effect}
        data-control-color={controlColor}
        data-vertical-margin={verticalMargin}
        data-common-url={commonUrl}
        data-widget-type={widgetType}
        data-slider-box-id={`${rootId}__box`}
        data-carousel-animation-function={carouselAnimationFunction}
        data-carousel-delay={carouselDelay}
        data-carousel-duration={carouselDuration}
        data-carousel-type={carouselType}
        className={cn(`swiper-container-${rootId}`, 'carousel__swiper-container', 'carousel__wrapper')}
        style={{ margin: `${verticalMargin || '0px'} auto` }}
      >
        <div id={`${rootId}__box`} className="swiper-wrapper">
          {slides.map((slide) => {
            const style = {
              padding: layoutProperties.padding,
              borderRadius: layoutProperties.borderRadius,
              borderWidth: layoutProperties.borderWidth,
              borderColor: slide.borderColor,
              borderStyle: 'solid',
            };
            const bgStyle = {
              ...bgStyles({
                color: slide.backgroundColor,
                option: slide.backgroundOption,
                image: 'unset',
              }),
            };

            return (
              <div data-hash={`${rootId}_${slide.id}`} key={slide.id} className={cn('carousel__swiper-slide', 'swiper-slide', `${widgetType}__big-slider-inner`)} style={style}>
                <div>
                  <div
                    className={`${widgetType}__big-slider-content-item`}
                  >
                    <ElementScripts elements={allElements} />
                    <ElementStyles type="common" />
                    <style>{`\n${styleInjectionPlaceholder}\n`}</style>
                    {slide.items.map(({
                      id, type, droppable, ...itemProps
                    }) => {
                      const hasType = usedTypes.has(type);
                      usedTypes.add(type);
                      return (
                        <>
                          <ItemComponent
                            layoutProps={layoutProperties}
                            id={id}
                            key={id}
                            type={type}
                            sizes={widgetSizes}
                            widgetType={WIDGET_LAYOUT_TYPES.CAROUSEL}
                            horizontal={false}
                            alignItems="center"
                            {...itemProps}
                            eventsByElementIdMap={eventsByElementIdMap}
                          />
                          {hasType ? null : (
                            <ElementStyles key={`${type}-${id}`} type={type} />
                          )}
                        </>
                      );
                    })}
                  </div>
                </div>
                {slide.link ? (
                  <a target="_blank" href={slide.link} className={`${widgetType}__big-slider-live-photo`} data-bg={`${slide.backgroundImage?.url}`} style={bgStyle} />
                ) : (
                  <div className={`${widgetType}__big-slider-live-photo`} data-bg={`${slide.backgroundImage?.url}`} style={bgStyle} />
                )}
              </div>
            );
          })}
        </div>
        {moveOption === 'hover' ? (
          <div className={cn('carousel__paginator-hover', 'swiper-pagination', `swiper-pagination-${rootId}`)} />
        ) : (
          <>
            {moveOption === 'pause' && (
              <div className="carousel__paginator-fraction-wrapper">
                <div className={cn('carousel__paginator-fraction', 'swiper-pagination', `swiper-pagination-${rootId}`)} />
                <button className="carousel__pause-play-btn carousel__pause-play-btn--pause">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="carousel__pause-icon"
                  >
                    <rect width="6" height="16" rx="3" fill="#3E3E3E" />
                    <rect x="14" width="6" height="16" rx="3" fill="#3E3E3E" />
                  </svg>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="carousel__play-icon"
                  >
                    <path d="M3 17V3L15 10L3 17Z" fill="#3E3E3E" />
                  </svg>
                </button>
              </div>
            )}
            <div className={cn('carousel__paginator-stories', 'swiper-pagination', `swiper-pagination-${rootId}`)} />
            <div className={`${widgetType}__big-slide-btn-box`}>
              <div className={`${widgetType}__btn ${widgetType}__big-slide-btn ` + 'swiper__btn-prev ' + `swiper-button-prev-${rootId}`} size="medium">
                <svg
                  className={`${widgetType}__big-slide-btn-icon`}
                  width="11"
                  height="17"
                  viewBox="0 0 11 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path fillRule="evenodd" clipRule="evenodd" d="M6.05383 8.5L9.15033 11.634C9.90444 12.3972 9.92193 13.6196 9.18996 14.4041L8.23806 15.4243C7.44446 16.2749 6.09517 16.2712 5.30613 15.4164L0.792361 10.5265C-0.264095 9.38204 -0.264094 7.61796 0.792361 6.47347L5.4832 1.39172C6.20374 0.611143 7.4088 0.53005 8.22742 1.20705L9.31513 2.1066C10.2204 2.85526 10.2885 4.21922 9.46237 5.05436L6.05383 8.5Z" fill="#3E3E3E" />
                </svg>

                <div className={`${widgetType}__big-slider-btn-bg`} />
              </div>
              <div className={`${widgetType}__btn ${widgetType}__big-slide-btn ` + 'swiper__btn-next ' + `swiper-button-next-${rootId}`} size="medium">
                <svg
                  width="11"
                  height="17"
                  viewBox="0 0 11 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{}}
                  className={`${widgetType}__big-slide-btn-icon`}
                >
                  <path fillRule="evenodd" clipRule="evenodd" d="M4.60486 8.5L1.37085 4.98848C0.616363 4.16925 0.676427 2.89152 1.50444 2.14668L2.42289 1.32048C3.23664 0.588471 4.48765 0.646565 5.23006 1.45084L9.86633 6.47347C10.9228 7.61796 10.9228 9.38204 9.86633 10.5265L5.23006 15.5492C4.48765 16.3534 3.23664 16.4115 2.42289 15.6795L1.50444 14.8533C0.676427 14.1085 0.616363 12.8307 1.37085 12.0115L4.60486 8.5Z" fill="#3E3E3E" />
                </svg>

                <div className={`${widgetType}__big-slider-btn-bg`} />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
