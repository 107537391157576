import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import makeStyle from '@material-ui/styles/makeStyles';
import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useDispatch } from 'react-redux';

import api from '../../api/offerWall';
import { CURRENT_EDITOR } from '../../constants/editor';
import { openTextEditor } from '../../help/handleEditor';
import { snackbar as snackbarActions } from '../../redux/actions';
import TextEditorField from '../OnsiteForm/WidgetFormsFields/TextEditorField';

import CheckCodeFields from './CheckCodeFields';
import CustomPhoneInput from './CustomPhoneInput';

const useStyles = makeStyle((theme) => ({
  button: {
    color: (props) => props.textColor || '#000000',
    borderColor: (props) => props.textColor || '#000000',
    '&:disabled': {
      color: (props) => props.textColor || '#000000',
      borderColor: (props) => props.textColor || '#000000',
      opacity: 0.35,
      cursor: 'not-allowed !important',
      pointerEvents: 'auto',
    },
  },
  offerDescriptionTitle: {
    textDecoration: 'underline',
    padding: 0,
    border: 'none',
    background: 'none',
    cursor: 'pointer',
    outline: 'none',
  },
}));

const checkMsisdnValidity = (
  msisdn,
  operatorCode,
  operatorPrefix,
  operatorLength,
) => {
  if (!msisdn) {
    return '';
  }
  if (!operatorCode && msisdn) {
    return msisdn.slice(0, -3).concat('XXX');
  }

  const msisdnFormat = `${operatorCode}${operatorPrefix}`;

  if (
    operatorCode
    && msisdn.startsWith(msisdnFormat)
    && msisdn.length === operatorLength
  ) {
    return msisdn.slice(0, -3).concat('XXX');
  }
  return msisdnFormat;
};

export const TelecomFields = ({
  msisdn = '',
  onCodeSend,
  onAccept,
  onShowFullDescription,
  phoneNumberLabel,
  notice,
  offerDescriptionTitle,
  price,
  textColor,
  offerId,
  cfApiKey,
  buttonTitle,
  operatorPrefix,
  operatorMask,
  operatorCountry,
  operatorCode,
  operatorLength,
  setActiveEditor,
  focusTextEditor,
}) => {
  const classes = useStyles({ textColor });
  const [telNumber, setTelNumber] = useState(
    checkMsisdnValidity(msisdn, operatorCode, operatorPrefix, operatorLength),
  );
  const [codeDisabled, setCodeDisabled] = useState(!telNumber.includes('XXX'));
  const [loading, setLoading] = useState(false);
  const code1Ref = useRef();
  const dispatch = useDispatch();

  const formattedTel = useMemo(() => telNumber.replace(/[- )(]/g, ''), [
    telNumber,
  ]);

  useEffect(() => {
    if (telNumber.includes('XXX')) {
      api.sendSmsCode(cfApiKey, msisdn, offerId).catch(() => {
        dispatch(
          snackbarActions.enqueueSnackbar({
            message: 'There are troubles with sending code',
            options: { variant: 'error' },
          }),
        );
      });
    }
  }, [cfApiKey, dispatch, msisdn, offerId, telNumber]);

  const handleCodeSend = () => {
    const tel = telNumber.includes('XXX') ? msisdn : formattedTel;
    setLoading(true);
    onCodeSend(tel).then(() => {
      setLoading(false);
      setCodeDisabled(false);
      code1Ref.current.focus();
    });
  };

  const onOperatorNumberChange = useCallback(
    (e) => {
      setTelNumber(e.target.value);
      setCodeDisabled(true);
    },
    [setTelNumber, setCodeDisabled],
  );

  const onClick = useCallback(
    (e) => {
      e.preventDefault();
      onShowFullDescription();
    },
    [onShowFullDescription],
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <FormLabel
          data-editor-name={CURRENT_EDITOR.CPA_OW_PHONE_NUMBER_LABEL}
          data-text-editor
          onClick={openTextEditor(setActiveEditor)}
          style={{ color: textColor }}
        >
          {focusTextEditor === CURRENT_EDITOR.CPA_OW_PHONE_NUMBER_LABEL ? (
            <TextEditorField
              editorType="inline-editor"
              label="Phone number title"
              name="phoneNumberLabel"
              fullWidth
              required
              focusTextEditor={focusTextEditor}
            />
          ) : (
            <span dangerouslySetInnerHTML={{ __html: phoneNumberLabel }} />
          )}
        </FormLabel>
        <CustomPhoneInput
          value={telNumber}
          onChange={onOperatorNumberChange}
          country={operatorCountry}
          textColor={textColor}
        />
      </Grid>
      <Grid item xs={12}>
        {codeDisabled && !loading && (
          <div
            data-editor-name={CURRENT_EDITOR.CPA_OW_PHONE_BUTTON_TITLE}
            data-text-editor
            onClick={openTextEditor(setActiveEditor)}
          >
            {focusTextEditor === CURRENT_EDITOR.CPA_OW_PHONE_BUTTON_TITLE ? (
              <Button
                fullWidth
                variant="outlined"
                size="large"
                className={classes.button}
              >
                <TextEditorField
                  editorType="inline-editor"
                  label="Button title"
                  name="buttonTitle"
                  fullWidth
                  required
                  focusTextEditor={focusTextEditor}
                />
              </Button>
            ) : (
              <Button
                fullWidth
                variant="outlined"
                size="large"
                onClick={handleCodeSend}
                disabled={
                  operatorLength
                    ? !telNumber || formattedTel.length !== operatorLength
                    : !telNumber || telNumber.includes('X')
                }
                className={classes.button}
              >
                <span dangerouslySetInnerHTML={{ __html: buttonTitle }} />
              </Button>
            )}
          </div>
        )}
        {codeDisabled && loading && (
          <Box display="flex" justifyContent="center">
            <CircularProgress />
            {' '}
          </Box>
        )}
        {!codeDisabled && (
          <CheckCodeFields
            code1Ref={code1Ref}
            textColor={textColor}
            telNumber={telNumber}
            onAccept={onAccept}
          />
        )}
      </Grid>
      {price && (
        <Grid item xs={12}>
          <Typography
            variant="body2"
            style={{ color: textColor, fontSize: 17, fontWeight: 800 }}
          >
            {price}
          </Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        <Typography variant="caption" style={{ color: textColor }}>
          <span
            data-editor-name={CURRENT_EDITOR.CPA_OW_PHONE_NOTICE}
            data-text-editor
            onClick={openTextEditor(setActiveEditor)}
          >
            {focusTextEditor === CURRENT_EDITOR.CPA_OW_PHONE_NOTICE ? (
              <TextEditorField
                editorType="inline-editor"
                label="Notice"
                name="notice"
                fullWidth
                multiline
                rows={3}
                focusTextEditor={focusTextEditor}
              />
            ) : (
              <span dangerouslySetInnerHTML={{ __html: notice }} />
            )}
          </span>
          <div
            data-editor-name={CURRENT_EDITOR.CPA_OW_PHONE_OFFER_DESCRIPTION_TITLE}
            data-text-editor
            onClick={openTextEditor(setActiveEditor)}
          >
            {focusTextEditor === CURRENT_EDITOR.CPA_OW_PHONE_OFFER_DESCRIPTION_TITLE ? (
              <button
                className={classes.offerDescriptionTitle}
                style={{ color: textColor }}
              >
                <TextEditorField
                  editorType="inline-editor"
                  label="Offer description title"
                  name="offerDescriptionTitle"
                  fullWidth
                  required
                  focusTextEditor={focusTextEditor}
                />
              </button>
            ) : (
              <button
                onClick={onClick}
                className={classes.offerDescriptionTitle}
                style={{ color: textColor }}
              >
                <span dangerouslySetInnerHTML={{ __html: offerDescriptionTitle }} />
              </button>
            )}
          </div>
        </Typography>
      </Grid>
    </Grid>
  );
};
