/*
  <div style="width:120px;height:1px;background-color:#000000;margin:0 10px"></div>
*/
export const separatorParser = (el, id) => {
  if (el && el.firstElementChild) {
    const separatorElement = el.firstElementChild;
    const { className } = separatorElement;
    const {
      width,
      height,
      margin,
      backgroundColor: color,
    } = separatorElement.style;
    const fullLength = className === 'cf-separator__full-length';
    const size = height;
    const length = width;
    const margins = margin.split(' ')[0];

    return {
      size,
      length,
      margins,
      fullLength,
      color,
      type: 'separator',
      id,
    };
  }
};
