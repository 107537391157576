import { fromPx } from "../format"
import { toPx } from "../parse"

const createMarks = (optionKey, values) => ([
    {
        value: values[optionKey]?.min,
        text: `${values[optionKey]?.min}px`
    },
    {
        value: values[optionKey]?.max,
        text: `${values[optionKey]?.max}px`
    },
])

const createMinMaxOptions = (optionKey, min, max) => ({
    [optionKey]: {
        min,
        max
    }
})

/**
 * Keys for values witch can be input with slider
 */
const boxShadowSliderKeys = {
    offsetX: 'offsetX',
    offsetY: 'offsetY',
    spreadRadius: 'spreadRadius',
    blurRadius: 'blurRadius',
}

const textShadowSliderKeys = {
    offsetX: 'offsetX',
    offsetY: 'offsetY',
    spreadRadius: 'spreadRadius',
}

/**
 * Minimun and maximum values for slider
 */
const boxShadowSliderValues = {
    ...createMinMaxOptions(boxShadowSliderKeys.offsetX, -50, 50),
    ...createMinMaxOptions(boxShadowSliderKeys.offsetY, -50, 50),
    ...createMinMaxOptions(boxShadowSliderKeys.spreadRadius, -50, 50),
    ...createMinMaxOptions(boxShadowSliderKeys.blurRadius, 0, 50),
}

const textShadowSliderValues = {
    ...boxShadowSliderValues,
    ...createMinMaxOptions(boxShadowSliderKeys.spreadRadius, 0, 50),
}

/**
 * Slider marks
 */
const boxShadowSliderMarks = Object.keys(boxShadowSliderKeys).reduce((acc, key) => ({
    ...acc,
    [key]: createMarks(key, boxShadowSliderValues)
}), {})

const textShadowSliderMarks = Object.keys(textShadowSliderKeys).reduce((acc, key) => ({
    ...acc,
    [key]: createMarks(key, textShadowSliderValues)
}), {})

/**
 * Readable labels for form fields
 */
const boxShadowSliderKeysMap = {
    [boxShadowSliderKeys.offsetX]: 'Shift Right',
    [boxShadowSliderKeys.offsetY]: 'Shift Down',
    [boxShadowSliderKeys.spreadRadius]: 'Spread',
    [boxShadowSliderKeys.blurRadius]: 'Blur',
}

/**
 * Key to react form
 */
const boxShadowFieldKeys = {
    ...boxShadowSliderKeys,
    color: 'color',
    inset: 'inset',
    rawValue: 'rawValue'
}

/**
 * Default box shadow object
 */
const defaultBoxShadowProp = {
    boxShadow: {
        [boxShadowFieldKeys.color]: 'rgba(0, 0, 0, 0.54)',
        [boxShadowFieldKeys.inset]: false,
        [boxShadowFieldKeys.offsetX]: 0,
        [boxShadowFieldKeys.offsetY]: 0,
        [boxShadowFieldKeys.spreadRadius]: 0,
        [boxShadowFieldKeys.blurRadius]: 0,
        [boxShadowFieldKeys.rawValue]: 'none',
    }
}

/**
 * 
 * @param {object} boxShadow
 * @property {boolean} boxShadow.inset
 * @property {number} boxShadow.offsetX
 * @property {number} boxShadow.offsetY
 * @property {number} boxShadow.blurRadius
 * @property {number} boxShadow.spreadRadius
 * @property {string} boxShadow.color
 * @property {boolean} textShadow
 * 
 * @returns {string} raw value
 */
const generateRawValue = ({inset = false, offsetX = 0, offsetY = 0, blurRadius = 0, spreadRadius = 0, color = '', textShadow = false} = {}) => 
    textShadow 
    ? `${color} ${toPx(offsetX)} ${toPx(offsetY)} ${toPx(spreadRadius)}`
    : `${inset ? 'inset' : ''} ${toPx(offsetX)} ${toPx(offsetY)} ${toPx(blurRadius)} ${toPx(spreadRadius)} ${color}`

/**
 * @param {CSSStyleDeclaration} styles
 * @example Box shadow format from styles
 * 
 * 'rgb(163, 51, 51) 3px 5px 21.29px 10px inset'
 * `${color} ${offsetX} ${offsetY} ${blur} ${spread} ${inset}`
 */
const parseBoxShadow = (styles) => {
    if (!(styles instanceof CSSStyleDeclaration)) return defaultBoxShadowProp

    const rawValueBoxShadow = styles.getPropertyValue('box-shadow')
    const rawValueTextShadow = styles.getPropertyValue('text-shadow')

    const boxShadowProps = parseBoxShadowString(rawValueBoxShadow)
    const textShadowProps = parseTextShadowString(rawValueTextShadow)

    return {
        ...boxShadowProps,
        ...textShadowProps,
    }
}

const parseBoxShadowString = (rawValue) => {
    const colorRegex = /(rgba?\((.)*\))/
    const { boxShadow: defaultBoxShadow } = defaultBoxShadowProp
    const values = rawValue.replace(colorRegex, '').split(' ').filter(v => v).map(v => fromPx(v))
    
    const color = (rawValue.match(colorRegex) || [])[0] || defaultBoxShadow.color
    const inset = rawValue.includes('inset')

    return {
        boxShadow: {
            [boxShadowFieldKeys.color]: color,
            [boxShadowFieldKeys.inset]: inset,
            [boxShadowFieldKeys.offsetX]: values[0] || 0,
            [boxShadowFieldKeys.offsetY]: values[1] || 0,
            [boxShadowFieldKeys.blurRadius]: values[2] || 0,
            [boxShadowFieldKeys.spreadRadius]: values[3] || 0,
            [boxShadowFieldKeys.rawValue]: rawValue,
        },
    }
}

const parseTextShadowString = (rawValueTextShadow) => {
    const colorRegex = /(rgba?\((.)*\))/
    const { boxShadow: defaultBoxShadow } = defaultBoxShadowProp
    const valuesTextShadow = rawValueTextShadow.replace(colorRegex, '').split(' ').filter(v => v).map(v => fromPx(v))
    const colorTextShadow = (rawValueTextShadow.match(colorRegex) || [])[0] || defaultBoxShadow.color

    return {
        textShadow: {
            [boxShadowFieldKeys.color]: colorTextShadow,
            [boxShadowFieldKeys.offsetX]: valuesTextShadow[0] || 0,
            [boxShadowFieldKeys.offsetY]: valuesTextShadow[1] || 0,
            [boxShadowFieldKeys.spreadRadius]: valuesTextShadow[2] || 0,
            [boxShadowFieldKeys.rawValue]: rawValueTextShadow,
        }
    }
}

export {
    boxShadowSliderKeys,
    boxShadowSliderKeysMap,
    boxShadowSliderValues,
    boxShadowSliderMarks,
    defaultBoxShadowProp,
    boxShadowFieldKeys,
    generateRawValue,
    parseBoxShadow,
    parseBoxShadowString,
    parseTextShadowString,

    textShadowSliderKeys,
    textShadowSliderValues,
    textShadowSliderMarks,
}