import { getSteps } from '../../help';
import {
  OPEN_MODAL_SELECT_ITEM,
  OPEN_MODAL_DELETE,
  OPEN_BASE_MENU,
  OPEN_RIGHT_MENU,
  CHANGE_ACTIVE_STEP,
  SHOW_PICKER_COLOR,
  TOGGLE_EDITOR_STYLE,
  TOGGLE_EDITOR_BLOCK_STYLE,
  SET_EDITOR_COLOR,
  SET_EDITOR_FONTSIZE,
  SET_EDITOR_FONTWEIGHT,
  SET_EDITOR_FONTFAMILY,
  SET_EDITOR_BORDER_COLOR,
  SET_EDITOR_BORDER,
  UNSET_EDITOR_BORDER,
  SET_BOLD_BUTTON_STATUS,
  SET_ITALIC_BUTTON_STATUS,
  SET_UNDERLINED_BUTTON_STATUS
} from '../actionTypes';

const INITIAL_STATE = {
  isShowModalSelectItem: false,
  isShowModalDelete: false,
  steppers: {
    activeStep: 0,
    steps: getSteps()
  },
  isOpenBaseMenuMobile: false,
  isOpenRightMenuMobile: false,
  isOpenPickerColor: false,
  activeCampaignId: null,
  boldButtonActive: false,
  italicButtonActive: false,
  underlineButtonActive: false,
  toggleEditorStyle: '',
  toggleEditorBlockStyle: '',
  editorColor: '',
  editorFontSize: '14',
  editorFontWeight: '',
  editorFontFamily: 'Roboto',
  editorBorderColor: '',
  editorBorderSet: false,
  editorBorderUnset: false
};

function programReducer(state = INITIAL_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case OPEN_MODAL_SELECT_ITEM: {
      return {
        ...state,
        isShowModalSelectItem: payload
      };
    }
    case OPEN_MODAL_DELETE: {
      return {
        ...state,
        isShowModalDelete: payload
      };
    }
    case CHANGE_ACTIVE_STEP: {
      return {
        ...state,
        steppers: {
          ...state.steppers,
          activeStep: payload
        }
      };
    }
    case OPEN_BASE_MENU: {
      return {
        ...state,
        isOpenBaseMenuMobile: payload
      };
    }
    case OPEN_RIGHT_MENU: {
      return {
        ...state,
        isOpenRightMenuMobile: payload
      };
    }
    case SHOW_PICKER_COLOR: {
      return {
        ...state,
        isOpenPickerColor: payload
      };
    }
    case TOGGLE_EDITOR_STYLE: {
      return {
        ...state,
        toggleEditorStyle: payload
      };
    }
    case TOGGLE_EDITOR_BLOCK_STYLE: {
      return {
        ...state,
        toggleEditorBlockStyle: payload
      };
    }
    case SET_EDITOR_COLOR: {
      return {
        ...state,
        editorColor: payload
      };
    }
    case SET_EDITOR_FONTSIZE: {
      return {
        ...state,
        editorFontSize: payload.toString()
      };
    }
    case SET_EDITOR_FONTWEIGHT: {
      return {
        ...state,
        editorFontWeight: payload
      };
    }
    case SET_EDITOR_FONTFAMILY: {
      return {
        ...state,
        editorFontFamily: payload
      };
    }
    case SET_EDITOR_BORDER_COLOR: {
      return {
        ...state,
        editorBorderColor: payload
      };
    }
    case SET_EDITOR_BORDER: {
      return {
        ...state,
        editorBorderSet: payload
      };
    }
    case UNSET_EDITOR_BORDER: {
      return {
        ...state,
        editorBorderUnset: payload
      };
    }
    case SET_BOLD_BUTTON_STATUS: {
      return {
        ...state,
        boldButtonActive: payload
      };
    }
    case SET_ITALIC_BUTTON_STATUS: {
      return {
        ...state,
        italicButtonActive: payload
      };
    }
    case SET_UNDERLINED_BUTTON_STATUS: {
      return {
        ...state,
        underlineButtonActive: payload
      };
    }
    default:
      return state;
  }
}

export default programReducer;
