import React, { memo } from 'react';

import SettingsMenu from '../SettingsMenu';
import BaseMenu from '../BaseMenu';

function Menu() {
  return (
    <>
      <BaseMenu />
      <SettingsMenu />
    </>
  );
}

export default memo(Menu);
