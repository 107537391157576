import axios from 'axios';
import axiosBearer from './axios';
import {object} from '../help';
import {DEFAULT_HEADER} from '../constants/network';
import {
  LOGIN_ENDPOINT,
  LOGOUT_ENDPOINT,
  MODULES_ENDPOINT, 
  OTP_ENDPOINT, 
  OTP_ENDPOINT_RESEND,
  PING_ENDPOINT,
  REGISTRATION_ENDPOINT
} from '../constants/endpoints';

async function register(data) {
  const options = {
    headers: {
      ...DEFAULT_HEADER,
    },
  };
  const { data: result } = await axios.post(REGISTRATION_ENDPOINT, object.camelToSnake(data), options);
  return object.snakeToCamel(result);
}

async function login(data) {
  const options = {
    headers: {
      ...DEFAULT_HEADER,
    },
  };
  const { data: result } = await axiosBearer.post(LOGIN_ENDPOINT, object.camelToSnake(data), options);
  return object.snakeToCamel(result);
}

async function otp(data) {
  const options = {
    headers: {
      ...DEFAULT_HEADER,
    },
  };
  await axiosBearer.post(OTP_ENDPOINT, object.camelToSnake(data), options);
}

async function otpResend() {
  const options = {
    headers: {
      ...DEFAULT_HEADER,
    },
  };
  await axiosBearer.post(OTP_ENDPOINT_RESEND, {}, options);
}

async function logout(authHeader) {
  const options = {
    headers: {
      ...DEFAULT_HEADER,
      ...authHeader,
    },
  };
  const result = await axiosBearer.get(LOGOUT_ENDPOINT, options);
  return result;
}

async function ping(authHeader) {
  const options = {
    headers: {
      ...DEFAULT_HEADER,
      ...authHeader,
    },
  };
  return await axiosBearer.get(PING_ENDPOINT, options);
}

async function getAllowedModules(authHeader) {
  const options = {
    headers: {
      ...DEFAULT_HEADER,
      ...authHeader,
    },
  };
  const { data: result, status } = await axios.get(MODULES_ENDPOINT, options);
  return { result, status };
}

export default {
  register,
  login,
  logout,
  ping,
  otp,
  otpResend,
  getAllowedModules
};
