/* eslint-disable */

      export default `
        <div>
          <style>
            #comfolks-chat-container {
    --comfolks-color-primary: #00b48c;
    --comfolks-color-header: #642887;
    --comfolks-font-size: 15px;
    --comfolks-header-height: 48px;
}

#comfolks-chat-container {
    pointer-events: auto !important;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Arial, sans-serif;
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1301;
}

#comfolks-chat-button-wr {
    position: relative;
}

#comfolks-chat-container .comfolks-button {
    text-decoration: none;
    box-shadow: 0 0 10px 1px #0000004d;
    border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    cursor: pointer !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
    padding: 8px;
    box-sizing: border-box;
}

#comfolks-chat-container .comfolks-button img {
    max-width: 100%;
}

button {
    cursor: pointer;
    border: 0;
}

a {
    color: inherit;
}

p {
    margin: 0;
    padding: 0;
}

.comfolks-button svg {
    width: 100%;
    height: 100%;
}

.comfolks-button .cross svg {
    width: 34px;
}

#comfolks-chat-button {
    background-color: var(--comfolks-color-primary);
}

#comfolks-chat-container #comfolks-toggle-button {
    position: relative !important;
    background-color: var(--comfolks-color-primary);
}

#comfolks-toggle-button {
    position: relative;
}

@keyframes blink {
    0%, 100% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }
}

.comfolks-message-icon {
    position: absolute;
    left: 0;
    top: 0;
    display: none;
    align-items: center;
    justify-content: center;
    width: 15px;
    height: 15px;
    color: #fff;
    border-radius: 100%;
    background-color: #bababa;
    animation: blink 1s infinite;
}

.comfolks-message-icon svg {
    max-width: 65%;
    max-height: 70%;
}

#comfolks-toggle-button.new-message .comfolks-message-icon {
    display: flex;
}

#comfolks-chat-button-wr.active .comfolks-message-icon {
    top: -123px;
    z-index: 1;
}

#comfolks-toggle-button .cross {
    opacity: 0;
    background-color: #d3d3d3;
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 100%;
}

#comfolks-chat-container #comfolks-telegram-button {
    opacity: 0;
    position: absolute !important;
    top: -60px;
    right: 0;
    padding: 2px;
    background-color: #29b6f6;
}

#comfolks-chat-container #comfolks-chat-button {
    opacity: 0;
    position: absolute !important;
    top: -120px;
    right: 0;
}

#comfolks-chat-button-wr.active #comfolks-toggle-button .cross,
#comfolks-chat-button-wr.active #comfolks-telegram-button,
#comfolks-chat-button-wr.active #comfolks-chat-button {
    opacity: 1;
}

#comfolks-chat-button img {
    cursor: pointer;
}

#comfolks-chat-window {
    display: none;
    position: fixed;
    overflow: hidden;
    bottom: 0;
    right: 0;
    border: 1px solid #cfcfcf;
    background-color: #ffffff;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    box-shadow: 0 0 10px 1px #0000004d;
    transition: 0.3s ease width, 0.3s ease height;
}

#comfolks-chat-window * {
    font-size: var(--comfolks-font-size);
}

@media (min-width: 768px) {
    #comfolks-chat-window {
        border-radius: 10px;
        bottom: 20px;
        right: 80px;
        width: 400px;
        height: 500px;
    }

    #comfolks-chat-window.maximized {
        width: 90vw;
        height: 90vh;
    }
}

#comfolks-chat-body {
    display: flex;
    flex-direction: column;
    height: calc(100% - var(--comfolks-header-height));
}

#comfolks-chat-dialog {
    padding: 16px;
    margin-bottom: auto;
    line-height: 21px;
    overflow-y: auto;
}

#comfolks-chat-dialog img {
    max-width: 100%;
}

#comfolks-chat-buttons {
    padding: 10px 16px 10px;
}

#comfolks-chat-buttons button {
    display: inline-block;
    font-size: var(--comfolks-font-size);
    cursor: pointer;
    background-color: #ffffff;
    border: 1px solid var(--comfolks-color-primary);
    color: var(--comfolks-color-primary);
    margin: 0 6px 6px 0;
    border-radius: 12px;
    padding: 8px;
    transition: 0.3s ease background-color;
}

#comfolks-chat-header {
    display: flex;
    align-items: center;
    background-color: var(--comfolks-color-header);
    height: var(--comfolks-header-height);
    padding: 0 16px;
    color: #fff;
}

.header-buttons {
    margin-left: auto;
}

.header-button svg {
    width: 20px;
    height: 20px;
}

.header-button {
    display: inline-block;
    color: #fff !important;
    border: 0;
    border-radius: 100%;
    background-color: rgba(255, 255, 255, 0.25);
    cursor: pointer;
    width: 30px;
    height: 30px;
    padding: 5px 0;
}

.comfolks-chat-bottom {
    position: relative;
    padding-top: 14px;
}

#comfolks-chat-loader {
    display: flex;
    justify-content: center;
    position: absolute;
    margin: 10px;
    top: -7px;
    right: 7px;
}

.comfolks-dot {
    height: 10px;
    width: 10px;
    background-color: #999;
    border-radius: 50%;
    margin: 0 5px;
    animation: comfolks-dot-bounce 1.0s infinite ease-in-out both;
}

.comfolks-dot:nth-child(1) {
    animation-delay: 0.0s;
}

.comfolks-dot:nth-child(2) {
    animation-delay: 0.3s;
}

.comfolks-dot:nth-child(3) {
    animation-delay: 0.6s;
}

@keyframes comfolks-dot-bounce {

    0%,
    80%,
    100% {
        transform: scale(0);
    }

    40% {
        transform: scale(1.0);
    }
}

#comfolks-chat-dialog .comfolks-otp-form {
    padding: 6px 0;
}

#comfolks-chat-dialog .comfolks-otp-input,
#comfolks-chat-dialog .comfolks-otp-textarea,
#comfolks-chat-dialog .comfolks-otp-selector {
    display: block;
    line-height: 20px;
    padding: 6px;
    border: 1px solid var(--comfolks-color-header);
    margin: 8px 0;
    width: 100%;
    box-sizing: border-box;
    border-radius: 6px;
}

#comfolks-chat-dialog .comfolks-otp-selector {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

#comfolks-chat-dialog .comfolks-otp-button {
    background-color: var(--comfolks-color-header);
    color: #fff;
    padding: 6px;
    border-radius: 6px;
    width: 100%;
    margin: 4px 0;
    box-sizing: border-box;
}

#comfolks-chat-dialog .comfolks-inline-button {
    background-color: transparent;
    color: var(--comfolks-color-header);
    width: 100%;
    margin: 4px 0;
}

#comfolks-chat-dialog .comfolks-otp-button-inline {
    color: var(--comfolks-color-header);
    margin: 4px 0;
}

#comfolks-chat-dialog .comfolks-otp-error {
    color: red;
    font-size: 12px;
}

#comfolks-chat-window input, #comfolks-chat-window select {
    font-size: 16px;
    font-family: inherit;
}

#comfolks-chat-window input:focus {
    transform: scale(1);
    transform-origin: 0 0;
}

#comfolks-chat-window a {
    color: var(--comfolks-color-primary) !important;
}

#comfolks-chat-window a span {
    color: var(--comfolks-color-primary) !important;
}

.phoneVerification__input {
    display: none;
}

#comfolks-chat-form {
    position: relative;
    padding: 16px;
    display: flex;
    gap: 8px;
    border-top: 1px solid #dbdbdb;
}

.comfolks-chat-input-wr {
    overflow: hidden;
    position: relative;
    display: flex !important;
    flex-grow: 1;
}

.comfolks-file-input {
    transform: translateX(-7px);
    position: absolute !important;
    right: 0;
    top: 10px;
    transition: 0.3s ease transform;
}

@media (min-width: 768px) {
    .comfolks-file-input {
        top: 0;
    }
}

.comfolks-chat-input-wr .comfolks-input-hidden {
    display: none;
}

.comfolks-file-label {
    cursor: pointer;
    display: inline-flex;
    padding: 7px 0;
}

#comfolks-chat-input {
    display: block;
    width: 100%;
    padding: 16px 72px 16px 12px;
    flex-grow: 1;
    line-height: 24px;
    border-radius: 12px;
    border: 1px solid #d8d8d8;
    outline-color: var(--comfolks-color-primary);
}

#comfolks-chat-input::placeholder {
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Arial, sans-serif;
}

#comfolks-chat-send {
    transform: translateX(50px);
    position: absolute !important;
    right: 0;
    top: 6px;
    height: 44px;
    width: 44px;
    border-radius: 100%;
    flex-shrink: 0;
    background-color: transparent;
    display: flex;
    pointer-events: none;
    opacity: 0;
    align-items: center;
    justify-content: center;
    transition: 0.4s ease transform, 0.4s ease opacity;
}

#comfolks-chat-send svg {
    width: 34px;
    height: 34px;
}

@media (min-width: 768px) {
    #comfolks-chat-send {
        top: -3px;
        height: 42px;
        width: 42px;
    }

    #comfolks-chat-send svg {
        width: 28px;
        height: 28px;
    }

    #comfolks-chat-input {
        padding: 6px 72px 6px 10px;
    }
}

#comfolks-chat-form.active #comfolks-chat-send {
    transform: translateX(0);
    opacity: 1;
    pointer-events: auto;
}

#comfolks-chat-form.active .comfolks-file-input {
    transform: translateX(-40px);
}

#comfolks-chat-message {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    margin-bottom: 18px;
    max-width: 470px;
}

#comfolks-user-message {
    position: relative;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 18px;
}

#comfolks-chat-time {
    font-size: 11px;
    position: absolute;
    bottom: 0;
    color: #e5e5e5;
    min-width: 200px;
    transition: 0.3s ease opacity;
}

#comfolks-chat-message #comfolks-chat-time,
#comfolks-chat-message-search #comfolks-chat-time {
    left: 12px;
    text-align: left;
    color: #8c8c8c;
}

#comfolks-chat-message-search #comfolks-chat-time {
    bottom: -21px;
}

#comfolks-user-message #comfolks-chat-time {
    right: 12px;
    text-align: right;
}

#comfolks-chat-message-inner {
    position: relative;
    overflow: hidden;
    color: #333;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    background-color: #f6f6f6;
    border-radius: 12px 12px 12px 0;
    padding: 12px 12px 20px 12px;
    max-width: 100%;
    min-width: 100px;
    box-sizing: border-box;
}

#comfolks-user-message-inner {
    color: #fff;
    overflow: hidden;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    background-color: var(--comfolks-color-primary);
    border-radius: 12px 12px 0 12px;
    padding: 12px 12px 20px 12px;
    max-width: 100%;
    min-width: 100px;
    box-sizing: border-box;
}

#comfolks-chat-message-search {
    color: #333;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    background-color: #f6f6f6;
    border-radius: 12px 12px 12px 0;
    border: 1px solid var(--comfolks-color-primary);
    padding: 12px;
    max-width: 100%;
    box-sizing: border-box;
    transition: 0.3s ease background-color;
    margin-bottom: 16px;
}

#comfolks-chat-body #comfolks-chat-message-search {
    overflow: visible !important;
}

#comfolks-chat-message-search:hover {
    background-color: #dcdcdc;
}

.recaptcha-slot {
    margin-bottom: 16px;
}

#comfolks-chat-date {
    background-color: #ededed;
    border-radius: 16px;
    color: rgba(51, 51, 51, 0.5);
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    margin: 28px auto 13px auto;
    padding: 4px 12px;
    width: -moz-fit-content;
    width: fit-content;
}

.comfolks-chat-avatar {
    border-radius: 50%;
    height: 32px;
    margin-right: 8px;
    width: 32px;
    background-color: var(--comfolks-color-header);
    background-size: cover;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    color: #fff;
}

/* rating-container */
.rating-title {
    margin-bottom: 12px;
    text-align: center;
}

.stars-container {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    margin-bottom: 12px;
}

.star {
    font-size: 30px !important;
    cursor: pointer;
    color: #ccc;
    margin: 0 4px;
    transition: color 0.2s;
}

.star:hover,
.star:hover~.star,
.star.selected,
.star.selected~.star {
    color: #f39c12;
}
          </style>
          <div chat-container id="comfolks-chat-container">
    <div id="comfolks-chat-button-wr">
        <button class="comfolks-button" id="comfolks-toggle-button">
            <div class="comfolks-message-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-mail"><rect width="20" height="16" x="2" y="4" rx="2"/><path d="m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7"/></svg>
            </div>
            <svg id="comfolks-chat-icon" width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="Communication / Chat_Conversation_Circle"><path stroke="#ffffff" id="Vector" d="M9.33814 15.9905C12.4946 15.8151 15 13.2003 15 10C15 6.68629 12.3137 4 9 4C5.68629 4 3 6.68629 3 10C3 11.1807 3.34094 12.2817 3.92989 13.21L3.50586 14.482L3.50518 14.4839C3.34278 14.9711 3.26154 15.2149 3.31938 15.3771C3.36979 15.5184 3.48169 15.6299 3.62305 15.6803C3.78472 15.7379 4.02675 15.6573 4.51069 15.4959L4.51758 15.4939L5.79004 15.0698C6.7183 15.6588 7.81935 15.9998 9.00006 15.9998C9.11352 15.9998 9.22624 15.9967 9.33814 15.9905ZM9.33814 15.9905C9.33822 15.9907 9.33806 15.9902 9.33814 15.9905ZM9.33814 15.9905C10.1591 18.3259 12.3841 20.0002 15.0001 20.0002C16.1808 20.0002 17.2817 19.6588 18.2099 19.0698L19.482 19.4939L19.4845 19.4944C19.9717 19.6567 20.2158 19.7381 20.378 19.6803C20.5194 19.6299 20.6299 19.5184 20.6803 19.3771C20.7382 19.2146 20.6572 18.9706 20.4943 18.4821L20.0703 17.21L20.2123 16.9746C20.7138 16.0979 20.9995 15.0823 20.9995 14C20.9995 10.6863 18.3137 8 15 8L14.7754 8.00414L14.6621 8.00967" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" /></g></svg>
            <div class="cross">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#fff" d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/><path d="M0 0h24v24H0z" fill="none"/></svg>
            </div>
        </button>
        <a href="https://t.me/ComfolksBot" target="_blank" class="comfolks-button" id="comfolks-telegram-button">
            <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 48 48" width="48px" height="48px"><path fill="#29b6f6" d="M24 4A20 20 0 1 0 24 44A20 20 0 1 0 24 4Z"/><path fill="#fff" d="M33.95,15l-3.746,19.126c0,0-0.161,0.874-1.245,0.874c-0.576,0-0.873-0.274-0.873-0.274l-8.114-6.733 l-3.97-2.001l-5.095-1.355c0,0-0.907-0.262-0.907-1.012c0-0.625,0.933-0.923,0.933-0.923l21.316-8.468 c-0.001-0.001,0.651-0.235,1.126-0.234C33.667,14,34,14.125,34,14.5C34,14.75,33.95,15,33.95,15z"/><path fill="#b0bec5" d="M23,30.505l-3.426,3.374c0,0-0.149,0.115-0.348,0.12c-0.069,0.002-0.143-0.009-0.219-0.043 l0.964-5.965L23,30.505z"/><path fill="#cfd8dc" d="M29.897,18.196c-0.169-0.22-0.481-0.26-0.701-0.093L16,26c0,0,2.106,5.892,2.427,6.912 c0.322,1.021,0.58,1.045,0.58,1.045l0.964-5.965l9.832-9.096C30.023,18.729,30.064,18.416,29.897,18.196z"/></svg>
        </a>
        <button class="comfolks-button" id="comfolks-chat-button">
            <svg id="comfolks-chat-icon" width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="Communication / Chat_Conversation_Circle"><path stroke="#ffffff" id="Vector" d="M9.33814 15.9905C12.4946 15.8151 15 13.2003 15 10C15 6.68629 12.3137 4 9 4C5.68629 4 3 6.68629 3 10C3 11.1807 3.34094 12.2817 3.92989 13.21L3.50586 14.482L3.50518 14.4839C3.34278 14.9711 3.26154 15.2149 3.31938 15.3771C3.36979 15.5184 3.48169 15.6299 3.62305 15.6803C3.78472 15.7379 4.02675 15.6573 4.51069 15.4959L4.51758 15.4939L5.79004 15.0698C6.7183 15.6588 7.81935 15.9998 9.00006 15.9998C9.11352 15.9998 9.22624 15.9967 9.33814 15.9905ZM9.33814 15.9905C9.33822 15.9907 9.33806 15.9902 9.33814 15.9905ZM9.33814 15.9905C10.1591 18.3259 12.3841 20.0002 15.0001 20.0002C16.1808 20.0002 17.2817 19.6588 18.2099 19.0698L19.482 19.4939L19.4845 19.4944C19.9717 19.6567 20.2158 19.7381 20.378 19.6803C20.5194 19.6299 20.6299 19.5184 20.6803 19.3771C20.7382 19.2146 20.6572 18.9706 20.4943 18.4821L20.0703 17.21L20.2123 16.9746C20.7138 16.0979 20.9995 15.0823 20.9995 14C20.9995 10.6863 18.3137 8 15 8L14.7754 8.00414L14.6621 8.00967" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" /></g></svg>
        </button>
    </div>
    <div id="comfolks-chat-window" style="display: none;">
        <div id="comfolks-chat-header">
            <span id="comfolks-chat-title">Чат-бот Ucell</span>
            <div class="header-buttons">
                <button class="header-button comfolks-chat-maximize">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-maximize-2"><polyline points="15 3 21 3 21 9"/><polyline points="9 21 3 21 3 15"/><line x1="21" x2="14" y1="3" y2="10"/><line x1="3" x2="10" y1="21" y2="14"/></svg>
                </button>
                <button class="header-button comfolks-chat-minimize" style="display: none;">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-minimize-2"><polyline points="4 14 10 14 10 20"/><polyline points="20 10 14 10 14 4"/><line x1="14" x2="21" y1="10" y2="3"/><line x1="3" x2="10" y1="21" y2="14"/></svg>
                </button>
                <button class="header-button comfolks-chat-close">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-chevron-down"><path d="m6 9 6 6 6-6"/></svg>
                </button>
            </div>
        </div>
        <div id="comfolks-chat-body">
            <div id="comfolks-chat-dialog"></div>
            <div class="comfolks-chat-bottom">
                <div id="comfolks-chat-loader" style="display: none;">
                    <div class="comfolks-dot"></div>
                    <div class="comfolks-dot"></div>
                    <div class="comfolks-dot"></div>
                </div>
                <div id="comfolks-chat-buttons"></div>
                <form id="comfolks-chat-form">
                    <div class="comfolks-chat-input-wr">
                        <input type="text" id="comfolks-chat-input" placeholder="Напишите сообщение..." />
                        <div class="comfolks-file-input" style="display: none;">
                            <input type="file" name="file" accept=".png, .jpg, .jpeg, .pdf" id="comfolks-input-file" class="comfolks-input-hidden">
                            <label for="comfolks-input-file" class="comfolks-file-label">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#999" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-paperclip"><path d="m21.44 11.05-9.19 9.19a6 6 0 0 1-8.49-8.49l8.57-8.57A4 4 0 1 1 18 8.84l-8.59 8.57a2 2 0 0 1-2.83-2.83l8.49-8.48"/></svg>
                            </label>
                        </div>
                        <button type="submit" id="comfolks-chat-send">
                            <svg fill="#999" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M23.6 9.8L7.4 11.5l5.2 4.6 11-6.3zm.4 1L13.2 17l1.4 6.9L24 10.8zm-12.8 6.7l-6.8-6c-.7-.6-.3-1.7.6-1.7l21.8-2.3c.9-.1 1.4.9.9 1.6L14.9 26.9c-.5.7-1.6.5-1.8-.4l-1.9-9z"></path></svg>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
          <script>
            const containerShadowRoot = document.querySelector('[data-chatbot]').shadowRoot.querySelector('[data-chatbot]');
const { campaignId } = containerShadowRoot.dataset;
const RECAPTCHA_KEY_ID = '';
const RECAPTCHA_URL = 'https://www.google.com/recaptcha/api.js';
const chatButton = containerShadowRoot.querySelector('#comfolks-chat-button');
const chatToggleButton = containerShadowRoot.querySelector('#comfolks-toggle-button');
const chatWindow = containerShadowRoot.querySelector('#comfolks-chat-window');
const chatDialog = containerShadowRoot.querySelector('#comfolks-chat-dialog');
const buttonsContainer = containerShadowRoot.querySelector('#comfolks-chat-buttons');
const preloader = containerShadowRoot.querySelector('#comfolks-chat-loader');
const chatForm = containerShadowRoot.querySelector('#comfolks-chat-form');
const fileInput = containerShadowRoot.querySelector('#comfolks-input-file');
const fileInputWr = containerShadowRoot.querySelector('.comfolks-file-input');
const chatInput = chatForm.querySelector('#comfolks-chat-input');
const maximizeButton = containerShadowRoot.querySelector('.comfolks-chat-maximize');
const minimizeButton = containerShadowRoot.querySelector('.comfolks-chat-minimize');

var robotSvg = "<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='lucide lucide-bot-message-square'><path d='M12 6V2H8'/><path d='m8 18-4 4V8a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2Z'/><path d='M2 12h2'/><path d='M9 11v2'/><path d='M15 11v2'/><path d='M20 12h2'/></svg>";

var userRoundSvg = "<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='lucide lucide-user-round'><circle cx='12' cy='8' r='5'/><path d='M20 21a8 8 0 0 0-16 0'/></svg>";

const findShadowHost = (element) => {
  let currentElement = element;
  while (currentElement) {
    if (currentElement.parentNode) {
      currentElement = currentElement.parentNode;
    } else if (currentElement.host) {
      return currentElement.host;
    } else {
      return null;
    }
  }
  return null;
};

const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func(...args), delay);
  };
};

const toggleElementDisplay = (el, show) => {
  const currentEl = el;
  if (show) {
    currentEl.style.display = 'flex';
  } else {
    currentEl.style.display = 'none';
  }
};

const scrollToBottom = (el) => {
  setTimeout(() => {
    el.scrollTo({
      top: el.scrollHeight,
      behavior: 'smooth',
    });
  },
  500);
};

const isImage = (url) => {
  const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp'];
  const urlExtension = url.split('.').pop().toLowerCase();
  return imageExtensions.includes(urlExtension);
};

const currentTime = () => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const day = String(currentDate.getDate()).padStart(2, '0');
  const hours = String(currentDate.getHours()).padStart(2, '0');
  const minutes = String(currentDate.getMinutes()).padStart(2, '0');
  const seconds = String(currentDate.getSeconds()).padStart(2, '0');
  return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
};

const createTimeElement = (time) => {
  const timeElement = document.createElement('div');
  timeElement.id = 'comfolks-chat-time';
  timeElement.textContent = time.split(' ')[1].slice(0, 5);
  return timeElement;
};

const createDateElement = (date) => {
  const dateElement = document.createElement('div');
  dateElement.id = 'comfolks-chat-date';
  dateElement.textContent = date === currentTime().split(' ')[0] ? 'Today' : date;
  return dateElement;
};

const createSearchButton = (response, callBack) => {
  const searchButton = document.createElement('button');
  searchButton.id = 'comfolks-chat-message-search';

  let textContent = response.settings?.title || response.content.replace(/<[^>]+>/g, '');
  textContent = textContent.substring(0, 120);

  searchButton.textContent = textContent;
  searchButton.onclick = async () => callBack(response);
  searchButton.appendChild(createTimeElement(response.created_at || currentTime()));

  return searchButton;
};

const createMessageElements = (isUserMessage = false) => {
  const chatMessage = document.createElement('div');
  chatMessage.id = isUserMessage ? 'comfolks-user-message' : 'comfolks-chat-message';

  const chatMessageInner = document.createElement('div');
  chatMessageInner.id = isUserMessage ? 'comfolks-user-message-inner' : 'comfolks-chat-message-inner';
  chatMessage.appendChild(chatMessageInner);

  return chatMessage;
};

const createImageElements = (item) => {
  const dialogueImage = document.createElement('img');
  dialogueImage.src = item.settings?.image?.url || item?.file;
  if (item.settings?.imageWidth) {
    dialogueImage.style.width = item.settings.imageWidth;
  }
  return dialogueImage;
};

const createInput = (type = 'text', id, placeholder) => {
  const input = document.createElement('input');
  input.classList.add('comfolks-otp-input');
  input.type = type;
  input.id = id;
  input.placeholder = placeholder;

  return input;
};

const createAvatar = (isSupportChat, userImg) => {
  const avatar = document.createElement('div');
  avatar.classList.add('comfolks-chat-avatar');
  if (userImg) {
    avatar.style.backgroundImage = 'url(' + userImg + ')';
  } else {
    avatar.innerHTML = isSupportChat ? userRoundSvg : robotSvg;
  }
  return avatar;
};

const createButton = (text, onClick) => {
  const button = document.createElement('button');
  button.textContent = text;
  button.onclick = onClick;
  return button;
};

const createOtp = (dialogue) => {
  const container = document.createElement('div');
  const phoneForm = document.createElement('form');
  phoneForm.classList.add('comfolks-otp-form');

  const phoneInput = document.createElement('input');
  phoneInput.type = 'tel';
  phoneInput.classList.add('comfolks-otp-input');

  const phoneError = document.createElement('div');
  phoneError.classList.add('comfolks-otp-error');

  const {
    getCodeButtonText,
    changeButtonText,
    resendButtonText,
    errorMessageText,
    successMessageText,
  } = dialogue.settings.otp;

  let { mask, code } = dialogue.settings.otp;
  const { maskCodeList } = dialogue.settings.otp;
  // TODO should use the universal value by default. For example +{000} 00 000-0000
  mask = mask || '+{998} 00 000-0000';
  code = code || '998';

  const maskElement = window.IMask(phoneInput, {
    mask,
    lazy: false,
    placeholderChar: '-',
    prepare(str) {
      phoneError.textContent = '';

      const value = this.unmaskedValue.replace(/\D/g, '');
      const isValid = window.comfolks.validateOperatorCode(value, maskCodeList, code);

      if (!isValid) {
        const currentErrorMessageText = errorMessageText || 'Телефон должен начинатся с +';
        phoneError.textContent = currentErrorMessageText + code + ' ' + maskCodeList.join('/');
      }

      return isValid ? str : '';
    },
  });

  const getCodeButton = document.createElement('button');
  getCodeButton.classList.add('comfolks-otp-button');
  getCodeButton.textContent = getCodeButtonText || 'Получить код';

  getCodeButton.onclick = (e) => {
    e.preventDefault();
    toggleElementDisplay(preloader, true);
    window.comfolks.getOtpChatbot(dialogue.id || dialogue.next_id, maskElement.unmaskedValue.replace(/\D/g, ''),
      () => {
        // handle success
        toggleElementDisplay(preloader, false);
        const otpInput = document.createElement('input');
        otpInput.classList.add('comfolks-otp-input');
        otpInput.inputmode = 'numeric';

        const maskOtpElement = window.IMask(otpInput, {
          mask: '0000',
          lazy: false,
          placeholderChar: '\u2000',
        });

        otpInput.placeholder = 'Введите код из SMS';

        const otpError = document.createElement('div');
        otpError.classList.add('comfolks-otp-error');

        const changePhoneButton = document.createElement('button');
        const otpForm = document.createElement('form');
        changePhoneButton.classList.add('comfolks-otp-button-inline');
        changePhoneButton.textContent = changeButtonText || 'Изменить телефон';
        changePhoneButton.onclick = (e) => {
          e.preventDefault();
          phoneForm.style.display = 'block';
          otpForm.style.display = 'none';
        };

        const resendButton = document.createElement('button');
        resendButton.classList.add('comfolks-otp-button-inline');
        resendButton.textContent = resendButtonText || 'Повторная отправка';
        resendButton.onclick = (e) => {
          e.preventDefault();
          maskOtpElement.value = '';
          toggleElementDisplay(preloader, true);
          window.comfolks.getOtpChatbot(dialogue.id || dialogue.next_id, maskElement.unmaskedValue.replace(/\D/g, ''),
            () => {
              // handle resend success
              toggleElementDisplay(preloader, false);
            }, (error) => {
              // handle resend error
              console.log(error);
              toggleElementDisplay(preloader, false);
              phoneError.textContent = error.message;
            });
        };

        phoneForm.classList.add('comfolks-otp-form');
        otpForm.appendChild(otpInput);
        otpForm.appendChild(otpError);
        otpForm.appendChild(changePhoneButton);
        otpForm.appendChild(resendButton);
        otpForm.oninput = () => {
          if (maskOtpElement.unmaskedValue.replace(/\D/g, '').length === 4) {
            toggleElementDisplay(preloader, true);
            window.comfolks.confirmOtpChatbot(dialogue.id || dialogue.next_id, maskOtpElement.unmaskedValue.replace(/\D/g, ''), dialogue.settings.smsCampaignId,
              () => {
                // handle confirm success
                toggleElementDisplay(preloader, false);
                const successMessage = document.createElement('p');
                successMessage.textContent = successMessageText || 'Услуга успешно подключена!';
                container.appendChild(successMessage);
              }, (error) => {
                // handle confirm error
                console.log(error);
                toggleElementDisplay(preloader, false);
                otpError.textContent = error.message;
              });
          }
        };
        container.appendChild(otpForm);
        phoneForm.style.display = 'none';
      }, (error) => {
        // handle error
        console.log(error);
        toggleElementDisplay(preloader, false);
        phoneError.textContent = error.message;
      });
  };
  phoneForm.appendChild(phoneInput);
  phoneForm.appendChild(phoneError);
  phoneForm.appendChild(getCodeButton);
  container.appendChild(phoneForm);
  return container;
};

const state = {
  chatOpen: false,
  supportEmailDialog: JSON.parse(localStorage.getItem('supportEmailDialog')) || {},
  supportChatEmail: JSON.parse(localStorage.getItem('supportChatEmail')) || false,
  supportChat: JSON.parse(localStorage.getItem('supportChat')) || false,
  gptImagesActive: JSON.parse(localStorage.getItem('gptImagesActive')) || false,
  gptPromptActive: JSON.parse(localStorage.getItem('gptPromptActive')) || false,
  gptChatActive: JSON.parse(localStorage.getItem('gptChatActive')) || false,
  chatBotItemId: localStorage.getItem('chatBotItemId') || '',
  currentNextBlockId: localStorage.getItem('currentNextBlockId') || '',
  rootDialog: {},
  listeners: [],

  subscribe(listener) {
    this.listeners.push(listener);
  },

  notify() {
    this.listeners.forEach((listener) => listener(this));
  },

  setSupportChatEmail(value) {
    this.supportChatEmail = value;
    localStorage.setItem('supportChatEmail', JSON.stringify(value));
    this.notify();
  },

  setSupportEmailDialog(value) {
    this.supportEmailDialog = value;
    localStorage.setItem('supportEmailDialog', JSON.stringify(value));
    this.notify();
  },

  setChatOpen(value) {
    this.chatOpen = value;
    this.notify();
  },

  setSupportChat(value) {
    this.supportChat = value;
    localStorage.setItem('supportChat', JSON.stringify(value));
    this.notify();
  },

  setGptImagesActive(value) {
    this.gptImagesActive = value;
    localStorage.setItem('gptImagesActive', JSON.stringify(value));
    this.notify();
  },

  setGptPromptActive(value) {
    this.gptPromptActive = value;
    localStorage.setItem('gptPromptActive', JSON.stringify(value));
    this.notify();
  },

  setGptChatActive(value) {
    this.gptChatActive = value;
    localStorage.setItem('gptChatActive', JSON.stringify(value));
    this.notify();
  },

  setChatBotItemId(value) {
    this.chatBotItemId = value;
    localStorage.setItem('chatBotItemId', value);
    this.notify();
  },

  setCurrentNextBlockId(value) {
    this.currentNextBlockId = value;
    localStorage.setItem('currentNextBlockId', value);
    this.notify();
  },

  setRootDialog(value) {
    this.rootDialog = value;
    this.notify();
  },

  resetChatMode() {
    this.setGptImagesActive(false);
    this.setGptPromptActive(false);
    this.setSupportChat(false);
    this.setGptChatActive(false);
  },
};

const createSupportForm = (dialogue, callBack) => {
  const {
    emailLabelText,
    nameLabelText,
    sendButtonText,
    errorFilledFields,
    successMessageText,
  } = dialogue?.settings?.feedbackForm || {};

  const { emailCampaignId } = dialogue.settings;
  if (!emailCampaignId) {
    throw new Error('emailCampaignId is not defined');
  }
  const container = document.createElement('div');
  const supportForm = document.createElement('form');
  supportForm.classList.add('comfolks-otp-form');

  const emailInput = createInput('email', 'feedBackEmailInput', emailLabelText || 'Введите ваш email');
  createInput('text', 'feedBackNameInput', nameLabelText || 'Введите ваше имя');

  const supportFormError = document.createElement('div');
  supportFormError.classList.add('comfolks-otp-error');

  const supportFormButton = document.createElement('button');
  supportFormButton.classList.add('comfolks-otp-button');
  supportFormButton.textContent = sendButtonText || 'Отправить';

  supportFormButton.onclick = async (e) => {
    e.preventDefault();
    const email = emailInput.value.trim();

    if (!email) {
      supportFormError.textContent = errorFilledFields || 'Пожалуйста, заполните все поля';
      return;
    }

    try {
      toggleElementDisplay(preloader, true);
      const result = await window.comfolks.supportGetOtpEmail(state.chatBotItemId, email, emailCampaignId);
      if (!result) {
        throw new Error('Incorrect data was received from the server:' + JSON.stringify(result));
      }
      supportForm.innerHTML = successMessageText || 'Спасибо, на вашу почту отправлен код с подтверждением';

      await callBack();
    } catch ({ message }) {
      supportFormError.textContent = message;
    } finally {
      toggleElementDisplay(preloader, false);
    }
  };

  supportForm.appendChild(emailInput);
  supportForm.appendChild(supportFormError);
  supportForm.appendChild(supportFormButton);

  container.appendChild(supportForm);
  return container;
};

const createSupportOtpForm = () => {
  const container = document.createElement('div');
  const form = document.createElement('form');
  form.classList.add('comfolks-otp-form');

  const otpInput = createInput('email', 'feedBackEmailInput', 'Введите код из email');

  const formError = document.createElement('div');
  formError.classList.add('comfolks-otp-error');

  const formButton = document.createElement('button');
  formButton.classList.add('comfolks-otp-button');
  formButton.textContent = 'Отправить';

  formButton.onclick = async (e) => {
    e.preventDefault();
    const code = otpInput.value.trim();

    if (!code) {
      formError.textContent = 'Пожалуйста, заполните все поля';
      return;
    }

    try {
      toggleElementDisplay(preloader, true);
      const result = await window.comfolks.supportOtpEmail(state.chatBotItemId, code);
      if (!result) {
        throw new Error('Incorrect data was received from the server:' + JSON.stringify(result));
      }
      form.innerHTML = 'Спасибо, теперь задайте свой вопрос оператору';
      state.setSupportChat(true);
      state.setSupportChatEmail(true);
    } catch ({ message }) {
      formError.textContent = message;
    } finally {
      toggleElementDisplay(preloader, false);
    }
  };

  form.appendChild(otpInput);
  form.appendChild(formError);
  form.appendChild(formButton);

  container.appendChild(form);
  return container;
};

const createFeedback = (dialogue) => {
  const {
    emailLabelText,
    phoneLabelText,
    nameLabelText,
    messageLabelText,
    sendButtonText,
    errorFilledFields,
    successMessageText,
  } = dialogue.settings.feedbackForm;
  const container = document.createElement('div');
  const feedbackForm = document.createElement('form');
  feedbackForm.classList.add('comfolks-otp-form');

  const emailInput = createInput('email', 'feedBackEmailInput', emailLabelText || 'Введите ваш email');
  const phoneInput = createInput('tel', 'feedBackPhoneInput', phoneLabelText || 'Введите ваш номер телефона');
  const nameInput = createInput('text', 'feedBackNameInput', nameLabelText || 'Введите ваше имя');

  const messageTextarea = document.createElement('textarea');
  messageTextarea.classList.add('comfolks-otp-textarea');
  messageTextarea.placeholder = messageLabelText || 'Введите ваше сообщение';

  const feedbackError = document.createElement('div');
  feedbackError.classList.add('comfolks-otp-error');

  const feedbackButton = document.createElement('button');
  feedbackButton.classList.add('comfolks-otp-button');
  feedbackButton.textContent = sendButtonText || 'Отправить';

  feedbackButton.onclick = async (e) => {
    e.preventDefault();
    const email = emailInput.value.trim();
    const phone = phoneInput.value.trim();
    const name = nameInput.value.trim();
    const message = messageTextarea.value.trim();

    if (!email || !phone || !name) {
      feedbackError.textContent = errorFilledFields || 'Пожалуйста, заполните все поля';
      return;
    }

    try {
      toggleElementDisplay(preloader, true);
      const result = await window.comfolks.sendFeedbackChatbot(state.currentNextBlockId, campaignId, email, phone, name, message);
      if (!result) {
        throw new Error('Incorrect data was received from the server:' + JSON.stringify(result));
      }
      feedbackForm.innerHTML = successMessageText || 'Спасибо, мы свяжемся с вами в ближайшее время';
    } catch ({ message }) {
      feedbackError.textContent = message;
    } finally {
      toggleElementDisplay(preloader, false);
    }
  };

  feedbackForm.appendChild(emailInput);
  feedbackForm.appendChild(phoneInput);
  feedbackForm.appendChild(nameInput);
  feedbackForm.appendChild(messageTextarea);
  feedbackForm.appendChild(feedbackError);
  feedbackForm.appendChild(feedbackButton);

  container.appendChild(feedbackForm);
  return container;
};

const createSupportRating = (dialogue) => {
  const container = document.createElement('div');
  container.classList.add('comfolks-otp-form');

  const title = document.createElement('div');
  title.classList.add('rating-title');
  title.textContent = dialogue.settings?.ratingTitle || 'Оцените ответ';

  const starsContainer = document.createElement('div');
  starsContainer.classList.add('stars-container');

  const stars = [];
  let ratingValue = 5;
  for (let i = 1; i <= 5; i++) {
    const star = document.createElement('span');
    star.classList.add('star');
    star.dataset.ratingValue = ratingValue;
    ratingValue--;
    star.innerHTML = '&#9733;';
    stars.push(star);
    starsContainer.appendChild(star);
  }

  const submitButton = document.createElement('button');
  submitButton.classList.add('comfolks-otp-button');
  submitButton.textContent = dialogue.settings?.submitButtonText || 'Отправить';

  const feedbackError = document.createElement('div');
  feedbackError.classList.add('comfolks-otp-error');

  let rating = '';
  stars.forEach((star) => {
    star.addEventListener('click', () => {
      stars.forEach((s) => s.classList.remove('selected'));
      star.classList.add('selected');
      rating = star.dataset.ratingValue;
    });
  });

  submitButton.onclick = async (e) => {
    e.preventDefault();
    if (!rating) {
      feedbackError.textContent = dialogue.settings?.errorNoRating || 'Пожалуйста, оцените ответ';
      return;
    }

    try {
      toggleElementDisplay(preloader, true);
      const result = await window.comfolks.supportRating(state.chatBotItemId, Number(rating));
      if (!result) {
        throw new Error('Incorrect data was received from the server:' + JSON.stringify(result));
      }
      container.innerHTML = dialogue.settings?.successMessageText || 'Спасибо за вашу оценку';
    } catch ({ message }) {
      feedbackError.textContent = message;
    } finally {
      toggleElementDisplay(preloader, false);
    }
  };

  container.appendChild(title);
  container.appendChild(starsContainer);
  container.appendChild(feedbackError);
  container.appendChild(submitButton);

  return container;
};

const createStep = (type = 'text', id, placeholder) => {
  const step = document.createElement('div');
  step.classList.add('comfolks-step');

  return step;
};

const createSelector = () => {
  const selectElement = document.createElement('select');
  selectElement.classList.add('comfolks-otp-selector');
  return selectElement;
};

const createOption = (value, text, selected = false, disabled = false) => {
  const option = document.createElement('option');
  option.value = value;
  option.textContent = text;
  if (selected) {
    option.selected = true;
  }
  if (disabled) {
    option.disabled = true;
  }
  return option;
};

const createDefaultOption = (text) => createOption('default', text, true, true);

const getSalesPointsList = async (city = '') => {
  const list = await window.comfolks.getSalesPoints(10, 1, city);
  if (!list) {
    throw new Error('Incorrect data was received from the server:' + JSON.stringify(list));
  }
  return list.points;
};

const getSimNumbersList = async () => {
  const list = await window.comfolks.getAvailableSimNumbers(10, 1);
  if (!list.numbers) {
    throw new Error('Incorrect data was received from the server:' + JSON.stringify(list));
  }
  return list.numbers;
};

const getTariffList = async () => {
  const list = await window.comfolks.getTariffList();
  if (!list) {
    throw new Error('Incorrect data was received from the server:' + JSON.stringify(list));
  }
  return list;
};

const getCitiesList = async (city) => {
  const list = await window.comfolks.getCitiesList();
  if (!list) {
    throw new Error('Incorrect data was received from the server:' + JSON.stringify(list));
  }
  return list;
};

const createOrder = async (dialogue, history) => {
  const {
    orderEmailLabelText: emailLabelText,
    orderPhoneLabelText: phoneLabelText,
    orderNameLabelText: nameLabelText,
    orderSimNumberLabelText: simNumberLabelText,
    orderCityLabelText: cityLabelText,
    orderSalesPointLabelText: salesPointLabelText,
    orderTariffLabelText: tariffLabelText,
    orderSendButtonText: sendButtonText,
    orderBackButtonText: backButtonText,
    orderErrorFilledFields: errorFilledFields,
    orderSuccessMessageText: successMessageText,
  } = dialogue.settings.orderForm;

  const container = document.createElement('div');

  const orderForm = document.createElement('form');
  orderForm.classList.add('comfolks-otp-form');

  const firstStep = createStep();
  const emailInput = createInput('email', 'orderEmailInput', emailLabelText || 'Введите ваш email');
  const phoneInput = createInput('tel', 'orderPhoneInput', phoneLabelText || 'Введите ваш номер телефона');
  const nameInput = createInput('text', 'orderNameInput', nameLabelText || 'Введите ваше имя');

  phoneInput.addEventListener('input', (event) => {
    const inputValue = event.target.value;
    const regex = /^[0-9\-\+]+$/;

    if (!regex.test(inputValue)) {
      event.target.value = inputValue.replace(/[^\\d\\-\\+]/g, '');
    }
  });

  const secondStep = createStep();
  secondStep.style.display = 'none';
  const simNumberSelector = createSelector();
  simNumberSelector.appendChild(createDefaultOption(simNumberLabelText || 'Выберите номер'));

  const tariffSelector = createSelector();
  tariffSelector.appendChild(createDefaultOption(tariffLabelText || 'Выберите тариф'));

  const thirdStep = createStep();
  thirdStep.style.display = 'none';
  const citySelector = createSelector();
  citySelector.appendChild(createDefaultOption(cityLabelText || 'Выберите ваш город'));

  const salesPointSelector = createSelector();

  const updateSalesPointSelector = (selector, salesPointList) => {
    selector.innerHTML = '';
    selector.appendChild(createDefaultOption(salesPointLabelText || 'Выберите ближайший пункт выдачи'));

    salesPointList.forEach((salesPoint) => {
      selector.appendChild(createOption(salesPoint.id, salesPoint.name));
    });
  };

  const handleCitySelection = async (city, salesPointSelector) => {
    if (city) {
      const salesPointList = await getSalesPointsList(city);
      updateSalesPointSelector(salesPointSelector, salesPointList);
    }
  };

  citySelector.addEventListener('change', (e) => { handleCitySelection(e.target.value, salesPointSelector); });

  const orderError = document.createElement('div');
  orderError.classList.add('comfolks-otp-error');

  const orderNextButton = document.createElement('button');
  orderNextButton.classList.add('comfolks-otp-button');
  orderNextButton.textContent = 'Далее к шагу 2';

  const backButton = document.createElement('button');
  backButton.classList.add('comfolks-inline-button');
  backButton.textContent = backButtonText || 'Назад';
  backButton.style.display = 'none';

  const orderSecondNextButton = document.createElement('button');
  orderSecondNextButton.classList.add('comfolks-otp-button');
  orderSecondNextButton.textContent = 'Далее к отправке формы';
  orderSecondNextButton.style.display = 'none';

  const orderButton = document.createElement('button');
  orderButton.classList.add('comfolks-otp-button');
  orderButton.textContent = sendButtonText || 'Отправить';
  orderButton.style.display = 'none';

  let currentStep = 0;

  const prepareRecaptcha = () => {
    const shadowHost = findShadowHost(containerShadowRoot);

    let externalScript = document.querySelector('script[src="' + RECAPTCHA_URL + '"]');
    if (!externalScript) {
      externalScript = document.createElement('script');
      externalScript.src = RECAPTCHA_URL;
      externalScript.async = true;
      externalScript.defer = true;
      shadowHost.appendChild(externalScript);
    }

    let recaptchaContainer = shadowHost.querySelector('.g-recaptcha');
    if (!recaptchaContainer) {
      recaptchaContainer = document.createElement('div');
      recaptchaContainer.classList.add('g-recaptcha');
      recaptchaContainer.setAttribute('data-sitekey', RECAPTCHA_KEY_ID);
      recaptchaContainer.setAttribute('slot', 'recaptcha-slot');
      shadowHost.appendChild(recaptchaContainer);
    } else {
      window.grecaptcha.reset();
    }
  };

  orderNextButton.onclick = async (e) => {
    e.preventDefault();
    const email = emailInput.value || '';
    const phone = phoneInput.value;
    const name = nameInput.value;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!phone || !name || !email || !emailRegex.test(email)) {
      orderError.innerHTML = errorFilledFields || 'Пожалуйста, заполните все поля';
      return null;
    }

    firstStep.style.display = 'none';
    secondStep.style.display = 'block';
    orderNextButton.style.display = 'none';
    orderSecondNextButton.style.display = 'block';
    orderError.innerHTML = '';
    backButton.style.display = 'block';
    currentStep = 1;

    const numberList = await getSimNumbersList();
    numberList.forEach((number) => {
      simNumberSelector.appendChild(
        createOption(number.id, number.number),
      );
    });

    const tariffList = await getTariffList();
    tariffList.forEach((tariff) => {
      tariffSelector.appendChild(
        createOption(tariff.tariff_code, tariff.title),
      );
    });
  };

  orderSecondNextButton.onclick = async (e) => {
    e.preventDefault();
    const simNumberId = simNumberSelector.value;
    const tariffId = tariffSelector.value;

    if (simNumberId === 'default' || tariffId === 'default') {
      orderError.innerHTML = errorFilledFields || 'Пожалуйста, заполните все поля';
      return null;
    }

    secondStep.style.display = 'none';
    thirdStep.style.display = 'block';
    orderSecondNextButton.style.display = 'none';
    orderButton.style.display = 'block';
    orderError.innerHTML = '';
    currentStep = 2;
    prepareRecaptcha();

    const recaptchaSlot = document.createElement('slot');
    recaptchaSlot.classList.add('recaptcha-slot');
    recaptchaSlot.setAttribute('name', 'recaptcha-slot');
    thirdStep.appendChild(recaptchaSlot);

    const cityList = await getCitiesList();
    cityList.forEach((city) => {
      citySelector.appendChild(
        createOption(city, city),
      );
    });

    const salesPointList = await getSalesPointsList();
    updateSalesPointSelector(salesPointSelector, salesPointList);
  };

  orderButton.onclick = async (e) => {
    e.preventDefault();
    const email = emailInput.value || '';
    const phone = phoneInput.value;
    const name = nameInput.value;
    const simNumberId = simNumberSelector.value;
    const simSalesPointId = salesPointSelector.value;

    const recaptchaToken = window.grecaptcha.getResponse();

    if (!phone || !name || !simNumberId || !simSalesPointId) {
      orderError.textContent = errorFilledFields || 'Пожалуйста, заполните все поля';
      return;
    }

    try {
      toggleElementDisplay(preloader, true);
      const result = await window.comfolks.orderSimCard(name, phone, simNumberId, simSalesPointId, recaptchaToken, email);
      if (!result) {
        throw new Error('Incorrect data was received from the server:' + JSON.stringify(result));
      }
      orderForm.innerHTML = successMessageText || 'Спасибо, мы свяжемся с вами в ближайшее время';
      window.grecaptcha.reset();
    } catch ({ message }) {
      orderError.textContent = message;
    } finally {
      toggleElementDisplay(preloader, false);
    }
  };

  backButton.onclick = (e) => {
    e.preventDefault();
    if (currentStep === 1) {
      firstStep.style.display = 'block';
      secondStep.style.display = 'none';
      orderNextButton.style.display = 'block';
      orderSecondNextButton.style.display = 'none';
      backButton.style.display = 'none';
    } else if (currentStep === 2) {
      secondStep.style.display = 'block';
      thirdStep.style.display = 'none';
      orderSecondNextButton.style.display = 'block';
      orderButton.style.display = 'none';
    }
    currentStep -= 1;
  };

  orderForm.appendChild(firstStep);
  firstStep.appendChild(emailInput);
  firstStep.appendChild(phoneInput);
  firstStep.appendChild(nameInput);

  orderForm.appendChild(secondStep);
  secondStep.appendChild(simNumberSelector);
  secondStep.appendChild(tariffSelector);

  orderForm.appendChild(thirdStep);
  thirdStep.appendChild(citySelector);
  thirdStep.appendChild(salesPointSelector);

  orderForm.appendChild(orderError);
  orderForm.appendChild(orderNextButton);
  orderForm.appendChild(orderSecondNextButton);
  orderForm.appendChild(orderButton);
  orderForm.appendChild(backButton);

  container.appendChild(orderForm);

  return container;
};

const loadDialogue = async (id, nextBlockId, responseId, withHistory = 0) => {
  state.setCurrentNextBlockId(nextBlockId);
  try {
    toggleElementDisplay(preloader, true);

    const dialogue = await window.comfolks.getChatbotBlock(id, nextBlockId, responseId, withHistory);

    toggleElementDisplay(preloader, false);

    if (!dialogue || typeof dialogue !== 'object') {
      throw new Error('Incorrect data was received from the server:' + JSON.stringify(dialogue));
    }

    return dialogue;
  } catch (error) {
    console.error('Error when receiving or processing a dialog:', error);
  }
};

const supportInit = async (dialogue, callBack) => {
  state.setChatBotItemId(dialogue.id || dialogue.next_id);
  try {
    const result = await window.comfolks.supportExists(dialogue.id || dialogue.next_id);
    if (!result) {
      throw new Error('Incorrect data was received from the server:' + JSON.stringify(result));
    }
    if (result.customer_id) {
      state.setSupportChat(true);
    }
    if (!result.email) {
      callBack();
    } else if (result.email) {
      state.supportChatEmail(true);
    }
  } catch ({ message }) {
    console.error(message);
  } finally {
    toggleElementDisplay(preloader, false);
  }
};

let previousDate = null;

const fillMessageElement = (messageElement, isCustomer, isSupport, element, userImg = '') => {
  const messageInnerElement = messageElement.querySelector(isCustomer ? '#comfolks-user-message-inner' : '#comfolks-chat-message-inner');
  const avatar = messageElement.querySelector('.comfolks-chat-avatar');
  if (!isCustomer && !avatar) {
    const avatarElement = createAvatar(isSupport, userImg);
    messageElement.prepend(avatarElement);
  }
  messageInnerElement.appendChild(element);
};

const resetCurrentMode = () => {
  state.setGptImagesActive(false);
  state.setGptPromptActive(false);
  state.setSupportChat(false);
  state.setGptChatActive(false);
};

const fillDialog = async (dialogue, toEnd = true, history = false, isLastDialogue = true) => {
  const currentDateTime = dialogue.created_at || currentTime();
  const currentDate = currentDateTime.split(' ')[0];
  if (previousDate === null) {
    previousDate = currentDate;
  }
  if (currentDate !== previousDate) {
    const dateElement = createDateElement(previousDate);
    chatDialog.prepend(dateElement);
    previousDate = currentDate;
  }
  if (dialogue.is_search_response) {
    const callBack = async (response) => {
      const newDialogue = await loadDialogue('', response.id || response.next_id);
      if (newDialogue.history.length > 0) {
        const fillPromises = [...newDialogue.history].reverse().map((dialogueItem) => fillDialog(dialogueItem, true, true));
        await Promise.all(fillPromises);
      } else {
        await fillDialog(newDialogue);
      }
    };
    if (toEnd) {
      chatDialog.appendChild(
        createSearchButton(dialogue, callBack),
      );
    } else {
      chatDialog.prepend(
        createSearchButton(dialogue, callBack),
      );
    }
    return null;
  }
  /* Create and fill message */
  const messageElement = createMessageElements(dialogue.is_customer);
  const fillMessageArgs = [messageElement, dialogue.is_customer, dialogue.is_support];
  fillMessageElement(...fillMessageArgs, createTimeElement(currentDateTime), dialogue.user_img);
  if (dialogue.content) {
    const dialogueText = document.createElement('p');
    dialogueText.innerHTML = dialogue.content;
    fillMessageElement(...fillMessageArgs, dialogueText);
  }
  if (dialogue.settings?.image || (dialogue.file && isImage(dialogue.file))) {
    fillMessageElement(...fillMessageArgs, createImageElements(dialogue));
  }
  if (dialogue.settings?.otpFields && dialogue.settings?.smsCampaignId) {
    fillMessageElement(...fillMessageArgs, createOtp(dialogue));
  }
  if (dialogue.settings?.gptChat && isLastDialogue) {
    resetCurrentMode();
    state.setGptChatActive(true);
  }
  if (dialogue.settings?.gptImages && isLastDialogue) {
    resetCurrentMode();
    state.setGptImagesActive(true);
  }
  if (dialogue.settings?.gptPrompt && isLastDialogue) {
    resetCurrentMode();
    state.setGptPromptActive(true);
  }
  if (dialogue.settings?.supportChat && isLastDialogue && !history) {
    resetCurrentMode();
    const supportEmailDialog = {
      ...dialogue,
      content: '',
      settings: {
        ...dialogue.settings,
        supportChat: false,
        supportEmail: true,
      },
    };
    state.setSupportEmailDialog(supportEmailDialog);
    const callBack = async () => {
      await fillDialog(supportEmailDialog);
    };
    await supportInit(dialogue, callBack);
  }
  if (dialogue.settings?.supportEmail && isLastDialogue) {
    const callBack = async () => {
      await fillDialog({
        ...dialogue,
        settings: {
          ...dialogue.settings,
          supportEmail: false,
          supportOtp: true,
        },
      });
    };
    fillMessageElement(...fillMessageArgs, createSupportForm(dialogue, callBack));
    scrollToBottom(chatDialog);
  }
  if (dialogue.settings?.supportOtp && isLastDialogue) {
    fillMessageElement(...fillMessageArgs, createSupportOtpForm());
    scrollToBottom(chatDialog);
  }
  if (dialogue.settings?.feedbackFields) {
    fillMessageElement(...fillMessageArgs, createFeedback(dialogue));
  }
  if (dialogue.rating_suggest) {
    fillMessageElement(...fillMessageArgs, createSupportRating(dialogue));
  }
  if (dialogue.settings?.orderFields) {
    fillMessageElement(...fillMessageArgs, await createOrder(dialogue));
  }
  if (toEnd) {
    chatDialog.appendChild(messageElement);
  } else {
    chatDialog.prepend(messageElement);
  }

  // response buttons

  const handleButtonClick = async (nextBlockId = '', responseId = '', responseContent = '') => {
    resetCurrentMode();
    if (responseContent) {
      await fillDialog({
        content: responseContent,
        is_customer: true,
        created_at: currentTime(),
      });
    }
    const newDialogue = await loadDialogue('', nextBlockId, responseId);
    await fillDialog(newDialogue);
    scrollToBottom(chatDialog);
  };

  if (isLastDialogue) {
    buttonsContainer.innerHTML = '';
    if (dialogue.responses) {
      dialogue.responses.forEach((response) => {
        const button = createButton(response.content, () => handleButtonClick('', response.id, response.content));
        buttonsContainer.appendChild(button);
      });
    } else {
      const menuButton = createButton('Меню', () => handleButtonClick(state.rootDialog.id));
      buttonsContainer.appendChild(menuButton);
    }
  }
};

const handleGptRequest = async (result, campaignId) => {
  try {
    if (result.id) {
      let response = await window.comfolks.responseGpt(campaignId, result.id);
      let requestCount = 0;
      while (response && response.result === 'in_progress') {
        if (requestCount > 20) {
          const error = new Error('Gpt error: request limit has been exceeded');
          await fillDialog({
            content: error.message,
            is_customer: false,
            created_at: currentTime(),
          });
          throw error;
        }
        await new Promise((resolve) => setTimeout(resolve, 2000));
        response = await window.comfolks.responseGpt(campaignId, result.id);
        requestCount++;
      }

      if (response && response.result === 'error') {
        const error = new Error(JSON.stringify(response));
        await fillDialog({
          content: error.message,
          is_customer: false,
          created_at: currentTime(),
        });
        throw error;
      }

      if (response && response.result === 'success') {
        await fillDialog({
          content: response.message,
          is_customer: false,
          created_at: currentTime(),
        });
      }
    }
  } catch (error) {
    console.error(error);
  } finally {
    toggleElementDisplay(preloader, false);
    scrollToBottom(chatDialog);
  }
};

let isLoading = false;
let currentHistoryPage = 1;

const loadHistory = debounce(async (chatDialog, campaignId, fillDialog) => {
  try {
    if (isLoading || chatDialog.scrollTop !== 0) {
      return null;
    }
    isLoading = true;
    toggleElementDisplay(preloader, true);

    const dialogue = await window.comfolks.getChatbotHistory(campaignId, currentHistoryPage + 1);

    toggleElementDisplay(preloader, false);
    isLoading = false;

    if (!dialogue || typeof dialogue !== 'object') {
      throw new Error('Incorrect data was received from the server:' + JSON.stringify(dialogue));
    }

    if (!chatDialog) {
      throw new Error('Necessary elements on the page are not found');
    }

    const previousScrollHeight = chatDialog.scrollHeight;

    if (dialogue.history.length > 0) {
      const fillDialogPromises = dialogue.history.map((dialogueItem) => fillDialog(dialogueItem, false, true, false));

      await Promise.all(fillDialogPromises);

      const addedHeight = chatDialog.scrollHeight - previousScrollHeight;
      chatDialog.scrollTop += addedHeight;
    }


    currentHistoryPage += 1;
    if (dialogue.history.length < dialogue.total.perPage
            || dialogue.total.count / dialogue.total.perPage <= currentHistoryPage) {
      chatDialog.removeEventListener('scroll', () => loadHistory(chatDialog, campaignId, fillDialog));
    }
  } catch (error) {
    console.error('Error when receiving or processing a dialog:', error);
  }
}, 300);

let isRequestInProgress = false;
let chatBotSeen = false;

chatDialog.addEventListener('scroll', () => loadHistory(chatDialog, campaignId, fillDialog));

fileInput.addEventListener('change', async (event) => {
  const file = event.target.files[0];
  if (file) {
    const formData = new FormData();
    formData.append('file', file);
    try {
      toggleElementDisplay(preloader, true);
      const data = await window.comfolks.supportFile(state.chatBotItemId, formData);
      if (data.file) {
        await fillDialog({
          content: '',
          is_customer: true,
          created_at: currentTime(),
          settings: {
            image: {
              url: data.file,
            },
          },
        });
        scrollToBottom(chatDialog);
      }
    } catch ({ message }) {
      console.error('Error when uploading file:', message);
    } finally {
      toggleElementDisplay(preloader, false);
      event.target.value = '';
    }
  }
});

const supportStatusMessages = (status) => {
  if (status === 'offline') {
    state.setRootDialog({
      ...state.rootDialog,
      supportStatusAll: 'offline',
    });
    fillDialog({
      content: 'Сейчас все операторы оффлайн, мы свяжемся с вами по email',
      is_customer: false,
      created_at: currentTime(),
    });

    if (!state.supportChatEmail && state.supportEmailDialog?.id) {
      fillDialog(state.supportEmailDialog);
    }
  } else if (status === 'online') {
    state.setRootDialog({
      ...state.rootDialog,
      supportStatusAll: 'online',
    });
  }
  scrollToBottom(chatDialog);
};

if (window.Echo && window.comfolks && window.comfolks.apiKey && window.comfolks.token) {
  const channelNameBase = window.pusherChannelChats + '.' + window.comfolks.apiKey;
  const channelName = channelNameBase + '.' + window.comfolks.token;
  const channelBase = window.Echo.channel(channelNameBase);
  const channel = window.Echo.channel(channelName);
  channel.listen('.' + window.pusherChannelChatsEventResponse, (data) => {
    if (!state.chatOpen) {
      chatToggleButton.classList.add('new-message');
    }
    fillDialog({
      content: data.text,
      is_customer: false,
      created_at: currentTime(),
      is_support: true,
      file: data.file,
      user_img: data.user_img,
      rating_suggest: data.rating_suggest,
    });
    scrollToBottom(chatDialog);
  });
  channelBase.listen('.' + window.pusherChannelChatsEventStatus, (data) => {
    supportStatusMessages(data.status_all);
  });
} else {
  console.error('Echo or required data not found in window.');
}

const onUserSubmit = async (e) => {
  e.preventDefault();
  if (!chatInput) {
    throw new Error('ChatInput element are not found');
  }
  const chatInputValue = chatInput.value.trim();
  if (isRequestInProgress || !chatInputValue) {
    return null;
  }
  isRequestInProgress = true;
  chatForm.classList.remove('active');
  await fillDialog({
    content: chatInputValue,
    is_customer: true,
    created_at: currentTime(),
  });
  scrollToBottom(chatDialog);
  chatInput.value = '';
  if (state.supportChat) {
    try {
      toggleElementDisplay(preloader, true);
      const result = await window.comfolks.supportQuestion(state.chatBotItemId, chatInputValue);
      if (result.message) {
        await fillDialog({
          content: result.message,
          is_customer: false,
          created_at: currentTime(),
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      toggleElementDisplay(preloader, false);
      supportStatusMessages(state.rootDialog.supportStatusAll);
      isRequestInProgress = false;
    }
  } else if (state.gptChatActive || state.gptImagesActive) {
    try {
      toggleElementDisplay(preloader, true);
      const result = state.gptImagesActive
        ? await window.comfolks.initGptImages(campaignId, chatInputValue)
        : await window.comfolks.initGpt(campaignId, chatInputValue);

      if (result.id) {
        await handleGptRequest(result, campaignId);
      }
    } catch (error) {
      console.error(error);
    } finally {
      toggleElementDisplay(preloader, false);
      scrollToBottom(chatDialog);
      isRequestInProgress = false;
    }
  } else {
    try {
      toggleElementDisplay(preloader, true);
      const result = await window.comfolks.initGptPrompt(campaignId, chatInputValue);
      if (result.id) {
        await handleGptRequest(result, campaignId);
      }
    } catch (error) {
      console.error(error);
    } finally {
      toggleElementDisplay(preloader, false);
      scrollToBottom(chatDialog);
      isRequestInProgress = false;
    }
  }
};

chatInput.addEventListener('input', (e) => {
  const chatInputValue = e.target.value.trim();
  if (chatInputValue.length > 0) {
    chatForm.classList.add('active');
  } else {
    chatForm.classList.remove('active');
  }
});

chatButton.addEventListener('click', async () => {
  if (chatWindow.style.display === 'none') {
    chatWindow.style.display = 'block';
    if (!campaignId) {
      console.error('Failed to get campaignId');
      return;
    }

    chatToggleButton.classList.remove('new-message');
    const newDialogue = await loadDialogue(campaignId, '', '', 1);
    state.setRootDialog({
      content: newDialogue.content,
      id: newDialogue.id,
      responses: newDialogue.responses,
      settings: newDialogue.settings,
      type: newDialogue.type,
      supportStatusAll: newDialogue.support_status_all,
    });
    if (newDialogue.history.length > 0) {
      const fillPromises = newDialogue.history.map(
        (dialogueItem, index) => fillDialog(
          dialogueItem, false, true, index === 0,
        ),
      );
      await Promise.all(fillPromises);
    } else {
      await fillDialog(newDialogue);
    }
    scrollToBottom(chatDialog);
    chatForm.addEventListener('submit', onUserSubmit);
    if (!chatBotSeen) {
      chatBotSeen = true;
      window.comfolks.actionSeenChatbot(campaignId);
    }

    state.setChatOpen(true);
  } else {
    chatWindow.style.display = 'none';
    chatForm.removeEventListener('submit', onUserSubmit);
    chatDialog.innerHTML = '';

    state.setChatOpen(false);
  }
});

chatToggleButton.addEventListener('click', (e) => {
  const parent = e.target.closest('#comfolks-chat-button-wr');
  if (parent) {
    if (parent.classList.contains('active')) {
      parent.classList.remove('active');
    } else {
      parent.classList.add('active');
    }
  }
});

function handleStateChange(newState) {
  if (newState.supportChat) {
    fileInputWr.style.display = 'block';
  } else {
    fileInputWr.style.display = 'none';
  }
}

// Subscribe to state changes
state.subscribe(handleStateChange);

maximizeButton.addEventListener('click', () => {
  chatWindow.classList.add('maximized');
  maximizeButton.style.display = 'none';
  minimizeButton.style.display = 'inline-block';
});

minimizeButton.addEventListener('click', () => {
  chatWindow.classList.remove('maximized');
  maximizeButton.style.display = 'inline-block';
  minimizeButton.style.display = 'none';
});

containerShadowRoot.querySelector('.comfolks-chat-close').addEventListener('click', () => {
  state.setChatOpen(false);
  chatWindow.style.display = 'none';
  chatForm.removeEventListener('submit', onUserSubmit);
  chatDialog.innerHTML = '';
});

          </script>
        </div>
      `;
    