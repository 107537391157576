/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

const DiscountIcon = ({ fillColor, ...props }) => (
  <svg width={17.43} height={18.108} {...props}>
    <defs>
      <style>
        {`.discount_svg__a{fill:${fillColor};stroke:${fillColor};stroke-width:.3px}`}
      </style>
    </defs>
    <path
      className="discount_svg__a"
      d="M16.395 9.329a.519.519 0 0 1 0-.459l.661-1.352a1.538 1.538 0 0 0-.664-2.042l-1.329-.705a.519.519 0 0 1-.27-.371l-.26-1.482a1.538 1.538 0 0 0-1.737-1.263l-1.49.211a.519.519 0 0 1-.436-.142L9.789.678a1.538 1.538 0 0 0-2.147 0L6.556 1.724a.519.519 0 0 1-.436.142l-1.49-.211a1.538 1.538 0 0 0-1.734 1.263L2.636 4.4a.519.519 0 0 1-.27.371l-1.329.705a1.538 1.538 0 0 0-.664 2.042l.661 1.352a.519.519 0 0 1 0 .459l-.661 1.352a1.538 1.538 0 0 0 .664 2.042l1.329.705a.519.519 0 0 1 .27.371l.26 1.482a1.537 1.537 0 0 0 1.516 1.278 1.58 1.58 0 0 0 .221-.016l1.49-.211a.519.519 0 0 1 .436.142L7.64 17.52a1.538 1.538 0 0 0 2.147 0l1.081-1.046a.52.52 0 0 1 .436-.142l1.49.211a1.538 1.538 0 0 0 1.737-1.262l.26-1.482a.519.519 0 0 1 .27-.371l1.329-.705a1.538 1.538 0 0 0 .664-2.042zm-.482 2.491l-1.329.705a1.535 1.535 0 0 0-.8 1.1l-.26 1.482a.52.52 0 0 1-.588.427l-1.49-.211a1.534 1.534 0 0 0-1.29.419l-1.081 1.046a.52.52 0 0 1-.727 0L7.27 15.743a1.533 1.533 0 0 0-1.07-.435 1.569 1.569 0 0 0-.22.016l-1.49.211a.52.52 0 0 1-.588-.427l-.26-1.482a1.534 1.534 0 0 0-.8-1.1l-1.329-.705a.52.52 0 0 1-.225-.691l.668-1.352a1.535 1.535 0 0 0 0-1.356L1.295 7.07a.52.52 0 0 1 .225-.691l1.329-.705a1.535 1.535 0 0 0 .8-1.1l.26-1.482a.52.52 0 0 1 .588-.427l1.49.211a1.535 1.535 0 0 0 1.29-.419l1.079-1.045a.52.52 0 0 1 .727 0l1.073 1.047a1.534 1.534 0 0 0 1.29.419l1.49-.211a.52.52 0 0 1 .588.427l.26 1.482a1.534 1.534 0 0 0 .8 1.1l1.329.705a.52.52 0 0 1 .225.691l-.661 1.352a1.535 1.535 0 0 0 0 1.356l.661 1.352a.52.52 0 0 1-.224.689z"
    />
    <path
      className="discount_svg__a"
      d="M12.447 5.366a.511.511 0 0 0-.723 0L4.98 12.11a.511.511 0 1 0 .723.723l6.744-6.744a.511.511 0 0 0 0-.723zM6.671 4.501a1.873 1.873 0 1 0 1.873 1.873 1.876 1.876 0 0 0-1.873-1.873zm0 2.725a.852.852 0 1 1 .852-.852.852.852 0 0 1-.852.852zM10.763 9.951a1.873 1.873 0 1 0 1.873 1.873 1.876 1.876 0 0 0-1.873-1.873zm0 2.725a.852.852 0 1 1 .852-.852.853.853 0 0 1-.852.852z"
    />
  </svg>
);

DiscountIcon.propTypes = {
  fillColor: PropTypes.string.isRequired,
};
export default DiscountIcon;
