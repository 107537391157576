import { handleActions } from "redux-actions";
import { putAnalytics, putAnalyticsSeparate, putRetentionRateChart, putMetricsFilters, putMetricsDetails, putHeatmap } from "../actions/analytics";

const INITIAL_STATE = {
  analytics: null,
  heatmap: null,
};

export default handleActions(
  {
    [putAnalytics]: (state, { payload: analytics }) => {
      return {
        ...state,
        analytics: analytics,
      };
    },
    [putAnalyticsSeparate]: (state, { payload: { mappedType, result } }) => {
      const analytics = mappedType
        ? { ...state.analytics, [mappedType]: result }
        : { ...state.analytics, ...result };
      return { ...state, analytics };
    },
    [putRetentionRateChart]: (state, { payload: retentionRateChart }) => {
      return {
        ...state,
        retentionRateChart,
      };
    },
    [putHeatmap]: (state, { payload: heatmap }) => {
      return {
        ...state,
        heatmap: heatmap,
      };
    },
    [putMetricsFilters]: (state, { payload: metricsFilters }) => {
      return {
        ...state,
        metricsFilters,
      };
    },
    [putMetricsDetails]: (state, { payload: metricsDetails }) => {
      return {
        ...state,
        metricsDetails,
      };
    },
  },
  INITIAL_STATE
);
