import { createAction } from 'redux-actions';
import { createRoutine, bindRoutineToReduxForm } from 'redux-saga-routines';

const CREATE_SOCIAL_SHARING = '@socialSharing/CREATE_SOCIAL_SHARING';
const FETCH_SOCIAL_SHARING = '@socialSharing/FETCH_SOCIAL_SHARING';
const PUT_SOCIAL_SHARING = '@socialSharing/PUT_SOCIAL_SHARING';
const UPDATE_SOCIAL_SHARING = '@socialSharing/UPDATE_SOCIAL_SHARING';
const HOLD_SOCIAL_SHARING = '@socialSharing/HOLD_SOCIAL_SHARING';
const ACTIVATE_SOCIAL_SHARING = '@socialSharing/ACTIVATE_SOCIAL_SHARING';
const FETCH_SOCIAL_SHARING_LIST = '@socialSharing/FETCH_SOCIAL_SHARING_LIST';
const PUT_SOCIAL_SHARING_LIST = '@socialSharing/PUT_SOCIAL_SHARING_LIST';
const FETCH_SOCIAL_SHARING_VISITORS = '@socialSharing/FETCH_VISITORS';
const PUT_SOCIAL_SHARING_VISITORS = '@socialSharing/PUT_VISITORS';
const FETCH_SOCIAL_SHARING_CAMPAIGN_NAMES =
  '@socialSharing/FETCH_CURRENT_CAMPAIGN_NAMES';
const PUT_SOCIAL_SHARING_CAMPAIGN_NAMES = '@socialSharing/PUT_CAMPAIGN_NAMES';
const VALIDATE_SOCIAL_SHARING_CAMPAIGN_NAME =
  '@socialSharing/VALIDATE_CAMPAIGN_NAME';
const UPLOAD_SOCIAL_SHARING_PROMO_CODES_FILE =
  '@socialSharing/UPLOAD_PROMO_CODES_FILE';

export const createSocialSharingRoutine = createRoutine(CREATE_SOCIAL_SHARING);
export const createSocialSharingHandler = bindRoutineToReduxForm(
  createSocialSharingRoutine
);

export const activateSocialSharing = createAction(ACTIVATE_SOCIAL_SHARING);
export const holdSocialSharing = createAction(HOLD_SOCIAL_SHARING);

export const fetchSocialSharing = createAction(FETCH_SOCIAL_SHARING);
export const putSocialSharing = createAction(PUT_SOCIAL_SHARING);
export const updateSocialSharing = createAction(UPDATE_SOCIAL_SHARING);

export const fetchSocialSharingList = createAction(FETCH_SOCIAL_SHARING_LIST);
export const putCampaignList = createAction(PUT_SOCIAL_SHARING_LIST);

export const fetchVisitors = createAction(FETCH_SOCIAL_SHARING_VISITORS);
export const putVisitors = createAction(PUT_SOCIAL_SHARING_VISITORS);

export const fetchCurrentCampaignNames = createAction(
  FETCH_SOCIAL_SHARING_CAMPAIGN_NAMES
);
export const putCampaignNames = createAction(PUT_SOCIAL_SHARING_CAMPAIGN_NAMES);

export const validateCampaignName = createAction(
  VALIDATE_SOCIAL_SHARING_CAMPAIGN_NAME
);

export const uploadPromoCodesFile = createAction(
  UPLOAD_SOCIAL_SHARING_PROMO_CODES_FILE
);
