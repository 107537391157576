import get from 'lodash/get'
import * as FORM_SCHEMAS from '../constants/formSchemas'

const createFormSchemaHelper = (
  // schemaName
) => {
  // TODO: For each schema
  // const selectedSchema = FORM_SCHEMAS[schemaName]

  /**
   * Convert strings to css selectors.
   *
   * @function
   * @template A
   * @param {object} form
   * @param {string} propertyName
   * @param {A} defaultValue
   * @returns {A}
   */
  const getPropertyValue = (form, propertyName, defaultValue) => {
    return get(form, `${propertyName}`, defaultValue)
  }

  /**
   *
   * @param {object} formValues
   * @returns {OnsiteWidget.Widget[]}
   */
  const getWidgets = (formValues) => {
    return getPropertyValue(formValues, FORM_SCHEMAS.FORM_SCHEMA_ONSITE.WIDGETS, [])
  }

  const getTriggers = (formValues) => {
    return getPropertyValue(formValues, FORM_SCHEMAS.FORM_SCHEMA_ONSITE.TRIGGER, [])
  }

  /**
   *
   * @param {object} formValues
   * @returns {OnsiteWidget.Widget}
   */
  const getEditedWidget = (formValues) => {
    return getPropertyValue(formValues, FORM_SCHEMAS.FORM_SCHEMA_ONSITE.EDITED_WIDGET, {})
  }

  return {
    getTriggers,
    getPropertyValue,
    getWidgets,
    getEditedWidget
  }
}

export const OnsiteFormHelper = createFormSchemaHelper(FORM_SCHEMAS.FORM_SCHEMA_NAMES.ONSITE)
