import { stringify } from 'qs';

import {
  SMS_ENDPOINT,
  SMS_VERIFY_CAMPAIGN_NAME_ENDPOINT,
  SMS_FETCH_CAMPAIGN_NAMES_ENDPOINT,
  SMS_CALCULATE_VISITORS_ENDPOINT,
  SMS_STATUS_ENDPOINT,
} from '../constants/endpoints';

import axios from './axios';

const postSms = async (id, SmsFormData) => {
  const { data: createdSms } = await axios.post(
    id ? `${SMS_ENDPOINT}/${id}` : SMS_ENDPOINT,
    SmsFormData,
  );
  return createdSms;
};

const changeStatusSms = async (SmsId, setObjectStatus) => {
  const { data } = await axios.post(
    `${SMS_STATUS_ENDPOINT}/${SmsId}`,
    { status: setObjectStatus },
  );
  return data;
};

const fetchSms = async (SmsId) => {
  const { data: Sms } = await axios.get(
    `${SMS_ENDPOINT}/${SmsId}`,
  );

  return Sms;
};

const fetchSmsList = async ({
  status = '', perPage = 10, page = 1, search = '',
}) => {
  const {
    data: { campaigns, total },
  } = await axios.get(
    `${SMS_ENDPOINT}?status=${status || ''}&page=${page}&per_page=${perPage}&search=${search || ''}`,
  );

  return [campaigns, total];
};

const fetchVisitors = async (fields) => {
  const {
    data: { visitors },
  } = await axios.get(
    `${SMS_CALCULATE_VISITORS_ENDPOINT}?${stringify(fields)}`,
  );

  return visitors;
};

const fetchCampaignNames = async (name) => {
  const { data: campaignNames } = await axios.get(
    `${SMS_FETCH_CAMPAIGN_NAMES_ENDPOINT}?name=${name}`,
  );

  return campaignNames;
};

const validateCampaignName = async (name, id) => {
  const { data } = await axios.get(
    `${SMS_VERIFY_CAMPAIGN_NAME_ENDPOINT}?name=${name}${id ? `&id=${id}` : ''
    }`,
  );
  return data;
};

export default {
  postSms,
  changeStatusSms,
  fetchSms,
  fetchSmsList,
  fetchVisitors,
  fetchCampaignNames,
  validateCampaignName,
};
