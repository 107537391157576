import get from 'lodash/get';

import { FORM_SCHEMA_ONSITE, FORM_WIDGET_SCHEMA } from '../../constants';
import {
  createElementEvents,
  directions,
  WIDGET_LAYOUT_TYPES,
} from '../../constants/onsiteWidgets';
import { getAllElements } from '../../help/element';

import {
  CAROUSEL_MOVE_OPTIONS,
  CAROUSEL_TYPES_OPTIONS,
  CAROUSEL_EFFECT,
} from './WidgetFormsFields/MainFields/FieldsByWidgetType/CarouselFields';

/**
 * @typedef {import("../../constants/onsiteWidgets").Element} Element
 * @typedef {import("../../constants/onsiteWidgets").ElementEvent} ElementEvent
 */

export const extractWidgetLayoutProperties = (values) => {
  const layoutProperties = {
    backgroundColor: get(values, 'backgroundColor'),
    backgroundImageUrl: get(values, 'backgroundImage.url'),
    backgroundOption: get(values, 'backgroundOption'),
    buttonIcon: get(values, 'buttonIcon'),
    buttonColor: get(values, 'buttonColor'),
    buttonSize: get(values, 'buttonSize'),
    borderColor: get(values, 'borderColor'),
    borderRadius: get(values, 'borderRadius'),
    borderWidth: get(values, 'borderWidth'),
    padding: get(values, 'padding'),
    position: get(values, 'position'),
    width: get(values, 'width'),
    height: get(values, 'height'),
  };
  const columnProperties = {
    flexDirection: get(values, 'flexDirection'),
    justifyContent: get(values, 'justifyContent'),
    alignItems: get(values, 'alignItems'),
  };

  const specificWidgetTypeProperties = {
    carousel: {
      slides: get(values, FORM_SCHEMA_ONSITE.PROPERTIES.CAROUSEL_SLIDES, []),
      moveOption: get(values, FORM_SCHEMA_ONSITE.PROPERTIES.CAROUSEL_MOVE_OPTION, CAROUSEL_MOVE_OPTIONS[0].value),
      carouselEffect: get(values, FORM_SCHEMA_ONSITE.PROPERTIES.CAROUSEL_EFFECT, CAROUSEL_EFFECT[0].value),
      carouselControlColor: get(values, FORM_SCHEMA_ONSITE.PROPERTIES.CAROUSEL_CONTROL_COLOR, 'rgba(115, 25, 130, 1)'),
      carouselVerticalMargin: get(values, FORM_SCHEMA_ONSITE.PROPERTIES.CAROUSEL_VERTICAL_MARGIN, '0px'),
      carouselCommonUrl: get(values, FORM_SCHEMA_ONSITE.PROPERTIES.CAROUSEL_COMMON_URL, ''),
      slideCount: get(values, FORM_SCHEMA_ONSITE.PROPERTIES.CAROUSEL_SLIDE_COUNT, 3),
      carouselDuration: get(values, FORM_SCHEMA_ONSITE.PROPERTIES.CAROUSEL_DURATION, '1200ms'),
      carouselDelay: get(values, FORM_SCHEMA_ONSITE.PROPERTIES.CAROUSEL_DELAY, '5000ms'),
      carouselAnimationFunction: get(values, FORM_SCHEMA_ONSITE.PROPERTIES.CAROUSEL_ANIMATION_FUNCTION, 'linear'),
      carouselType: get(values, FORM_SCHEMA_ONSITE.PROPERTIES.CAROUSEL_TYPE, CAROUSEL_TYPES_OPTIONS[0].value),
      carouselSlideWidth: get(values, FORM_SCHEMA_ONSITE.PROPERTIES.CAROUSEL_SLIDE_WIDTH, '60px'),
      carouselIntegratedOptions: get(values, FORM_SCHEMA_ONSITE.PROPERTIES.CAROUSEL_INTEGRATED_OPTIONS, 'integrated'),
    },
  };


  return {
    layoutId: `${get(values, 'id')}_${get(values, 'type')}`,
    widgetType: get(values, 'type'),
    horizontal: columnProperties.flexDirection === directions.ROW,
    sizeType: get(values, 'sizeType'),
    columnQuantity: get(values, 'columnQuantity'),
    items: get(values, 'items', []),
    items2: get(values, 'items2', []),
    events: get(values, 'events', []),
    timeClose: get(values, 'timeClose'),
    layoutProperties,
    columnProperties,
    specificWidgetTypeProperties,
  };
};

/**
 * @param {OnsiteWidget.Element[]} elements
 * @param {OnsiteWidget.Event[]} events
 * @returns {object} `{ [element.id]: event, ... }`
 * @example
 *
 * Return example:
 * { collapse-23d3: [{action_1: { target: ..., ... }}, {action_2: { target: ..., ... }}] }
 * { collapse-23d3: [{action: { target: ..., ... }}] }
 *
 */
export const getWidgetEventsByElementId = (elements, events) => {
  if (!Array.isArray(elements) || !Array.isArray(events)) return {};

  return elements.reduce((acc, el) => {
    const actionKeys = Object.keys(el).filter((key) => key.startsWith('action'));
    const matchingEvents = [];

    if (actionKeys.length > 0) {
      const actionEvents = actionKeys.map((key) => {
        const event = events.find((ev) => ev.id === el[key]);
        if (event) {
          return { [key]: event };
        }
      }).filter(Boolean);

      matchingEvents.push(...actionEvents);
    }

    return {
      ...acc,
      [el.id]: matchingEvents,
    };
  }, {});
};

/**
 * Create event by elements.
 *
 * @param {OnsiteWidget.Element[]} allItems
 * @returns {OnsiteWidget.Event[]}
 */
export const getWidgetEvents = (allItems) => {
  if (!Array.isArray(allItems)) return [];

  const result = [];

  for (let index = 0; index < allItems.length; index++) {
    const events = createElementEvents(allItems[index]);
    result.push(...events);
  }

  return result;
};

export const getAllWidgetItems = (widget = {}) => {
  if (widget.type === WIDGET_LAYOUT_TYPES.CAROUSEL) {
    const items = widget.carouselSlides.map((slide) => slide.items).flat();
    return getAllElements(items);
  }
  const items1 = get(widget, FORM_WIDGET_SCHEMA.properties.items, []);
  const items2 = get(widget, FORM_WIDGET_SCHEMA.properties.items2, []);

  return getAllElements([...items1, ...items2]);
};
