import { createAction } from 'redux-actions';
import { createRoutine, bindRoutineToReduxForm } from 'redux-saga-routines';

const FETCH_BLACK_LIST_UPLOADS = '@blacklist/FETCH_BLACK_LIST_UPLOADS';
const FETCH_BLACK_LIST_UPLOADS_LIST = '@blacklist/FETCH_BLACK_LIST_UPLOADS_LIST';
const FETCH_BLACK_LIST_SELECT_LIST = '@blacklist/FETCH_BLACK_LIST_SELECT_LIST';
const PUT_BLACK_LIST_UPLOADS = '@blacklist/PUT_BLACK_LIST_UPLOADS';
const PUT_BLACK_LIST_UPLOADS_LIST = '@blacklist/PUT_BLACK_LIST_UPLOADS_LIST';
const PUT_BLACK_LIST_SELECT_LIST = '@blacklist/PUT_BLACK_LIST_SELECT_LIST';
const ACTIVATE_BLACK_LIST_UPLOADS = '@blacklist/ACTIVATE_BLACK_LIST_UPLOADS';
const HOLD_BLACK_LIST_UPLOADS = '@blacklist/HOLD_BLACK_LIST_UPLOADS';
const VALIDATE_BLACK_LIST_UPLOADS_NAME = '@blacklist/VALIDATE_BLACK_LIST_UPLOADS_NAME';
const CREATE_BLACK_LIST_UPLOADS = '@blacklist/CREATE_BLACK_LIST_UPLOADS';
const FETCH_FILE_REPORT_SIM = '@blacklist/FETCH_FILE_REPORT_SIM';

export const fetchBlackListUploads = createAction(FETCH_BLACK_LIST_UPLOADS);
export const fetchBlackListUploadsList = createAction(FETCH_BLACK_LIST_UPLOADS_LIST);
export const putBlackListUploads = createAction(PUT_BLACK_LIST_UPLOADS);
export const putBlackListUploadsList = createAction(PUT_BLACK_LIST_UPLOADS_LIST);
export const putBlackListSelectList = createAction(PUT_BLACK_LIST_SELECT_LIST);
export const fetchBlackListSelectList = createAction(FETCH_BLACK_LIST_SELECT_LIST);
export const activateBlackListUploads = createAction(ACTIVATE_BLACK_LIST_UPLOADS);
export const holdBlackListUploads = createAction(HOLD_BLACK_LIST_UPLOADS);
export const validateBlackListUploadName = createAction(VALIDATE_BLACK_LIST_UPLOADS_NAME);
export const fetchFileReport = createAction(FETCH_FILE_REPORT_SIM);

export const createBlackListUploadRoutine = createRoutine(CREATE_BLACK_LIST_UPLOADS);
export const createBlackListUploadHandler = bindRoutineToReduxForm(
    createBlackListUploadRoutine
);