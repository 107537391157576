import React, { useState, useContext, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { GiftCard } from '../../components/OfferWall/GiftCard'
import { ECommerceFields } from '../../components/OfferWall/ECommerceFields'
import { TelecomFields } from '../../components/OfferWall/TelecomFields'
import { OfferAccepted } from '../../components/OfferWall/OfferAccepted'
import api from '../../api/offerWall'
import { OFFER_TYPES } from '../../constants/cpa'
import { snackbar as snackbarActions } from '../../redux/actions'
import { OfferWallContext } from './OfferWallContext'
import { ROUTE_CPA_WALL } from '../../constants'
import { mapResponseToOffer } from '../../mappers/cpaMappers'

export const OfferModal = ({ location, match, history }) => {
  const dispatch = useDispatch()
  const { hash, offerId } = match.params
  const cfApiKey = hash.slice(0, 8)
  const campaignHash = hash.slice(8)
  const { wallState } = useContext(OfferWallContext)
  const {
    username,
    msisdn,
    offers,
    operatorPrefix,
    operatorMask,
    operatorCountry,
    operatorCode,
    operatorLength
  } = wallState
  const query = location.search

  const offer = offers ? offers.find((item) => item.id === Number(offerId)) : undefined

  const [isOfferModalOpen, setOfferModalOpen] = useState(true)
  const [isAcceptedOfferModalOpen, setAcceptedOfferModalOpen] = useState(false)
  const [fullDescription, openFullDescription] = useState(false)

  const [currentOffer, setCurrentOffer] = useState(offers ? offer : undefined)

  useEffect(() => {
    if (!offer && window.comfolks) {
      api
        .fetchOfferInfoById(cfApiKey, offerId)
        .then((offer) => {
          if (Array.isArray(offer) && offer.length === 0) {
            history.push(location.pathname.replace(`/${offerId}`, ''))
          }
          setCurrentOffer(mapResponseToOffer(offer))
        })
        .catch((error) => {
          dispatch(
            snackbarActions.enqueueSnackbar({
              message: 'Offer card loading error occurred',
              options: { variant: 'error' }
            })
          )
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username])

  useEffect(() => {
    if (window.comfolks) {
      api.countOfferCardClick(cfApiKey, offerId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username])

  const showFullDescription = () => {
    openFullDescription(true)
  }

  const onECommAccepted = ({ username, email }) => {
    api
      .acceptEComOffer(cfApiKey, username, email, offerId, campaignHash)
      .then(() => {
        dispatch(
          snackbarActions.enqueueSnackbar({
            message: 'Offer has been successfully accepted!',
            options: { variant: 'success' }
          })
        )
        setOfferModalOpen(false)
        setAcceptedOfferModalOpen(true)
      })
      .catch((error) => {
        const errorMessage = error.response.data.message
        dispatch(
          snackbarActions.enqueueSnackbar({
            message: errorMessage || 'Offer acceptance error occurred',
            options: { variant: 'error' }
          })
        )
      })
  }

  const onCodeSend = async (telNumber) => {
    api.sendSmsCode(cfApiKey, telNumber, offerId).catch((error) => {
      const errorMessage = error.response.data.message
      dispatch(
        snackbarActions.enqueueSnackbar({
          message: errorMessage || 'There are troubles with sending code',
          options: { variant: 'error' }
        })
      )
    })
  }

  const onTelecomAccepted = (telNumber, code) => {
    api
      .acceptTelecomOffer(cfApiKey, telNumber, code, offerId, campaignHash, query)
      .then((response) => {
        console.log(response)
        dispatch(
          snackbarActions.enqueueSnackbar({
            message: 'Offer has been successfully accepted!',
            options: { variant: 'success' }
          })
        )
        setOfferModalOpen(false)
        setAcceptedOfferModalOpen(true)
      })
      .catch((error) => {
        const errorMessage = error.response.data.message
        dispatch(
          snackbarActions.enqueueSnackbar({
            message: errorMessage || 'Offer acceptance error occurred',
            options: { variant: 'error' }
          })
        )
      })
  }

  const onClose = () => {
    history.push(`${ROUTE_CPA_WALL}/${hash}${query}`)
  }

  if (currentOffer && isOfferModalOpen) {
    return (
      <GiftCard
        open={isOfferModalOpen}
        onBack={onClose}
        showFullDescription={fullDescription}
        {...currentOffer}
        background={currentOffer.background}
        textColor={currentOffer.textColor}
      >
        {currentOffer && currentOffer.type === OFFER_TYPES.ECOM && (
          <ECommerceFields
            onShowFullDescription={showFullDescription}
            onAccept={onECommAccepted}
            username={username}
            textColor={currentOffer.textColor}
          />
        )}
        {currentOffer && currentOffer.type === OFFER_TYPES.TELECOM && (
          <TelecomFields
            onShowFullDescription={showFullDescription}
            onCodeSend={onCodeSend}
            onAccept={onTelecomAccepted}
            msisdn={msisdn}
            notice={currentOffer.notice}
            offerDescriptionTitle={currentOffer.offerDescriptionTitle}
            phoneNumberLabel={currentOffer.phoneNumberLabel}
            price={currentOffer.price}
            textColor={currentOffer.textColor}
            operator={currentOffer.operator}
            buttonTitle={currentOffer.buttonTitle}
            cfApiKey={cfApiKey}
            offerId={offerId}
            operatorPrefix={operatorPrefix}
            operatorMask={operatorMask}
            operatorCountry={operatorCountry}
            operatorCode={operatorCode}
            operatorLength={operatorLength}
          />
        )}
      </GiftCard>
    )
  }

  if (isAcceptedOfferModalOpen) {
    return (
      <OfferAccepted
        redirectUrl={currentOffer?.landingPageUrl}
        open={isAcceptedOfferModalOpen}
        onBack={onClose}
      />
    )
  }

  return null
}
