import React, { memo } from 'react';
import IconButton from '@material-ui/core/IconButton';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { programState as programStateAction } from '../../redux/actions';


function toggle(event, open, toggleMenu) {
  if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
    return;
  }
  toggleMenu(open);
}

function HamburgerPanel({ toggleMenu, type, styleHamburger }) {
  return (
    <IconButton
      color="primary"
      className={styleHamburger}
      aria-label="icon-button-menu"
      component="span"
      onClick={e => toggle(e, true, toggleMenu)}
    >
      <i className="material-icons">
        {type}
      </i>
    </IconButton>
  );
}

HamburgerPanel.propTypes = {
  toggleMenu: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  styleHamburger: PropTypes.string.isRequired,
};

const mapDispatchToProps = dispatch => bindActionCreators({
  toggleBaseMenuMobile: programStateAction.toggleBaseMenuMobile,
}, dispatch);

export default connect(null, mapDispatchToProps)(memo(HamburgerPanel));
