/**
 * 
 * @param {string} str 
 */
const converStringToSelector = (str) => str.startsWith('.') ? str : `.${str}`


/**
 * Convert strings to css selectors.
 * 
 * @function
 * @template A
 * @param {A} values
 * @returns {A}
 */
const CssClassesToSelectors = (values) => {
    const result = {}

    Object.keys(values).forEach(key => {
        result[key] = converStringToSelector(values[key])
    })

    return result
}

export default CssClassesToSelectors