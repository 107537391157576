import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';


export const toHtml = editorState => {
  const rawContentState = convertToRaw(editorState.getCurrentContent());
  const markup = draftToHtml(rawContentState);

  return markup;
};
