import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

import {
  USER_REGISTRATION_REQUEST,
  USER_REGISTRATION_SUCCESS,
  USER_LOGOUT_REQUEST,
  USER_LOGOUT_SUCCESS,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_GET_ALLOWED_MODULES_REQUEST,
  USER_GET_ALLOWED_MODULES_SUCCESS,
  USER_PING,
  USER_OTP_REQUEST, 
  USER_OTP_RESEND,
} from '../redux/actionTypes';
import { snackbar } from '../redux/actions';
import { auth as api } from '../api';
import history from '../navigation/history';
import {SNACK_VARIANTS, DEFAULT_VARIANT_MESSSAGE, ROUTE_OTP} from '../constants';
import { getAuthHeader } from './selectors';
import get from "lodash/get";
import {
  pingFailure,
  pingFailureOTP,
  userOtpFailure,
  userOtpResendSuccess,
  userOtpSuccess
} from "../redux/actions/user";

function* userRegistration({ payload }) {
  try {
    const { body } = payload;
    const result = yield call(api.register, body);
    if (result) {
      yield put({ type: USER_REGISTRATION_SUCCESS, payload: result });
      yield call(history.push, '/analytics-all');
    }
  } catch (error) {
    const { response: { data = DEFAULT_VARIANT_MESSSAGE } = {} } = error;
    yield put(snackbar.enqueueSnackbar({
      message: data,
      options: { variant: SNACK_VARIANTS.error },
    }));
  }
}

function* userGetAllowedModules() {
  try {
    const authHeader = yield select(getAuthHeader);
    const result = yield call(api.getAllowedModules, authHeader);
    const resultIds = yield result.result.modules.map(item => item.module_id)
    if (result) {
      yield put({ type: USER_GET_ALLOWED_MODULES_SUCCESS, payload: resultIds});
    }
  } catch (error) {
    const { response: { data = DEFAULT_VARIANT_MESSSAGE } = {} } = error;
    yield put(snackbar.enqueueSnackbar({
      message: data,
      options: { variant: SNACK_VARIANTS.error },
    }));
  }
}

function* userLogin({ payload }) {
  try {
    const { body } = payload;
    const result = yield call(api.login, body);
    if (result) {
      yield put({ type: USER_LOGIN_SUCCESS, payload: result });
      yield call(history.push, '/analytics-all');
    }
  } catch (error) {
    const { response: { data = DEFAULT_VARIANT_MESSSAGE } = {} } = error;
    yield put(snackbar.enqueueSnackbar({
      message: data,
      options: { variant: SNACK_VARIANTS.error },
    }));
  }
}

function* userOtp({ payload }) {
  try {
    const { body } = payload;
    yield call(api.otp, body);
    yield put(userOtpSuccess());
    yield call(history.push, '/');
  } catch (error) {
    const errorData = {
      'message': get(error, 'response.data.message', 'Invalid OTP'),
      'status': get(error, 'response.status', 500),
    }
    if (errorData.status === 401) {
      yield put(pingFailure(errorData));
    } else {
      yield put(userOtpFailure(errorData));
    }
  }
}

function* otpResend() {
  try {
    yield call(api.otpResend);
    yield put(userOtpResendSuccess());
    yield put(snackbar.enqueueSnackbar({
      message: 'Resend new OTP',
      options: { variant: SNACK_VARIANTS.success },
    }));
  } catch (error) {
    const errorData = {
      'message': get(error, 'response.data.message', 'Something went wrong (default error message)'),
      'status': get(error, 'response.status', 500),
    }
    if (errorData.status === 401) {
      yield put(pingFailure(errorData));
    } else {
      yield put(snackbar.enqueueSnackbar({
        message: errorData.message,
        options: { variant: SNACK_VARIANTS.error },
      }));
    }
  }
}

function* userLogout() {
  try {
    const authHeader = yield select(getAuthHeader);
    // const result =
    yield call(api.logout, authHeader);
  } catch (error) {}
  yield put({ type: USER_LOGOUT_SUCCESS });
  yield call(history.push, '/authorization');
  yield put(snackbar.enqueueSnackbar({
    message: 'Success logout',
    options: { variant: SNACK_VARIANTS.success },
  }));
}

function* userPing() {
  try {
    const authHeader = yield select(getAuthHeader);
    if (authHeader && authHeader.Authorization && authHeader.Authorization.includes('Bearer')) {
      yield call(api.ping, authHeader);
    }
  } catch (error) {
    const errorData = {
      'message': get(error, 'response.data.message', 'Something went wrong (default error message)'),
      'status': get(error, 'response.status', 500),
    }
    console.log('ping error', errorData)
    if (errorData.status === 401) { // Unauthenticated (token error or OTP required)
      if (errorData.message.startsWith('OTP required')) {
        yield put(pingFailureOTP(errorData));
        yield call(history.push, ROUTE_OTP);
      } else {
        yield put(pingFailure(errorData));
      }
    }
  }
}

export default function* watcher() {
  yield takeLatest(USER_REGISTRATION_REQUEST, userRegistration);
  yield takeLatest(USER_LOGOUT_REQUEST, userLogout);
  yield takeLatest(USER_PING, userPing);
  yield takeLatest(USER_LOGIN_REQUEST, userLogin);
  yield takeLatest(USER_OTP_REQUEST, userOtp);
  yield takeLatest(USER_OTP_RESEND, otpResend);
  yield takeLatest(USER_GET_ALLOWED_MODULES_REQUEST, userGetAllowedModules);
}
