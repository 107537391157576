import React, { useEffect, useMemo, useState } from 'react';

import { elements } from '../../../constants/onsiteWidgets';
import { Button } from '../../../generator/items/Button';
import ErrorBoundary from '../../../generator/items/ErrorBoundary';
import { Form } from '../../../generator/items/Form';
import { Image } from '../../../generator/items/Image';
import { OrderForm } from '../../../generator/items/OrderForm';
import { PhoneVerification } from '../../../generator/items/PhoneVerification';
import { ClickPay } from '../../../generator/items/ClickPay';
import { ProductCard } from '../../../generator/items/ProductCard';
import { Rating } from '../../../generator/items/Rating';
import { Separator } from '../../../generator/items/Separator';
import { Social } from '../../../generator/items/Social';
import { Text } from '../../../generator/items/Text/Text';
import { Video } from '../../../generator/items/Video';

const Stepper = React.lazy(() => import('../../../generator/items/Stepper'));
const Collapse = React.lazy(() => import('../../../generator/items/Collapse'));

const elementByTypeMapping = {
  [elements.BUTTON]: Button,
  [elements.FORM]: Form,
  [elements.ORDER_FORM]: OrderForm,
  [elements.IMAGE]: Image,
  [elements.VIDEO]: Video,
  [elements.SEPARATOR]: Separator,
  [elements.TEXT]: Text,
  [elements.RATING]: Rating,
  [elements.SOCIAL]: Social,
  [elements.COLLAPSE]: Collapse,
  [elements.STEPPER]: Stepper,
  [elements.PHONE_VERIFICATION]: PhoneVerification,
  [elements.CLICK_PAY]: ClickPay,
  [elements.ERROR_BOUNDARY]: ErrorBoundary,
  [elements.PRODUCT_CARD]: ProductCard,
};

const getPreviewCommonItemStyle = (horizontal, active, disableEvents, marginForButton) => ({
  pointerEvents: disableEvents ? 'none' : 'auto',
  cursor: 'pointer',
  outline: 'none',
  // ...(horizontal ? { height: '100%', marginRight: '10px' } : { margin: item.boxShadow?.spreadRadius > 0 ? `${item.boxShadow?.spreadRadius + 'px'} 0` : '', width: '100%' }),
  ...(horizontal ? { height: '100%', marginRight: '10px' } : { margin: marginForButton > 0 ? `${`${marginForButton}px`} 0` : '0 0 10px 0', width: '100%' }),
  // ...(horizontal ? { height: '100%', marginRight: '10px' } : { marginBottom: '10px', width: '100%' }),
  border: active ? '1px dashed cyan' : '1px dashed lightgray',
});

const commonStyle = {
  display: 'flex',
  pointerEvents: 'none',
};

const getCommonItemStyle = (horizontal, additionStyles) => (horizontal
  ? {
    ...commonStyle,
    ...additionStyles,
    flexDirection: 'row',
    height: '100%',
  }
  : {
    ...commonStyle,
    ...additionStyles,
    flexDirection: 'column',
    width: '100%',
  });

const Item = ({
  item,
  innerRef,
  horizontal,
  sizes,
  active,
  alignItems,
  layoutProps,
  disableEvents = true,
  childrenElements,
  eventsByElementIdMap,
  setActiveEditor,
  focusTextEditor,
  values,
  events,
  activeElement,
  form,
  section,
  ...rest
}) => {
  const ItemComponent = elementByTypeMapping[item.type];

  const [marginForButton, setMarginForButton] = useState({});

  function addMargin() {
    if (item.boxShadow?.blurRadius > 15) {
      return item.boxShadow?.spreadRadius - 5;
    }
    return item.boxShadow?.spreadRadius;
  }

  useEffect(() => {
    setMarginForButton(addMargin());
  }, [item.boxShadow?.spreadRadius, item.boxShadow?.blurRadius]);

  const width = useMemo(() => {
    switch (item.widthOption) {
    case 'fullWidth':
      return '100%';
    case 'fixed':
    case 'dependingOnText':
      return 'fit-content';
    default:
    }
  }, [item.widthOption]);

  const disableEventsByType = item.type === elements.VIDEO ? false : disableEvents;

  return (
    <div ref={innerRef} {...rest} style={{ width }}>
      <div style={getPreviewCommonItemStyle(horizontal, active, disableEventsByType, marginForButton)}>
        <ItemComponent
          layoutProps={layoutProps}
          commonStyle={getCommonItemStyle(horizontal, { alignItems })}
          horizontal={horizontal}
          alignItems={alignItems}
          sizes={sizes}
          eventsByElementIdMap={eventsByElementIdMap}
          setActiveEditor={setActiveEditor}
          focusTextEditor={focusTextEditor}
          values={values}
          events={events}
          activeElement={activeElement}
          form={form}
          section={section}
          isEditing
          {...item}
        />
      </div>
    </div>
  );
};

export default Item;
