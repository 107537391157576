import { createAction } from 'redux-actions';
import { createRoutine, bindRoutineToReduxForm } from 'redux-saga-routines';

const CREATE_CAMPAIGN_OFFER = '@campaignOffer/CREATE_CAMPAIGN_OFFER';
const FETCH_CAMPAIGN_OFFER = '@campaignOffer/FETCH_CAMPAIGN_OFFER';
const PUT_CAMPAIGN_OFFER = '@campaignOffer/PUT_CAMPAIGN_OFFER';
const UPDATE_CAMPAIGN_OFFER = '@campaignOffer/UPDATE_CAMPAIGN_OFFER';
const HOLD_CAMPAIGN_OFFER = '@campaignOffer/HOLD_CAMPAIGN_OFFER';
const ACTIVATE_CAMPAIGN_OFFER = '@campaignOffer/ACTIVATE_CAMPAIGN_OFFER';
const FETCH_CAMPAIGN_OFFER_LIST = '@campaignOffer/FETCH_CAMPAIGN_OFFER_LIST';
const PUT_CAMPAIGN_OFFER_LIST = '@campaignOffer/PUT_CAMPAIGN_OFFER_LIST';
const PUT_CAMPAIGN_OFFER_CAMPAIGN_NAMES = '@campaignOffer/PUT_CAMPAIGN_NAMES';
const VALIDATE_CAMPAIGN_OFFER_CAMPAIGN_NAME = '@campaignOffer/VALIDATE_CAMPAIGN_NAME';
const UPLOAD_CAMPAIGN_OFFER_PROMO_CODES_FILE = '@campaignOffer/UPLOAD_PROMO_CODES_FILE';

export const createCampaignOfferRoutine = createRoutine(CREATE_CAMPAIGN_OFFER);
export const createCampaignOfferHandler = bindRoutineToReduxForm(
  createCampaignOfferRoutine
);

export const activateCampaignOffer = createAction(ACTIVATE_CAMPAIGN_OFFER);
export const holdCampaignOffer = createAction(HOLD_CAMPAIGN_OFFER);

export const fetchCampaignOffer = createAction(FETCH_CAMPAIGN_OFFER);
export const putCampaignOffer = createAction(PUT_CAMPAIGN_OFFER);
export const updateCampaignOffer = createAction(UPDATE_CAMPAIGN_OFFER);

export const fetchCampaignOfferList = createAction(FETCH_CAMPAIGN_OFFER_LIST);
export const putCampaignOfferList = createAction(PUT_CAMPAIGN_OFFER_LIST);

export const putCampaignNames = createAction(PUT_CAMPAIGN_OFFER_CAMPAIGN_NAMES);

export const validateCampaignName = createAction(
  VALIDATE_CAMPAIGN_OFFER_CAMPAIGN_NAME
);

export const uploadPromoCodesFile = createAction(
  UPLOAD_CAMPAIGN_OFFER_PROMO_CODES_FILE
);
