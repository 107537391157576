import { handleActions } from 'redux-actions';

import { putSmsTree, putSmsTreeList, updateSmsTree } from '../actions/smsTree';

const INITIAL_STATE = {
  list: [],
  total: 0,
  editedItem: null,
};

export default handleActions(
  {
    [putSmsTree]: (state, { payload }) => ({ ...state, editedItem: payload }),
    [putSmsTreeList]: (state, { payload: { list, total } }) => ({
      ...state,
      list,
      total,
    }),
    [updateSmsTree]: (state, { payload }) => ({
      ...state,
      editedItem: { ...state.editedItem, ...payload },
    }),
  },
  INITIAL_STATE,
);
