import { stringify } from 'qs';

import {
  EMAIL_ENDPOINT,
  EMAIL_STATUS_ENDPOINT,
  EMAIL_CALCULATE_VISITORS_ENDPOINT,
  EMAIL_FETCH_CAMPAIGN_NAMES_ENDPOINT,
  EMAIL_VERIFY_CAMPAIGN_NAME_ENDPOINT,
  EMAIL_SEND_PROMOCODES_FILE_ENDPOINT,
  EMAIL_TEMPLATE_ENDPOINT,
  EMAIL_INFO_ENDPOINT,
} from '../constants/endpoints';

import axios from './axios';

const postEmail = async (id, EmailFormData) => {
  const { data: createdEmail } = await axios.post(
    id ? `${EMAIL_ENDPOINT}/${id}` : EMAIL_ENDPOINT,
    EmailFormData,
  );
  return createdEmail;
};

const changeStatusEmail = async (EmailId, setObjectStatus) => {
  const { data } = await axios.post(
    `${EMAIL_STATUS_ENDPOINT}/${EmailId}`,
    { status: setObjectStatus },
  );
  return data;
};

const fetchEmail = async (EmailId) => {
  const { data: Email } = await axios.get(
    `${EMAIL_ENDPOINT}/${EmailId}`,
  );

  return Email;
};

const fetchEmailList = async ({
  status = '', perPage = 10, page = 1, search = '',
}) => {
  const {
    data: { campaigns, total },
  } = await axios.get(
    `${EMAIL_ENDPOINT}?status=${status || ''}&page=${page}&per_page=${perPage}&search=${search || ''}`,
  );

  return [campaigns, total];
};

const fetchVisitors = async (fields) => {
  const {
    data: { visitors },
  } = await axios.get(
    `${EMAIL_CALCULATE_VISITORS_ENDPOINT}?${stringify(fields)}`,
  );

  return visitors;
};

const fetchCampaignNames = async (name) => {
  const { data: campaignNames } = await axios.get(
    `${EMAIL_FETCH_CAMPAIGN_NAMES_ENDPOINT}?name=${name}`,
  );

  return campaignNames;
};

const validateCampaignName = async (name, id) => {
  const { data } = await axios.get(
    `${EMAIL_VERIFY_CAMPAIGN_NAME_ENDPOINT}?name=${name}${
      id ? `&id=${id}` : ''
    }`,
  );
  return data;
};

const fetchDownloadStat = async (id) => {
  await axios.get(`${EMAIL_INFO_ENDPOINT}/${id}`, { responseType: 'blob' }).then((response) => {
    const href = URL.createObjectURL(response.data);
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', `${id}_email_info.xlsx`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  });
};

const sendPromoCodesFile = async (file) => {
  const data = new FormData();
  data.append('file', file);

  const {
    data: { success, count },
  } = await axios.post(EMAIL_SEND_PROMOCODES_FILE_ENDPOINT, data);

  return { success, count };
};

const fetchTemplateList = async () => {
  const { data } = await axios.get(`${EMAIL_TEMPLATE_ENDPOINT}/list`);
  return data;
};

const postTemplate = async (template, id) => {
  const { settings, html } = template;
  const { type: widget_type } = settings;
  const { data: createdTemplate } = await axios.post(
    id ? `${EMAIL_TEMPLATE_ENDPOINT}/${id}` : EMAIL_TEMPLATE_ENDPOINT,
    JSON.stringify({
      settings,
      widget_type,
      html,
    }),
  );

  return createdTemplate;
};

async function deleteTemplate(id) {
  const { data: result, status } = await axios.delete(`${EMAIL_TEMPLATE_ENDPOINT}/${id}`);
  return { result, status };
}

export default {
  postEmail,
  changeStatusEmail,
  fetchEmail,
  fetchDownloadStat,
  fetchEmailList,
  fetchVisitors,
  fetchCampaignNames,
  validateCampaignName,
  sendPromoCodesFile,
  fetchTemplateList,
  postTemplate,
  deleteTemplate,
};
