import {
  SIM_NUMBER_UPLOADS_ENDPOINT,
  SIM_NUMBER_UPLOADS_FILE_REPORT,
  SIM_NUMBER_UPLOADS_STATUS_ENDPOINT,
  SIM_NUMBER_UPLOADS_VERIFY_NAME_ENDPOINT,
  SIM_SALES_POINT_UPLOADS_ENDPOINT, 
  SIM_SALES_POINT_UPLOADS_FILE_REPORT,
  SIM_SALES_POINT_UPLOADS_STATUS_ENDPOINT,
  SIM_SALES_POINT_UPLOADS_VERIFY_NAME_ENDPOINT,
  SERVICE_UPLOADS_ENDPOINT,
  SERVICE_UPLOADS_FILE_REPORT,
  SERVICE_UPLOADS_STATUS_ENDPOINT,
  SERVICE_UPLOADS_VERIFY_NAME_ENDPOINT,
} from '../constants/endpoints';

import axios from './axios';

const changeStatus = async (id, setObjectStatus) => {
  const { data } = await axios.post(
    `${SIM_NUMBER_UPLOADS_STATUS_ENDPOINT}/${id}`,
    { status: setObjectStatus },
  );
  return data;
};

const changeStatusPoints = async (id, setObjectStatus) => {
  const { data } = await axios.post(
    `${SIM_SALES_POINT_UPLOADS_STATUS_ENDPOINT}/${id}`,
    { status: setObjectStatus },
  );
  return data;
};

const changeStatusServices = async (id, setObjectStatus) => {
  const { data } = await axios.post(
    `${SERVICE_UPLOADS_STATUS_ENDPOINT}/${id}`,
    { status: setObjectStatus },
  );
  return data;
};

const fetchCampaign = async (id) => {
  const { data: campaign } = await axios.get(`${SIM_NUMBER_UPLOADS_ENDPOINT}/${id}`);

  return campaign;
};

const fetchCampaignPoints = async (id) => {
  const { data: campaign } = await axios.get(`${SIM_SALES_POINT_UPLOADS_ENDPOINT}/${id}`);

  return campaign;
};

const fetchCampaignServices = async (id) => {
  const { data: campaign } = await axios.get(`${SERVICE_UPLOADS_ENDPOINT}/${id}`);

  return campaign;
};

const fetchCampaignList = async ({
  status = '', perPage = 10, page = 1, search = '',
}) => {
  const {
    data: { campaigns, total },
  } = await axios.get(
    `${SIM_NUMBER_UPLOADS_ENDPOINT}?status=${status || ''}&page=${page}&per_page=${perPage}&search=${search || ''}`,
  );

  return [campaigns, total];
};

const fetchCampaignPointsList = async ({
  status = '', perPage = 10, page = 1, search = '',
}) => {
  const {
    data: { campaigns, total },
  } = await axios.get(
    `${SIM_SALES_POINT_UPLOADS_ENDPOINT}?status=${status || ''}&page=${page}&per_page=${perPage}&search=${search || ''}`,
  );

  return [campaigns, total];
};

const fetchCampaignServicesList = async ({
  status = '', perPage = 10, page = 1, search = '',
}) => {
  const {
    data: { campaigns, total },
  } = await axios.get(
    `${SERVICE_UPLOADS_ENDPOINT}?status=${status || ''}&page=${page}&per_page=${perPage}&search=${search || ''}`,
  );

  return [campaigns, total];
};

const validateCampaignName = async (name, id) => {
  const { data } = await axios.get(
    `${SIM_NUMBER_UPLOADS_VERIFY_NAME_ENDPOINT}?name=${name}${
      id ? `&id=${id}` : ''
    }`,
  );
  return data;
};

const validatePointsCampaignName = async (name, id) => {
  const { data } = await axios.get(
    `${SIM_SALES_POINT_UPLOADS_VERIFY_NAME_ENDPOINT}?name=${name}${
      id ? `&id=${id}` : ''
    }`,
  );
  return data;
};

const validateServicesCampaignName = async (name, id) => {
  const { data } = await axios.get(
    `${SERVICE_UPLOADS_VERIFY_NAME_ENDPOINT}?name=${name}${
      id ? `&id=${id}` : ''
    }`,
  );
  return data;
};

const postSimNumberUpload = async (id, data) => {
  const { data: createdSimNumberUpload } = await axios.post(
    id ? `${SIM_NUMBER_UPLOADS_ENDPOINT}/${id}` : SIM_NUMBER_UPLOADS_ENDPOINT,
    data,
  );
  return createdSimNumberUpload;
};

const postSimPointsUpload = async (id, data) => {
  const { data: createdSimNumberUpload } = await axios.post(
    id ? `${SIM_SALES_POINT_UPLOADS_ENDPOINT}/${id}` : SIM_SALES_POINT_UPLOADS_ENDPOINT,
    data,
  );
  return createdSimNumberUpload;
};

const postServicesUpload = async (id, data) => {
  const { data: createdServicesUpload } = await axios.post(
    id ? `${SERVICE_UPLOADS_ENDPOINT}/${id}` : SERVICE_UPLOADS_ENDPOINT,
    data,
  );
  return createdServicesUpload;
};

const fetchFileReport = async id => {
  await axios.get(`${SIM_NUMBER_UPLOADS_FILE_REPORT}/${id}`, { responseType: 'blob' }).then((response) => {
    const href = URL.createObjectURL(response.data);
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', `${id}_sim_numbers_info.xlsx`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  });
};

const fetchPointsFileReport = async id => {
  await axios.get(`${SIM_SALES_POINT_UPLOADS_FILE_REPORT}/${id}`, { responseType: 'blob' }).then((response) => {
    const href = URL.createObjectURL(response.data);
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', `${id}_sim_sales_points_info.xlsx`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  });
};

const fetchServicesFileReport = async id => {
  await axios.get(`${SERVICE_UPLOADS_FILE_REPORT}/${id}`, { responseType: 'blob' }).then((response) => {
    const href = URL.createObjectURL(response.data);
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', `${id}_services_info.xlsx`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  });
};

export default {
  changeStatus,
  changeStatusPoints,
  changeStatusServices,
  fetchCampaign,
  fetchCampaignPoints,
  fetchCampaignServices,
  fetchCampaignList,
  fetchCampaignPointsList,
  fetchCampaignServicesList,
  validateCampaignName,
  validatePointsCampaignName,
  validateServicesCampaignName,
  postSimNumberUpload,
  postSimPointsUpload,
  postServicesUpload,
  fetchFileReport,
  fetchPointsFileReport,
  fetchServicesFileReport,
};
