import {
  SMS_TREE_ENDPOINT,
  SMS_TREE_STATUS_ENDPOINT,
  SMS_TREE_VERIFY_CAMPAIGN_NAME_ENDPOINT,
} from '../constants/endpoints';

import axios from './axios';

const postSmsTree = async (id, SmsTreeFormData) => {
  const { data: createdSmsTree } = await axios.post(
    id ? `${SMS_TREE_ENDPOINT}/${id}` : SMS_TREE_ENDPOINT,
    SmsTreeFormData,
  );
  return createdSmsTree;
};

const changeStatusSmsTree = async (SmsId, setObjectStatus) => {
  const { data } = await axios.post(
    `${SMS_TREE_STATUS_ENDPOINT}/${SmsId}`,
    { status: setObjectStatus },
  );
  return data;
};

const fetchSmsTree = async (SmsTreeId) => {
  const { data: SmsTree } = await axios.get(
    `${SMS_TREE_ENDPOINT}/${SmsTreeId}`,
  );

  return SmsTree;
};

const fetchSmsTreeList = async ({
  status = '', perPage = 10, page = 1, search = '',
}) => {
  const {
    data: { campaigns, total },
  } = await axios.get(
    `${SMS_TREE_ENDPOINT}?status=${status || ''}&page=${page}&per_page=${perPage}&search=${search || ''}`,
  );

  return [campaigns, total];
};

const validateCampaignName = async (name, id) => {
  const { data } = await axios.get(
    `${SMS_TREE_VERIFY_CAMPAIGN_NAME_ENDPOINT}?name=${name}${id ? `&id=${id}` : ''
    }`,
  );
  return data;
};

export default {
  postSmsTree,
  fetchSmsTree,
  fetchSmsTreeList,
  changeStatusSmsTree,
  validateCampaignName,
};
