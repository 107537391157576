import { handleActions } from 'redux-actions';
import { putWebPush, putWebPushList, updateWebPush } from '../actions/webPush';

const INITIAL_STATE = {
  list: [],
  total: 0,
  editedItem: null
};

export default handleActions(
  {
    [putWebPush]: (state, { payload }) => ({ ...state, editedItem: payload }),
    [putWebPushList]: (state, { payload: { list, total } }) => ({
      ...state,
      list,
      total
    }),
    [updateWebPush]: (state, { payload }) => ({
      ...state,
      editedItem: { ...state.editedItem, ...payload }
    })
  },
  INITIAL_STATE
);
