import { useEffect } from 'react';

export const useClickOutside = (target, handler) => {
  useEffect(() => {
    const listener = (event) => {
      const isClickedOutside = () => {
        const elements = document.querySelectorAll(target);
        return Array.from(elements).every(element => !element.contains(event.target));
      };

      if (isClickedOutside()) {
        handler();
      }
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [target, handler]);
};
