import { CLOSE_WIDGET_CLASS_NAME, buttonActionTypes } from '../../constants/onsiteWidgets';
import { parseAction } from '../../help/actions';
import { parseBoxShadow } from '../../help/itemStyles/boxShadow';

import { parseStyles } from './commonParsers/commonStylesParser';

/*
  <div>
    <input name="email" placeholder="Write!" />
    <button style="color:#ffffff;background-color:#000000">Click me!</button>
  </div>
*/

const getButtonFields = (el) => {
  const text = el.innerHTML;
  const { color } = el.style;
  const { backgroundColor } = el.style;
  const { borderRadius } = el.style;
  const { fontSize } = el.style;
  const { fontFamily } = el.style;
  const { fontWeight } = el.style;
  const { textDecoration } = el.style;
  const { fontStyle } = el.style;
  const boxShadow = parseBoxShadow(el.style);

  return {
    text,
    buttonStyles: {
      color,
      backgroundColor,
      borderRadius,
      fontSize,
      fontFamily: fontFamily === '"Times New Roman"' ? 'Times New Roman' : fontFamily,
      fontWeight,
      textDecoration,
      fontStyle,
      ...boxShadow,
    },
  };
};

const getInputFields = (el) => {
  const placeholder = el.getAttribute('placeholder');
  const {
    fontSize,
    fontFamily,
    fontWeight,
    textDecoration,
    borderRadius,
    fontStyle,
    color,
    backgroundColor,
    borderColor,
    height,
  } = el.style;

  return {
    placeholder,
    inputStyles: {
      fontSize,
      fontFamily: fontFamily === '"Times New Roman"' ? 'Times New Roman' : fontFamily,
      fontWeight,
      textDecoration,
      borderRadius,
      fontStyle,
      color,
      backgroundColor,
      borderColor,
      height,
    },
  };
};

const getFormFields = (el) => {
  const { flexDirection } = el.style;
  let showButton = true;
  if (el.dataset.showButton) {
    showButton = JSON.parse(el.dataset.showButton);
  }
  const orientation = flexDirection;

  const { widthOption, maxWidth, position } = el.dataset;

  if (el.classList.contains(CLOSE_WIDGET_CLASS_NAME)) {
    return {
      showButton,
      orientation,
      action: buttonActionTypes.closeType,
      widthOption,
      maxWidth,
      position,
    };
  }

  const actionString = el.dataset.eventTrigger;
  let action = {};
  if (actionString) {
    const actionS = JSON.parse(actionString);
    action = parseAction(actionS);
  }


  return {
    showButton,
    orientation,
    widthOption,
    maxWidth,
    position,
    ...action,
  };
};

export const formParser = (el, id) => {
  const buttonElement = el.getElementsByTagName('button').item(0);
  const inputElement = el.getElementsByTagName('input').item(0) || el.getElementsByTagName('textarea').item(0);
  if (inputElement) {
    const inputFields = getInputFields(inputElement);
    const selector = inputElement.getAttribute('name');

    const formFields = getFormFields(el);

    const styleOptions = parseStyles(el.style);

    const buttonFields = getButtonFields(buttonElement);

    return {
      type: 'form',
      ...buttonFields,
      ...inputFields,
      ...formFields,
      ...styleOptions,
      selector,
      id,
    };
  }

  return {};
};
