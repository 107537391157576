import React from 'react';
import PropTypes from 'prop-types';

const PaymentIcon = ({ fillColor, ...props }) => (
  <svg width={16} height={11} {...props}>
    <g stroke={fillColor} fill="none">
      <g strokeWidth={1.3}>
        <rect width={16} height={11} rx={2} stroke="none" />
        <rect x={0.65} y={0.65} width={14.7} height={9.7} rx={1.35} />
      </g>
      <path strokeWidth={1.35} d="M.5 4.5h15" />
      <path strokeLinecap="round" strokeWidth={1.35} d="M3.5 7.5h2" />
    </g>
  </svg>
);

PaymentIcon.propTypes = {
  fillColor: PropTypes.string.isRequired,
};

export default PaymentIcon;
