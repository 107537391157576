import { makeStyles } from '@material-ui/styles';
import { EditorState, ContentState, RichUtils, SelectionState } from 'draft-js';
import React, {
  memo, useEffect, useMemo, useRef, useState,
} from 'react';
import { Editor } from 'react-draft-wysiwyg';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { FONT_FAMILY_TYPES } from '../../../constants/onsiteWidgets';

import { ColorComponent } from './ColorComponent';
import { fromHtml } from './fromHtml';
import { toHtml } from './toHtml';


const useStyles = makeStyles((theme) => ({
  wrapperClass: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  editorClass: {
    width: '100%',
    marginTop: theme.spacing(2),
    border: '1px solid #cccccc !important',
    padding: '18.5px 14px',
    borderRadius: '4px !important',
    height: '80% !important',
    boxSizing: 'border-box',
    transition: 'border-color 0.5s',
    '&:hover': {
      borderColor: '#212121 !important',
    },
    '&:focus-within': {
      padding: '17.5px 13px',
      border: `2px solid ${theme.palette.primary.main} !important`,
    },
    '& div': {
      margin: 0,
    },
  },
}));

const getEditorState = (value, cb) => {
  const contentBlock = fromHtml(value);
  if (contentBlock) {
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const editorState = EditorState.createWithContent(contentState);
    cb && cb(editorState);
    return editorState;
  }
  return EditorState.createEmpty();
};


function setAllSelectionEditorState(editorState, currentContent) {
  const selection = editorState.getSelection().merge({
    anchorKey: currentContent.getFirstBlock().getKey(),
    anchorOffset: 0,
    focusOffset: currentContent.getLastBlock().getText().length,
    focusKey: currentContent.getLastBlock().getKey(),
  });
  const editorStateWithAllSelection = EditorState.acceptSelection(editorState, selection);

  return editorStateWithAllSelection;
}


const TextEditor = memo((props) => {
  const {
    value = '<p>Text!</p>', onChange, className = '', editorType = 'modal',
  } = props;

  const [editorState, setEditorState] = useState(() => getEditorState(value));

  const prevValue = useRef(value);
  const editorRef = useRef(null);
  const wrapperRef = useRef(null);

  useEffect(() => {
    if (prevValue.current !== value) {
      getEditorState(value, (editorState) => {
        prevValue.current = value;
        setEditorState(editorState);
      });
    }
  }, [value]);

  useEffect(() => {
    const currentContent = editorState.getCurrentContent();
    const editorStateWithAllSelection = setAllSelectionEditorState(editorState, currentContent);
    setEditorState(editorStateWithAllSelection);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const focusEditor = () => {
    if (editorRef.current && editorRef.current.editor) {
      const currentContent = editorState.getCurrentContent();
      const lastBlock = currentContent.getLastBlock();
      const lastKey = lastBlock.getKey();
      const length = lastBlock.getLength();

      const selection = new SelectionState({
        anchorKey: lastKey,
        anchorOffset: length,
        focusKey: lastKey,
        focusOffset: length,
      });

      const newEditorState = EditorState.forceSelection(editorState, selection);
      setEditorState(newEditorState);

      setTimeout(() => {
        if (editorRef) {
          editorRef.current.editor.focus();
        }
      }, 0);
    }
  };

  useEffect(() => {
    if (editorType === 'inline-editor') {
      const timer = setTimeout(() => {
        focusEditor();
      }, 0);

      return () => clearTimeout(timer);
    }
  }, []);


  const classes = useStyles();

  function onEditorStateChange(editorState) {
    const selectionBefore = editorState.getSelection();
    const currentContent = editorState.getCurrentContent();
    if (!selectionBefore.hasFocus) {
      const editorStateWithAllSelection = setAllSelectionEditorState(editorState, currentContent);
      const markup = toHtml(editorStateWithAllSelection);
      onChange(markup);
      prevValue.current = markup;
      setEditorState(editorStateWithAllSelection);
    }
    if (selectionBefore.hasFocus) {
      const markup = toHtml(editorState);
      onChange(markup);
      prevValue.current = markup;
      setEditorState(editorState);
    }
  }

  function handleReturn(e) {
    e.preventDefault();
    onEditorStateChange(RichUtils.insertSoftNewline(editorState));
    return 'handled';
  }

  const toolbar = useMemo(() => ({
    options: ['link', 'inline', 'fontSize', 'colorPicker', 'fontFamily', 'textAlign'],
    inline: { options: ['bold', 'italic', 'underline'] },
    fontFamily: {
      options: [
        FONT_FAMILY_TYPES.MONTSERRAT,
        FONT_FAMILY_TYPES.ROBOTO,
        FONT_FAMILY_TYPES.ARIAL,
        FONT_FAMILY_TYPES.GEORGIA,
        FONT_FAMILY_TYPES.IMPACT,
        FONT_FAMILY_TYPES.TAHOMA,
        FONT_FAMILY_TYPES.TIMES,
        FONT_FAMILY_TYPES.VERDANA,
        FONT_FAMILY_TYPES.HELVETICA,
        FONT_FAMILY_TYPES.PRAGMATICA,
        FONT_FAMILY_TYPES.WALSHEIM,
      ],
    },
    fontSize: {
      options: [8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36, 38, 40, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62, 64, 66, 68, 70, 72, 74, 76, 78, 80, 82, 84, 86, 88, 90, 92, 94, 96],
    },
    link: {
      inDropdown: true,
    },
    colorPicker: {
      component: ColorComponent,
    },

    textAlign: { isDropdown: true, options: ['left', 'center', 'right'] },
  }), []);

  const toolbarRef = useRef(null);
  let isDragging = false;
  let initialX = 0;
  let initialY = 0;
  let lastX = 0;
  let lastY = 0;

  const handleMouseDown = (e) => {
    isDragging = true;
    initialX = e.clientX - lastX;
    initialY = e.clientY - lastY;
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      const currentX = e.clientX - initialX;
      const currentY = e.clientY - initialY;

      lastX = currentX;
      lastY = currentY;

      toolbarRef.current.style.transform = `translate3d(${currentX}px, ${currentY}px, 0)`;
    }
  };

  const handleMouseUp = () => {
    isDragging = false;
  };

  useEffect(() => {
    const toolbarElement = document.querySelector('.rdw-editor-toolbar');
    toolbarRef.current = toolbarElement;

    if (toolbarElement && editorType === 'inline-editor') {
      toolbarElement.addEventListener('mousedown', handleMouseDown);
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    }

    return () => {
      if (toolbarElement && editorType === 'inline-editor') {
        toolbarElement.removeEventListener('mousedown', handleMouseDown);
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
      }
    };
  }, []);

  return (
    <div ref={wrapperRef}>
      <Editor
        ref={editorRef}
        editorState={editorState}
        wrapperClassName={className}
        editorClassName={classes.editorClass}
        onEditorStateChange={onEditorStateChange}
        handleReturn={handleReturn}
        toolbar={toolbar}
      />
    </div>
  );
});

const renderTextEditor = ({ input, ...rest }) => <TextEditor {...input} {...rest} />;

export default renderTextEditor;
