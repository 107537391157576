import {
  call, put, takeLatest, delay,
} from 'redux-saga/effects';

import { chatBot as api } from '../api';
import {
  mapFormValuesToChatBot,
  mapChatBotToFormValues,
} from '../mappers/chatBotMappers';
import { chatBot as chatBotActions, snackbar as snackbarActions } from '../redux/actions';

function* createChatBot({ payload: { values } }) {
  try {
    yield put(chatBotActions.createChatBotRoutine.request());
    const [transformedValues] = mapFormValuesToChatBot(values);

    const { id } = transformedValues;

    const data = new FormData();
    data.append('form', JSON.stringify(transformedValues));

    const chatBot = yield call(api.postChatBot, id, data);

    yield put(chatBotActions.createChatBotRoutine.success(chatBot));
  } catch (error) {
    yield put(
      snackbarActions.enqueueSnackbar({
        message: 'Create chat bot error occurred!',
        options: { variant: 'error' },
      }),
    );
    yield put(chatBotActions.createChatBotRoutine.failure(error));
  } finally {
    yield put(chatBotActions.createChatBotRoutine.fulfill());
  }
}

const STATUSES = {
  active: 1,
  onHold: 2,
  archived: 3,
};

const INVERT_STATUSES = {
  1: 'active',
  2: 'onHold',
  3: 'archived',
};

function* activateChatBot({ payload: { id, pageView } }) {
  try {
    yield call(api.changeStatusChatBot, id, STATUSES.active);
    yield put(
      chatBotActions.fetchChatBotList({ page: 1, perPage: 10, status: pageView }),
    );
  } catch (error) {
    const message = error.request && error.request.status === 403 ? 'You cannot set Active status!' : 'Change status error occurred!';
    yield put(
      snackbarActions.enqueueSnackbar({
        message,
        options: { variant: 'error' },
      }),
    );
  }
}

function* holdChatBot({ payload: { id, pageView } }) {
  try {
    yield call(api.changeStatusChatBot, id, STATUSES.onHold);
    yield put(
      chatBotActions.fetchChatBotList({ page: 1, perPage: 10, status: pageView }),
    );
  } catch (error) {
    yield put(
      snackbarActions.enqueueSnackbar({
        message: 'Hold chat bot error occurred!',
        options: { variant: 'error' },
      }),
    );
  }
}

function* fetchChatBot({ payload: chatBotId }) {
  try {
    const chatBot = yield call(api.fetchChatBot, chatBotId);
    yield put(chatBotActions.putChatBot(mapChatBotToFormValues(chatBot)));
  } catch (error) {
    yield put(
      snackbarActions.enqueueSnackbar({
        message: 'Fetch chat bot error occurred!',
        options: { variant: 'error' },
      }),
    );
  }
}

const mapStatus = (list) => list.map((item) => ({ ...item, status: INVERT_STATUSES[item.status] }));

function* fetchChatBotList({
  payload: {
    page, perPage, status, search,
  },
}) {
  try {
    const [list, total] = yield call(api.fetchChatBotList, {
      page,
      perPage,
      status: STATUSES[status],
      search,
    });

    yield put(chatBotActions.putChatBotList({ list: mapStatus(list), total }));
  } catch (error) {
    yield put(
      snackbarActions.enqueueSnackbar({
        message: 'Fetch sms list error occurred!',
        options: { variant: 'error' },
      }),
    );
  }
}

function* fetchDownloadStat({payload}) {
  try {
    yield call(api.fetchDownloadStat, payload);
  } catch (error) {
    console.error(error);
  }
}

function* validateCampaignName({
  payload: {
    name, id, resolve, reject,
  },
}) {
  try {
    if (name) {
      yield delay(500);

      const result = yield call(api.validateCampaignName, name, id);
      if (result) {
        resolve();
      } else {
        reject({ campaign_name: 'This name is already used' });
      }
    }
  } catch (error) {
    reject();
  }
}

export default function* watcher() {
  yield takeLatest(chatBotActions.createChatBotRoutine.TRIGGER, createChatBot);
  yield takeLatest(chatBotActions.activateChatBot.toString(), activateChatBot);
  yield takeLatest(chatBotActions.holdChatBot.toString(), holdChatBot);
  yield takeLatest(chatBotActions.fetchChatBot.toString(), fetchChatBot);
  yield takeLatest(chatBotActions.fetchDownloadStat.toString(), fetchDownloadStat);
  yield takeLatest(
    chatBotActions.fetchChatBotList.toString(),
    fetchChatBotList,
  );
  yield takeLatest(
    chatBotActions.validateCampaignName.toString(),
    validateCampaignName,
  );
}
