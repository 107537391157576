import isNil from 'lodash/fp/isNil';
import isEmpty from 'lodash/fp/isEmpty';
import pick from 'lodash/fp/pick';
import cond from 'lodash/fp/cond';
import T from 'lodash/fp/T';
import { eq, convertFromSecond, convertToSecond } from './helpers';

export const transformTarget = cond([
  [
    eq('pageVisited'),
    target => {
      const { value, dimension } = target;

      if (isNil(value) || isEmpty(dimension)) {
        return pick(['type', 'url'], target);
      }

      return pick(['type', 'url', 'time'], {
        ...target,
        time: convertToSecond[dimension](value)
      });
    }
  ],
  [
    eq('campaign'),
    target => {
      let result = {
        type: 'campaign',
        ids: typeof target.ids === 'string' ? target.ids.split(',') : target.ids,
        seen: target.seen === 'true' || target.seen === true,
      }
      if (!isNil(target.value) && !isEmpty(target.dimension) && target.value > 0 && !isEmpty(target.time_mode)) {
        result.time = convertToSecond[target.dimension](target.value)
        result.time_mode = target.time_mode
      }
      return result
    }
  ],
  [T, v => v]
]);

export const transformToTarget = cond([
  [
    eq('pageVisited'),
    target => {
      const { value, dimension } = convertFromSecond(target.time);

      return {
        ...target,
        dimension,
        value
      };
    }
  ],
  [
    eq('campaign'),
    target => {
      const { value, dimension } = convertFromSecond(target.time);

      return {
        ...target,
        dimension,
        value
      };
    }
  ],
  [T, v => v]
]);