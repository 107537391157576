/* eslint-disable import/no-webpack-loader-syntax */
import React from "react";
import {
  elements as ELEMENTS,
} from '../../../constants/onsiteWidgets'
import createUniqueStylesFactory from "../../../help/itemStyles/createUniqueStyles";

// Load compiled files
import phoneVerificationStyle from "!!raw-loader!../../../assets/css/PhoneVerification.css";
import clickPayStyle from "!!raw-loader!../../../assets/css/ClickPay.css";
import buttonRipple from "!!raw-loader!../../../assets/css/buttonRipple.css";
import rating from "!!raw-loader!../../../assets/css/rating.css";
import productCard from "!!raw-loader!../../../assets/css/product-card.css";

const stylesByTypeMapping = {
  [ELEMENTS.PHONE_VERIFICATION]: [phoneVerificationStyle],
  [ELEMENTS.CLICK_PAY]: [clickPayStyle],
  [ELEMENTS.RATING]: [rating],
  [ELEMENTS.RAPRODUCT_CARDTING]: [productCard],
  common: [buttonRipple],
}

/**
 * @type {React.FC<{ type: string, id: string }>}
 */
const ElementStyles = ({
  type,
  id,
}) => {
  if (!stylesByTypeMapping[type]) return null

  const createStyles = createUniqueStylesFactory(id);
  const styles = stylesByTypeMapping[type].map(createStyles);

  return (
    <>
      {styles.map((style, index) => (
        <style key={index} dangerouslySetInnerHTML={{ __html: style }} /> 
      ))}
    </>
  );
};

export default ElementStyles;
