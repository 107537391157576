import { stringify } from "qs";
import axios from "./axios";

const transformRequest = (data, headers) => {
  const customerToken = window.comfolks ? window.comfolks.token : null;

  headers.Authorization = `Bearer ${customerToken}`;

  return JSON.stringify(data);
};

// GET [origin]/api/v1/[cf_api_key]/offerwall/[campaign_hash]
const fetchCampaignWallInfo = async (campaignId) => {
  const { data: campaignData } = await axios.get(
    `/cpa/${campaignId}`,
    {
      transformRequest,
    }
  );

  return campaignData;
};

// GET [origin]/api/v1/[domain_key]/offerwall/[campaign_hash]/offers?skip=...&take=..
const fetchOfferWallOffers = async (campaignId, cfApiKey, skip, take) => {
  const { data: campaignData } = await axios.get(
    `/cpa/${campaignId}/offers?skip=${skip}&take=${take}`,
    {
      transformRequest,
    }
  );

  return campaignData;
};

//GET [origin]/api/v1/[cf_api_key]/offerwall/offer/[offer_id]
const fetchOfferInfoById = async (cfApiKey, offerId) => {
  const { data: campaignData } = await axios.get(
    `/cpa/offer/${offerId}`,
    {
      transformRequest,
    }
  );
  return campaignData;
};

//POST [origin]/api/v1/[domain_key]/offerwall/offer/[offer_id]/event/3
const countOfferCardClick = async (cfApiKey, offerId) => {
  return axios.post(
    `/cpa/offer/${offerId}/event/click`,
    null,
    {
      transformRequest,
    }
  );
};

// POST /modules/cpa/offer-wall/offer/:offerId - body: {username, identifier}
const acceptEComOffer = async (
  cfApiKey,
  username,
  email,
  offerId,
  campaignHash
) => {
  return axios.post(
    `/cpa/offer/${offerId}`,
    {
      username,
      email,
      campaign_hash: campaignHash,
    },
    {
      transformRequest,
    }
  );
};

// GET [origin]/api/v1/00000000/offerwall/offer/[offer_id]/otp

const sendSmsCode = async (cfApiKey, msisdn, offerId) => {
  const query = stringify({ msisdn });

  await axios.get(`/cpa/offer/${offerId}/get-otp?${query}`, {
    transformRequest,
  });
};

// POST [origin]/api/v1/00000000/offerwall/offer/[offer_id]/code - body: {msisdn, code}
const acceptTelecomOffer = async (
  cfApiKey,
  msisdn,
  code,
  offerId,
  campaignHash,
  query
) => {
  return await axios.post(
    `/cpa/offer/${offerId}/confirm-otp${query}`,
    {
      code,
      offer_id: offerId,
      lp_url: window.location.href,
      referrer: document.referrer,
      campaign_hash: campaignHash,
    },
    {
      transformRequest,
    }
  );
};

export default {
  fetchCampaignWallInfo,
  acceptEComOffer,
  sendSmsCode,
  acceptTelecomOffer,
  fetchOfferInfoById,
  fetchOfferWallOffers,
  countOfferCardClick,
};
