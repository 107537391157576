import { ServerStyleSheets } from '@material-ui/core';
import React from 'react';
import ReactDOMServer from 'react-dom/server';

import {
  CarouselLayoutComponent,
} from '../components/Carousel/CarouselLayoutComponent';
import ElementScripts from '../components/OnsiteForm/DesignStep/ElementScripts';
import ElementStyles from '../components/OnsiteForm/DesignStep/ElementStyles';
import {
  getWidgetEventsByElementId,
  extractWidgetLayoutProperties,
  getWidgetEvents,
} from '../components/OnsiteForm/selectors';
import {
  elements,
  sizeByTypeMapping,
  COLUMN_QUANTITY,
  FONT_FAMILY_TYPES,
  fontTypeToFontFamiliesMapping, WIDGET_LAYOUT_TYPES,
} from '../constants/onsiteWidgets';
import { getAllElements } from '../help/element';
import { getUniqueIdWithPrefix } from '../help/getUniqueIdWithPrefix';
import { putCompiledScripts } from '../redux/actions/fields';
import { store } from '../redux/store';

import { ItemComponent } from './itemComponents';
import { LayoutComponent, Column } from './layoutComponents';

/* eslint-disable import/no-webpack-loader-syntax */
import common from '!!raw-loader!../assets/css/common.css';
import modals from '!!raw-loader!../assets/css/modals.css';

const replaceConstructorFontToEnvFont = (str) => Object.values(FONT_FAMILY_TYPES).reduce(
  (acc, fontName) => acc.replace(
    new RegExp(`font-family:(\\s+|)${fontName}`, 'g'),
    `font-family:${fontTypeToFontFamiliesMapping[fontName]}`,
  ),
  str,
);

const injectStyles = (markup, styles, placeholder) => markup.replace(placeholder, styles);

const toHtml = (values) => {
  const {
    layoutId,
    items,
    items2,
    widgetType,
    horizontal,
    sizeType,
    columnQuantity,
    layoutProperties,
    columnProperties,
    height,
    width,
    timeClose,
    specificWidgetTypeProperties,
  } = extractWidgetLayoutProperties(values);
  const widgetSizes = sizeByTypeMapping[sizeType];
  const twoColumned = columnQuantity === COLUMN_QUANTITY.TWO_COLUMNS;
  const { slides } = specificWidgetTypeProperties.carousel;
  const slideItems = slides.map((slide) => slide.items).flat();
  const widgetItems = widgetType === WIDGET_LAYOUT_TYPES.CAROUSEL
    ? slideItems : [...items, ...items2];
  const allElements = getAllElements(widgetItems);
  const events = getWidgetEvents(allElements);
  const eventsByElementIdMap = getWidgetEventsByElementId(allElements, events);

  store.dispatch(putCompiledScripts([]));

  const usedTypes = new Set();

  const getLayoutComponentChildren = () => {
    // if(widgetType === PULLED) {
    //   return (
    //     <PulledLayoutComponent
    //       widgetType={widgetType}
    //       id={layoutId}
    //       allElements={allElements}
    //       styleInjectionPlaceholder={styleInjectionPlaceholder}
    //       layoutProperties={layoutProperties}
    //       eventsByElementIdMap={eventsByElementIdMap}
    //       items={items}
    //     />
    //   )
    // }
    if (widgetType === WIDGET_LAYOUT_TYPES.CAROUSEL) {
      const {
        slides,
        moveOption,
        carouselDuration,
        carouselDelay,
        carouselAnimationFunction,
        carouselType,
        carouselSlideWidth,
        carouselEffect,
        carouselControlColor,
        carouselVerticalMargin,
        carouselCommonUrl,
      } = specificWidgetTypeProperties.carousel;

      return (
        <CarouselLayoutComponent
          id={layoutId}
          moveOption={moveOption}
          widgetType={widgetType}
          slides={slides}
          allElements={allElements}
          styleInjectionPlaceholder={styleInjectionPlaceholder}
          carouselDuration={carouselDuration}
          carouselDelay={carouselDelay}
          carouselAnimationFunction={carouselAnimationFunction}
          carouselType={carouselType}
          slideWidth={carouselSlideWidth}
          effect={carouselEffect}
          controlColor={carouselControlColor}
          verticalMargin={carouselVerticalMargin}
          commonUrl={carouselCommonUrl}
          layoutProperties={layoutProperties}
          eventsByElementIdMap={eventsByElementIdMap}
        />
      );
    }
    return (
      <>
        <Column {...columnProperties} sizes={widgetSizes} twoColumned={twoColumned}>
          {items.map(({
            id, type, droppable, ...itemProps
          }) => {
            const hasType = usedTypes.has(type);
            usedTypes.add(type);
            return (
              <>
                <ItemComponent
                  layoutProps={layoutProperties}
                  id={id}
                  key={id}
                  type={type}
                  sizes={widgetSizes}
                  widgetType={widgetType}
                  horizontal={horizontal}
                  alignItems={columnProperties.alignItems}
                  {...itemProps}
                  eventsByElementIdMap={eventsByElementIdMap}
                />
                { hasType ? null : (
                  <ElementStyles key={`${type}-${id}`} type={type} />
                )}
              </>
            );
          })}
          <ElementScripts elements={allElements} />
          <ElementStyles type="common" />
          <style dangerouslySetInnerHTML={{ __html: modals }} />
          <style>{`\n${styleInjectionPlaceholder}\n`}</style>
        </Column>
        {twoColumned && (
          <Column {...columnProperties} sizes={widgetSizes} twoColumned={twoColumned}>
            {items2.map(({
              id, type, droppable, ...itemProps
            }) => {
              const hasType = usedTypes.has(type);
              usedTypes.add(type);
              return (
                <>
                  <ItemComponent
                    layoutProps={layoutProperties}
                    id={id}
                    key={id}
                    type={type}
                    sizes={widgetSizes}
                    widgetType={widgetType}
                    horizontal={horizontal}
                    alignItems={columnProperties.alignItems}
                    {...itemProps}
                    eventsByElementIdMap={eventsByElementIdMap}
                  />
                  {hasType ? null : (
                    <ElementStyles key={`${type}-${id}`} type={type} />
                  )}
                </>
              );
            })}
          </Column>
        )}
      </>
    );
  };


  const styleInjectionPlaceholder = getUniqueIdWithPrefix('STYLE_INJECTION_PLACEHOLDER');
  const Widget = (
    <>
      <style dangerouslySetInnerHTML={{ __html: common }} />
      <LayoutComponent
        layoutId={layoutId}
        widgetType={widgetType}
        sizeType={sizeType}
        sizes={widgetSizes}
        width={width}
        height={height}
        timeClose={timeClose}
        isExport
        specificWidgetTypeProperties={specificWidgetTypeProperties}
        layoutProperties={layoutProperties}
        {...layoutProperties}
      >
        {getLayoutComponentChildren()}
      </LayoutComponent>
    </>
  );
  const sheets = new ServerStyleSheets();
  const result = sheets.collect(Widget);
  const markup = ReactDOMServer.renderToStaticMarkup(result);
  const processedMarkup = replaceConstructorFontToEnvFont(markup);
  return injectStyles(processedMarkup, sheets.toString(), styleInjectionPlaceholder);
};

export default toHtml;
