import React from 'react'
import { Grid, InputAdornment, MenuItem } from '@material-ui/core'
import { Field } from 'redux-form'
import { transitionTimingFunctions } from '../../../../constants/onsiteWidgets'
import { fromMs } from '../../../../help/format'
import { beNaturalNumberMs } from '../../../../help/normalizers'
import { toMs } from '../../../../help/parse'
import { SelectField, TextField } from '../../../Fields';
import { FORM_SCHEMA_ONSITE } from '../../../../constants'



const CarouselMillisecondDuration = (props) => (
  <TextField
    {...props}
    InputProps={{
      endAdornment: <InputAdornment position='end'>ms</InputAdornment>
    }}
  />
)

const CarouselMillisecondDelay = (props) => (
  <TextField
    {...props}
    InputProps={{
      endAdornment: <InputAdornment position='end'>ms</InputAdornment>
    }}
  />
)

const CarouselDurationInput = ({ ...fieldsProps }) => (
  <Field
    label='Slider speed'
    normalize={beNaturalNumberMs}
    component={CarouselMillisecondDuration}
    parse={toMs}
    format={fromMs}
    type='number'
    name={`${FORM_SCHEMA_ONSITE.PROPERTIES.CAROUSEL_DURATION}`}
    {...fieldsProps}
  />
)

const CarouselDelayInput = ({ ...fieldsProps  }) => (
  <Field
    label='Slider delay'
    normalize={beNaturalNumberMs}
    component={CarouselMillisecondDelay}
    parse={toMs}
    format={fromMs}
    name={`${FORM_SCHEMA_ONSITE.PROPERTIES.CAROUSEL_DELAY}`}
    type='number'
    {...fieldsProps}
  />
)

const TransitionCarouselFunctionSelect = ({ ...fieldsProps }) => (
  <Field label='Animation' component={SelectField} {...fieldsProps}
         name={`${FORM_SCHEMA_ONSITE.PROPERTIES.CAROUSEL_ANIMATION_FUNCTION}`}>
    <MenuItem value={transitionTimingFunctions.linear}>linear</MenuItem>
    <MenuItem value={transitionTimingFunctions.easeInOutBack}>easeInOutBack</MenuItem>
    <MenuItem value={transitionTimingFunctions.easeInSine}>easeInSine</MenuItem>
    <MenuItem value={transitionTimingFunctions.easeOutBack}>easeOutBack</MenuItem>
    <MenuItem value={transitionTimingFunctions.easeOutExpo}>easeOutExpo</MenuItem>
  </Field>
)


const CarouselSettings = () => {
  return (
    <Grid item>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <CarouselDurationInput fullWidth />
        </Grid>
        <Grid item xs={6}>
          <CarouselDelayInput fullWidth />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <TransitionCarouselFunctionSelect fullWidth />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default CarouselSettings;
