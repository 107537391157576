import { DARK_BLUE } from './index';

export const CURRENT_EDITOR = {
  BUTTON_TEXT: 'buttonText',
  FORM_BUTTON_TEXT: 'formButtonText',
  FORM_BUTTON_NEXT_TEXT: 'formButtonNextText',
  FORM_BUTTON_NEXT_SECOND_TEXT: 'formButtonNextSecondText',
  FORM_BUTTON_BACK_TEXT: 'formButtonBackText',
  FORM_TARIFF_INFO: 'formTariffInfo',
  FORM_POINT_INFO: 'formPointInfo',
  PHONE_INPUT_LABEL: 'phoneInputLabel',
  HELPER_TEXT: 'helperText',
  PHONE_SUBMIT_TEXT: 'phoneSubmitText',
  TELEGRAM_BUTTON_TEXT: 'telegramButtonText',
  CODE_INPUT_LABEL: 'codeInputLabel',
  CODE_INPUT_SUB_LABEL: 'codeInputSubLabel',
  BACK_LINK_TEXT: 'backLinkText',
  OTP_RESEND: 'otpResend',
  SUCCESS_TEXT: 'successText',
  TEXT_HTML: 'textHtml',
  CPA_OW_SHORT_DESCRIPTION: 'cpaOwShortDescription',
  CPA_OW_FULL_DESCRIPTION: 'cpaOwFullDescription',
  CPA_OW_HEADER_DESCRIPTION: 'cpaOwHeaderDescription',
  CPA_OW_BONUS: 'cpaOwBonus',
  CPA_OW_PHONE_NUMBER_LABEL: 'cpaOwPhoneNumberLabel',
  CPA_OW_PHONE_BUTTON_TITLE: 'cpaOwPhoneButtonTitle',
  CPA_OW_PHONE_NOTICE: 'cpaOwPhoneNotice',
  CPA_OW_PHONE_OFFER_DESCRIPTION_TITLE: 'cpaOwPhoneOfferDescriptionTitle',
  CLICK_PAY_OTP_LABEL: 'clickPayOtpLabel',
  CLICK_PAY_OTP_SEND_BTN: 'click_payOtpSendBtn',
  CLICK_PAY_SUCCESS_TEXT: 'clickPaySuccessText',
  CLICK_FORM_BUTTON_BACK_TEXT: 'clickFormButtonBackText',
};

export const BOLD = 'BOLD';
export const ITALIC = 'ITALIC';
export const UNDERLINE = 'UNDERLINE';
export const HIGHLIGHT = 'HIGHLIGHT';
export const TEXT_LEFT = 'textLeft';
export const TEXT_RIGHT = 'textRight';
export const TEXT_CENTER = 'textCenter';
export const MEDIUM = 'MEDIUM';
export const REGULAR = 'REGULAR';
export const LIGHT = 'LIGHT';
export const FONT_ROBOTO = 'fontFamilyRoboto';
export const FONT_SIZE_DEFAULT = 'fontSize14';
export const FONT_COLOR_DEFAULT = `color${DARK_BLUE}`;
export const LINE_HEIGHT_DEFAULT = `LINE_HEIGHT_DEFAULT`;

export const DEFAULT = [FONT_ROBOTO, FONT_SIZE_DEFAULT, FONT_COLOR_DEFAULT, REGULAR, LINE_HEIGHT_DEFAULT];

export const BLOCK_RENDER_MAP = {
  [TEXT_LEFT]: {
    element: 'div',
  },
  [TEXT_RIGHT]: {
    element: 'div',
  },
  [TEXT_CENTER]: {
    element: 'div',
  },
};

export const INLINE_STYLE_MAP = {
  [TEXT_CENTER]: 'center',
  [TEXT_RIGHT]: 'right',
  [TEXT_LEFT]: 'left',
};
