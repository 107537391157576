import React, { Suspense, lazy } from "react";
import { Route, Switch } from "react-router-dom";
import {
  withStyles,
  createMuiTheme,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CssBaseline from "@material-ui/core/CssBaseline";
import { green } from "@material-ui/core/colors";
import * as serviceWorker from "./serviceWorker";
import PrivateRoute from "./components/PrivateRoute";
import Notifier from "./components/Notifier";
import Page404 from "./pages/Page404";
import Page403 from "./pages/Page403";
import OfferWallPage from "./pages/OfferWallPage";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import { user as userActions } from "./redux/actions";
import { ROUTE_CPA_WALL, ROUTE_ERROR } from "./constants";
const LCRoutes = lazy(() => import("./LCRoutes"));
const { REACT_APP_LOGGED_IN_LIVING_TIME: LOGGED_IN_LIVING_TIME } = process.env;

const styles = () => ({
  app: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    flex: "1 1 auto",
    // overflowY: "hidden",
  },
});

const outerTheme = createMuiTheme({
  // TODO: Сan in the future change the color of this project
  palette: {
    primary: {
      main: "#3f51b5",
      dark: "#022163",
    },
    grey: {
      400: "#8992A7",
      200: "#EBEAEB",
    },
    text: {
      primary: "#212121",
    },
    secondary: {
      main: green[500],
      dark: green[600],
      light: green[300],
    },
  },
});

const DELTA_THRESHOLD = Number(LOGGED_IN_LIVING_TIME) * 1000;

function App({
  classes,
  isLoggedIn,
  timeLogIn,
  rememberMe,
  userLogout,
  // getAllowedModules,
}) {
  // useEffect(() => {
  //   if (isLoggedIn) {
  //     getAllowedModules();
  //   }
  // }, [getAllowedModules, isLoggedIn]);
  // todo поменять getAllowedModules

  const currentTime = Date.now();
  const delta = currentTime - timeLogIn;
  if (!rememberMe && delta > DELTA_THRESHOLD && isLoggedIn) {
    userLogout();
  }

  return (
    <MuiThemeProvider theme={outerTheme}>
      <CssBaseline />
      <div className={classes.app}>
        <Notifier />
        <Suspense fallback={null}>
          <Switch>
            <Route path="/" component={LCRoutes} />
          </Switch>
        </Suspense>
      </div>
    </MuiThemeProvider>
  );
}

App.propTypes = {
  classes: PropTypes.shape(),
  isLoggedIn: PropTypes.bool.isRequired,
  timeLogIn: PropTypes.number,
  userLogout: PropTypes.func.isRequired,
  rememberMe: PropTypes.bool.isRequired,
};

App.defaultProps = {
  classes: {},
  timeLogIn: null,
};

const mapStateToProps = ({ user = {} }) => ({
  isLoggedIn: user.isLoggedIn,
  timeLogIn: user.timeLogIn,
  rememberMe: user.rememberMe,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      userLogout: userActions.userLogoutRequest,
      getAllowedModules: userActions.userGetAllowedModules,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(App));

serviceWorker.unregister();
