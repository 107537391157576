import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({

  CardActionArea: {
    height: '100%',
  },

  imgBlock: {
    display: 'flex',
    backgroundImage: ({backgroundImage}) =>`url(${backgroundImage})`,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '400px',
    overflow: 'hidden',
    backgroundSize: '101%!important',
    backgroundRepeat: 'no-repeat!important',
  },


  circle: {
    width: '70px',
    height: '70px',
    borderRadius: '100%',
    position: 'absolute',
    left: '15px',
    top: '15px',
    overflow: 'hidden',
    zIndex: '2',
    '& img': {
      height: 'auto',
      width: 'auto',
      maxHeight: '100%',
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
    }
  },

  textBlock: {
    position: 'absolute',
    left: '0',
    right: '0',
    bottom: '0',
    display: 'flex',
    height: '50%',
    flexDirection: 'column',
    padding: '70px 10px 10px',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    background: (props) => `linear-gradient(${props.degrees}, ${props.gradientStartColor} ${props.startGradientWidth}%, ${props.gradientEndColor} ${props.endGradientWidth}%)`,
    // background: (props) => `${props.gradientStartColor}`,
    color: '#ffffff',
  },

  title: {
    width: '100%',
    fontSize: (props) => props?.company_name_font_size,
    textAlign: (props) => props?.company_name_text_align,
    fontWeight: (props) => props?.company_name_bold ? 'bold': '100',
    textDecoration: (props) => props?.company_name_underline ? 'underline': 'none',
    color: (props) => props?.company_name_color,
    fontStyle: (props) => props?.company_name_italic ? 'italic': 'normal',
    fontFamily: (props) => props?.company_name_font_family,
    margin: '5px 0',
  },
  brandName: {
    width: '100%',
    fontSize: (props) => props?.brand_name_font_size,
    textAlign: (props) => props?.brand_name_text_align,
    fontWeight: (props) => props?.brand_name_bold ? 'bold': '100',
    textDecoration: (props) => props?.brand_name_underline ? 'underline': 'none',
    color: (props) => props?.brand_name_color,
    fontStyle: (props) => props?.brand_name_italic ? 'italic': 'normal',
    fontFamily: (props) => props?.brand_name_font_family,
    margin: '5px 0',
  },
  descriptionText: {
    width: '100%',
    fontSize: (props) => props?.description_font_size,
    textAlign: (props) => props?.description_text_align,
    fontWeight: (props) => props?.description_bold ? 'bold': '100',
    textDecoration: (props) => props?.description_underline ? 'underline': 'none',
    color: (props) => props?.description_color,
    fontStyle: (props) => props?.description_italic ? 'italic': 'normal',
    fontFamily: (props) => props?.description_font_family,
  },

  cardSize: {
    width: '100%',
    height: '100%',
  },


  largeImgBlock: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '64%',
    right: '0',
    top: '0',
    height: '100%',
    overflow: 'hidden',
    backgroundSize: 'cover!important',
    backgroundRepeat: 'no-repeat!important',
    backgroundPositionX: 'right!important',
    position: 'absolute',
    '@media (max-width: 659px)': {
      display: 'flex',
      backgroundImage: ({backgroundImage}) =>`url(${backgroundImage})`,
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '400px',
      overflow: 'hidden',
      backgroundSize: '101%!important',
      backgroundRepeat: 'no-repeat!important',
    }

  },
  largeTextBlock: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    padding: '270px 10px 10px',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    color: '#ffffff',
    width: '100%',
    zIndex: '1',
    position: 'absolute',
    left: '0',
    bottom: '0',
    background: (props) => `linear-gradient(${props.isHorizontal ? '90deg' : props.degrees}, ${props.gradientStartColor} ${props.startGradientWidth}%, ${props.gradientEndColor} ${props.endGradientWidth}%)`,
    '@media (max-width: 659px)': {
      position: 'absolute',
      left: '0',
      right: '0',
      bottom: '0',
      display: 'flex',
      height: '50%',
      flexDirection: 'column',
      padding: '70px 10px 10px',
      justifyContent: 'flex-end',
      alignItems: 'flex-start',
      color: '#ffffff',
      background: (props) => `linear-gradient(0deg, ${props.gradientStartColor} ${props.startGradientWidth}%, ${props.gradientEndColor} ${props.endGradientWidth}%)`,
    }
  },

  largeText: {
    maxWidth: '50%',
  }
});



export const Teaser = ({ textStyles, offer, settings, bgColor, color, onTeaserClick}) => {
  let {
    logo,
    teaserLogo,
    companyName,
    degrees,
    gradientStartColor,
    gradientEndColor,
    startGradientWidth,
    endGradientWidth,
    brandName,
    description,
    isHorizontal,
  } = offer;

  let {
    // brand name
    brand_name_bold,
    brand_name_italic,
    brand_name_underline,
    brand_name_font_size,
    brand_name_font_family,
    brand_name_text_align,
    brand_name_color,
    //company name
    company_name_bold,
    company_name_italic,
    company_name_underline,
    company_name_font_size,
    company_name_font_family,
    company_name_text_align,
    company_name_color,
    // description
    description_bold,
    description_italic,
    description_underline,
    description_font_size,
    description_font_family,
    description_text_align,
    description_color,
  } = settings;



  const classes = useStyles({
    bgColor,
    color,
    textStyles,
    degrees,
    gradientStartColor,
    gradientEndColor,
    startGradientWidth,
    endGradientWidth,
    backgroundImage: teaserLogo,
    // brand name
    brand_name_bold,
    brand_name_italic,
    brand_name_underline,
    brand_name_font_size,
    brand_name_font_family,
    brand_name_text_align,
    brand_name_color,
    //company name
    company_name_bold,
    company_name_italic,
    company_name_underline,
    company_name_font_size,
    company_name_font_family,
    company_name_text_align,
    company_name_color,
    // description
    description_bold,
    description_italic,
    description_underline,
    description_font_size,
    description_font_family,
    description_text_align,
    description_color,
  });

  let cardActionAreaClass = classes.imgBlock
  let contentBlockStyle = classes.textBlock
  let descriptionText = classes.descriptionText
  let cardSizeStyle = ''
  if(isHorizontal) {
    cardActionAreaClass += ' ' + classes.largeImgBlock
    contentBlockStyle += ' ' + classes.largeTextBlock
    descriptionText += ' ' + classes.largeText
    cardSizeStyle += ' ' + classes.cardSize
  }


  return (
    <Card className={cardSizeStyle}>
      <CardActionArea onClick={onTeaserClick} className={classes.CardActionArea}>
        {logo && <CardMedia
          className={classes.circle}
          image={logo}
          title={`${companyName} logo`}
        />}
        <div className={cardActionAreaClass}></div>
        <CardContent className={contentBlockStyle}>
          <Typography className={classes.brandName} gutterBottom variant="h6" component="h2">
            {brandName}
          </Typography>
          <Typography className={classes.title} gutterBottom variant="h6" component="h2">
            {companyName}
          </Typography>
          <Typography className={descriptionText} variant="body2" color="textSecondary" component="p">
            {description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
