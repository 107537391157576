import React, { useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { OfferWallProvider } from "./OfferWallContext";
import { OfferWallController } from "./OfferWallController";
import { OfferModal } from "./OfferModal";
import { EventEmitter } from "../../help/eventEmitter";
import { ROUTE_CPA_WALL } from "../../constants/routes";
import { ON_READY } from "./constants";
import {checkSubDomain} from "../../common";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { user as userActions } from '../../redux/actions';

// 2.1 Для тестового окружения [origin]/install.dev.js?cf_api_key=[cf_api_key]
// 2.2 Для боевого окружения: [origin]/install.js?cf_api_key=[cf_api_key]

const useImportScript = (resourceUrl) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = resourceUrl;
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [resourceUrl]);
};

const createResourceUrl = (cfApiKey, scriptUrl, cpaCampaignId) =>
  `${scriptUrl}?cf_api_key=${cfApiKey}&cpaCampaignId=${cpaCampaignId}`;

let scriptUrl = process.env.REACT_APP_CF_INSTALL_SCRIPT_URL;
scriptUrl = checkSubDomain(scriptUrl)

const OfferWallPage = ({ location, match, history, userApiKey }) => {
  const { hash } = match.params;
  const cfApiKey = hash.slice(0, 8);
  const cpaCampaignId = hash.slice(8);
  userApiKey(cfApiKey)
  const url = createResourceUrl(cfApiKey, scriptUrl, cpaCampaignId);

  useImportScript(url);

  useEffect(() => {
    if (!window.onComfolksReady) {
      window.onComfolksReady = function () {
        EventEmitter.dispatch(ON_READY);
      };
    }

    return () => {
      window.onComfolksReady = undefined;
    };
  }, []);

  return (
    <OfferWallProvider>
      <OfferWallController
        location={location}
        match={match}
        history={history}
      />
      <Switch>
        <Route
          exact
          path={`${ROUTE_CPA_WALL}/:hash/:offerId`}
          component={OfferModal}
        />
      </Switch>
    </OfferWallProvider>
  );
};

const mapDispatchToProps = {
  userApiKey: userActions.userApiKey
};
export default connect(null, mapDispatchToProps)(withRouter(OfferWallPage));
