import axios from 'axios';

import { DEFAULT_HEADER } from '../constants/network';
import { CAMPAIGN_ENDPOINT } from '../constants/endpoints';

async function getCampaigns(data, authHeader) {
  const options = {
    headers: {
      ...DEFAULT_HEADER,
      ...authHeader,
    },
  };
  const { data: result, status } = await axios.get(CAMPAIGN_ENDPOINT, options);
  return { result, status };
}

async function getCampaign(data, authHeader) {
  const { id } = data;
  const options = {
    headers: {
      ...DEFAULT_HEADER,
      ...authHeader,
    },
  };
  const { data: result, status } = await axios.get(`${CAMPAIGN_ENDPOINT}/${id}`, options);
  return { result, status };
}

async function deleteCampaign(data, authHeader) {
  const { id } = data;
  const options = {
    headers: {
      ...DEFAULT_HEADER,
      ...authHeader,
    },
  };
  const { data: result, status } = await axios.delete(`${CAMPAIGN_ENDPOINT}/${id}`, options);
  return { result, status };
}

export default {
  getCampaigns,
  deleteCampaign,
  getCampaign,
};
