import React from 'react';
import PropTypes from 'prop-types';

const SvgVk = ({ fillColor, ...props }) => (
  <svg width={20} height={11.881} {...props}>
    <path
      fill={fillColor}
      d="M19.544.806c.137-.464 0-.806-.661-.806H16.7a.939.939 0 0 0-.95.62 18.324 18.324 0 0 1-2.688 4.474c-.509.509-.743.672-1.021.672-.137 0-.349-.163-.349-.627V.806c0-.557-.156-.806-.616-.806H7.634a.528.528 0 0 0-.557.5c0 .527.787.65.869 2.135v3.223c0 .705-.126.835-.4.835-.743 0-2.547-2.725-3.616-5.844-.215-.605-.427-.85-.988-.85H.75c-.624 0-.75.293-.75.62 0 .579.743 3.457 3.457 7.259a8.525 8.525 0 0 0 6.672 4c1.392 0 1.563-.312 1.563-.85 0-2.48-.126-2.714.572-2.714.323 0 .88.163 2.179 1.415 1.485 1.485 1.73 2.15 2.562 2.15h2.187c.624 0 .939-.312.757-.928-.416-1.3-3.226-3.962-3.353-4.14-.323-.416-.23-.6 0-.973 0 0 2.673-3.761 2.948-5.035z"
    />
  </svg>
);

SvgVk.propTypes = {
  fillColor: PropTypes.string,
};

SvgVk.defaultProps = {
  fillColor: '#ffffff',
};

export default SvgVk;
