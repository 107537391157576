import { createAction } from 'redux-actions';

const FETCH_MEDIA = "@media/FETCH_MEDIA";
const EMPTY_MEDIA = "@media/EMPTY_MEDIA";
const ADD_MEDIA = "@media/ADD_MEDIA";
const GET_MEDIA = "@media/GET_MEDIA";

export const emptyMedia = createAction(EMPTY_MEDIA);
export const fetchMedia = createAction(FETCH_MEDIA);
export const getMedia = createAction(GET_MEDIA)
export const addMedia1 = createAction(ADD_MEDIA)
