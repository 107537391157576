import { imageParser } from "../imageParser";
import { buttonParser } from "../buttonParser";
import { formParser } from "../formParser";
import { orderFormParser } from "../orderFormParser";
import { productCardParser } from "../productCardParser";
import { separatorParser } from "../separatorParser";
import { textParser } from "../textParser";
import { socialParser } from "../socialParser";
import { ratingParser } from "../ratingParser";
import { stepperParser } from "../stepperParser";
import { phoneVerificationParser } from "../phoneVerificationParser";
import { clickPayParser } from "../clickPayParser";
import { FORM_WIDGET_ELEMENT_CHILDREN_KEY } from "../../../constants";
import { collapseParser } from "../collapseParser";
import { cloneWidgetElement } from "../../../help/element";
import { createErrorBoundary } from "../../../constants/onsiteWidgets";

const itemTypeToParserMapping = {
    image: imageParser,
    form: formParser,
    orderForm: orderFormParser,
    button: buttonParser,
    separator: separatorParser,
    text: textParser,
    social: socialParser,
    rating: ratingParser,
    stepper: stepperParser,
    phoneVerification: phoneVerificationParser,
    clickPay: clickPayParser,
    collapse: collapseParser,
    productCard: productCardParser,
  };

/**
 * @type {OnsiteWidget.HtmlChildrenParser}
 */
const childrenParser = (children, id, type, horizontal, createNewIds = false) => {
    if (!children) return

    const elType = id.split("-")[0]
    if (elType === 'stepper' && children.length > 0) {
        children = children[0].children
    }
    return Array.from(children)
    .map((child, index) => {
        if (!child.id) return null
        const itemType = child.id.split("-")[0];
        const parser = itemTypeToParserMapping[itemType];
        let result
      
        if (!parser) return null

        try {
          let itemObject = parser(child, child.id, type, horizontal, { childrenParser });
          
          if (createNewIds) {
            itemObject = cloneWidgetElement(itemObject)
          }
      
          result = itemObject
        } catch (error) {
          result = createErrorBoundary({ text: error.toString(), targetType: itemType })
        }
      
        return {
            ...result,
            parentElement: {
              id,
              index
            }
        }
    })
    .filter(el => el)
  }

/**
 * @param {Parameters<OnsiteWidget.HtmlChildrenParser>} params
 */
const toOnsiteWidgetChildren = (...params) => {
  const result = childrenParser(...params)

  return {
    [FORM_WIDGET_ELEMENT_CHILDREN_KEY]: result
  }
}

export const createChildrenParser = (...savedArguments) => {
  return (...params) => toOnsiteWidgetChildren(...params, ...savedArguments)
}

export default toOnsiteWidgetChildren