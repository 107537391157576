import isEmpty from 'lodash/isEmpty';

import { DEFAULT_ELEMENT_VALUES, elements } from '../../constants/onsiteWidgets';
import { parseBoxShadow } from '../../help/itemStyles/boxShadow';
import CssClassesToSelectors from '../../help/itemStyles/CssClassesToSelectors';
import { classNames } from '../items/ClickPay';

const selectors = CssClassesToSelectors(classNames);

const defaultValues = DEFAULT_ELEMENT_VALUES[elements.CLICK_PAY];

const getButtonFields = (el) => {
  if (!el) return {};
  const text = el.innerHTML;
  const { color } = el.style;
  const { padding } = el.style;
  const { backgroundColor } = el.style;
  const { borderRadius } = el.style;
  const { fontSize } = el.style;
  const { fontFamily } = el.style;
  const { fontWeight } = el.style;
  const { textDecoration } = el.style;
  const { fontStyle } = el.style;
  const boxShadow = parseBoxShadow(el.style);
  const { maxWidth } = el.style;

  let paddingHorizontal = null;
  let paddingVertical = null;

  if (padding) {
    const paddingArray = padding.split(' ');
    paddingVertical = paddingArray[0];
    if (paddingArray.length === 1) {
      paddingHorizontal = paddingVertical;
    } else {
      paddingHorizontal = paddingArray[1];
    }
  }

  return {
    text,
    buttonStyles: Object.assign({},
      color && { color },
      paddingHorizontal && { paddingHorizontal },
      paddingVertical && { paddingVertical },
      backgroundColor && { backgroundColor },
      borderRadius && { borderRadius },
      fontSize && { fontSize },
      fontFamily && { fontFamily },
      fontWeight && { fontWeight },
      textDecoration && { textDecoration },
      fontStyle && { fontStyle },
      maxWidth && { maxWidth },
      boxShadow && { ...boxShadow }),
  };
};

const getInputFields = (el) => {
  if (!el) return {};
  const { fontSize } = el.style;
  const { fontFamily } = el.style;
  const { fontWeight } = el.style;
  const { textDecoration } = el.style;
  const { borderRadius } = el.style;
  const { fontStyle } = el.style;
  const { color } = el.style;
  const { backgroundColor } = el.style;
  const { borderColor } = el.style;

  return {
    inputStyles: {
      fontSize,
      fontFamily: fontFamily === '"Times New Roman"' ? 'Times New Roman' : fontFamily,
      fontWeight,
      textDecoration,
      borderRadius,
      fontStyle,
      color,
      backgroundColor,
      borderColor,
    },
  };
};

const getElementStyle = (el) => {
  if (!el) return {};
  const {
    borderRadius,
    backgroundColor,
    color,
    padding,
    margin,
    fontSize,
    fontFamily,
    fontWeight,
    fontStyle,
    textDecoration,
  } = el.style;

  let paddingHorizontal = null;
  let marginHorizontal = null;
  let marginVertical = null;
  let paddingVertical = null;

  if (padding) {
    const paddingArray = padding.split(' ');
    paddingVertical = paddingArray[0];
    if (paddingArray.length === 1) {
      paddingHorizontal = paddingVertical;
    } else {
      paddingHorizontal = paddingArray[1];
    }
  }

  if (margin) {
    const marginArray = margin.split(' ');
    marginVertical = marginArray[0];
    if (marginArray.length === 1) {
      marginHorizontal = marginVertical;
    } else {
      marginHorizontal = marginArray[1];
    }
  }

  return Object.assign({},
    borderRadius && { borderRadius },
    backgroundColor && { backgroundColor },
    color && { color },
    paddingHorizontal && { paddingHorizontal },
    paddingVertical && { paddingVertical },
    marginHorizontal && { marginHorizontal },
    marginVertical && { marginVertical },
    fontSize && { fontSize },
    fontFamily && { fontFamily },
    fontWeight && { fontWeight },
    fontStyle && { fontStyle },
    textDecoration && { textDecoration });
};

const getFormFields = (el) => {
  const orientation = el.style.flexDirection;
  return { orientation };
};

const getErrorFields = (el) => {
  if (!el) return {};

  const fields = getInputFields(el);

  return {
    errorStyles: {
      ...fields.inputStyles,
    },
  };
};

/**
 * @param {HTMLElement} el
 */
const getPhoneSectionFields = (el) => {
  if (!el) return {};

  const badgeTexts = {};
  let badgeStyles = {};
  const badges = el.querySelectorAll(selectors.badge);
  if (badges && badges.length > 0) {
    [...badges].map((item, index) => {
      if (isEmpty(badgeStyles)) {
        badgeStyles = getElementStyle(item);
        const { activeBgColor } = item.dataset;
        if (activeBgColor) {
          badgeStyles.activeBgColor = activeBgColor;
        }
      }
      badgeTexts[`badgeText${index + 1}`] = item.innerText;
    });
  }

  const helperText = el.querySelector('[data-editor-name="helperText"] span')?.innerHTML;
  const phoneInputPlaceholder = el.querySelector(selectors.phoneLabel)?.textContent;
  const amountInputStyles = getInputFields(el.querySelector(selectors.amount))?.inputStyles;
  const amountInputPlaceholder = el.querySelector(selectors.amountLabel)?.textContent;

  const errorStyles = getErrorFields(el.querySelector(selectors.errorContainer));

  const result = {
    amountInputStyles,
    phoneInputPlaceholder,
    amountInputPlaceholder,
    ...errorStyles,
    badgeTexts,
    badgeStyles,
    helperText,
  };

  return result;
};

/**
 * @param {HTMLElement} el
 */
const getCardSectionFields = (el) => {
  if (!el) return {};

  const cardStyles = getElementStyle(el);
  const cardInputStyles = getInputFields(el.querySelector(selectors.card))?.inputStyles;

  const cardInputPlaceholder = el.querySelector(selectors.cardLabel).textContent;
  const cardMonthInputPlaceholder = el.querySelector(selectors.cardMonthLabel).textContent;
  const cardYearInputPlaceholder = el.querySelector(selectors.cardYearLabel).textContent;

  let imageStyles = {};
  const image = {};
  const img = el.querySelector('img');
  if (img) {
    image.url = img.src;
    imageStyles = img.style;
  }

  const result = {
    cardStyles,
    cardInputStyles,
    imageStyles,
    image,
    cardInputPlaceholder,
    cardMonthInputPlaceholder,
    cardYearInputPlaceholder,
  };

  return result;
};

/**
 * @param {HTMLElement} el
 * @param {string} id
 */
export const clickPayParser = (el, id) => {
  const phoneSection = el.querySelector(selectors.phoneSection);
  const cardSection = el.querySelector(selectors.cardSection);
  const rowSection = el.querySelector(selectors.rowSection);
  const phoneSectionProps = getPhoneSectionFields(phoneSection);
  const cardSectionProps = getCardSectionFields(cardSection);
  const phoneInputLabel = el.querySelector(selectors.sectionTitle)?.innerHTML;
  const clickPayOtpLabel = el.querySelector(selectors.sectionTitleOtp)?.innerHTML;
  const clickPaySuccessText = el.querySelector(selectors.sectionTitleSuccess)?.innerHTML;
  const clickPayOtpSendBtn = el.querySelector(selectors.otpBtn)?.innerHTML;
  const clickBackButtonText = el.querySelector(selectors.buttonBack)?.innerHTML;
  const formFields = getFormFields(el);
  const isWidget = !!cardSection;

  const otpInputPlaceholder = el.querySelector(selectors.otpLabel)?.textContent;

  let maskCodeList = null;
  if (el.dataset.maskCodeList) {
    try {
      maskCodeList = JSON.parse(el.dataset.maskCodeList);
    } catch (error) {
      maskCodeList = null;
    }
  }

  const buttonStyle = getButtonFields(el.querySelector(selectors.depositBtn));

  const rowStyles = {};
  if (rowSection && rowSection.style.margin) {
    const marginArray = rowSection.style.margin.split(' ');
    rowStyles.marginVertical = marginArray[0];
    if (marginArray.length === 1) {
      rowStyles.marginHorizontal = rowStyles.marginVertical;
    } else {
      rowStyles.marginHorizontal = marginArray[1];
    }
  }

  return {
    ...defaultValues,
    type: 'clickPay',
    ...phoneSectionProps,
    ...formFields,
    ...cardSectionProps,
    ...buttonStyle,
    otpInputPlaceholder: otpInputPlaceholder || 'Input otp code',
    rowStyles,
    phoneInputLabel,
    clickPaySuccessText: clickPaySuccessText || 'Payment Successful',
    clickPayOtpLabel: clickPayOtpLabel || 'Enter otp code',
    clickPayOtpSendBtn: clickPayOtpSendBtn || 'Send Code',
    clickBackButtonText: clickBackButtonText || 'Back',
    isWidget,
    operator: el.dataset.mno,
    service: el.dataset.service,
    maxWidth: el.dataset.maxWidth,
    widthOption: el.dataset.widthOption,
    widthButtonOption: el.dataset.widthButtonOption,
    position: el.dataset.position,
    mask: el.dataset.mask,
    validators: el.dataset.validators,
    phonePlaceholder: el.dataset.placeholder,
    maskCodeList,
    phoneLength: el.dataset.phoneLength,
    orientation: el.dataset.orientation,
    code: el.dataset.code,
    activeTab: 'phone',
    id,
  };
};
