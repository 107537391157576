import { createAction } from 'redux-actions';
import { createRoutine, bindRoutineToReduxForm } from 'redux-saga-routines';

const CREATE_SMS_TREE = '@sms-tree/CREATE_SMS_TREE';
const FETCH_SMS_TREE = '@sms-tree/FETCH_SMS_TREE';
const PUT_SMS_TREE = '@sms-tree/PUT_SMS_TREE';
const UPDATE_SMS_TREE = '@sms-tree/UPDATE_SMS_TREE';
const HOLD_SMS_TREE = '@sms-tree/HOLD_SMS_TREE';
const ACTIVATE_SMS_TREE = '@sms-tree/ACTIVATE_SMS_TREE';
const FETCH_SMS_TREE_LIST = '@sms-tree/FETCH_SMS_TREE_LIST';
const PUT_SMS_TREE_LIST = '@sms-tree/PUT_SMS_TREE_LIST';
const PUT_SMS_TREE_CAMPAIGN_NAMES = '@sms-tree/PUT_CAMPAIGN_NAMES';
const VALIDATE_SMS_TREE_CAMPAIGN_NAME = '@sms-tree/VALIDATE_CAMPAIGN_NAME';
const PUT_SMS_TREE_VISITORS = '@sms-tree/PUT_VISITORS';

export const createSmsTreeRoutine = createRoutine(CREATE_SMS_TREE);
export const createSmsTreeHandler = bindRoutineToReduxForm(
  createSmsTreeRoutine,
);

export const activateSmsTree = createAction(ACTIVATE_SMS_TREE);
export const holdSmsTree = createAction(HOLD_SMS_TREE);

export const fetchSmsTree = createAction(FETCH_SMS_TREE);
export const putSmsTree = createAction(PUT_SMS_TREE);
export const updateSmsTree = createAction(UPDATE_SMS_TREE);

export const fetchSmsTreeList = createAction(FETCH_SMS_TREE_LIST);
export const putSmsTreeList = createAction(PUT_SMS_TREE_LIST);

export const putVisitors = createAction(PUT_SMS_TREE_VISITORS);

export const putCampaignNames = createAction(PUT_SMS_TREE_CAMPAIGN_NAMES);

export const validateCampaignName = createAction(
  VALIDATE_SMS_TREE_CAMPAIGN_NAME,
);
