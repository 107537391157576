import React, { createContext, useState, useCallback } from "react";

export const OfferWallContext = createContext({});

export const OfferWallProvider = ({ children }) => {
  const [wallState, setWallState] = useState({ offers: [] });
  const setOffers = useCallback(
    (offers) => {
      setWallState({ ...wallState, offers });
    },
    [wallState]
  );

  return (
    <OfferWallContext.Provider value={{ wallState, setWallState, setOffers }}>
      {children}
    </OfferWallContext.Provider>
  );
};
